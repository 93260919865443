import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

import form from './schemas/form';
import validations from './schemas/validations';
import {initialValues, initialValuesFromObj} from './schemas/initialValues';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import SuccessCreatedDialog from '../../../components/SuccessCreatedDialog';
import SnackNotification from '../../../components/SnackNotification';

import ShipForm from './components/ShipForm';

import { getShipById, addShip, updateShip } from '../../../Services/ShipService'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ShipCreator from '../../forms/shipCreationForm';


function getSteps() {
    return ["Informacion", "Direccion", "Contactos"];
  }

const CreateShip = ({action}) => {
    const params = useParams();
    const [openError, setOpenError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [inputs, setInputs] = useState();
    const [id, setId] = useState(0)
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const { formId, formField } = form;

    const isAdd = action === "add";
    const isUpdate = action === "update";
    const isView = action === "view";
    const handleBack = () => setActiveStep(activeStep - 1);
    const submitForm = async (values, actions) => {
        console.log(values);
        if(isAdd){
            addShip(values).then(resp => {
                console.log(resp.data)
                actions.setSubmitting(false);
                setOpenDialog(true)
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            }); }
        else if(isUpdate){
            updateShip(id, values).then(resp => {
                console.log(resp.data);
                setOpenDialog(true);
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            })
        }
        actions.setSubmitting(false);
    }

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
          submitForm(values, actions);
        } else {
          setActiveStep(activeStep + 1);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      };

    useEffect(()=>{
        if(params.id != null){
            setId(params.id)
            getShipById(params.id).then(resp => {
                console.log(resp.data)
                setInputs(resp.data)
            }).catch(err => {
                console.error(err);
            });
        }
    },[ ]);

    function getStepContent(stepIndex, formData) {
        switch (stepIndex) {
          case 0:
            return <ShipCreator isView={isView} formData={formData} />;
          default:
            return null;
        }
      }
      
    return (
        <MDBox pt={3} pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
                <Grid item xs={12} lg={8}>
                    <MDBox mt={6} mb={8} textAlign="center">
                        <MDBox mb={1}>
                            <MDTypography variant="h3" fontWeight="bold">
                            { isAdd && "Creacion de Nave" }
                            { isUpdate && "Edicion de Nave" }
                            { isView && "Visualizacion de Nave" }
                            </MDTypography>
                        </MDBox>
                        {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                            { inputs != null && inputs != undefined &&  inputs.part_id }
                        </MDTypography> */}
                    </MDBox>
                    <Formik
                        initialValues={ inputs != null && inputs != undefined ? initialValuesFromObj(inputs) : initialValues }
                        validationSchema={validations}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card>
                                    <MDBox p={3}>
                                    <Form id={formId} autoComplete="off">
                  <Card>
                    {/* <MDBox mt={-3} mx={2}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox> */}
                    <MDBox p={2}>
                      <MDBox>
                        {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                      {/*   <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="outlined" color="dark" onClick={handleBack}>
                              Atras
                            </MDButton>
                          )}
                            <MDButton
                              disabled={isSubmitting}
                              variant="gradient"
                              color="dark"
                              type="submit"
                            >
                              {isLastStep ? "Aceptar" : "Siguiente"}
                            </MDButton>
                        </MDBox> */}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
                                   {/*      <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                            <Link to="/ships/list" style={{ alignSelf: "start" }} >
                                                <MDButton variant="gradient" color="error" sx={{ height: "100%" }}>
                                                    Cancelar
                                                </MDButton>
                                            </Link>
                                            <MDButton
                                                disabled={isView}
                                                variant="gradient"
                                                color="success"
                                                type="submit"
                                                >
                                                Aceptar
                                            </MDButton>
                                        </MDBox> */}
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
            <SuccessCreatedDialog open={openDialog} message={ isUpdate ? "Nave Actualizada!" : "Nave Creada!" } route="/ships/list" />
            <SnackNotification  type="error" message="Ha ocurrido un error" closeCallback={console.log()} openFlag={openError} setOpenFlag={setOpenError} />
        </MDBox>
    )
}

export default CreateShip