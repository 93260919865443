import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        work_card,
        ata,
        ata_sect,
        task_number,
        description,
        remarks,
        mod,
        documentation,
        applicability,
        interval,
        margin,
        mpn,
        pn,
        limit_type,
        revision,
        ship,
        employee,
        revision_employee,
    }
} = checkout;

const validations = Yup.object().shape();

export default validations;