import checkout from "./form";

//   description
//   correction
//   date
//   date_repair
//   TTAP

const {
    formField: {
        TTAF, TTAW, calendarLimit, calendarWarning, cycle_ng, cycle_nf, cycleLimit, cycleWarning, workReportCorrection, remark
    }
} = checkout;

export const initialValues = {
    [TTAF.name]: "",
    [TTAW.name]: "",
    [calendarLimit.name]: "",
    [calendarWarning.name]: "",
    [cycle_ng.name]: "",
    [cycle_nf.name]: "",
    [cycleLimit.name]: "",
    [cycleWarning.name]: "",
    [workReportCorrection.name]: "",
    [remark.name]: "",
}

export const initialValuesFromObj = (obj) => {
    return {
        [TTAF.name]: obj.TTAF,
        [TTAW.name]: obj.TTAW,
        [calendarLimit.name]: obj.calendarLimit,
        [calendarWarning.name]: obj.calendarWarning,
        [cycle_ng.name]: obj.cycle_ng,
        [cycle_nf.name]: obj.cycle_nf,
        [cycleLimit.name]: obj.cycleLimit,
        [cycleWarning.name]: obj.cycleWarning,
        [workReportCorrection.name]: obj.workReportCorrection,
        [remark.name]: obj.remark,
    }
}
