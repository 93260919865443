import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

export const getReceptions = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/vehicle_reception/?reception_or_delivery='R'";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getReceptionsId = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = `/vehicle_reception/${id}/` ;
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getDeliversId = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/vehicle_entrega/" + id + "/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getDelivers = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/vehicle_entrega/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getShips = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/vehicle/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getAircraft = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/aircraft/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getTurbine = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/turbine/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getTypeAircraft = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/type_aircraft/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getShip = (id) => {
    let route = "/vehicle/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getPilots = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/pilot/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getEmployeeCard = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/employee_card/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getEmployeeCardID = (id) => {
    let route = "/employee_card/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}



export const getParty = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/parties/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getPartyID = (id) => {
    let route = "/parties/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const getCOTO = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/coto/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getCOTOID = (id) => {
    let route = "/coto/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}


export const getCOTORiskID = (id) => {
    let route = "/coto_risk/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const getIssues = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/issues/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getIssuesID = (id) => {
    let route = "/issues/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}









export const getLicenList = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/license_type/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getJobDescript = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/job_description/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getJobDescriptID = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/job_description/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getCarForm = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/car/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getCarFormID = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/car/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const deleteCarID = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/car/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers);
}

export const deleteJobDescriptID = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/job_description/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers);
}


export const getClients = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/clients/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getProviders = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/provider/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getEvaProviders = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/provider_evaluation/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getEvaProvidersID = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/provider_evaluation/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const list_evaluation = (id) => {
    let route = "/provider/list_evaluation/?id=".concat(id)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const deletegetEmployeeCardID = (id) => {
    let route = "/employee_card/".concat(id) + "/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getCertifications = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/certifications/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getCertificationsID = (id) => {
    let route = "/certifications/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const deleteCertificationsID = (id) => {
    let route = "/certifications/".concat(id) + "/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getCertificationsByEmployee = (id) => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/certifications/list_courser_by_participants/?id=".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}



export const getClientSatis = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/client_satisfaction/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getClientSatisID = (id) => {
    let route = "/client_satisfaction/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const deleteClientSatisID = (id) => {
    let route = "/client_satisfaction/".concat(id) + "/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getProcess = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/procces/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getUsers = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/procces/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}


export const getProcessID = (id) => {
    let route = "/procces/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const deleteProcessID = (id) => {
    let route = "/procces/".concat(id) + "/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getQualitySupplier = () => {
    //let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/supplier_quality/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const getQualitySupplierID = (id) => {
    let route = "/supplier_quality/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const deleteQualitySupplierID = (id) => {
    let route = "/supplier_quality/".concat(id) + "/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}