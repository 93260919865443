import { useState, useRef, useEffect } from "react";
import axios from "axios";
// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import { getReceptionsId } from "../../Services/formServices";
import Loader from "../../components/Loader";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";



function ReceptionForm({ action }) {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = day + "/" + month + "/" + year;


  const params = useParams();
  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);

  //info states
  const [vehicles, setVehicles] = useState([]);
  const [matricula, setMatricula] = useState();
  const [hourmtr, setHourmtr] = useState();
  const [type, setType] = useState();
  const [type2, setType2] = useState();
  const [pilots, setPilots] = useState([]);
  const [pilot, setPilot] = useState();
  const [serialN, setSerialN] = useState();
  const [serie, setSerie] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [ship, setShip] = useState([]);
  const [page, setPage] = useState(1);

  const [vehicle_model, setvehicle_model] = useState();
  const [vehicle_type, setvehicle_type] = useState();
  const [turbine_model, setvehicle_turbine] = useState();

  const [mgb, setmgb] = useState(1);
  const [tgb, settgb] = useState(1);
  const [hydraulic, sethydraulic] = useState(1);
  const [turbine, setturbine] = useState(1);

  








  //inspection states
  const [shipHours, setShipHours] = useState(0);
  const [turbHours, setTurbHours] = useState(0);
  const [last, setLast] = useState(0);
  const [NG, setNG] = useState(0);
  const [NF, setNF] = useState(0);
  const [usage, setUsage] = useState(0);

  //mantainance states
  const [shipMan, setShipMan] = useState([]);
  const [turbMan, setTurbMan] = useState([]);
  const [turbManOpt, setTurbManOpt] = useState();
  const [VEMD, setVEMD] = useState();
  const [repPilot, setRepPilot] = useState();

  //Documentos
  const [documents, setDocuments] = useState([]);

  //Pilot Report
  const [reported, setReported] = useState([]);
  const [problem, setProblem] = useState();
  const [problemDesc, setProblemDesc] = useState();
  const [addProblem, setAddProblem] = useState();
  const [addProblemReport, setaddProblemReport] = useState();
  

  //Works to do
  const [worksToDo, setWorksToDo] = useState();
  const [worksFinish, setWorksFinish] = useState([0, 0, 0, 0, 0, 0, 0]);

  //Articles in ship
  const [articlesInShip, setArticlesInShip] = useState();
  const [headsets, setHeadsets] = useState(0);

  //damages outside
  const [interiorPhotos, setInteriorPhotos] = useState([
    { name: "", file: null },
  ]);

  const [panelIns, setPanelIns] = useState();
  const [panelInsImg, setPanelInsImg] = useState();
  
  const [pilotChair, setPilotChair] = useState();
  const [pilotChairImg, setPilotChairImg] = useState();
  
  const [copilotChair, setCopilotChair] = useState();
  const [copilotChairImg, setCopilotChairImg] = useState();
  
  const [passengers, setPassengers] = useState();
  const [passengersImg, setPassengersImg] = useState();
  
  const [roofs, setRoofs] = useState();
  const [roofsImg, setRoofsImg] = useState();
  
  const [mats, setMatsI] = useState();
  const [matsImg, setMatsIImg] = useState();
  
  const [othersInt, setOtherInts] = useState();
  const [othersIntImg, setOtherIntsImg] = useState();

  //damages inside
  const [frontal, setFrontal] = useState();
  const [frontalimg, setFrontalImg] = useState();
  const [pilotSide, setPilotSide] = useState();
  const [pilotSideImg, setpilotSideImg] = useState();
  const [copilotSide, setCopilotSide] = useState();
  const [copilotSideImg, setCopilotSideImg] = useState();
  const [rear, setRear] = useState();
  const [rearImg, setRearImg] = useState();
  const [bluepale, setBluePale] = useState();
  const [bluepaleImg, setBluePaleImg] = useState();
  const [yellpale, setYellPale] = useState();
  const [yellpaleImg, setYellPaleImg] = useState();
  const [redpale, setRedPale] = useState();
  const [redpaleImg, setRedPaleImg] = useState();
  const [rotorcola, setRotorCola] = useState();
  const [rotorcolaImg, setRotorColaImg] = useState();
  const [others, setOthers] = useState();
  const [othersImg, setOthersImg] = useState();
  const [rear_hits, setrear_hitsImg] = useState();
  const [rear_hitsURL, setrear_hitsURL] = useState();

  
  //lights
  const [lightsOp, setLightsOp] = useState([{ name: "", option: "" }]);
  const [lightObs, setLightObs] = useState();

  const [lightsInShip, setLightInShip] = useState([0, 0, 0, 0, 0, 0]);

  //doors
  const [doorsOp, setdoorsOp] = useState([{ name: "", option: "" }]);
  const [doorsObs, setDoorsObs] = useState();

  const [doorsInShip, setDoorsInShip] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  //levels
  const [levelsOp, setLevelsOp] = useState([]);
  const [fuel, setFuel] = useState(0);

  //exterior
  const [extOp, setExtOp] = useState([0, 1, 0, 0, 1, 0, 0, 1, 0, 0]);
  const [extObs, setExtObs] = useState();
  const [elt_date, setelt_date] = useState();

  const [genObs, setGenObs] = useState();

  const [otherPhotos, setOtherPhotos] = useState();
  const [otherPhotosImg, setOtherPhotosImg] = useState();

  const [sign, setSign] = useState();
  const sigCanvas = useRef({});

  const handleSetObservations = (flag, content) => {
    if (flag === 1) {
      setLightObs(content);
    } else if (flag === 2) {
      setDoorsObs(content);
    } else if (flag === 3) {
      setExtObs(content);
    }
  };

  const addOptions = (flag) => {
    if (flag === 1) {
      setLightsOp([...lightsOp, { name: "", option: "" }]);
      console.log(addProblem);
    } else if (flag === 2) {
      setdoorsOp([...doorsOp, { name: "", option: "" }]);
    } else if (flag === 3) {
      setLevelsOp([...levelsOp, { name: "", option: "" }]);
    } else if (flag === 4) {
      setExtOp([...extOp, { name: "", option: "" }]);
    }
  };

  const addDescription = (flag) => {
    if (flag === 1) {
      setAddProblem([
        ...addProblem,
        { reported: "", problem: "", description: "" },
      ]);
      console.log(addProblem);
    }
  };

  const handleRemoveClick = (index, flag) => {
    if (flag === 1) {
      const list = [...addProblem];
      list.splice(index, 1);
      setAddProblem(list);
    }
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getReceptionsId(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    getAllShips();
    getAllPilots();
    (action === 'view') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);


  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([ab], { type: mimeString });
  }

  
  const saveSign = () => {
    setSign(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    
    const signatureCanvas = sigCanvas.current;
    const signatureImageBase64 = signatureCanvas.toDataURL();
  
    // Convierte la imagen base64 en un Blob
    const signatureBlob = dataURLtoBlob(signatureImageBase64);
  
    // Crea un objeto File a partir del Blob
    const signatureFile = new File([signatureBlob], 'signature.png'+params.id, { type: 'image/png' });
    setSign(signatureFile)
    // Ahora puedes enviar signatureFile al servidor o realizar cualquier acción que necesites
    console.log(signatureFile);

  };
  const userToken = localStorage.getItem("Token") || "";

  const getAllShips = () => {
    axios
      .get("https://fn.devpackgroup.com/api/v1/vehicle/", {
        headers: { Authorization: "Token " + userToken },
      })
      .then(
        function (response) {
          console.log(response.data);
          setVehicles(
            response.data.map((v) => ({
              value: v.id,
              label: v.registration,
              serie: v.serie,
              serieT: v.serieT,
              vehicle_model: v.vehicle_model,
              turbine_model: v.turbine_model,
              vehicle_type: v.vehicle_type,

            }))
          );
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
        }.bind(this)
      );
  };

  const getAllPilots = () => {
    axios
      .get("https://fn.devpackgroup.com/api/v1/pilot/", {
        headers: { Authorization: "Token " + userToken },
      })
      .then(
        function (response) {
          console.log(response.data);
          setPilots(
            response.data.map((v) => ({
              value: v.id,
              label:
                v.first_name +
                " " +
                v.second_name +
                " " +
                v.first_last_name +
                " " +
                v.second_last_name,
              phone: v.phone,
              email: v.email
            }))
          );
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
        }.bind(this)
      );
  };

  const createReception = () => {

    

    //console.log(outsidePhotos[0].file)
    const receptionData = new FormData();
    receptionData.append("vehicle", matricula);
    receptionData.append("pilot", pilot);
    receptionData.append("vehicle_hours", shipHours);
    receptionData.append("turbine_hours", turbHours);
    receptionData.append("last_flight", last);
    receptionData.append("ng_cycle", NG);
    receptionData.append("nf_cycle", NF);
    receptionData.append("usage", usage);
    receptionData.append("maintenancev", shipMan);
    receptionData.append("maintenancet", turbMan);
    receptionData.append("failed_vemd", turbManOpt);
    receptionData.append("observations_vemd", VEMD);
    receptionData.append("pilot_report", repPilot);
    receptionData.append("problem_reported", addProblemReport);

    
    receptionData.append("documents", documents.join(","));
    receptionData.append("problems", problem);
    receptionData.append("problems_desc", addProblem);
    receptionData.append("done_works", worksFinish.join(","));
    receptionData.append("pending_works", worksToDo);
    receptionData.append("articles_inside_vehicle", articlesInShip);
    receptionData.append("headsets", headsets);
    receptionData.append("lightnings", lightsInShip.join(","));
    receptionData.append("comments_about_lightnings", lightObs);
    receptionData.append("doors", doorsInShip.join(","));
    receptionData.append("comments_about_doors", doorsObs);
    receptionData.append("mgb", mgb);
    receptionData.append("tgb", tgb);
    receptionData.append("hydraulic", hydraulic);
    receptionData.append("turbine", turbine);
    receptionData.append("oil_gallons", fuel);
    receptionData.append("exterior", extOp.join(","));
    receptionData.append("elt_date", elt_date);
    receptionData.append("comments_about_exterior", extObs);
    receptionData.append("general_comments", genObs);
    receptionData.append("reception_or_delivery", "R");
    receptionData.append("path_to_picture_back_hits", rearImg);

    
    

    receptionData.append("path_to_picture_rear_hits", rear_hits);



    receptionData.append("path_to_picture_frontal_hits", frontalimg);
    receptionData.append("path_to_picture_pilots_side_hits", pilotSideImg);
    receptionData.append("path_to_picture_copilots_side_hits", copilotSideImg);
    receptionData.append("path_to_picture_blue_pala_hits", bluepaleImg);

    receptionData.append("path_to_picture_yellow_pala_hits", yellpaleImg);

    receptionData.append("path_to_picture_red_pala_hits", redpaleImg);

    receptionData.append("path_to_picture_tailor_rotor_hits", rotorcolaImg);

    receptionData.append("path_to_picture_others_hits", othersImg);

    receptionData.append("path_to_picture_instruments_panel", panelInsImg);

    receptionData.append("path_to_picture_pilots_chair", pilotChairImg);

    receptionData.append("path_to_picture_copilots_chair", copilotChairImg);

    receptionData.append("path_to_picture_passengers_chair", passengersImg);

    receptionData.append("path_to_picture_rooftop", roofsImg);

    receptionData.append("path_to_picture_carpets", matsImg);


    receptionData.append("path_to_picture_others_inside", othersIntImg);

    receptionData.append("path_to_picture_others", otherPhotosImg);


    receptionData.append("path_to_sign", sign);

    axios
      .post(
        "https://fn.devpackgroup.com/api/v1/vehicle_reception/",
        receptionData,
        {
          headers: { Authorization: "Token " + userToken },
        }
      )
      .then(
        function (response) {
          console.log(response.data);
          alert("Recepcion Creada Exitosamente")
          window.location.replace("/reception_data");
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
          alert("Error al crear Recepcion")
        }.bind(this)
      );
  };

  const answers = [
    { id: 1, label: "Si", value: "1" },
    { id: 2, label: "No", value: "0" },
  ];

  const answers1 = [
    { id: 1, label: "Si", value: "YES" },
    { id: 2, label: "No", value: "NO" },
    { id: 3, label: "N/A", value: "N" },
  ];

  const answers2 = [
    { id: 1, label: "OK", value: "1" },
    { id: 2, label: "NOT", value: "0" },
  ];

  const answers3 = [
    { id: 1, label: "Vacio", value: "E" },
    { id: 2, label: "Minimo", value: "MI" },
    { id: 3, label: "Ok", value: "O" },
    { id: 4, label: "Max", value: "MA" },
  ];

  const manNave = [
    { id: 1, label: "50 horas", value: "H50" },
    { id: 2, label: "150 horas // 12 meses", value: "H15012" },
    { id: 3, label: "12 meses", value: "M12" },
    { id: 4, label: "150 horas", value: "H150" },
    { id: 5, label: "24 meses", value: "M24" },
  ];

  const manTurb = [
    { id: 1, label: "Anual", value: "A" },
    { id: 2, label: "15 años", value: "15" },
    { id: 3, label: "30 horas", value: "30" },
    { id: 4, label: "50 horas", value: "50" },
    { id: 5, label: "100 horas", value: "100" },
  ];

  const naves = [
    { id: 1, label: "Helicoptero" },
    { id: 2, label: "Monomotor" },
    { id: 3, label: "Bimotor" },
  ];

  const reportes = [
    { id: 1, label: "Reportado Verbalmente" },
    { id: 2, label: "Reportado Telefonicamente" },
  ];

  const works = [
    { id: 1, label: "Chequeo de 50 horas" },
    { id: 2, label: "Chequeo de 100 horas" },
    { id: 3, label: "Trabajo solicitado por el dueño" },
    { id: 4, label: "Modificaciones STC" },
    { id: 5, label: "Control de tiempos limites de vida" },
    { id: 6, label: "Control de AD´s y service bulletins" },
    { id: 7, label: "Papeleria para renovación de tarjeta" },
  ];

  const articles = [
    { id: 1, label: "GPS Portatil" },
    { id: 2, label: "Radio Portatil" },
    { id: 3, label: "Alfombras" },
    { id: 4, label: "Neutralizadores de Olores" },
    { id: 5, label: "Litros de Aceite" },
    { id: 6, label: "Desarmadores" },
    { id: 7, label: "Protectores de sol" },
    { id: 8, label: "Bolsas de vomitar" },
    { id: 9, label: "Limpiador de ventanas" },
    { id: 10, label: "Cera de lustrar de aeronave" },
    { id: 11, label: "Computers de vuelo" },
    { id: 12, label: "Cobertores" },
    { id: 13, label: "Pilot Log Book" },
    { id: 14, label: "Candados de la nave" },
    { id: 15, label: "Pilot Cover" },
    { id: 16, label: "Tow Bar" },
    { id: 17, label: "Lona para tapar el avión" },
    { id: 18, label: "Maletín" },
    { id: 19, label: "Anteojos" },
    { id: 20, label: "Linterna" },
    { id: 21, label: "Fuel Tester" },
    { id: 22, label: "Toallas" },
    { id: 23, label: "Mapas" },
    { id: 24, label: "Lapiceros" },
    { id: 25, label: "Plotters" },
    { id: 26, label: "Libros de la aeronave" },
    { id: 27, label: "Extinguidor" },
  ];

  const lights = [
    { id: 1, label: "Luces Interior" },
    { id: 2, label: "Luces panel" },
    { id: 3, label: "Anticolisión" },
    { id: 4, label: "Luces posición" },
    { id: 5, label: "Luz de taxi" },
    { id: 6, label: "Luz de landing" },
  ];

  const doors = [
    { id: 1, label: "Puerta piloto" },
    { id: 2, label: "Puerta copiloto" },
    { id: 3, label: "Puerta carga derecha" },
    { id: 4, label: "Puerta carga izquierda" },
    { id: 5, label: "Puerta carga trasera" },
    { id: 6, label: "Cowling transmision izquierda" },
    { id: 7, label: "Cowling transmision derecha" },
    { id: 8, label: "Cowling flecha larga" },
    { id: 9, label: "Cowling TGB" },
    { id: 10, label: "Cowling motor" },
  ];

  const levels = [
    { id: 1, label: "Nivel MGB" },
    { id: 2, label: "Nivel TGB" },
    { id: 3, label: "Nivel hídraulico" },
    { id: 4, label: "Nivel turbina" },
  ];

  const exterior = [
    { id: 1, label: "Plataforma transmisión" },
    { id: 2, label: "Plataforma turbina" },
    { id: 3, label: "Estabilizador horizontal" },
    { id: 4, label: "Estabilizador vertical" },
    { id: 5, label: "Skies" },
    { id: 6, label: "Turbo Pilot" },
    { id: 7, label: "Antenas" },
    { id: 8, label: "Sensor de temperatura" },
    { id: 9, label: "Batería" },
    { id: 10, label: "ELT" },
  ];

  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Formulario de Recepcion de Naves
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              Los campos marcados con (*) son de caracter obligatorio
            </p>
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                {/* LISTO */}
                {page === 1 &&
                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Información{" "}
                        </p>
                        <br />
                        <MDInput
                          type="text"
                          label="Fecha *"
                          disabled={true}
                          variant="standard"
                          defaultValue={action === 'add' ? today : inputs && inputs.created_at}
                          fullWidth
                          //placeholder="Fecha *"
                          InputLabelProps={{ shrink: true }}
                        //onChange={handleGeoChange}
                        />
                      </MDBox>
                      <div style={{ display: 'flex' }}>
                        <MDBox width={'30%'} mb={2}>
                          {
                            action === 'add' ?
                              <Select
                                options={vehicles}
                                //isDisabled={action === 'view'? true : false}
                                //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                                onChange={(o) => {
                                  setMatricula(o.value);
                                  setType(o.serie);
                                  setType2(o.serieT);
                                  setvehicle_model(o.vehicle_model);
                                  setvehicle_type(o.vehicle_type);
                                  setvehicle_turbine(o.turbine_model);
                                }}
                              ></Select>
                              :
                              <Select
                                options={vehicles}
                                placeholder="Matricula *"
                                onChange={(o) => {
                                  setMatricula(o.value);
                                  setType(o.serie);
                                  setType2(o.serieT);
                                  setvehicle_model(o.vehicle_model);
                                  setvehicle_type(o.vehicle_type);
                                  setvehicle_turbine(o.turbine_model);
                                }}
                                isDisabled={action === 'view' ? true : false}
                                defaultValue={(action === 'view' || action === 'update') && inputs ? [(vehicles && vehicles.find(label => label.label === inputs.vehicle.registration))] : null}

                              ></Select>
                          }


                        </MDBox>
                        <br />
                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="text"
                            label="Tipo *"
                            variant="standard"
                            value={vehicle_type ? vehicle_type : inputs && inputs.vehicle && inputs.vehicle.aircraft_type.name}
                            fullWidth
                            placeholder={vehicle_type ? vehicle_type : "Tipo *"}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              //setType(o.target.value);
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />
                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="text"
                            label="Modelo de nave *"
                            variant="standard"
                            value={vehicle_model ? vehicle_model : inputs && inputs.vehicle && inputs.vehicle.aircraft.name}
                            fullWidth
                            placeholder={vehicle_model ? vehicle_model : "Modelo de nave*"}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <MDBox width={'30%'} mb={2}>
                          <MDInput
                            type="number"
                            label="Numero de Serie *"
                            variant="standard"
                            value={type ? type : inputs && inputs.vehicle && inputs.vehicle.serie}
                            fullWidth
                            placeholder={type ? type : "Numero de serie de nave*"}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />
                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="number"
                            label="Modelo de turbina *"
                            variant="standard"
                            value={turbine_model ? turbine_model : inputs && inputs.vehicle && inputs.vehicle.turbine.name}
                            fullWidth
                            placeholder={turbine_model ? turbine_model : inputs && inputs.vehicle && inputs.vehicle.turbine.name}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />

                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="number"
                            label="Numero de serie de turbina *"
                            variant="standard"
                            value={type2 ? type2 : inputs && inputs.vehicle && inputs.vehicle.serieT}
                            fullWidth
                            placeholder={type2 ? type2 : inputs && inputs.vehicle && inputs.vehicle.serieT}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />
                      </div>
                      <br />
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Piloto{" "}
                      </p>
                      <div style={{ display: 'flex' }}>
                        <MDBox width={'30%'} mb={2}>

                          {
                            action === 'add' ?
                              <Select
                                options={pilots}
                                //isDisabled={action === 'view'? true : false}
                                //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                                onChange={(o) => {
                                  setPilot(o.value);
                                  setPhone(o.phone);
                                  setEmail(o.email)
                                  console.log(o.value);
                                }}
                              ></Select>
                              :
                              <Select
                                options={pilots}
                                placeholder="Matricula *"
                                onChange={(o) => {
                                  setPilot(o.value);
                                  setPhone(o.phone);
                                  setEmail(o.email)
                                  console.log(o.value);
                                }}
                                isDisabled={action === 'view' ? true : false}
                                defaultValue={(action === 'view' || action === 'update') && inputs ? [(pilots && pilots.find(label => label.value === inputs.pilot.id))] : null}

                              ></Select>
                          }
                          {/*   <Select
                    options={pilots}
                    placeholder="Piloto*"
                    onChange={(o) => {
                      setPilot(o.value);
                      setPhone(o.phone);
                      setEmail(o.email)
                      console.log(o.value);
                    }}
                  ></Select> */}
                        </MDBox>
                        <br />
                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="text"
                            label="Email *"
                            variant="standard"
                            value={email ? email : inputs && inputs.pilot && inputs.pilot.email}
                            fullWidth
                            placeholder={email ? email : inputs && inputs.pilot && inputs.pilot.email}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>
                        <br />
                        <MDBox width={'30%'} ml={6} mb={2}>
                          <MDInput
                            type="number"
                            label="Telefono *"
                            variant="standard"
                            value={phone ? phone : inputs && inputs.pilot && inputs.pilot.phone}
                            fullWidth
                            placeholder={phone ? phone : inputs && inputs.pilot && inputs.pilot.phone}
                            InputLabelProps={{ shrink: true }}
                            onChange={(o) => {
                              setPhone(o.target.value);
                              console.log(o.target.value);
                            }}
                          />
                        </MDBox>

                      </div>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "right" }}>


                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(2);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>




                  </>


                }
                {/* LISTO*/}
                {page === 2 &&
                  <>


                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Inspección{" "}
                        </p>
                        <br />
                        <MDInput
                          type="number"
                          label="Horas Nave *"
                          variant="standard"
                          fullWidth
                          placeholder="Horas Nave *"
                          defaultValue={action === 'add' ? 0 : inputs.vehicle_hours}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setShipHours(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Horas turbina *"
                          variant="standard"
                          fullWidth
                          placeholder="Horas turbina *"
                          defaultValue={action === 'add' ? 0 : inputs.turbine_hours}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setTurbHours(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Último vuelo *"
                          variant="standard"
                          fullWidth
                          placeholder="Último vuelo *"
                          defaultValue={action === 'add' ? 0 : inputs.last_flight}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setLast(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Ciclos NG *"
                          variant="standard"
                          fullWidth
                          placeholder="Ciclos NG *"
                          defaultValue={action === 'add' ? 0 : inputs.ng_cycle}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setNG(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Ciclos NF *"
                          variant="standard"
                          fullWidth
                          placeholder="Ciclos NF *"
                          defaultValue={action === 'add' ? 0 : inputs.nf_cycle}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setNF(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="USAGE % *"
                          variant="standard"
                          fullWidth
                          placeholder="USAGE % *"
                          defaultValue={action === 'add' ? 0 : inputs.usage}
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setUsage(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(1);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(3);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>
                  </>
                }


                {page === 3 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Mantenimiento{" "}
                        </p>
                        <br />
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Mantenimiento Nave{" "}
                        </p>
                        <RadioGroup
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {manNave.map((index, key) => (
                            <>
                              {/* <li>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                shipMan.push(index.value);
                                console.log(shipMan);
                              } else if (e.target.checked === false) {
                                shipMan.splice(key, 1);
                                console.log(shipMan);
                              }
                            }}
                          />
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>
                        </li> */}
                              <>
                                {action === 'view' ?
                                  <FormControlLabel
                                    style={{ display: "flex", flexDirection: "row" }}
                                    value={index.value}
                                    label={index.label}
                                    checked={action === 'view' && (index.value === inputs.maintenancev) ? true : false}
                                    control={<Radio onClick={() => {
                                      shipMan[0] = index.value
                                      //jsonAnswers.metrics.at(0).rate = index.value
                                      // console.log(jsonAnswers.shipMan.at(0).rate)
                                    }} />}
                                  />
                                  :
                                  <FormControlLabel
                                    style={{ display: "flex", flexDirection: "row" }}
                                    value={index.value}
                                    label={index.label}
                                    //checked = {action === 'view' && inputs.punctuation.split(",")[0] ==  id? true : false}
                                    control={<Radio onClick={() => {
                                      shipMan[0] = index.value
                                      //jsonAnswers.metrics.at(0).rate = index.value
                                      // console.log(jsonAnswers.metrics.at(0).rate)
                                    }} />}
                                  />

                                }
                              </>
                            </>
                          ))}
                        </RadioGroup>
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Mantenimiento Turbina{" "}
                        </p>
                        <RadioGroup
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {manTurb.map((index, key) => (
                            <>
                              {/*  <li>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                turbMan.push(index.label);
                                console.log(turbMan);
                              } else if (e.target.checked === false) {
                                turbMan.splice(key, 1);
                                console.log(turbMan);
                              }
                            }}
                          />
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>
                        </li> */}
                              {action === 'view' ?
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  checked={action === 'view' && (index.value === inputs.maintenancet) ? true : false}
                                  control={
                                    <Radio onClick={() => {
                                      turbMan[key] = index.value
                                      //jsonAnswers.metrics.at(0).rate = index.value
                                      // console.log(jsonAnswers.shipMan.at(0).rate)
                                    }} />}
                                />
                                :
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  //checked = {action === 'view' && inputs.punctuation.split(",")[0] ==  id? true : false}
                                  control={<Radio onClick={() => {
                                    turbMan[0] = index.value
                                    //jsonAnswers.metrics.at(0).rate = index.value
                                    // console.log(jsonAnswers.metrics.at(0).rate)
                                  }} />}
                                />

                              }
                            </>
                          ))}
                        </RadioGroup>
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Falla VEMD{" "}
                        </div>
                        <br />
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers1.map((index) => (
                              <>
                                {
                                  action === 'view' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={action === 'view' && (index.value === inputs.failed_vemd) ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            setTurbManOpt(index.value);
                                            console.log(index.value);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      control={
                                        <Radio
                                          onClick={() => {
                                            setTurbManOpt(index.value);
                                            console.log(index.value);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={action === 'add' ? '' : inputs.observations_vemd}
                          type="text"
                          label="Observaciones VEMD"
                          variant="standard"
                          fullWidth
                          placeholder="Observaciones VEMD"
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setVEMD(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          defaultValue={action === 'add' ? '' : inputs.pilot_report}
                          label="Reporte Piloto"
                          variant="standard"
                          fullWidth
                          placeholder="Reporte Piloto"
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setRepPilot(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(2);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(4);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>

                  </>

                }
                {/* LISTO */}
                {page === 4 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >

                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Documentos{" "}
                        </p>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Cert. Autonavegabilidad{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}

                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[0] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents, 'docuamnts');
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}

                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[0] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />

                                }

                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Cert. Matricula{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[1] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[1] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }

                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Homologación ruido{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[2] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[2] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Licencia de radios{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[3] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[3] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Seguro{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[4] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[4] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Manual de Vuelo{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[5] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[5] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                        <br />
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Bitácora de Vuelos{" "}
                        </div>
                        <div style={{ marginLeft: "4vh" }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {answers.map((index, key) => (
                              <>
                                {
                                  action === 'add' ?
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.documents[6] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.documents[6] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            documents.push(index.value);
                                            console.log(index.value, documents);
                                          }}
                                        />
                                      }
                                    />
                                }
                              </>
                            ))}
                          </RadioGroup>
                        </div>
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(3);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(5);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>

                    </Card>

                  </>

                }


                {page === 5 &&

                  <>

                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Reporte del piloto de la Aeronave{" "}
                        </p>
                        <br />
                    
                            <>
                              <br />
                              <p style={{ fontSize: "1.5vh", textAlign: "justify" }}>
                                Reporte
                              </p>
                              <br />
                              <ul
                                style={{
                                  listStyleType: "none",
                                  display: "flex",
                                  fontSize: "1.3vh",
                                  flexDirection: "column",
                                  textAlign: "left",
                                }}
                              >
                                {reportes.map((index, key) => (
                                  <>
                                  {
                                    action === 'view'?
                                    <FormControlLabel
                                    style={{ display: "flex", flexDirection: "row" }}
                                    value={index.value}
                                    label={index.label}
                                  checked={(action === 'view' || action === 'update') && inputs && inputs.problem_reported === index.label ? true : false}
                                    control={
                                      <Radio
                                        onClick={() => {
                                          setaddProblemReport(index.label)
                                        }}
                                      />
                                    }
                                  />
                                  :
                                  <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  control={
                                    <Radio
                                      onClick={() => {
                                        setaddProblemReport(index.label)
                                      }}
                                    />
                                  }
                                />
                                  }
                                   
                                  </>
                                ))}
                              </ul>
                              <br />
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  label="Problema Reportado *"
                                  variant="standard"
                                  fullWidth
                                  placeholder="Problema Reportado *"
                                  InputLabelProps={{ shrink: true }}
                                  defaultValue={action === 'add' ? problem : inputs.problems}
                                  onChange={(o) => {
                                    setProblem(o.target.value);
                                    console.log(o.target.value);
                                  }}
                                />
                              </MDBox>
                              <br />

                              <MDBox mb={2}>
                                <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                                  {" "}
                                  Descripcion del problema{" "}
                                </p>
                                <br />
                                <textarea
                                  name=""
                                  id=""
                                  rows="10"
                                  style={{
                                    resize: "None",
                                    width: "100%",
                                    border: "solid black 1px",
                                    borderRadius: "8px",
                                    padding: "2vh",
                                    fontFamily: "Arial",
                                  }}
                                  defaultValue={action === 'add' ? '' : inputs.problems_desc}
                                  onChange={(o) => {
                                    setAddProblem(o.target.value)
                                    console.log(o.target.value);
                                  }}
                                />
                                <br />
                                <br />
                             {/*    {addProblem.length > 1 && (
                                  <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={() => {
                                      handleRemoveClick(key, 1);
                                    }}
                                  >
                                    Remove
                                  </MDButton>
                                )}{" "} */}
                                {/*    {addProblem.length - 1 === key && (
                                  <>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      onClick={() => addDescription(1)}
                                    >
                                      Agregar Problema
                                    </MDButton>
                                  </>
                                )} */}
                              </MDBox>
                              <br />
                              <hr />
                            </>
                    
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(4);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(6);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>

                    </Card>
                  </>

                }
                {/* LISTO */}
                {page === 6 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Trabajos pendientes{" "}
                        </p>
                        <br />
                        {/* <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {works.map((index, key) => (
                            <>
                              <li>
                                {
                                  action === 'add' ?
                                    <Checkbox
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.pending_works[key] === "1" ? true : false}

                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          worksToDo.splice(key, 1, 1);
                                          console.log(worksToDo);
                                        } else if (e.target.checked === false) {
                                          worksToDo.push(0);
                                          console.log(worksToDo);
                                        }
                                      }}
                                    />
                                    :
                                    <Checkbox
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.pending_works[key] === "1" ? true : false}

                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          worksToDo.splice(key, 1, 1);
                                          console.log(worksToDo);
                                        } else if (e.target.checked === false) {
                                          worksToDo.push(0);
                                          console.log(worksToDo);
                                        }
                                      }}
                                    />
                                }

                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  {index.label}
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul> */}
                            <MDInput
                          type="text"
                          defaultValue={action === 'add' ? '' : inputs.pending_works}
                          label="Servicio de Bateria"
                          variant="standard"
                          fullWidth
                          disabled={action === 'add' ? false : true}
                          placeholder="Servicio de Bateria"
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setWorksToDo(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(5);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(7);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>

                  </>

                }
                {/* LISTO */}
                {page === 7 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Articulos que se encontraron en el interior de la aeronave{" "}
                        </p>
                        <br />
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          <b> NOTA IMPORTANTE: </b>El taller no se hace responsable por
                          articulos dejados en la aeronave si no están debidamente señalados
                          en el listado y firmados por el cliente o el piloto{" "}
                        </p>
                        <br />
                        <MDInput
                          defaultValue={action === 'add' ? '' : inputs.articles_inside_vehicle}
                          type="text"
                          label="Articulos"
                          variant="standard"
                          fullWidth
                          placeholder="0"
                          InputLabelProps={{ shrink: true }}
                          disabled={action === 'add' ? false: true}
                          onChange={(o) => {
                            setArticlesInShip(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                   {/*      <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {articles.map((index, key) => (
                            <>
                              <li>
                                {
                                  action === 'add' ?
                                    <Checkbox
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.articles_inside_vehicle[key] === "1" ? true : false}

                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          articlesInShip.splice(key,1,1);
                                          console.log(articlesInShip);
                                        } else if (e.target.checked === false) {
                                          articlesInShip.push(0);
                                          console.log(articlesInShip);
                                        }
                                      }}
                                    />
                                    :
                                    <Checkbox
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.articles_inside_vehicle[key] === "1" ? true : false}

                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          //articlesInShip.splice(key,1,1);
                                          console.log(articlesInShip);
                                        } else if (e.target.checked === false) {
                                          articlesInShip.push(0);
                                          console.log(articlesInShip);
                                        }
                                      }}
                                    />
                                }
                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  {index.label}
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul> */}
                        <br />
                        <MDInput
                          defaultValue={action === 'add' ? 0 : inputs.headsets}
                          type="number"
                          label="Headsets"
                          variant="standard"
                          fullWidth
                          placeholder="0"
                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setHeadsets(o.target.value);
                            console.log(o.target.value);
                          }}
                        />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(6);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(8);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>

                  </>

                }


                {page === 8 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Golpes o rayones encontrados en fuselaje{" "}
                        </p>
                        <br />
                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Frontal"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setFrontal(URL.createObjectURL(e.target.files[0]));
                                  setFrontalImg(e.target.files[0]);
                                }}
                              />
                              {frontal ? <img style={{ width: '50%' }} src={frontal} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_frontal_hits} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Lado del Piloto"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setPilotSide(URL.createObjectURL(e.target.files[0]));
                                  setpilotSideImg(e.target.files[0]);
                                }}
                              />
                              {pilotSide ? <img style={{ width: '50%' }} src={pilotSide} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_pilots_side_hits} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Lado del Copiloto"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setCopilotSide(URL.createObjectURL(e.target.files[0]));
                                  setCopilotSideImg(e.target.files[0]);

                                }}
                              />
                              {copilotSide ? <img style={{ width: '50%' }} src={copilotSide} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_copilots_side_hits} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Parte Trasera"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setRear(URL.createObjectURL(e.target.files[0]));
                                  setRearImg(e.target.files[0]);
                                }}
                              />
                              {rear && <img style={{ width: '50%' }} src={rear} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Parte Trasera"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setrear_hitsURL(URL.createObjectURL(e.target.files[0]));
                                  setrear_hitsImg(e.target.files[0]);
                                }}
                              />
                              {rear_hits && <img style={{ width: '50%' }} src={rear_hits} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Pala Azul"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setBluePale(URL.createObjectURL(e.target.files[0]));
                                  setBluePaleImg(e.target.files[0]);
                                }}
                              />
                              {bluepale ? <img style={{ width: '50%' }} src={bluepale} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_blue_pala_hits} />}
                              <br />
                              <br />
                              {/* <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Pala Amarilla"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setYellPale(URL.createObjectURL(e.target.files[0]));
                                  setYellPaleImg(e.target.files[0]);
                                }}
                              />
                              {yellpale ? <img style={{ width: '50%' }} src={yellpale} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_yellow_pala_hits} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Pala Roja"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setRedPale(URL.createObjectURL(e.target.files[0]));
                                  setRedPaleImg(e.target.files[0]);
                                }}
                              />
                              {redpale ? <img style={{ width: '50%' }} src={redpale} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_red_pala_hits} />}
                              <br />
                              <br />
                              {/* <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Rotor de Cola"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setRotorCola(URL.createObjectURL(e.target.files[0]));
                                  setRotorColaImg(e.target.files[0]);
                                }}
                              />
                              {rotorcola ? <img style={{ width: '50%' }} src={rotorcola} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_tailor_rotor_hits} />}
                              <br />
                              <br />
                              {/* <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Otros"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setOthers(URL.createObjectURL(e.target.files[0]));
                                  setOthersImg(e.target.files[0]);
                                }}
                              />
                              {others ? <img style={{ width: '50%' }} src={others} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_others_hits} />}
                              <br />
                              <br />
                              {/*    <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                        </ul>
                        <br />
                      </MDBox>

                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(7);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(9);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>



                    </Card>

                  </>

                }
                {/* LISTO */}
                {page === 9 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>Interior </p>

                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Panel de instrumentos"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setPanelIns(URL.createObjectURL(e.target.files[0]));
                                  setPanelInsImg(e.target.files[0]);
                                }}
                              />
                              {action === 'add' ? (panelIns && <img style={{ width: '50%' }} src={panelIns} />) : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_instruments_panel} />}
                              <br />
                              <br />
                              {/*   <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Silla del Piloto"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setPilotChair(URL.createObjectURL(e.target.files[0]));
                                  setPilotChairImg(e.target.files[0]);
                                }}
                              />
                              {pilotChair ? <img style={{ width: '50%' }} src={pilotChair} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_pilots_chair} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Silla del Copiloto"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setCopilotChair(URL.createObjectURL(e.target.files[0]));
                                  setCopilotChairImg(e.target.files[0]);
                                }}
                              />
                              {copilotChair ? <img style={{ width: '50%' }} src={copilotChair} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_copilots_chair} />}

                              <br />
                              <br />
                              {/*   <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Silla del Pasajeros"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setPassengers(URL.createObjectURL(e.target.files[0]));
                                  setPassengersImg(e.target.files[0]);
                                }}
                              />
                              {passengers ? <img style={{ width: '50%' }} src={passengers} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_passengers_chair} />}

                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Techos"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setRoofs(URL.createObjectURL(e.target.files[0]));
                                  setRoofsImg(e.target.files[0]);
                                }}
                              />
                              {roofs ? <img style={{ width: '50%' }} src={roofs} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_rooftop} />}
                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Alfombras"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setMatsI(URL.createObjectURL(e.target.files[0]));
                                  setMatsIImg(e.target.files[0]);
                                }}
                              />
                              {mats ? <img style={{ width: '50%' }} src={mats} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_carpets} />}
                              <br />
                              <br />
                              {/* <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                          <li>
                            <FormLabel style={{ fontSize: "1.8vh" }}>
                              <br />
                              <MDInput
                                type="file"
                                label="Otros del interior"
                                variant="standard"
                                fullWidth
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  setOtherInts(URL.createObjectURL(e.target.files[0]));
                                  setOtherIntsImg(e.target.files[0]);
                                }}
                              />
                              {othersInt ? <img style={{ width: '50%' }} src={othersInt} /> : <img style={{ width: '50%' }} src={inputs && inputs.path_to_picture_others_inside} />}

                              <br />
                              <br />
                              {/*  <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Photo
                              </MDButton> */}
                              <br />
                              <br />
                              <hr />
                            </FormLabel>
                          </li>
                        </ul>
                        <br />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(8);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(10);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>

                    </Card>

                  </>

                }
                {/* LISTO */}
                {page === 10 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>Luces </p>
                        <br />
                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {lights.map((indexi, key) => (
                            <>
                              <li>
                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  <div
                                    style={{
                                      textAlign: "justify",
                                      width: "35%",
                                      fontSize: "1.5vh",
                                    }}
                                  >
                                    {indexi.label}{" "}
                                  </div>
                                  <br />
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    {answers.map((index) => (
                                      <>
                                        {
                                          action === 'view' ?
                                            <FormControlLabel
                                              style={{ display: "flex", flexDirection: "row" }}
                                              value={index.value}
                                              label={index.label}
                                              checked={(action === 'view' || action === 'update') && inputs && inputs.lightnings[key] === index.value ? true : false}

                                              control={
                                                <Radio
                                                  onClick={() => {
                                                    lightsInShip.splice(key, 1, 1)
                                                    console.log(index.value, lightsInShip);
                                                  }}
                                                />
                                              }
                                            />
                                            :
                                            <FormControlLabel
                                              style={{ display: "flex", flexDirection: "row" }}
                                              value={index.value}
                                              label={index.label}
                                              //checked={(action === 'view' || action === 'update') && inputs && inputs.lightnings[key] === index.value ? true : false}

                                              control={
                                                <Radio
                                                  onClick={(e) => {
                                                    if (e.target.value === '1') {
                                                      lightsInShip[key] = 1
                                                    }
                                                    else{
                                                      lightsInShip[key] = 0

                                                    }
                                                    console.log('sadfsdgsdgsd');
                                                    console.log(index.value, lightsInShip);
                                                  }}
                                                />
                                              }
                                            />
                                        }

                                      </>
                                    ))}
                                  </RadioGroup>
                                  <br />
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul>
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Observaciones Luces{" "}
                        </div>
                        <br />
                        <textarea
                          defaultValue={action === 'add' ? '' : inputs.comments_about_lightnings}
                          name=""
                          id=""
                          rows="10"
                          style={{
                            resize: "None",
                            width: "100%",
                            border: "1px solid black",
                            borderRadius: "8px",
                            padding: "2vh",
                            fontFamily: "Arial",
                          }}
                          onChange={(o) => handleSetObservations(1, o.target.value)}
                        />
                        <br />
                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(9);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(11);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>



                    </Card>

                  </>

                }
                {/* LISTO */}
                {page === 11 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Puertas / Cowlings{" "}
                        </p>
                        <br />
                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {doors.map((indexi, key) => (
                            <>
                              <li>
                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  <div
                                    style={{
                                      textAlign: "justify",
                                      width: "35%",
                                      fontSize: "1.5vh",
                                    }}
                                  >
                                    {indexi.label}{" "}
                                  </div>
                                  <br />
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    {answers2.map((index) => (
                                      <>
                                    {
                                      action === 'view'? 
                                      <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      checked={(action === 'view' || action === 'update') && inputs && inputs.doors[key] === index.value ? true : false}

                                      control={
                                        <Radio
                                          onClick={() => {
                                            doorsInShip.splice(key, 1, 1);
                                            console.log(index.value, doorsInShip);
                                          }}
                                        />
                                      }
                                    />
                                    :
                                    <FormControlLabel
                                    style={{ display: "flex", flexDirection: "row" }}
                                    value={index.value}
                                    label={index.label}
                                    //checked={(action === 'view' || action === 'update') && inputs && inputs.doors[key] === index.value ? true : false}

                                    control={
                                      <Radio
                                        onClick={(e) => {
                                          if (e.target.value === '1') {
                                            doorsInShip[key] = 1
                                          }
                                          else{
                                            doorsInShip[key] = 0

                                          }
                                            
                                          console.log(index.value, doorsInShip, 'asdadasdas');
                                          console.log(index.value, doorsInShip);
                                        }}
                                      />
                                    }
                                  />
                                    }
                                      </>
                                    ))}
                                  </RadioGroup>
                                  <br />
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul>
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Observaciones Puertas / Cowling{" "}
                        </div>
                        <br />
                        <textarea
                          defaultValue={action === 'add' ? '' : inputs.comments_about_doors}
                          name=""
                          id=""
                          rows="10"
                          style={{
                            resize: "None",
                            width: "100%",
                            border: "1px solid black",
                            borderRadius: "8px",
                            padding: "2vh",
                            fontFamily: "Arial",
                          }}
                          onChange={(o) => handleSetObservations(2, o.target.value)}
                        />

                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(10);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(12);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>

                  </>

                }
                {/* NO LISTO */}

                {page === 12 &&

                  <>

                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          Niveles aceite/combustible{" "}
                        </p>
                        <br />
                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {levels.map((indexi, key) => (
                            <>
                              <li>
                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  <div
                                    style={{
                                      textAlign: "justify",
                                      width: "35%",
                                      fontSize: "1.5vh",
                                    }}
                                  >
                                    {indexi.label}{" "}
                                  </div>
                                  <br />
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    {answers3.map((index) => (
                                      <>
                                        {
                                          action === 'view' ?
                                            <FormControlLabel
                                              style={{ display: "flex", flexDirection: "row" }}
                                              value={index.value}
                                              label={index.label}
                                              checked={(action === 'view' || action === 'update') &&
                                                (key === 0 ?
                                                  inputs && inputs.mgb === index.value ? true : false
                                                  :
                                                  key === 1 ?
                                                    inputs && inputs.tgb === index.value ? true : false
                                                    :
                                                    key === 2 ?
                                                      inputs && inputs.hydraulic === index.value ? true : false
                                                      :
                                                      inputs && inputs.turbine === index.value ? true : false
                                                )
                                              }

                                              control={
                                                <Radio
                                                  onClick={() => {
                                                    levelsOp.push(index.value);
                                                    (key === 0 ?
                                                      setmgb(index.value) 
                                                      :
                                                      key === 1 ?
                                                       settgb(index.value)
                                                        :
                                                        key === 2 ?
                                                          sethydraulic(index.value) 
                                                          :
                                                          setturbine(index.value)
                                                    )
                                                    console.log(levelsOp);
                                                  }}
                                                />
                                              }
                                            />
                                            :
                                            <FormControlLabel
                                              style={{ display: "flex", flexDirection: "row" }}
                                              value={index.value}
                                              label={index.label}
                                              //checked={(action === 'view' || action === 'update') && inputs && inputs.mgb === index.value ? true : false}

                                              control={
                                                <Radio
                                                onClick={() => {
                                                  levelsOp.push(index.value);
                                                  (key === 0 ?
                                                    setmgb(index.value) 
                                                    :
                                                    key === 1 ?
                                                     settgb(index.value)
                                                      :
                                                      key === 2 ?
                                                        sethydraulic(index.value) 
                                                        :
                                                        setturbine(index.value)
                                                  )
                                                  console.log(levelsOp);
                                                }}
                                                />
                                              }
                                            />
                                        }
                                      </>
                                    ))}
                                  </RadioGroup>
                                  <br />
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul>
                        <MDInput
                          type="number"
                          label="Galones de combustible *"
                          variant="standard"
                          fullWidth
                          placeholder="0"
                          defaultValue={action === 'add' ? fuel : inputs.oil_gallons}

                          InputLabelProps={{ shrink: true }}
                          onChange={(o) => {
                            setFuel(o.target.value);
                            console.log(o.target.value);
                          }}
                        />

                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(11);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(13);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>


                    </Card>
                  </>

                }
                {/*  LISTO */}
                {page === 13 &&

                  <>

                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>Exterior</p>
                        <br />
                        <ul
                          style={{
                            listStyleType: "none",
                            display: "flex",
                            fontSize: "1.3vh",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          {exterior.map((indexi, key) => (
                            <>
                              <li>
                                <FormLabel style={{ fontSize: "1.8vh" }}>
                                  <div
                                    style={{
                                      textAlign: "justify",
                                      width: "35%",
                                      fontSize: "1.5vh",
                                    }}
                                  >
                                    {indexi.label}{" "}
                                  </div>
                                  <br />
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    {answers2.map((index, keyA) => (
                                      <>
                                      {
                                        action === 'view'?
                                        <FormControlLabel
                                        style={{ display: "flex", flexDirection: "row" }}
                                        value={index.value}
                                        label={index.label}
                                        checked={(action === 'view' || action === 'update') && inputs && inputs.exterior[key] === index.value ? true : false}
                                        control={
                                          <Radio
                                            onClick={() => {
                                              //extOp.push(index.value);
                                              console.log(extOp);
                                            }}
                                          />
                                        }
                                      />
                                      :
                                      <FormControlLabel
                                      style={{ display: "flex", flexDirection: "row" }}
                                      value={index.value}
                                      label={index.label}
                                      //checked={(action === 'view' || action === 'update') && inputs && inputs.exterior[key] === index.value ? true : false}
                                      control={
                                        <Radio
                                          onClick={() => {
                                            extOp[key]= (index.value);
                                            console.log(extOp);
                                          }}
                                        />
                                      }
                                    />
                                      }
                                      </>
                                    ))}
                                  </RadioGroup>
                                  <br />
                                </FormLabel>
                              </li>
                            </>
                          ))}
                        </ul>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              //setdateB(e)
                              const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                              setelt_date(formattedDate)
                              console.log(formattedDate)

                            }}
                            disabled={action === 'view'}
                            defaultValue={(action === 'update') && inputs ? dayjs(inputs.elt_date) : null}
                            value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.elt_date, 'DD/MM/YYYY') : null}
                            label="Fecha de Vencimiento ELT:" />
                        </DemoContainer>
                      </LocalizationProvider>
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                        >
                          Observaciones Exterior{" "}
                        </div>
                        <br />
                        <textarea
                          defaultValue={action === 'add' ? '' : inputs.comments_about_exterior}
                          name=""
                          id=""
                          rows="10"
                          style={{
                            resize: "None",
                            width: "100%",
                            border: "1px solid black",
                            borderRadius: "8px",
                            padding: "2vh",
                            fontFamily: "Arial",
                          }}
                          onChange={(o) => handleSetObservations(3, o.target.value)}
                        />

                      </MDBox>
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(12);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            setPage(14);
                          }}
                        >
                          Siguiente Pagina
                        </MDButton>

                      </div>

                    </Card>


                  </>

                }

                {page === 14 &&

                  <>
                    <Card
                      variant="gradient"
                      style={{ padding: '4vh' }}
                    >
                      <MDBox mb={2}>
                        <div
                          style={{ textAlign: "justify", width: "35%", fontSize: "2vh" }}
                        >
                          Observaciones Generales{" "}
                        </div>
                        <br />
                        <textarea
                          defaultValue={action === 'add' ? '' : inputs.general_comments}
                          name=""
                          id=""
                          rows="10"
                          style={{
                            resize: "None",
                            width: "100%",
                            border: "1px solid black",
                            borderRadius: "8px",
                            padding: "2vh",
                            fontFamily: "Arial",
                          }}
                          onChange={(o) => setGenObs(o.target.value)}
                        />
                        <br />
                      </MDBox>
                      <br />

                      <FormLabel style={{ fontSize: "1.8vh" }}>
                        <br />
                        <MDInput
                          type="file"
                          label="Otras fotos"
                          variant="standard"
                          fullWidth
                          placeholder="0"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setOtherPhotos(e.target.files[0]);
                          }}
                        />
                        {
                          otherPhotos ? <img width={'50%'} src={otherPhotos} alt="other" /> : <img width={'50%'} src={inputs && inputs.path_to_picture_others} alt="other" />
                        }
                        <br />
                        <br />
                        {/*  <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => addDescription(2)}
                        >
                          Add Photo
                        </MDButton> */}
                        <br />
                        <br />
                        <hr />
                      </FormLabel>
                      <br />

                      <MDBox mb={2}>
                        <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                          {" "}
                          Firma Digital{" "}
                        </p>
                        <br />
                       {action === 'add' &&  <SignatureCanvas
                          ref={sigCanvas}
                          penColor="blue"
                          canvasProps={{ width: 600, height: 200, className: "signature" }}
                          style={{ background: "white" }}
                        />}
                        {
                          sign ? <img width={'50%'} src={sign} alt="other" /> : <img width={'50%'} src={inputs && inputs.path_to_sign} alt="other" />
                        }
                        <br />
                       {action === 'add' &&  <MDButton variant="gradient" color="info" onClick={saveSign}>
                          Guardar Firma
                        </MDButton>}
                      </MDBox>
                      <br />
                    {action === 'add' &&  <MDButton
                        variant="gradient"
                        color="success"
                        onClick={() => {

                          createReception();
                        }}
                      >
                        Recibir Nave
                      </MDButton>}
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "left" }}>

                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => {
                            setPage(13);
                          }}
                        >
                          Pagina Anterior
                        </MDButton>

                      </div>



                    </Card>

                  </>

                }


              </MDBox>
            </MDBox>
          </>
      }
    </>
  );
}

export default ReceptionForm;
