import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { Divider } from '@mui/material';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';


// Wizard page components
import AssemblyForm from './components/CreateShipPartForm';


import {
  addShipPart,
  getShipPartById,
  updateShipPart,
} from '../../../Services/ShipService';


const CreateShipPart = ({ action }) => {
  const { id } = useParams();
  const { formId, formField } = form;

  const navigate = useNavigate();

  
  const [inputs, setInputs] = useState();
  const [searchparams] = useSearchParams();
  const aircraftId = searchparams.get('aircraft');
  const assemblyId = searchparams.get('assembly');


  const isAdd = action === 'add';
  const isUpdate = action === 'update';
  const isView = action === 'view';


  const handleSubmit = async (values, actions) => {
    const newValues = {...values, aircraft: aircraftId};

    if (assemblyId !== undefined){
      newValues.assembly = assemblyId;
    }

    if (isAdd) {
      const { data } = await addShipPart(newValues);
      navigate(`/ships/shipparts/view/${data.id}`, { replace: true });
    }
     else if (isUpdate) {
      const { data } = await updateShipPart(newValues, id);
      navigate(`/ships/shipparts/view/${data.id}`, { replace: true });
    }
    actions.setSubmitting(false);
  };


  useEffect(() => {
    if (id != null) {
      getShipPartById(id)
        .then((resp) => {
          setInputs(resp.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id]);

  return (
    <MDBox pt={3} pb={8}>
      <Grid container justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign='center'>
            <MDBox mb={1}>
              <MDTypography variant='h3' fontWeight='bold'>
                {isAdd && 'Creacion de Ship part'}
                {isUpdate && 'Edicion de Ship part'}
                {isView && 'Visualizacion de Ship part'}
              </MDTypography>
            </MDBox>
            {inputs != null && inputs !== undefined && (
              <div>
                <MDBox
                  mb={1}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    No. {inputs.id}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Date: {inputs.created_at}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Created By: {inputs.user.name}
                  </MDTypography>
                </MDBox>
                <Divider sx={{ margin: '0.5rem 0' }} />
              </div>
            )}
          </MDBox>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <AssemblyForm
                      isView={isView}
                      isUpdate={isUpdate}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <Link
                        to={`/ships/aircraft/view/${aircraftId}`}
                        style={{ alignSelf: 'start' }}
                      >
                        <MDButton
                          variant='gradient'
                          color='error'
                          sx={{ height: '100%' }}
                        >
                          Cancelar
                        </MDButton>
                      </Link>
                      <MDButton
                        disabled={isView}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default CreateShipPart;
