
import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

export const getStaffList = () => {
    let route = "/staff_manager/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getStaffById = () => {
    let route = "/staff_manager/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}
export const addStaff = (data) => {
    let route = "/staff_manager/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const updateStaff = (id, data) => {
    let route = "/staff_manager/".concat(id.concat("/"));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "name" || key === "initial" || key === "authorization" ){
            
            formData.append(key, data[key]);
            
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const deleteStaff = (id) => {
    let route = "/staff_manager/".concat(id).concat("/");
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}