const form = {
    formId: "new-invoice-form",
    formField: {
        client: {
            name: "client",
            label: "Customer",
            type: "text",
            errorMsg: "Campo requerido",
        },
        ATTA_code: {
            name: "ATTA_code",
            label: "ATTA Code",
            type: "text",
            errorMsg: "Campo requerido",
        },
        ship: {
            name: "ship",
            label: "Aircraft",
            type: "text",
            errorMsg: "Campo requerido",
        },

        description: {
            name: "description",
            label: "Description",
            type: "text",
            errorMsg: "Campo requerido",
        },
        type: {
            name: "type",
            label: "type",
            type: "text",
            errorMsg: "Campo requerido",
        },
        currency: {
            name: "currency",
            label: "Currency",
            type: "text",
            errorMsg: "Campo requerido",
        },

        subject: {
            name: "subject",
            label: "Subject",
            type: "text",
            errorMsg: "Campo requerido",
        },
        tax: {
            name: "tax",
            label: "Tax",
            type: "text",
            errorMsg: "Campo requerido",
        },
        reference: {
            name: "reference",
            label: "Reference Correlative",
            type: "text",
            errorMsg: "Campo requerido",
        },
    }
}

export default form;