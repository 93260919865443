import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

// ---------- HTTP Calls

export const getTasks = (page, filter, value) => {
    let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/task/?".concat("page=".concat(page)).concat(filterStr);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getTaskDetail = (page, taskId) => {
    let route = "/task_detail/?".concat("page=".concat(page)).concat("&task_id=".concat(taskId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getTaskList = () => {
    let route = "/task/get_tasks_list";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getTaskById = (id) => {
    let route = "/task/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addTask = (data) => {
    let route = "/task/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const updateTask = (id, data) => {
    let route = "/task/".concat(id.concat("/"));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "ship"){
            if(typeof data[key] === "object"){
                formData.append(key, data[key].id);
            }
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const addTaskDetail = (data) => {
    let route = "/task_detail/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getSubTasks = (page, taskId) => {
    let route = "/sub_task/?".concat("page=".concat(page)).concat("&task__id=".concat(taskId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addSubTask = (task, data) => {
    let route = "/sub_task/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    formData.append("task", task)
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const updateSubTask = (id, data) => {
    let route = "/sub_task/".concat(id).concat("/");
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "ship"){
            if(typeof data[key] === "object"){
                formData.append(key, data[key].id);
            }
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const getSubTaskById = (id) => {
    let route = "/sub_task/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getSubTasksDetails = (page, subTaskId) => {
    let route = "/sub_task_detail/?".concat("page=".concat(page)).concat("&sub_task__id=".concat(subTaskId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addSubTaskDetail = (data) => {
    let route = "/sub_task_detail/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getTaskWarning = (aircraftId) => {
    let route = "/task/get_warning_task/?" + "aircraft_id=".concat(aircraftId);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const fetchBluePrintTasks = (pageId) => {
    let route = "/blueprint_task/?page=".concat(pageId);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const getShipTasks = (aircraftId) => {
    console.log('el id es:', aircraftId)
    let route = "/blueprint_task/?ship_id=".concat(aircraftId);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const createBPtask = (ship, ata, desc, cond, active) => {
    let route = "/blueprint_task/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession())}
    let formData = new FormData();
    formData.append('ship', ship);
    formData.append('ata_code', ata);
    formData.append('task_description', desc);
    formData.append('conditional', cond);
    formData.append('active', active);
    return doPost(route, formData, headers);
}