import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import { getTasks, getTaskDetail, addTaskDetail } from '../../Services/TaskService'
import { buildData } from '../../utils/tableData'
import { taskHeaders, taskItemsHeaders } from '../../utils/tableHeaders'

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import { Typography, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid';

import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import MDBox from '../../components/MDBox'
import DataTable from '../../components/DataTable'
import DeleteDialog from '../../components/DeleteDialog';
import ContentDialog from '../../components/ContentDialog';
import SnackNotification from '../../components/SnackNotification';

const Task = () => {
    const [task, setTask] = useState([]);
    const [taskPage, setTaskPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState();
    const [taskLoading, setTaskLoading] = useState(true);

    const [idSelected, setIdSelected] = useState()
    const [details, setDetails] = useState([])
    const [currentSecondPage, setCurrentSecondPage] = useState(1);
    const [totalSecondPages, setTotalSecondPages] = useState();
    const [secondLoading, setSecondLoading] = useState(false);

    const [openCreate, setopenCreate] = useState(false)
    const [inputs, setInputs] = useState({});
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    
    const loadTask = (page, filter, value) => {
        getTasks(page, filter, value).then((resp) => {
            setTask(buildData(resp.data.tasks, taskHeaders()));
            setTaskPage(parseInt(resp.data.current_page));
            setTaskTotalPages(resp.data.pages);
            setTaskLoading(false);
        }).catch((err) => {
            console.error(err.response);
        });
    };

    const loadTaskDetail = (page, taskId) => {
        getTaskDetail(page, taskId).then(resp => {
            console.log(resp.data)
            setDetails(buildData(resp.data.task_details, taskItemsHeaders()))
            setCurrentSecondPage(parseInt(resp.data.current_page))
            setTotalSecondPages(resp.data.pages)
            setSecondLoading(false)
        }).catch((err) => {
            console.error(err.response);
        });
    }

    const handleTaskPagination = (event, value) => {
        setTaskPage(value);
        loadTask(value);
    };

    const handleSecondPagination = (event, value) => {
        setCurrentSecondPage(value)
        loadTaskDetail(value, idSelected)
    }

    // Menu and Filters
    const [filter, setFilter] = useState();
    const [filterLabel, setFilterLabel] = useState("Filtros");
    const [valueFilter, setValueFilter] = useState();

    const filters = [
        {label:"No.", value:"id"},
        {label:"ATTA", value:"ata"},
        {label:"Description", value:"description"},
    ]

    const getFilterLabel = (value) => {
        let label;
        filters.forEach(option => {
            if(option.value === value){
                label = option.label;
            }
        })
        return label;
    }

    const handleSearch = (value) => {
        console.log("search", value)
        setValueFilter(value);
        loadTask(1, filter, value)
    }

    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => {
        setMenu(null)
    };
    const handleChangeFilter = (value) => {
        setFilter(value);
        setFilterLabel(getFilterLabel(value))
        setMenu(null)
        closeMenu()

    };
    const clearFilter = () => {
        setMenu(null);
        setFilter()
        setFilterLabel("Filtros")
        loadTask(1)
        closeMenu()
    }

    const viewRegister = (task) => {
        window.location.replace('/task/view/'.concat(task.id));
    };

    const editRegister = (task) => {
        window.location.replace('/task/update/'.concat(task.id));
    };

    const selectRegister = (task) => {
        setIdSelected(task.id)
        loadTaskDetail(1, task.id)
    }

    const handleChange = (event, type) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value})) ;
    }

    const closeModals = () => {
        setopenCreate(false)
        setInputs({})
    }

    const createDetail = () => {
        addTaskDetail(inputs).then(resp => {
            console.log(resp.data)
            setOpenSuccess(true)
            loadTaskDetail(1, idSelected)
            closeModals()
        }).catch((err) => {
            console.error(err);
            setOpenError(true)
        })
    }

    useEffect(()=>{
        loadTask(1)
    },[])

    const renderMenu = (
        <Menu
          anchorEl={menu}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(menu)}
          onClose={closeMenu}
          keepMounted
        >
            {
                filters.map((option)=>(
                    <MenuItem key={option.value} onClick={()=> handleChangeFilter(option.value)} value={option.value} >{option.label}</MenuItem>
                ))
            }
          {/* <MenuItem onClick={()=> handleChange('nombre')} value="nombre" >Nombre</MenuItem>
          <MenuItem onClick={()=> handleChange('correo')} value="correo" >Correo</MenuItem> */}
          <Divider sx={{ margin: "0.5rem 0" }} />
          <MenuItem onClick={clearFilter}>
            <MDTypography variant="button" color="error" fontWeight="regular">
              Eliminar filtro
            </MDTypography>
          </MenuItem>
        </Menu>
    );

    return (
        <div>
            <Typography variant="h4" component="div">
                Task
            </Typography>
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <Link to="/task/create">
                        <MDButton variant="gradient" color="info">
                            Agregar 
                        </MDButton>
                    </Link>
                    <MDBox display="flex">
                        <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                        { filterLabel }&nbsp;
                        <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                        <MDButton variant="outlined" color="dark">
                            <Icon>description</Icon>
                            &nbsp;export csv
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Card>
                    { task.rows !== undefined && task.rows.length > 0 && (<><DataTable handleSearch={handleSearch} 
                     useActions useView useEdit useSelect selectAction={selectRegister} editAction={editRegister} viewAction={viewRegister} table={task} showTotalEntries={false} entriesPerPage={false} canSearch />
                    <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={taskTotalPages} page={taskPage} onChange={handleTaskPagination} /> </MDBox> </>) }  
                    { taskLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                    { task.rows !== undefined &&  task.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                </Card>
            </MDBox>
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    { idSelected &&
                        <MDButton variant="gradient" color="info" onClick={()=>{setopenCreate(true); setInputs({ task:idSelected })}}>
                            Agregar 
                        </MDButton>
                    }
                    {/* <MDBox display="flex">
                        <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                        Filtros&nbsp;
                        <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                        <MDButton variant="outlined" color="dark">
                            <Icon>description</Icon>
                            &nbsp;export csv
                        </MDButton>
                        </MDBox>
                    </MDBox> */}
                </MDBox>
                <Card>
                    { details.rows !== undefined && details.rows.length > 0 && 
                        <><DataTable useActions  table={details} showTotalEntries={false} entriesPerPage={false} 
                        /> 
                        <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={totalSecondPages} page={currentSecondPage} onChange={handleSecondPagination} /> </MDBox> </>}  
                    { secondLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                    { details.rows !== undefined &&  details.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                </Card>
            </MDBox>

            <ContentDialog open={openCreate} title={"Task Detail"}  closeCallback={closeModals} maxWidth="md" fullWidth={true} >
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={1.5}>
                                <TextField value={inputs.inventory_detail || ""} onChange={handleChange} sx={{ width: "100%" }} id="inventory_detail" InputProps={{name: "inventory_detail" }} label="Item" variant="outlined" />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    <MDBox mt={2}>
                        <MDButton
                            variant="gradient"
                            color="success"
                            type="submit"
                            onClick={createDetail}
                            >
                                Aceptar
                        </MDButton>
                    </MDBox>
                </MDBox>
            </ContentDialog>

            <SnackNotification  type="error" message="Ha ocurrido un error" closeCallback={console.log()} openFlag={openError} setOpenFlag={setOpenError} />
            <SnackNotification  type="success" message="Parte creado" closeCallback={console.log()} openFlag={openSuccess} setOpenFlag={setOpenSuccess} />
        </div>
    )
}

export default Task