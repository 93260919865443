import checkout from "./form";

const {
    formField: {
        inventory,
        tracker_id,
        serial_number,
        qty,
        u_m,
        cost,
        freight,
        retail,
        location,
        used_state,
        supplier,
        condition,
        base,
        market_price,
        purchase_price,
        total_cost,
        currency,
        sale_price,
        bill_id,
        waybill,
        po_number,
        ps_number,
        expiration_date,
        creation_date,
        register,
        equipment,
        alt_1,
        alt_2,
        alt_3,
        alt_4,
        alt_5,
        currency_from,
        rate,
        control_code,
        adjusted_rate,
        adjusted_market_price,
        adjusted_sale_price,
        adjusted_freight,
        adjusted_total_cost,
        adjusted_total_price,
        file_Logcard,
        file_8130,
        file_FORM1,
        condition_state
    }
} = checkout;

export const initialValues = {
    [inventory.name]:"",
    [tracker_id.name]:"",
    [serial_number.name]:"",
    [qty.name]:"",
    [u_m.name]:"",
    [cost.name]:"",
    [freight.name]:"",
    [retail.name]:"",
    [location.name]:"",
    [used_state.name]:"",
    [supplier.name]:{label: "", id: ""},
    [condition.name]:"",
    [base.name]:"",
    [market_price.name]:"",
    [purchase_price.name]:"",
    [total_cost.name]:"",
    [currency.name]:"",
    [sale_price.name]:"",
    [bill_id.name]:"",
    [waybill.name]:"",
    [po_number.name]:"",
    [ps_number.name]:"",
    [expiration_date.name]:"",
    [creation_date.name]:"",
    [register.name]:"",
    [equipment.name]:"",
    [alt_1.name]:"",
    [alt_2.name]:"",
    [alt_3.name]:"",
    [alt_4.name]:"",
    [alt_5.name]:"",
    [currency_from.name]:"",
    [rate.name]:"",
    [control_code.name]:"",
    [adjusted_rate.name]:"",
    [adjusted_market_price.name]:"",
    [adjusted_sale_price.name]:"",
    [adjusted_freight.name]:"",
    [adjusted_total_cost.name]:"",
    [adjusted_total_price.name]:"",
    [file_Logcard.name]:false,
    [file_8130.name]:false,
    [file_FORM1.name]:false,
    [condition_state.name]:"",
}

export const initialValuesWithInventory = (inventoryId, usedState) => {
    return {
        [inventory.name]:inventoryId || "",
        [tracker_id.name]:"",
        [serial_number.name]:"",
        [qty.name]:"",
        [u_m.name]:"",
        [cost.name]:"",
        [freight.name]:"",
        [retail.name]:"",
        [location.name]:"",
        [used_state.name]:usedState || "",
        [supplier.name]:{label: "", id: ""},
        [condition.name]:"",
        [base.name]:"",
        [market_price.name]:"",
        [purchase_price.name]:"",
        [total_cost.name]:"",
        [currency.name]:"",
        [sale_price.name]:"",
        [bill_id.name]:"",
        [waybill.name]:"",
        [po_number.name]:"",
        [ps_number.name]:"",
        [expiration_date.name]:"",
        [creation_date.name]:"",
        [register.name]:"",
        [equipment.name]:"",
        [alt_1.name]:"",
        [alt_2.name]:"",
        [alt_3.name]:"",
        [alt_4.name]:"",
        [alt_5.name]:"",
        [currency_from.name]:"",
        [rate.name]:"",
        [control_code.name]:"",
        [adjusted_rate.name]:"",
        [adjusted_market_price.name]:"",
        [adjusted_sale_price.name]:"",
        [adjusted_freight.name]:"",
        [adjusted_total_cost.name]:"",
        [adjusted_total_price.name]:"",
        [file_Logcard.name]:false,
        [file_8130.name]:false,
        [file_FORM1.name]:false,
        [condition_state.name]:"",
    }
}

export const initialValuesFromObj = (obj) => {
    let resp = {
        [inventory.name]:obj.inventory.id || "",
        [tracker_id.name]:obj.tracker_id,
        [serial_number.name]:obj.serial_number,
        [qty.name]:obj.qty,
        [u_m.name]:obj.u_m,
        [cost.name]:obj.cost,
        [freight.name]:obj.freight,
        [retail.name]:obj.retail,
        [location.name]:obj.location,
        [used_state.name]:obj.used_state,
        [supplier.name]:{label: obj.supplier.Company || "", id: obj.supplier.id || ""},
        [condition.name]:obj.condition,
        [base.name]:obj.base,
        [market_price.name]:obj.market_price,
        [purchase_price.name]:obj.purchase_price,
        [total_cost.name]:obj.total_cost,
        [currency.name]:obj.currency,
        [sale_price.name]:obj.sale_price,
        [bill_id.name]:obj.bill_id,
        [waybill.name]:obj.waybill,
        [po_number.name]:obj.po_number,
        [ps_number.name]:obj.ps_number,
        [expiration_date.name]:obj.expiration_date,
        [creation_date.name]:obj.creation_date,
        [register.name]:obj.register,
        [equipment.name]:obj.equipment,
        [alt_1.name]:obj.alt_1,
        [alt_2.name]:obj.alt_2,
        [alt_3.name]:obj.alt_3,
        [alt_4.name]:obj.alt_4,
        [alt_5.name]:obj.alt_5,
        [currency_from.name]:obj.currency_from,
        [rate.name]:obj.rate,
        [control_code.name]:obj.control_code,
        [adjusted_rate.name]:obj.adjusted_rate,
        [adjusted_market_price.name]:obj.adjusted_market_price,
        [adjusted_sale_price.name]:obj.adjusted_sale_price,
        [adjusted_freight.name]:obj.adjusted_freight,
        [adjusted_total_cost.name]:obj.adjusted_total_cost,
        [adjusted_total_price.name]:obj.adjusted_total_price,
        [file_Logcard.name]:obj.file_Logcard,
        [file_8130.name]:obj.file_8130,
        [file_FORM1.name]:obj.file_FORM1,
        [condition_state.name]:obj.condition_state,
    }
    return resp;
}