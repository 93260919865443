import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import MDButton from '../../components/MDButton';
import MDBox from '../../components/MDBox';
import DataTable from '../../components/DataTable';
import ContentDialog from '../../components/ContentDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import {
  Event,
  InsertDriveFile,
  Book,
  ContentPaste,
  Segment,
  AccessTime,
  Scale,
  AirplanemodeInactive,
  Description,
  History,
  Engineering,
} from '@mui/icons-material';

import { getShipById } from '../../Services/ShipService';
import {
  getTasks,
  getTaskDetail,
  getSubTasks,
  getTaskWarning
} from '../../Services/TaskService';
import { getLabors, getMiscs } from '../../Services/InvoicieService';
import { buildData } from '../../utils/tableData';
import {
  taskHeaders,
  taskItemsHeaders,
  laborHeaders,
  micsHeaders,
  subTaskHeaders,
  logsHeaders,
  warningHeaders
} from '../../utils/tableHeaders';
import { getAllLogs } from '../../Services/MainBoard';
import CreateLogs from './CreateLogs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Board = ({ isAircraft }) => {
  const { id } = useParams();
  console.log(id)

  const [ship, setShip] = useState({});
  const [task, setTask] = useState([]);
  const [taskPage, setTaskPage] = useState(1);
  const [taskTotalPages, setTaskTotalPages] = useState();
  const [taskLoading, setTaskLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [idSelected, setIdSelected] = useState();
  const [details, setDetails] = useState([]);
  const [currentSecondPage, setCurrentSecondPage] = useState(1);
  const [totalSecondPages, setTotalSecondPages] = useState();
  const [secondLoading, setSecondLoading] = useState(false);

  const [labors, setLabors] = useState([]);
  const [laborPage, setLaborPage] = useState(1);
  const [laborTotalPages, setLaborTotalPages] = useState();
  const [laborLoading, setLaborLoading] = useState(true);

  const [misc, setMisc] = useState([]);
  const [miscPage, setMiscPage] = useState(1);
  const [miscTotalPages, setMiscTotalPages] = useState();
  const [miscLoading, setMiscLoading] = useState(true);

  const [subTask, setSubTask] = useState([]);
  const [subTaskPage, setSubTaskPage] = useState(1);
  const [subTaskTotalPages, setSubTaskTotalPages] = useState();
  const [subTaskLoading, setSubTaskLoading] = useState(true);

  const [logs, setlogs] = useState(null);
  const [currentlogsPage, setCurrentlogsPage] = useState(1);
  const [totallogsPages, setTotallogsPages] = useState();
  const [showLogsDialog, setShowLogsDialog] = useState(true);

  const [warnings, setWarnings] = useState(null);
  const [currentWarningPage, setCurrentWarningPage] = useState(1);
  const [totalWarningPages, setTotalWarningPages] = useState();

  const navigate = useNavigate();

  const loadLogs = async (page) => {
    const { data } = await getAllLogs(page, id);
    console.log(data)
    setlogs(buildData(data.flight_logs, logsHeaders()));
    setCurrentlogsPage(parseInt(data.current_page));
    setTotallogsPages(data.pages);
  };
  const loadWarnings = async () => {
    const { data } = await getTaskWarning(id);
    setWarnings(buildData(data.tasks_alerts, warningHeaders()));
    setCurrentWarningPage(parseInt(data.current_page));
    setTotalWarningPages(data.pages);
    console.log('LA DATA WARNING', data)
  };

  const handleChangePageLogs = (event, value) => {
    setCurrentlogsPage(value);
    loadLogs(value);
  };

  useEffect(() => {
    loadLogs(1);
    loadWarnings();
  }, []);

  const loadShip = async () => {
    try {
      const { data } = await getShipById(id);
      console.log(data);
      setShip(data);
      console.log(data, 'ship')
    } catch (err) {
      console.error(err);
    }
  };

  const loadTaskList = async (page) => {
    try {
      const { data } = await getTasks(page, 'aircraft__id', id);
      setTask(buildData(data.tasks, taskHeaders()));
      setTaskPage(parseInt(data.current_page));
      setTaskTotalPages(data.pages);
      setTaskLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const loadTaskParts = async (page, taskId) => {
    try {
      const { data } = await getTaskDetail(page, taskId);
      setDetails(buildData(data.task_details, taskItemsHeaders()));
      setCurrentSecondPage(parseInt(data.current_page));
      setTotalSecondPages(data.pages);
      setSecondLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const setTotalLabor = (laborList) => {
    let newList = [];
    laborList.forEach((element) => {
      newList.push({
        ...element,
        total: parseInt(element.rate) * parseInt(element.hours),
      });
    });
    return newList;
  };

  const loadLabors = async (page, taskId) => {
    try {
      const { data } = await getLabors(page, taskId);
      setLabors(buildData(setTotalLabor(data.invoice_labors), laborHeaders()));
      setLaborPage(parseInt(data.current_page));
      setLaborTotalPages(data.pages);
      setLaborLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const loadMiscs = async (page, taskId) => {
    try {
      const { data } = await getMiscs(page, taskId);
      setMisc(buildData(data.invoice_misc, micsHeaders()));
      setMiscPage(parseInt(data.current_page));
      setMiscTotalPages(data.pages);
      setMiscLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const loadSubTasks = async (page, taskId) => {
    try {
      const { data } = await getSubTasks(page, taskId);
      setSubTask(buildData(data.sub_tasks, subTaskHeaders()));
      setSubTaskPage(parseInt(data.current_page));
      setSubTaskTotalPages(data.pages);
      setSubTaskLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTaskPagination = (event, value) => {
    setTaskPage(value);
    loadTaskList(value);
  };

  const handleSecondPagination = (event, value) => {
    setCurrentSecondPage(value);
    loadTaskParts(value, idSelected);
  };

  const handleMiscPagination = (event, value) => {
    setLaborPage(value);
    loadLabors(value, idSelected);
  };

  const handleLaborPagination = (event, value) => {
    setMiscPage(value);
    loadMiscs(value, idSelected);
  };

  const handleSubTaskPagination = (event, value) => {
    setTaskPage(value);
    loadSubTasks(value, idSelected);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadShip();
    loadTaskList(1);
    loadWarnings();

  }, []);

  const [openNeeds, setOpenNeeds] = useState(false);
  const [openAdds, setOpenAdds] = useState(false);

  const closeModals = () => {
    setOpenNeeds(false);
    setOpenAdds(false);
  };

  const closeSelectors = () => {
    setOpenTaskSelector(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openTaskSelector, setOpenTaskSelector] = useState(false);
  const [viewType, setViewType] = useState(false);

  const openSelectTask = (type) => {
    setViewType(type);
    setOpenTaskSelector(true);
  };

  const viewAction = (value, type) => {
    setIdSelected(value.id);
    switch (viewType) {
      case 'refs':
        openRefs(value.id);
        break;
      case 'needs':
        openNeedsTask(value.id);
        break;
      case 'adds':
        openAdditionalWork(value.id);
        break;
      default:
        break;
    }
  };

  const openRefs = (task) => {};

  const openNeedsTask = (task) => {
    loadTaskParts(1, task);
    loadLabors(1, task);
    loadMiscs(1, task);
    setOpenNeeds(true);
    loadWarnings();
  };

  const openAdditionalWork = (task) => {
    loadSubTasks(1, task);
    setOpenAdds(true);
  };

  return (
    <>
    
    <Typography variant="h4" component="div">
      Aircraft: {id}
      </Typography>
      <br></br>
      <Grid container spacing={2}>
        <Grid container item xs={12} sx={{ marginBottom: '50px' }}>
          <Box
            direction='row'
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '15px',
            }}
          >
            {isAircraft && (
              <>
                <MDButton variant='outlined' color='dark' startIcon={<Event />}>
                  Forecast List
                </MDButton>
                <Link to={`/mainboard/${id}/work-reports`}>
                  <MDButton
                    variant='outlined'
                    color='dark'
                    startIcon={<InsertDriveFile />}
                  >
                    Work Reports
                  </MDButton>
                </Link>
                {/* <Link to={`/mainboard/${id}/logs/`}> */}
                <MDButton variant='outlined' color='dark' startIcon={<Book />}>
                  eLogBook
                </MDButton>
                {/* </Link> */}
              </>
            )}

            <Link to={`/mainboard/${id}/inspections${isAircraft ? '-aircraft': '-templete'}`}>
              <MDButton
                variant='outlined'
                color='dark'
                startIcon={<ContentPaste />}
                onClick={() => console.log('inspections')}
              >
                Inspections
              </MDButton>
            </Link>

            <Link to={`/mainboard/${id}/components/`}>
              <MDButton variant='outlined' color='dark' startIcon={<Segment />}>
                Components
              </MDButton>
            </Link>

            <Link to={`/mainboard/${id}/ads/`}>
              <MDButton
                variant='outlined'
                color='dark'
                startIcon={<AccessTime />}
              >
                AD's
              </MDButton>
            </Link>

            {isAircraft && (
              <>
                <MDButton variant='outlined' color='dark' startIcon={<Scale />}>
                  Weight/Balance
                </MDButton>
                <Link to={`/mainboard/${id}/deffects/`}>
                  <MDButton
                    variant='outlined'
                    color='dark'
                    startIcon={<AirplanemodeInactive />}
                  >
                    Defects
                  </MDButton>
                </Link>
              </>
            )}

            <MDButton
              variant='outlined'
              color='dark'
              startIcon={<Description />}
            >
              Documents
            </MDButton>

            {isAircraft && (
              <>
                <MDButton
                  variant='outlined'
                  color='dark'
                  startIcon={<History />}
                >
                  History
                </MDButton>
                <MDButton
                  variant='outlined'
                  color='dark'
                  startIcon={<Engineering />}
                >
                  Compute
                </MDButton>
              </>
            )}

            <Link to={`/mainboard/${id}/tasks/`}>
              <MDButton
                variant='outlined'
                color='dark'
                startIcon={<Engineering />}
              >
                Task manager
              </MDButton>
            </Link>

            <MDButton variant='outlined' color='dark' startIcon={<Book />}>
              Reports
            </MDButton>
          </Box>
        </Grid>
        <Grid container item xs={6} spacing={1}>
          <Typography variant='h6' component='div'>
            Aircraft Data
          </Typography>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <Card>
                <Table sx={{ minWidth: '100%' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Registration
                      </TableCell>
                      <TableCell align='right'>{ship.register}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Make
                      </TableCell>
                      <TableCell align='right'>{ship.make}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Type
                      </TableCell>
                      <TableCell align='right'>0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        TTAF
                      </TableCell>
                      <TableCell align='right'>0</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Table sx={{ minWidth: '100%' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Serial Number
                      </TableCell>
                      <TableCell align='right'>{ship.serial_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Model
                      </TableCell>
                      <TableCell align='right'>{ship.model}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Year mfg
                      </TableCell>
                      <TableCell align='right'>{ship.year}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Landing
                      </TableCell>
                      <TableCell align='right'>1</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Typography variant='h6' component='div'>
            inspect Information
          </Typography>
          <TableContainer component={Card} sx={{ width: '100%' }}>
            <Table sx={{ maWidth: '100%' }}>
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Last Insp.</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Performed</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Nextg Insp.</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Due</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Remaining</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Hours</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Calendar</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cycle</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                  <TableCell align='center'>0</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        
        <Grid container item xs={12}>
          <Typography variant='h6' component='div'>
            Warnings
          </Typography>
          <TableContainer component={Card} sx={{ width: '100%' }}>

            
          <Table sx={{ maWidth: '100%' }}>
          {warnings ?
              <>
                <DataTable
                  useView
                  useEdit
                  table={warnings}
                  showTotalEntries={false}
                  entriesPerPage={false}
                  // canSearch
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={totalWarningPages}
                    page={currentWarningPage}
                    //onChange={handleChangePageW}
                  />{' '}
                </MDBox>{' '}
              </>
            :<>Loading...</>}
            </Table>

          </TableContainer>
        </Grid>

        <ContentDialog
          open={openTaskSelector}
          title={'Tasks'}
          closeCallback={closeSelectors}
          maxWidth='md'
          fullWidth={true}
        >
          <Card>
            {task.rows !== undefined && task.rows.length > 0 && (
              <>
                <DataTable
                  useActions
                  useView
                  viewAction={viewAction}
                  table={task}
                  showTotalEntries={false}
                  entriesPerPage={false}
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={taskTotalPages}
                    page={taskPage}
                    onChange={handleTaskPagination}
                  />{' '}
                </MDBox>{' '}
              </>
            )}
            {taskLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress color='info' size={80} />
              </Box>
            )}
            {task.rows !== undefined && task.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen registros
              </Typography>
            )}
          </Card>
        </ContentDialog>

        <ContentDialog
          open={openNeeds}
          title={'Necesidades para completar'}
          closeCallback={closeModals}
          maxWidth='md'
          fullWidth={true}
        >
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
              >
                <Tab label='Part' {...a11yProps(0)} />
                <Tab label='Labour' {...a11yProps(1)} />
                <Tab label='Misc' {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Card>
                {details.rows !== undefined && details.rows.length > 0 && (
                  <>
                    <DataTable
                      useActions
                      table={details}
                      showTotalEntries={false}
                      entriesPerPage={false}
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={totalSecondPages}
                        page={currentSecondPage}
                        onChange={handleSecondPagination}
                      />{' '}
                    </MDBox>{' '}
                  </>
                )}
                {secondLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )}
                {details.rows !== undefined && details.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen registros
                  </Typography>
                )}
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card>
                {labors.rows !== undefined && labors.rows.length > 0 && (
                  <>
                    <DataTable
                      table={labors}
                      showTotalEntries={false}
                      entriesPerPage={false}
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={laborTotalPages}
                        page={laborPage}
                        onChange={handleLaborPagination}
                      />{' '}
                    </MDBox>
                  </>
                )}
                {laborLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )}
                {labors.rows !== undefined && labors.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen registros
                  </Typography>
                )}
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card>
                {misc.rows !== undefined && misc.rows.length > 0 && (
                  <>
                    <DataTable
                      table={misc}
                      showTotalEntries={false}
                      entriesPerPage={false}
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={miscTotalPages}
                        page={miscPage}
                        onChange={handleMiscPagination}
                      />{' '}
                    </MDBox>
                  </>
                )}
                {miscLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )}
                {misc.rows !== undefined && misc.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen registros
                  </Typography>
                )}
              </Card>
            </TabPanel>
          </Box>
        </ContentDialog>

        <ContentDialog
          open={openAdds}
          title={'SubTaks'}
          closeCallback={closeModals}
          maxWidth='md'
          fullWidth={true}
        >
          <Card>
            {subTask.rows !== undefined && subTask.rows.length > 0 && (
              <>
                <DataTable
                  table={subTask}
                  showTotalEntries={false}
                  entriesPerPage={false}
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={subTaskTotalPages}
                    page={subTaskPage}
                    onChange={handleSubTaskPagination}
                  />{' '}
                </MDBox>{' '}
              </>
            )}
            {subTaskLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress color='info' size={80} />
              </Box>
            )}
            {subTask.rows !== undefined && subTask.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen registros
              </Typography>
            )}
          </Card>
        </ContentDialog>
      </Grid>

      <ContentDialog
        open={showLogsDialog && isAircraft}
        title='Logs'
        maxWidth='lg'
        closeCallback={() => setShowLogsDialog(false)}
      >
        <CreateLogs />
        {/* {logs && (
          <>
            <Card>
              {logs.rows !== undefined && logs.rows.length > 0 && (
                <>
                  <DataTable
                    //   handleSearch={handleSearch}
                    // useActions
                    useView
                    useEdit
                    // useDelete
                    // useSelect
                    // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                    editAction={(data) =>
                      navigate(
                        `/ships/shipparts/update/${data.id}?aircraft=${id}`
                      )
                    }
                    viewAction={(data) =>
                      navigate(`/ships/shipparts/view/${data.id}`)
                    }
                    // deleteAction={deleteRegister}
                    table={logs}
                    showTotalEntries={false}
                    entriesPerPage={false}
                    // canSearch
                  />
                  <MDBox ml={1}>
                    <Pagination
                      sx={{ marginTop: '20px', marginBottom: '20px' }}
                      color='info'
                      count={totallogsPages}
                      page={currentlogsPage}
                      onChange={handleChangePageLogs}
                    />{' '}
                  </MDBox>{' '}
                </>
              )} */}
        {/* {shipPartsLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )} */}
        {/* {logs?.rows !== undefined && logs?.rows.length === 0 && (
                <Typography
                  variant='h4'
                  component='div'
                  sx={{ margin: '100px' }}
                >
                  No Existen logs
                </Typography>
              )} */}
        {/* </Card>
          </>
        )} */}
      </ContentDialog>
    </>
  );
};

export default Board;
