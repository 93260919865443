import React, { useState, useEffect } from 'react'
import axios from "axios";
// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "./formLayout";

import { getAircraft, getIssuesID, getPartyID, getShip, getTurbine, getTypeAircraft } from "../../Services/formServices";
import { getShipById, addShip, updateShip } from '../../Services/ShipService'

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from '../../components/Loader';
import FileViewer from "react-file-viewer";



function ShipCreator({ isView, formData, isUpdate, action }) {
  const params = useParams();
  const [matricula, setMatricula] = useState();
  const [serie, setSerie] = useState();
  const [aircraft, setAircraft] = useState();
  const [turbine, setTurbine] = useState();
  const [typeAircraft, setTypeAircraft] = useState();
  const [serieT, setSerieT] = useState();
  const [vtype, setVtypeT] = useState();
  const [vtypeT, setVtype] = useState();
  const [v2type, setV2type] = useState();
  const [subtype, setSubtype] = useState();
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [imageAir, setImageAir] = useState();
  const [imageRe, setImageRe] = useState();
  const [re_path, setRE] = useState(false);
  const [air_path, setAir] = useState(false);
  const [years, setYear] = useState([]);
  const [YearS, setYearS] = useState();

  const [oparator_address, setoparator_address] = useState();
  const [oparator_name, setoparator_name] = useState();
  const [registration_date, setregistration_date] = useState();
  const [airworthiness_date, setairworthiness_date] = useState();
  const [registration_path, setregistration_path] = useState();
  const [airworthiness_path, setairworthiness_path] = useState();


  const userToken = localStorage.getItem('Token') || '';
  const [id, setId] = useState(0)
  const [optionsV, setOptions] = useState([]);
  const [optionsV2, setOptions2] = useState([]);
  const [inputs, setInputs] = useState();
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);

  const [loadingData, setloadingData] = useState(false)

  const vtypes = [
    { label: 'RISK', value: 'RISK' },
    { label: 'OPPORTUNITY', value: 'OPPORTUNITY' },
    { label: 'MIXED', value: 'MIXED' },
    
  ]

  const v2types = [
    { label: 'EXTERNAL', value: 'EXTERNAL' },
    { label: 'INTERNAL', value: 'INTERNAL' },
  ]

  const createShip = () => {
    const shipData = new FormData();


    shipData.append('note', matricula);
    shipData.append('name', serie);
    shipData.append('bias', v2type);


    axios.post(
      'https://fn.devpackgroup.com/api/v1/issues/',
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Party Creada Exitosamente")
      window.location.replace("/issues_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al crear Party")
    }.bind(this))

  };

  const updateShip = () => {
    const shipData = new FormData();
    matricula && shipData.append('reason', matricula);
    serie && shipData.append('name', serie);
    v2type && shipData.append('type', v2type);
    axios.patch(
      `https://fn.devpackgroup.com/api/v1/issues/${params.id}/`,
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Party Actualizada Exitosamente")
      //window.location.reload();
      window.location.replace("/issues_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al actualizar party")
    }.bind(this))

  };


  function getShipId() {
    if (params.id != null) {
      setId(params.id)
      getIssuesID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)

        var vtypeF = (vtypes.find(label => label.label === resp.data.vtype))
        optionsV.push(vtypeF)
        var vtypeF2 = (v2types.find(label => label.value === resp.data.v2type))
        optionsV2.push(vtypeF2)
      }).catch(err => {
        console.error(err);
      });
    }
  }




  useEffect(() => {
    //loadaricraft();
    //loadturbine();
    //getYear();
    //loadtypeAircraft();
    (action === 'view' || action === 'update') && getShipId()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);

  }, []);

  const onError = e => {
    console.log(e, "error in file-viewer");
  };

  return (
    <CoverLayout image={bgImage}>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {action === 'view' && 'Vista de Issues'}
                  {action === 'add' && 'Creacion de Issues'}
                  {action === 'update' && 'Editar Issues'}
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <br />
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
              <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Name: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerie(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.name : null}
                      value={action === 'view' && inputs ? inputs.name : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerie(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Notes: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Notes:"
                      variant="standard"
                      fullWidth
                      placeholder="Notes"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.note : null}
                      value={(action === 'view') && inputs ? inputs.note : null}
                      onChange={(e) => setMatricula(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Notes:"
                      variant="standard"
                      fullWidth
                      placeholder="Notes"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setMatricula(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Bias: </p>
                  <br />
                  {
                    //(inputs && [(typeAircraft && typeAircraft.find(label => label.label === inputs.aircraft_type.name))].length > 0) &&
                    
                  }
                  {
                    action === 'add' ?
                    <Select
                      options={vtypes}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setV2type(e.value)
                      }}
                    ></Select>
                    :
                    <Select
                      options={vtypes}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(vtypes.find(label => label.label === (inputs && inputs.bias)))] : null}
                      onChange={(e) => {
                        setV2type(e.value)
                      }}
                    ></Select>
                  }

                </MDBox>
              </MDBox>
              <br />
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/issues_table")}
              >
                Cancelar
              </MDButton>
              {
                action !== 'view' &&
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => action === 'add' ? createShip() : updateShip()}
                >
                  {action === 'add' && 'Create Issues'}
                  {action === 'update' && 'Edit Issues'}
                </MDButton>
              }
            </MDBox>
          </>
      }
    </CoverLayout>
  );
}
export default ShipCreator;
