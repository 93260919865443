import { useState, useRef, useEffect } from "react";
import axios from "axios";
// react-router-dom components
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../../forms/formLayout";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { getDeliversId } from "../../../Services/formServices";

// Images
import bgImage from "../../../assets/images/fn.jpg";
import "./form.css";

function UpdateDeliver() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = day + "/" + month + "/" + year;

    const [dataReceived, setDataReceived] = useState([])

    const id = localStorage.getItem("idDeliver");
    console.log(id, 'idDeliver');

    const getDataReceived = () => {
        getDeliversId(id)
            .then((response) => {
                setDataReceived(response.data)
            })
    }

    const stateSetter = () => {

        dataReceived.map((index) => {
            setMatricula(index.vehicle.registration)
            setType(index.vehicle.vtype)
            setSerialN(index.vehicle.serie)
            setPilot(index.pilot.first_name.concat(' ').concat(index.pilot.first_last_name))
            setEmail(index.pilot.email)
            setPhone(index.pilot.phone)
            setHourmtr(index.vehicle_hours)
            setTurbHours(index.turbine_hours)
            setLast(index.last_flight)
            setNG(index.ng_cycle)
            setNF(index.nf_cycle)
            setUsage(index.usage)
            setShipMan(index.maintenancev)
            setVEMD(index.observations_vemd)
            setRepPilot(index.pilot_report)
            setDocuments(index.documents)
            setProblem(index.problems)
            setHeadsets(index.headsets)
            setLightObs(index.comments_about_lightnings)
            setDoorsObs(index.comments_about_doors)
            setFuel(index.oil_gallons)
            setExtObs(index.comments_about_exterior)
            setGenObs(index.general_comments)
        })
    }


    //info states
    const [vehicles, setVehicles] = useState([]);
    const [matricula, setMatricula] = useState();
    const [hourmtr, setHourmtr] = useState();
    const [type, setType] = useState();
    const [type2, setType2] = useState();
    const [pilots, setPilots] = useState([]);
    const [pilot, setPilot] = useState();
    const [serialN, setSerialN] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [ship, setShip] = useState([]);
    const [page, setPage] = useState(1);

    console.log(dataReceived)
    //inspection states
    const [shipHours, setShipHours] = useState();
    const [turbHours, setTurbHours] = useState();
    const [last, setLast] = useState();
    const [NG, setNG] = useState();
    const [NF, setNF] = useState();
    const [usage, setUsage] = useState();

    //mantainance states
    const [shipMan, setShipMan] = useState([]);
    const [turbMan, setTurbMan] = useState([]);
    const [turbManOpt, setTurbManOpt] = useState();
    const [VEMD, setVEMD] = useState();
    const [repPilot, setRepPilot] = useState();

    //Documentos
    const [documents, setDocuments] = useState([]);

    //Pilot Report
    const [reported, setReported] = useState([]);
    const [problem, setProblem] = useState();
    const [problemDesc, setProblemDesc] = useState();
    const [addProblem, setAddProblem] = useState([
        { reported: "", problem: "", description: "" },
    ]);

    //Works to do
    const [worksToDo, setWorksToDo] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [worksFinish, setWorksFinish] = useState([0, 0, 0, 0, 0, 0, 0]);

    //Articles in ship
    const [articlesInShip, setArticlesInShip] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [headsets, setHeadsets] = useState();

    //damages outside
    const [interiorPhotos, setInteriorPhotos] = useState([
        { name: "", file: null },
    ]);

    const [panelIns, setPanelIns] = useState();
    const [pilotChair, setPilotChair] = useState();
    const [copilotChair, setCopilotChair] = useState();
    const [passengers, setPassengers] = useState();
    const [roofs, setRoofs] = useState();
    const [mats, setMatsI] = useState();
    const [othersInt, setOtherInts] = useState();

    //damages inside
    const [frontal, setFrontal] = useState();
    const [pilotSide, setPilotSide] = useState();
    const [copilotSide, setCopilotSide] = useState();
    const [rear, setRear] = useState();
    const [bluepale, setBluePale] = useState();
    const [yellpale, setYellPale] = useState();
    const [redpale, setRedPale] = useState();
    const [rotorcola, setRotorCola] = useState();
    const [others, setOthers] = useState();

    //lights
    const [lightsOp, setLightsOp] = useState([{ name: "", option: "" }]);
    const [lightObs, setLightObs] = useState();

    const [lightsInShip, setLightInShip] = useState([0, 0, 0, 0, 0, 0]);

    //doors
    const [doorsOp, setdoorsOp] = useState([{ name: "", option: "" }]);
    const [doorsObs, setDoorsObs] = useState();

    const [doorsInShip, setDoorsInShip] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    //levels
    const [levelsOp, setLevelsOp] = useState([]);
    const [fuel, setFuel] = useState();

    //exterior
    const [extOp, setExtOp] = useState([0, 1, 0, 0, 1, 0, 0, 1, 0, 0]);
    const [extObs, setExtObs] = useState();

    const [genObs, setGenObs] = useState();

    const [otherPhotos, setOtherPhotos] = useState([]);

    const [sign, setSign] = useState();
    const sigCanvas = useRef({});

    const handleSetObservations = (flag, content) => {
        if (flag === 1) {
            setLightObs(content);
        } else if (flag === 2) {
            setDoorsObs(content);
        } else if (flag === 3) {
            setExtObs(content);
        }
    };

    const addOptions = (flag) => {
        if (flag === 1) {
            setLightsOp([...lightsOp, { name: "", option: "" }]);
            console.log(addProblem);
        } else if (flag === 2) {
            setdoorsOp([...doorsOp, { name: "", option: "" }]);
        } else if (flag === 3) {
            setLevelsOp([...levelsOp, { name: "", option: "" }]);
        } else if (flag === 4) {
            setExtOp([...extOp, { name: "", option: "" }]);
        }
    };

    const addDescription = (flag) => {
        if (flag === 1) {
            setAddProblem([
                ...addProblem,
                { reported: "", problem: "", description: "" },
            ]);
            console.log(addProblem);
        }
    };

    const handleRemoveClick = (index, flag) => {
        if (flag === 1) {
            const list = [...addProblem];
            list.splice(index, 1);
            setAddProblem(list);

        }
    };

    useEffect(() => {
        getAllShips();
        getAllPilots();
        getDataReceived();
        stateSetter();

    }, []);

    const saveSign = () => {
        setSign(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    };
    const userToken = localStorage.getItem("Token") || "";

    const getAllShips = () => {
        axios
            .get("https://fn.devpackgroup.com/api/v1/vehicle/", {
                headers: { Authorization: "Token " + userToken },
            })
            .then(
                function (response) {
                    console.log(response.data);
                    setVehicles(
                        response.data.map((v) => ({
                            value: v.id,
                            label: v.registration,
                            type: v.vtype,
                            type2: v.v2type,
                            serial: v.serie,
                        }))
                    );
                }.bind(this)
            )
            .catch(
                function (error) {
                    console.log(error);
                }.bind(this)
            );
    };

    const getAllPilots = () => {
        axios
            .get("https://fn.devpackgroup.com/api/v1/pilot/", {
                headers: { Authorization: "Token " + userToken },
            })
            .then(
                function (response) {
                    console.log(response.data);
                    setPilots(
                        response.data.map((v) => ({
                            value: v.id,
                            label:
                                v.first_name +
                                " " +
                                v.second_name +
                                " " +
                                v.first_last_name +
                                " " +
                                v.second_last_name,
                            phone: v.phone,
                            email: v.email
                        }))
                    );
                }.bind(this)
            )
            .catch(
                function (error) {
                    console.log(error);
                }.bind(this)
            );
    };

    const UpdateDeliver = () => {
        //console.log(outsidePhotos[0].file)
        const receptionData = new FormData();
        receptionData.append("vehicle", matricula);
        receptionData.append("pilot", pilot);
        receptionData.append("vehicle_hours", shipHours);
        receptionData.append("turbine_hours", turbHours);
        receptionData.append("last_flight", last);
        receptionData.append("ng_cycle", NG);
        receptionData.append("nf_cycle", NF);
        receptionData.append("usage", usage);
        receptionData.append("maintenancev", shipMan);
        receptionData.append("maintenancet", 'YES');
        receptionData.append("observations_vemd", VEMD);
        receptionData.append("pilot_report", repPilot);
        receptionData.append("documents", documents);
        receptionData.append("problems", problem);
        receptionData.append("done_works", worksFinish.join(","));
        receptionData.append("pending_works", worksToDo.join(","));
        receptionData.append("articles_inside_vehicle", articlesInShip.join(","));
        receptionData.append("headsets", headsets);
        receptionData.append("lightnings", lightsInShip.join(","));
        receptionData.append("comments_about_lightnings", lightObs);
        receptionData.append("doors", doorsInShip.join(","));
        receptionData.append("comments_about_doors", doorsObs);
        receptionData.append("mgb", 'O');
        receptionData.append("tgb", 'E');
        receptionData.append("hydraulic", 'MA');
        receptionData.append("turbine", 'MA');
        receptionData.append("oil_gallons", fuel);
        receptionData.append("exterior", extOp.join(","));
        receptionData.append("comments_about_exterior", extObs);
        receptionData.append("general_comments", genObs);
        receptionData.append("reception_or_delivery", "D");
        receptionData.append("path_to_picture_frontal_hits", frontal);
        receptionData.append("path_to_picture_pilots_side_hits", pilotSide);
        receptionData.append("path_to_picture_copilots_side_hits", copilotSide);
        receptionData.append("path_to_picture_rear_hits", rear);
        receptionData.append("path_to_picture_blue_pala_hits", bluepale);
        receptionData.append("path_to_picture_yellow_pala_hits", yellpale);
        receptionData.append("path_to_picture_red_pala_hits", redpale);
        receptionData.append("path_to_picture_tailor_rotor_hits", rotorcola);
        receptionData.append("path_to_picture_others_hits", others);
        receptionData.append("path_to_picture_instruments_panel", panelIns);
        receptionData.append("path_to_picture_pilots_chair", pilotChair);
        receptionData.append("path_to_picture_copilots_chair", copilotChair);
        receptionData.append("path_to_picture_passengers_chair", passengers);
        receptionData.append("path_to_picture_rooftop", roofs);
        receptionData.append("path_to_picture_carpets", mats);
        receptionData.append("path_to_picture_others_inside", othersInt);
        receptionData.append("path_to_picture_others", otherPhotos);
        receptionData.append("path_to_sign", sign);

        axios
            .post(
                "https://fn.devpackgroup.com/api/v1/vehicle_reception/update_vehicle_reception/",
                receptionData,
                {
                    headers: { Authorization: "Token " + userToken },
                }
            )
            .then(
                function (response) {
                    console.log(response.data);
                }.bind(this)
            )
            .catch(
                function (error) {
                    console.log(error);
                }.bind(this)
            );
    };

    const answers = [
        { id: 1, label: "Si", value: "1" },
        { id: 2, label: "No", value: "0" },
    ];

    const answers1 = [
        { id: 1, label: "Si", value: "1" },
        { id: 2, label: "No", value: "0" },
        { id: 3, label: "N/A", value: "na" },
    ];

    const answers2 = [
        { id: 1, label: "OK", value: "1" },
        { id: 2, label: "NOT", value: "0" },
    ];

    const answers3 = [
        { id: 1, label: "Vacio", value: "E" },
        { id: 2, label: "Minimo", value: "MI" },
        { id: 3, label: "Ok", value: "O" },
        { id: 4, label: "Max", value: "MA" },
    ];

    const manNave = [
        { id: 1, label: "50 horas", value: "H50" },
        { id: 2, label: "150 horas // 12 meses", value: "H15012" },
        { id: 3, label: "12 meses", value: "M12" },
        { id: 4, label: "150 horas", value: "H150" },
        { id: 5, label: "24 meses", value: "M24" },
    ];

    const manTurb = [
        { id: 1, label: "Anual", value: "Anual" },
        { id: 2, label: "15 años", value: "15 años" },
        { id: 3, label: "30 horas", value: "30 horas" },
        { id: 4, label: "50 horas", value: "50 horas" },
        { id: 5, label: "100 horas", value: "100 horas" },
    ];

    const naves = [
        { id: 1, label: "Helicoptero" },
        { id: 2, label: "Monomotor" },
        { id: 3, label: "Bimotor" },
    ];

    const reportes = [
        { id: 1, label: "Reportado Verbalmente" },
        { id: 2, label: "Reportado Telefonicamente" },
    ];

    const works = [
        { id: 1, label: "Chequeo de 50 horas" },
        { id: 2, label: "Chequeo de 100 horas" },
        { id: 3, label: "Trabajo solicitado por el dueño" },
        { id: 4, label: "Modificaciones STC" },
        { id: 5, label: "Control de tiempos limites de vida" },
        { id: 6, label: "Control de AD´s y service bulletins" },
        { id: 7, label: "Papeleria para renovación de tarjeta" },
    ];

    const articles = [
        { id: 1, label: "GPS Portatil" },
        { id: 2, label: "Radio Portatil" },
        { id: 3, label: "Alfombras" },
        { id: 4, label: "Neutralizadores de Olores" },
        { id: 5, label: "Litros de Aceite" },
        { id: 6, label: "Desarmadores" },
        { id: 7, label: "Protectores de sol" },
        { id: 8, label: "Bolsas de vomitar" },
        { id: 9, label: "Limpiador de ventanas" },
        { id: 10, label: "Cera de lustrar de aeronave" },
        { id: 11, label: "Computers de vuelo" },
        { id: 12, label: "Cobertores" },
        { id: 13, label: "Pilot Log Book" },
        { id: 14, label: "Candados de la nave" },
        { id: 15, label: "Pilot Cover" },
        { id: 16, label: "Tow Bar" },
        { id: 17, label: "Lona para tapar el avión" },
        { id: 18, label: "Maletín" },
        { id: 19, label: "Anteojos" },
        { id: 20, label: "Linterna" },
        { id: 21, label: "Fuel Tester" },
        { id: 22, label: "Toallas" },
        { id: 23, label: "Mapas" },
        { id: 24, label: "Lapiceros" },
        { id: 25, label: "Plotters" },
        { id: 26, label: "Libros de la aeronave" },
        { id: 27, label: "Extinguidor" },
    ];

    const lights = [
        { id: 1, label: "Luces Interior" },
        { id: 2, label: "Luces panel" },
        { id: 3, label: "Anticolisión" },
        { id: 4, label: "Luces posición" },
        { id: 5, label: "Luz de taxi" },
        { id: 6, label: "Luz de landing" },
    ];

    const doors = [
        { id: 1, label: "Puerta piloto" },
        { id: 2, label: "Puerta copiloto" },
        { id: 3, label: "Puerta carga derecha" },
        { id: 4, label: "Puerta carga izquierda" },
        { id: 5, label: "Puerta carga trasera" },
        { id: 6, label: "Cowling transmision izquierda" },
        { id: 7, label: "Cowling transmision derecha" },
        { id: 8, label: "Cowling flecha larga" },
        { id: 9, label: "Cowling TGB" },
        { id: 10, label: "Cowling motor" },
    ];

    const levels = [
        { id: 1, label: "Nivel MGB" },
        { id: 2, label: "Nivel TGB" },
        { id: 3, label: "Nivel hídraulico" },
        { id: 4, label: "Nivel turbina" },
    ];

    const exterior = [
        { id: 1, label: "Plataforma transmisión" },
        { id: 2, label: "Plataforma turbina" },
        { id: 3, label: "Estabilizador horizontal" },
        { id: 4, label: "Estabilizador vertical" },
        { id: 5, label: "Skies" },
        { id: 6, label: "Turbo Pilot" },
        { id: 7, label: "Antenas" },
        { id: 8, label: "Sensor de temperatura" },
        { id: 9, label: "Batería" },
        { id: 10, label: "ELT" },
    ];

    return (
        <CoverLayout image={bgImage}>
            {dataReceived.map((index) => (
                <>
                    <Card>
                        <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="success"
                            mx={2}
                            mt={-3}
                            p={3}
                            mb={1}
                            textAlign="center"
                        >
                            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                Formulario de Recepcion de Naves
                            </MDTypography>
                        </MDBox>
                    </Card>
                    <br />
                    <br />
                    <br />
                    <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        Los campos marcados con (*) son de caracter obligatorio
                    </p>
                    <br />
                    <MDBox pt={4} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            {page === 1 &&
                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Información{" "}
                                            </p>
                                            <br />
                                            <MDInput
                                                type="text"
                                                label="Fecha *"
                                                disabled={true}
                                                variant="standard"
                                                value={today}
                                                fullWidth
                                                //placeholder="Fecha *"
                                                InputLabelProps={{ shrink: true }}
                                            //onChange={handleGeoChange}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <Select
                                                options={vehicles}
                                                //value={matricula}
                                                isDisabled={true}
                                                placeholder={index.vehicle.registration}
                                                onChange={(o) => {
                                                    setMatricula(o.value);
                                                    setType(o.type);
                                                    setType2(o.type2);
                                                    setSerialN(o.serial);
                                                    console.log(o.value);
                                                }}
                                            ></Select>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Tipo *"
                                                disabled={true}
                                                variant="standard"
                                                value={index.vehicle.vtype}
                                                fullWidth
                                                placeholder={index.vehicle.vtype}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setType(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="number"
                                                label="Modelo de nave *"
                                                disabled={true}
                                                variant="standard"
                                                value={index.vehicle.serie}
                                                fullWidth
                                                placeholder={index.vehicle.serie}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="number"
                                                label="Numero de Serie *"
                                                disabled={true}
                                                variant="standard"
                                                value={index.vehicle.serie}
                                                fullWidth
                                                placeholder={index.vehicle.serie}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="number"
                                                label="Modelo de turbina *"
                                                disabled={true}
                                                variant="standard"
                                                value={index.vehicle.serie}
                                                fullWidth
                                                placeholder={index.vehicle.serie}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />

                                        <MDBox mb={2}>
                                            <MDInput
                                                type="number"
                                                label="Numero de serie de turbina *"
                                                disabled={true}
                                                variant="standard"
                                                value={index.vehicle.serie}
                                                fullWidth
                                                placeholder={index.vehicle.serie}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <Select
                                                options={pilots}

                                                placeholder={index.pilot.first_name.concat(' ').concat(index.pilot.first_last_name)}
                                                onChange={(o) => {
                                                    setPilot(o.value);
                                                    setPhone(o.phone);
                                                    setEmail(o.email)
                                                    console.log(o.value);
                                                }}
                                            ></Select>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Email *"
                                                variant="standard"
                                                value={index.pilot.email}
                                                fullWidth
                                                placeholder={index.pilot.email}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="number"
                                                label="Telefono *"
                                                variant="standard"
                                                value={index.pilot.phone}
                                                fullWidth
                                                placeholder={index.pilot.phone}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setPhone(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "right" }}>


                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(2);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>




                                </>


                            }

                            {page === 2 &&
                                <>


                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Inspección{" "}
                                            </p>
                                            <br />
                                            <MDInput
                                                type="text"
                                                label="Horas Nave *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.vehicle_hours}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setShipHours(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Horas turbina *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.turbine_hours}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setTurbHours(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Último vuelo *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.last_flight}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setLast(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Ciclos NG *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.ng_cycle}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setNG(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Ciclos NF *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.nf_cycle}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setNF(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="USAGE % *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.usage}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setUsage(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(1);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(3);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>
                                </>
                            }


                            {page === 3 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Mantenimiento{" "}
                                            </p>
                                            <br />
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Mantenimiento Nave{" "}
                                            </p>
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {manNave.map((index, key) => (
                                                    <>

                                                        <li>
                                                            <Checkbox
                                                                //checked={isChecked}
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        shipMan.push(index.value);
                                                                        console.log(shipMan);
                                                                    } else if (e.target.checked === false) {
                                                                        shipMan.splice(key, 1);
                                                                        console.log(shipMan);
                                                                    }
                                                                }}
                                                            />
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                {index.label}
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Mantenimiento Turbina{" "}
                                            </p>
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {manTurb.map((index, key) => (
                                                    <>
                                                        <li>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        turbMan.push(index.label);
                                                                        console.log(turbMan);
                                                                    } else if (e.target.checked === false) {
                                                                        turbMan.splice(key, 1);
                                                                        console.log(turbMan);
                                                                    }
                                                                }}
                                                            />
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                {index.label}
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Falla VEMD{" "}
                                            </div>
                                            <br />
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers1.map((index) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            setTurbManOpt(index.value);
                                                                            console.log(index.value);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Observaciones VEMD"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.observations_vemd}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setVEMD(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Reporte Piloto"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.pilot_report}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setRepPilot(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(2);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(4);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>

                                </>

                            }

                            {page === 4 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >

                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Documentos{" "}
                                            </p>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Cert. Autonavegabilidad{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Cert. Matricula{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Homologación ruido{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Licencia de radios{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Seguro{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Manual de Vuelo{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                            <br />
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Bitácora de Vuelos{" "}
                                            </div>
                                            <div style={{ marginLeft: "4vh" }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    style={{ display: "flex", flexDirection: "row" }}
                                                >
                                                    {answers.map((index, key) => (
                                                        <>
                                                            <FormControlLabel
                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                value={index.value}
                                                                label={index.label}
                                                                control={
                                                                    <Radio
                                                                        onClick={() => {
                                                                            documents.push(index.value);
                                                                            console.log(index.value, documents);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(3);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(5);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>

                                    </Card>

                                </>

                            }


                            {page === 5 &&

                                <>

                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Reporte del piloto de la Aeronave{" "}
                                            </p>
                                            <br />
                                            {addProblem.map((index, key) => {
                                                return (
                                                    <>
                                                        <br />
                                                        <p style={{ fontSize: "1.5vh", textAlign: "justify" }}>
                                                            Reporte #{key + 1}{" "}
                                                        </p>
                                                        <br />
                                                        <ul
                                                            style={{
                                                                listStyleType: "none",
                                                                display: "flex",
                                                                fontSize: "1.3vh",
                                                                flexDirection: "column",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {reportes.map((index, key) => (
                                                                <>
                                                                    <FormControlLabel
                                                                        style={{ display: "flex", flexDirection: "row" }}
                                                                        value={index.value}
                                                                        label={index.label}
                                                                        control={
                                                                            <Radio
                                                                                onClick={() => {
                                                                                    addProblem[key].reported = index.label;
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </>
                                                            ))}
                                                        </ul>
                                                        <br />
                                                        <MDBox mb={2}>
                                                            <MDInput
                                                                type="text"
                                                                label="Problema Reportado *"
                                                                variant="standard"
                                                                fullWidth
                                                                placeholder={index.problems}
                                                                InputLabelProps={{ shrink: true }}
                                                                onChange={(o) => {
                                                                    setProblem(o.target.value);
                                                                    console.log(o.target.value);
                                                                }}
                                                            />
                                                        </MDBox>
                                                        <br />

                                                        <MDBox mb={2}>
                                                            <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                                                                {" "}
                                                                Descripcion del problema{" "}
                                                            </p>
                                                            <br />
                                                            <textarea
                                                                name=""
                                                                id=""
                                                                rows="10"
                                                                placeholder={index.problems}
                                                                style={{
                                                                    resize: "None",
                                                                    width: "100%",
                                                                    border: "solid black 1px",
                                                                    borderRadius: "8px",
                                                                    padding: "2vh",
                                                                    fontFamily: "Arial",
                                                                }}
                                                                onChange={(o) => {
                                                                    addProblem[key].description = o.target.value;
                                                                    console.log(o.target.value);
                                                                }}
                                                            />
                                                            <br />
                                                            <br />
                                                            {addProblem.length > 1 && (
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        handleRemoveClick(key, 1);
                                                                    }}
                                                                >
                                                                    Remove
                                                                </MDButton>
                                                            )}{" "}
                                                            {addProblem.length - 1 === key && (
                                                                <>
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="info"
                                                                        onClick={() => addDescription(1)}
                                                                    >
                                                                        Agregar Problema
                                                                    </MDButton>
                                                                </>
                                                            )}
                                                        </MDBox>
                                                        <br />
                                                        <hr />
                                                    </>
                                                );
                                            })}
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(4);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(6);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>

                                    </Card>
                                </>

                            }

                            {page === 6 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Trabajos pendientes{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {works.map((index, key) => (
                                                    <>
                                                        <li>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        worksToDo.splice(key, 1, 1);
                                                                        console.log(worksToDo);
                                                                    } else if (e.target.checked === false) {
                                                                        worksToDo.push(0);
                                                                        console.log(worksToDo);
                                                                    }
                                                                }}
                                                            />
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                {index.label}
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        </MDBox>
                                        <br />
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Trabajos Realizados{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {works.map((index, key) => (
                                                    <>
                                                        <li>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        worksToDo.splice(key, 1, 1);
                                                                        console.log(worksToDo);
                                                                    } else if (e.target.checked === false) {
                                                                        worksToDo.push(0);
                                                                        console.log(worksToDo);
                                                                    }
                                                                }}
                                                            />
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                {index.label}
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(5);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(7);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>

                                </>

                            }

                            {page === 7 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Articulos que se encontraron en el interior de la aeronave{" "}
                                            </p>
                                            <br />
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                <b> NOTA IMPORTANTE: </b>El taller no se hace responsable por
                                                articulos dejados en la aeronave si no están debidamente señalados
                                                en el listado y firmados por el cliente o el piloto{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {articles.map((index, key) => (
                                                    <>
                                                        <li>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        //articlesInShip.splice(key,1,1);
                                                                        console.log(articlesInShip);
                                                                    } else if (e.target.checked === false) {
                                                                        articlesInShip.push(0);
                                                                        console.log(articlesInShip);
                                                                    }
                                                                }}
                                                            />
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                {index.label}
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <br />
                                            <MDInput
                                                type="number"
                                                label="Headsets"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.headsets}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setHeadsets(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(6);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(8);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>

                                </>

                            }


                            {page === 8 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Golpes o rayones encontrados en fuselaje{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Frontal"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setFrontal(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {frontal && <img src={frontal} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Lado del Piloto"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setPilotSide(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {pilotSide && <img src={pilotSide} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Lado del Copiloto"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setCopilotSide(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {copilotSide && <img src={copilotSide} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Parte Trasera"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setRear(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {rear && <img src={rear} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Pala Azul"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setBluePale(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {bluepale && <img src={bluepale} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Pala Amarilla"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setYellPale(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {yellpale && <img src={yellpale} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Pala Roja"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setRedPale(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {redpale && <img src={redpale} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Rotor de Cola"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setRotorCola(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {rotorcola && <img src={rotorcola} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Otros"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setOthers(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        {others && <img src={others} />}
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                            </ul>
                                            <br />
                                        </MDBox>

                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(7);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(9);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>



                                    </Card>

                                </>

                            }
                            {page === 9 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>Interior </p>

                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Panel de instrumentos"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setPanelIns(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Silla del Piloto"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setPanelIns(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Silla del Piloto"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setPilotChair(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Silla del Pasajeros"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setPassengers(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Techos"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setRoofs(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Alfombras"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setMatsI(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                                <li>
                                                    <FormLabel style={{ fontSize: "1.8vh" }}>
                                                        <br />
                                                        <MDInput
                                                            type="file"
                                                            label="Otros del interior"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="0"
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => {
                                                                setOtherInts(URL.createObjectURL(e.target.files[0]));
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            onClick={() => addDescription(2)}
                                                        >
                                                            Add Photo
                                                        </MDButton>
                                                        <br />
                                                        <br />
                                                        <hr />
                                                    </FormLabel>
                                                </li>
                                            </ul>
                                            <br />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(8);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(10);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>

                                    </Card>

                                </>

                            }

                            {page === 10 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>Luces </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {lights.map((indexi, key) => (
                                                    <>
                                                        <li>
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "justify",
                                                                        width: "35%",
                                                                        fontSize: "1.5vh",
                                                                    }}
                                                                >
                                                                    {indexi.label}{" "}
                                                                </div>
                                                                <br />
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    style={{ display: "flex", flexDirection: "row" }}
                                                                >
                                                                    {answers.map((index) => (
                                                                        <>
                                                                            <FormControlLabel
                                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                                value={index.value}
                                                                                label={index.label}
                                                                                control={
                                                                                    <Radio
                                                                                        onClick={() => {
                                                                                            lightsInShip.splice(key, 1, 1)
                                                                                            console.log(index.value, lightsInShip);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </>
                                                                    ))}
                                                                </RadioGroup>
                                                                <br />
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Observaciones Luces{" "}
                                            </div>
                                            <br />
                                            <textarea
                                                name=""
                                                id=""
                                                rows="10"
                                                placeholder={index.comments_about_lightnings}
                                                style={{
                                                    resize: "None",
                                                    width: "100%",
                                                    border: "1px solid black",
                                                    borderRadius: "8px",
                                                    padding: "2vh",
                                                    fontFamily: "Arial",
                                                }}
                                                onChange={(o) => handleSetObservations(1, o.target.value)}
                                            />
                                            <br />
                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(9);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(11);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>



                                    </Card>

                                </>

                            }

                            {page === 11 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Puertas / Cowlings{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {doors.map((indexi, key) => (
                                                    <>
                                                        <li>
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "justify",
                                                                        width: "35%",
                                                                        fontSize: "1.5vh",
                                                                    }}
                                                                >
                                                                    {indexi.label}{" "}
                                                                </div>
                                                                <br />
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    style={{ display: "flex", flexDirection: "row" }}
                                                                >
                                                                    {answers2.map((index) => (
                                                                        <>
                                                                            <FormControlLabel
                                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                                value={index.value}
                                                                                label={index.label}
                                                                                control={
                                                                                    <Radio
                                                                                        onClick={() => {
                                                                                            doorsInShip.splice(key, 1, 1);
                                                                                            console.log(index.value, doorsInShip);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </>
                                                                    ))}
                                                                </RadioGroup>
                                                                <br />
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Observaciones Puertas / Cowling{" "}
                                            </div>
                                            <br />
                                            <textarea
                                                name=""
                                                id=""
                                                rows="10"
                                                placeholder={index.comments_about_doors}
                                                style={{
                                                    resize: "None",
                                                    width: "100%",
                                                    border: "1px solid black",
                                                    borderRadius: "8px",
                                                    padding: "2vh",
                                                    fontFamily: "Arial",
                                                }}
                                                onChange={(o) => handleSetObservations(2, o.target.value)}
                                            />

                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(10);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(12);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>

                                </>

                            }


                            {page === 12 &&

                                <>

                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                Niveles aceite/combustible{" "}
                                            </p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {levels.map((indexi, key) => (
                                                    <>
                                                        <li>
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "justify",
                                                                        width: "35%",
                                                                        fontSize: "1.5vh",
                                                                    }}
                                                                >
                                                                    {indexi.label}{" "}
                                                                </div>
                                                                <br />
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    style={{ display: "flex", flexDirection: "row" }}
                                                                >
                                                                    {answers3.map((index) => (
                                                                        <>
                                                                            <FormControlLabel
                                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                                value={index.value}
                                                                                label={index.label}
                                                                                control={
                                                                                    <Radio
                                                                                        onClick={() => {
                                                                                            levelsOp.push(index.value);
                                                                                            console.log(levelsOp);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </>
                                                                    ))}
                                                                </RadioGroup>
                                                                <br />
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <MDInput
                                                type="number"
                                                label="Galones de combustible *"
                                                variant="standard"
                                                fullWidth
                                                placeholder={index.oil_gallons}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(o) => {
                                                    setFuel(o.target.value);
                                                    console.log(o.target.value);
                                                }}
                                            />

                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(11);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(13);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>


                                    </Card>
                                </>

                            }

                            {page === 13 &&

                                <>

                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>Exterior</p>
                                            <br />
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    fontSize: "1.3vh",
                                                    flexDirection: "column",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {exterior.map((indexi, key) => (
                                                    <>
                                                        <li>
                                                            <FormLabel style={{ fontSize: "1.8vh" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "justify",
                                                                        width: "35%",
                                                                        fontSize: "1.5vh",
                                                                    }}
                                                                >
                                                                    {indexi.label}{" "}
                                                                </div>
                                                                <br />
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    style={{ display: "flex", flexDirection: "row" }}
                                                                >
                                                                    {answers2.map((index) => (
                                                                        <>
                                                                            <FormControlLabel
                                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                                value={index.value}
                                                                                label={index.label}
                                                                                control={
                                                                                    <Radio
                                                                                        onClick={() => {
                                                                                            //extOp.push(index.value);
                                                                                            console.log(extOp);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </>
                                                                    ))}
                                                                </RadioGroup>
                                                                <br />
                                                            </FormLabel>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "1.5vh" }}
                                            >
                                                Observaciones Exterior{" "}
                                            </div>

                                            <br />
                                            <textarea
                                                name=""
                                                placeholder={index.comments_about_exterior}
                                                id=""
                                                rows="10"
                                                style={{
                                                    resize: "None",
                                                    width: "100%",
                                                    border: "1px solid black",
                                                    borderRadius: "8px",
                                                    padding: "2vh",
                                                    fontFamily: "Arial",
                                                }}
                                                onChange={(o) => handleSetObservations(3, o.target.value)}
                                            />

                                        </MDBox>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(12);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {
                                                    setPage(14);
                                                }}
                                            >
                                                Siguiente Pagina
                                            </MDButton>

                                        </div>

                                    </Card>


                                </>

                            }

                            {page === 14 &&

                                <>
                                    <Card
                                        variant="gradient"
                                        style={{ padding: '4vh' }}
                                    >
                                        <MDBox mb={2}>
                                            <div
                                                style={{ textAlign: "justify", width: "35%", fontSize: "2vh" }}
                                            >
                                                Observaciones Generales{" "}
                                            </div>
                                            <br />
                                            <textarea
                                                name=""
                                                id=""
                                                placeholder={index.general_comments}
                                                rows="10"
                                                style={{
                                                    resize: "None",
                                                    width: "100%",
                                                    border: "1px solid black",
                                                    borderRadius: "8px",
                                                    padding: "2vh",
                                                    fontFamily: "Arial",
                                                }}
                                                onChange={(o) => setGenObs(o.target.value)}
                                            />
                                            <br />
                                        </MDBox>
                                        <br />

                                        <FormLabel style={{ fontSize: "1.8vh" }}>
                                            <br />
                                            <MDInput
                                                type="file"
                                                label="Otras fotos"
                                                variant="standard"
                                                fullWidth
                                                placeholder="0"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => {
                                                    setOtherPhotos(e.target.files[0]);
                                                }}
                                            />
                                            <br />
                                            <br />
                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => addDescription(2)}
                                            >
                                                Add Photo
                                            </MDButton>
                                            <br />
                                            <br />
                                            <hr />
                                        </FormLabel>
                                        <br />

                                        <MDBox mb={2}>
                                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                                                {" "}
                                                Firma Digital{" "}
                                            </p>
                                            <br />
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor="blue"
                                                canvasProps={{ width: 600, height: 200, className: "signature" }}
                                                style={{ background: "white" }}
                                            />
                                            <br />
                                            <MDButton variant="gradient" color="info" onClick={saveSign}>
                                                Guardar Firma
                                            </MDButton>
                                        </MDBox>
                                        <br />
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={() => {

                                                UpdateDeliver();
                                            }}
                                        >
                                            Guardar Nave
                                        </MDButton>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "left" }}>

                                            <MDButton
                                                variant="gradient"
                                                color="error"
                                                onClick={() => {
                                                    setPage(13);
                                                }}
                                            >
                                                Pagina Anterior
                                            </MDButton>

                                        </div>



                                    </Card>

                                </>

                            }


                        </MDBox>
                    </MDBox>



                </>
            ))}

        </CoverLayout>
    );
}

export default UpdateDeliver;
