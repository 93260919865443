// description, correction, date, date_repair, TTAP

const form = {
  formId: 'new-deffects-form',
  formField: {
    description: {
      name: 'description',
      label: 'Description',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    correction: {
      name: 'correction',
      label: 'Correction',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    date: {
      name: 'date',
      label: 'Date',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    date_repair: {
      name: 'date_repair',
      label: 'Date Repair',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    TTAP: {
      name: 'TTAP',
      label: 'TTAP',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
  },
};

export default form;
