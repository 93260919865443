import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

import form from '../CreateTask/schemas/form';
import validations from '../CreateTask/schemas/validations';
import {initialValues, initialValuesFromObj} from '../CreateTask/schemas/initialValues';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import SuccessCreatedDialog from '../../../components/SuccessCreatedDialog';
import SnackNotification from '../../../components/SnackNotification';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import DataTable from '../../../components/DataTable';
import MDInput from '../../../components/MDInput';
import { TextField } from '@mui/material';

import TaskForm from '../CreateTask/components/TaskForm'

import { addSubTask, getSubTaskById, updateSubTask } from '../../../Services/TaskService'

const CreateSubTask = ({action, task, id, cancelAction, isSubTask}) => {
    const [openError, setOpenError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [inputs, setInputs] = useState();

    const { formId, formField } = form;

    const isAdd = action === "add";
    const isUpdate = action === "update";
    const isView = action === "view";

    const submitForm = async (values, actions) => {
        console.log(values);
        if(isAdd){
            addSubTask(task, values).then(resp => {
                console.log(resp.data)
                actions.setSubmitting(false);
                setOpenDialog(true)
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            });
        }else if (isUpdate){
            updateSubTask(id,values).then(resp => {
                console.log(resp.data)
                actions.setSubmitting(false);
                setOpenDialog(true)
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            });
        }
        actions.setSubmitting(false);
    }

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    }

    useEffect(()=>{
        console.log(isSubTask)
        if(id != null){
            getSubTaskById(id).then(resp => {
                console.log(resp.data)
                setInputs(resp.data)
            }).catch(err => {
                console.error(err);
            });
        }
    },[ ]);

    return (
        <MDBox pt={3} pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
                <Grid item xs={12} lg={8}>
                    <MDBox mt={6} mb={8} textAlign="center">
                        <MDBox mb={1}>
                            <MDTypography variant="h3" fontWeight="bold">
                            { isAdd && "Creacion de SubTask" }
                            { isUpdate && "Edicion de SubTask" }
                            { isView && "Visualizacion de SubTask" }
                            </MDTypography>
                        </MDBox>
                        {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                            { inputs != null && inputs != undefined &&  inputs.part_id }
                        </MDTypography> */}
                    </MDBox>
                    <Formik
                        initialValues={ inputs != null && inputs != undefined ? initialValuesFromObj(inputs) : initialValues }
                        validationSchema={validations}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card>
                                    <MDBox p={3}>
                                        <TaskForm isView={isView} isUpdate={isUpdate} formData={{values,touched,formField, errors,setFieldValue}} isSubTask/>
                                        <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                                
                                            <MDButton
                                                disabled={isView}
                                                variant="gradient"
                                                color="success"
                                                type="submit"
                                                >
                                                Aceptar
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
            <SuccessCreatedDialog open={openDialog} message={ isUpdate ? "SubTask Actualizada!" : "SubTask Creada!" } close={cancelAction} />
            <SnackNotification  type="error" message="Ha ocurrido un error" closeCallback={console.log()} openFlag={openError} setOpenFlag={setOpenError} />
        </MDBox>
    )
}

export default CreateSubTask