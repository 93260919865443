import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App'

import { MaterialUIControllerProvider } from "./context";
import theme from "./assets/theme";
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

ReactDOM.render(
  <React.StrictMode>
    <MaterialUIControllerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </MaterialUIControllerProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

