import checkout from "./form";

//   description
//   correction
//   date
//   date_repair
//   TTAP

const {
    formField: {
        description, correction, date, date_repair, TTAP
    }
} = checkout;

export const initialValues = {
    [description.name]: "",
    [correction.name]: "",
    [date.name]: "",
    [date_repair.name]: "",
    [TTAP.name]: "",
}

export const initialValuesFromObj = (obj) => {
    return {
        [description.name]: obj.description,
        [correction.name]: obj.correction,
        [date.name]: obj.date,
        [date_repair.name]: obj.date_repair,
        [TTAP.name]: obj.TTAP,
    }
}
