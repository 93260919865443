import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    component_name,
    ata_code,
    action,
    make,
    model,
    part_id,
    serial,
    tracking,
    link,
    active,
  },
} = checkout;

const validations = Yup.object().shape({
  [component_name.name]: Yup.string().required(component_name.errorMsg),
  [ata_code.name]: Yup.string().required(ata_code.errorMsg),
  [action.name]: Yup.string().required(action.errorMsg),
  [make.name]: Yup.string().required(make.errorMsg),
  [model.name]: Yup.string().required(model.errorMsg),
  [part_id.name]: Yup.string().required(part_id.errorMsg),
  [serial.name]: Yup.string().required(serial.errorMsg),
  [tracking.name]: Yup.string().required(tracking.errorMsg),
  [link.name]: Yup.string().required(link.errorMsg),
  [active.name]: Yup.string().required(active.errorMsg),
});

export default validations;
