


const form = {
  formId: 'new-inspections-templete-form',
  formField: {
    TTAF: {
      name: 'ttfa_hours_limit',
      label: 'TTAF hours limit',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    TTAFW:{
      name: 'ttfa_hours_warning',
      label: 'TTAF hours warning',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    TTAFP:{
      name: 'ttfa_hours_performed',
      label: 'TTAF hours performed',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    TTAFD:{
      name: 'ttfa_hours_next_due',
      label: 'TTAF hours manual next due',
      type: 'number',
      errorMsg: 'Campo requerido',
    },

    calendarLimit: {
      name: 'calendar_limit',
      label: 'Calendar limit',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    calendarWarning: {
      name: 'calendar_warning',
      label: 'Calendar warning',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    calendarPerformed: {
      name: 'calendar_performed',
      label: 'Calendar performed',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    calendarNextDue: {
      name: 'calendar_next_due',
      label: 'Calendar manual next due',
      type: 'date',
      errorMsg: 'Campo requerido',
    },

    cycle_ng: {
      name: 'cycle_ng',
      label: 'Cycle NG',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    cycle_nf: {
      name: 'cycle_nf',
      label: 'Cycle NF',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    cycleLimit: {
      name: 'cycle_limit',
      label: 'Cycle limit',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    cycleWarning: {
      name: 'cycle_warning',
      label: 'Cycle warning',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    cyclePerformed: {
      name: 'cycle_performed',
      label: 'Cycle performed',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    cycleNextDue: {
      name: 'cycle_next_due',
      label: 'Cycle manual next due',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    workReportCorrection: {
      name: 'work_report_correction',
      label: 'Work report correction',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    remark: {
      name: 'remark',
      label: 'Remark',
      type: 'text',
      //errorMsg: 'Campo requerido',
    },
  },
};

// 

export default form;
