import checkout from "./form";

const {
    formField: {
       component_name, ata_code, action, make, model, part_id, serial, tracking, link, active
    }
} = checkout;

export const initialValues = {
    // [aircraft.name]: {label: "", id: ""},
    [component_name.name]: "",
    [ata_code.name]: "",
    [action.name]: "",
    [make.name]: "",
    [model.name]: "",
    [part_id.name]: "",
    [serial.name]: "",
    [tracking.name]: "",
    [link.name]: "",
    [active.name]: "",
}

export const initialValuesFromObj = (obj) => {
    return {
        // [aircraft.name]: {label: '', id: ''},
        [component_name.name]: obj.component_name,
        [ata_code.name]: obj.ata_code,
        [action.name]: obj.action,
        [make.name]: obj.make,
        [model.name]: obj.model,
        [part_id.name]: obj.part_id,
        [serial.name]: obj.serial,
        [tracking.name]: obj.tracking,
        [link.name]: obj.link,
        [active.name]: obj.active,
    }
}
