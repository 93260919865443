import React, { useState } from 'react';
import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';

const AssemblyForm = ({ handleChange, inputs, isView, formData, isUpdate }) => {
  const [activeValue, setActiveValue] = useState();
  const [inputActiveValue, setInputActiveValue] = useState();

  // const [ships, setShips] = useState([]);

  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    component_name,
    ata_code,
    action,
    make,
    model,
    part_id,
    serial,
    tracking,
    link,
    active,
  } = formField;

  const {
    // ship: shipV,
    component_name: component_nameV,
    ata_code: ata_codeV,
    action: actionV,
    make: makeV,
    model: modelV,
    part_id: part_idV,
    serial: serialV,
    tracking: trackingV,
    link: linkV,
    active: activeV,
  } = values;

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormField
            type={component_name.type}
            label={component_name.label}
            name={component_name.name}
            value={component_nameV}
            error={errors.component_name && touched.component_name}
            success={component_nameV.length > 0 && !errors.component_name}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={ata_code.type}
            label={ata_code.label}
            name={ata_code.name}
            value={ata_codeV}
            error={errors.ata_code && touched.ata_code}
            success={ata_codeV.length > 0 && !errors.ata_code}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={action.type}
            label={action.label}
            name={action.name}
            value={actionV}
            error={errors.action && touched.action}
            success={actionV.length > 0 && !errors.action}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={make.type}
            label={make.label}
            name={make.name}
            value={makeV}
            error={errors.make && touched.make}
            success={makeV.length > 0 && !errors.make}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={model.type}
            label={model.label}
            name={model.name}
            value={modelV}
            error={errors.model && touched.model}
            success={modelV.length > 0 && !errors.model}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={part_id.type}
            label={part_id.label}
            name={part_id.name}
            value={part_idV}
            error={errors.part_id && touched.part_id}
            success={part_idV.length > 0 && !errors.part_id}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={serial.type}
            label={serial.label}
            name={serial.name}
            value={serialV}
            error={errors.serial && touched.serial}
            success={serialV.length > 0 && !errors.serial}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={tracking.type}
            label={tracking.label}
            name={tracking.name}
            value={trackingV}
            error={errors.tracking && touched.tracking}
            success={trackingV.length > 0 && !errors.tracking}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField
            type={link.type}
            label={link.label}
            name={link.name}
            value={linkV}
            error={errors.link && touched.link}
            success={linkV.length > 0 && !errors.link}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={[
              { id: 'True', label: 'Activo' },
              { id: 'False', label: 'Inactivo' },
            ]}
            value={activeValue}
            inputValue={inputActiveValue}
            onChange={(event, newValue) => {
              setActiveValue(newValue);
              setFieldValue('active', newValue.id);
            }}
            defaultValue={activeV}
            onInputChange={(event, newInputValue) => {
              setInputActiveValue(newInputValue);
            }}
            disabled={isView}
            renderInput={(params) => (
              <MDInput {...params} variant='standard' label={active.label} />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default AssemblyForm;
