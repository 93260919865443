
import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

export const getShips = (page, filter, value) => {
    let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/vehicle/?".concat("page=".concat(page)).concat(filterStr);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}


export const list_clients_and_pilots = (id) => {
    let route = "/clients/list_vehicles/?id=".concat(id)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const list_pilots = (id) => {
    let route = "/pilot/list_vehicles/?id=".concat(id)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const list_pilots_clients = (id) => {
    let route = "/vehicle/list_clients_and_pilots/?id=".concat(id)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const getShipById = (id) => {
    let route = "/vehicle/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const getShipList = () => {
    let route = "/ship/get_ships_list/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addShip = (data) => {
    let route = "/ship/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const updateShip = (id, data) => {
    let route = "/ship/".concat(id.concat("/"));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "client" || key === "ship"){
            if(typeof data[key] === "object"){
                formData.append(key, data[key].id);
            }
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const deleteShip = (id) => {
    let route = "/vehicle/".concat(id).concat("/");
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getShipParts = (shipId, page) => {
    let route = "/ship_part/?".concat("page=".concat(page)).concat("&aircraft_id=".concat(shipId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getShipPartsList = () => {
    let route = "/ship_part/get_ship_part_list/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getAssemblyShipParts = (assemblyId, page) => {
    let route = "/ship_part/?".concat("page=".concat(page)).concat("&assembly__id=".concat(assemblyId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getAssemly = (shipId, page) => {
    let route = "/assembly/?".concat("page=".concat(page)).concat("&aircraft_id=".concat(shipId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
};

export const getAssemblyById = (id) => {
    let route = "/assembly/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
};

export const getAssemlyList = () => {
    let route = "/assembly/get_assembly_list/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
};

export const addShipPart = (data) => {
    let route ="/ship_part/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "instalation_date" || key === "next_date"){
            formData.append(key, data[key].concat("T00:00"))
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const addAssembly = (data) => {
    let route = "/assembly/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getShipPartById = (id) => {
    let route = "/ship_part/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
};

export const updateShipPart = (data, id) => {
    let route ="/ship_part/".concat(id).concat('/');
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "instalation_date" || key === "next_date"){
            formData.append(key, data[key].concat("T00:00"))
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const deleteShipPart = (id) => {
    let route = "/ship_part/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doDelete(route, headers);
}

export const formatShipParts = (data) => {
    let inputs = {
        id: data.id,
        inventory_detail: data.inventory_detail.id,
        instalation_date: data.instalation_date.slice(0, data.instalation_date.indexOf('T')),
        link: data.link,
        next_date: data.next_date.slice(0, data.next_date.indexOf('T'))
    }
    return inputs;
}

export const fetchShipMotors = (shipId, page) => {
    let route = "/motor/?".concat("page=".concat(page)).concat("&ship_id=".concat(shipId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const fetchShipAircrafts = (shipId, page) => {
    let route = "/aircraft/?".concat("page=".concat(page)).concat("&ship_id=".concat(shipId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getAllAircrafts = () => {
    let route = "/aircraft/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
};

export const fetchAircraftById = (airCraftId) => {
    let route = "/aircraft/".concat(airCraftId);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}

export const addAirCraft = (data) => {
    let route = '/aircraft/';
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
};

export const updateAirCraft = (data, id) => {
    let route = '/aircraft/'.concat(id).concat('/');
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
};

export const updateAssembly = (data, id) => {
    let route = '/assembly/'.concat(id).concat('/');
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const createAircraft = (data, id) => {
    let route = '/ship/'.concat(id).concat('/create_aircraft/')
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const createAircraftTaskFromBP = (data, id) =>{
    let route = '/aircraft/'.concat(id).concat('/create_aircraft_tasks_new/')
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}


