import checkout from "./form";

const {
    formField: {
        work_card,
        ata,
        ata_sect,
        task_number,
        description,
        remarks,
        mod,
        documentation,
        applicability,
        interval,
        margin,
        mpn,
        pn,
        limit_type,
        revision,
        ship,
        employee,
        revision_employee,
    }
} = checkout;

export const initialValues = {
    [work_card.name]:"",
    [ata.name]:"",
    [ata_sect.name]:"",
    [task_number.name]:"",
    [description.name]:"",
    [remarks.name]:"",
    [mod.name]:"",
    [documentation.name]:"",
    [applicability.name]:"",
    [interval.name]:"",
    [margin.name]:"",
    [mpn.name]:"",
    [pn.name]:"",
    [limit_type.name]:"",
    [revision.name]:"",
    [ship.name]:{label: "", id: ""},
    [employee.name]:"",
    [revision_employee.name]:"",
};

export const initialValuesFromObj = (obj) => {
    let resp = {
        [work_card.name]:obj.work_card,
        [ata.name]:obj.ata,
        [ata_sect.name]:obj.ata_sect,
        [task_number.name]:obj.task_number,
        [description.name]:obj.description,
        [remarks.name]:obj.remarks,
        [mod.name]:obj.mod,
        [documentation.name]:obj.documentation,
        [applicability.name]:obj.applicability,
        [interval.name]:obj.interval,
        [margin.name]:obj.margin,
        [mpn.name]:obj.mpn,
        [pn.name]:obj.pn,
        [limit_type.name]:obj.limit_type,
        [revision.name]:obj.revision,
        [ship.name]:{label: obj.ship?.serial_number || "", id: obj.ship?.id || ""},
        [employee.name]:obj.employee?.id ,
        [revision_employee.name]:obj.revision_employee?.id ,
    }
    return resp;
}