const form = {
  formId: 'new-assembly-form',
  formField: {
    aircraft: {
      name: 'aircraft',
      label: 'Aircraft',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    component_name: {
      name: 'component_name',
      label: 'Component Name',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    ata_code: {
      name: 'ata_code',
      label: 'ATA Code',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    action: {
      name: 'action',
      label: 'Action',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    make: {
      name: 'make',
      label: 'Make',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    model: {
      name: 'model',
      label: 'Model',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    part_id: {
      name: 'part_id',
      label: 'Part ID',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    serial: {
      name: 'serial',
      label: 'Serial',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    tracking: {
      name: 'tracking',
      label: 'Tracking',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    link: {
      name: 'link',
      label: 'Link',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    active: {
      name: 'active',
      label: 'Status',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
  },
};

export default form;
