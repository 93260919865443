import React, {useState, useEffect} from "react";

import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from "@mui/material";

import MDBox from '../../../../components/MDBox';
import MDInput from '../../../../components/MDInput';
import FormField from '../../../../components/FormField';

import { getShipList } from '../../../../Services/ShipService'

const TaskForm = ({isView, formData, isUpdate, isSubTask}) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const { 
      work_card,
      ata,
      ata_sect,
      task_number,
      description,
      remarks,
      mod,
      documentation,
      applicability,
      interval,
      margin,
      mpn,
      pn,
      limit_type,
      revision,
      ship,
      employee,
      revision_employee,
    } = formField;
    const { 
      work_card:work_cardV,
      ata:ataV,
      ata_sect:ata_sectV,
      task_number:task_numberV,
      description:descriptionV,
      remarks:remarksV,
      mod:modV,
      documentation:documentationV,
      applicability:applicabilityV,
      interval:intervalV,
      margin:marginV,
      mpn:mpnV,
      pn:pnV,
      limit_type:limit_typeV,
      revision:revisionV,
      ship:shipV,
      employee:employeeV,
      revision_employee:revision_employeeV,
    } = values;

    const [shipOptions, setShipOptions] = useState([]);
    const [shipValue, setShipValue] = useState();
    const [shipInputValue, setShipInputValue] = useState();

    const [employeeOptions, setEmployeOptions] = useState([]);
    const [employeeValue, setEmployeeValue] = useState();
    const [employeeInputValue, setEmployeeInputValue] = useState();
    const [revisionEmployeeValue, setRevisionEmployeeValue] = useState();
    const [revisionEmployeeInputValue, setRevisionEmployeeInputValue] = useState();

    const loadEmployee = () => {}

    const loadShips = () => {
      getShipList().then(resp => {
        setShipOptions(resp.data)
      })
    }

    useEffect(()=> {
      loadShips();
  }, []);

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormField 
            type={work_card.type} label={work_card.label} name={work_card.name} 
            value={work_cardV} error={errors.work_card && touched.work_card} 
            success={work_cardV.length > 0 && !errors.work_card}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={ata.type} label={ata.label} name={ata.name} 
            value={ataV} error={errors.ata && touched.ata} 
            success={ataV.length > 0 && !errors.ata}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={ata_sect.type} label={ata_sect.label} name={ata_sect.name} 
            value={ata_sectV} error={errors.ata_sect && touched.ata_sect} 
            success={ata_sectV.length > 0 && !errors.ata_sect}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField 
            type={task_number.type} label={task_number.label} name={task_number.name} 
            value={task_numberV} error={errors.task_number && touched.task_number} 
            success={task_numberV.length > 0 && !errors.task_number}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={description.type} label={description.label} name={description.name} 
            value={descriptionV} error={errors.ata && touched.description} 
            success={descriptionV.length > 0 && !errors.description}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={remarks.type} label={remarks.label} name={remarks.name} 
            value={remarksV} error={errors.remarks && touched.remarks} 
            success={remarksV.length > 0 && !errors.remarks}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField 
            type={mod.type} label={mod.label} name={mod.name} 
            value={modV} error={errors.mod && touched.mod} 
            success={modV.length > 0 && !errors.mod}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={documentation.type} label={documentation.label} name={documentation.name} 
            value={documentationV} error={errors.documentation && touched.documentation} 
            success={documentationV.length > 0 && !errors.documentation}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={applicability.type} label={applicability.label} name={applicability.name} 
            value={applicabilityV} error={errors.applicability && touched.applicability} 
            success={applicabilityV.length > 0 && !errors.applicability}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField 
            type={interval.type} label={interval.label} name={interval.name} 
            value={intervalV} error={errors.interval && touched.interval} 
            success={intervalV.length > 0 && !errors.interval}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={margin.type} label={margin.label} name={margin.name} 
            value={marginV} error={errors.margin && touched.margin} 
            success={marginV.length > 0 && !errors.margin}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={mpn.type} label={mpn.label} name={mpn.name} 
            value={mpnV} error={errors.mpn && touched.mpn} 
            success={mpnV.length > 0 && !errors.mpn}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField 
            type={pn.type} label={pn.label} name={pn.name} 
            value={pnV} error={errors.pn && touched.pn} 
            success={pnV.length > 0 && !errors.pn}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={limit_type.type} label={limit_type.label} name={limit_type.name} 
            value={limit_typeV} error={errors.margin && touched.limit_type} 
            success={limit_typeV.length > 0 && !errors.limit_type}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormField 
            type={revision.type} label={revision.label} name={revision.name} 
            value={revisionV} error={errors.revision && touched.revision} 
            success={revisionV.length > 0 && !errors.revision}
            InputProps={{readOnly: isView,}} 
          />
        </Grid>
        {!isSubTask && <>
          { (isView || isUpdate) &&
          <Grid item xs={12} md={12}>
              <Typography variant="body2" component="div" >Aircraft: {shipV.label} </Typography>
            </Grid>
          }
          { !(isView) &&
            <Grid item xs={12} md={12}>
                <Autocomplete options={shipOptions}
                        value={shipValue}
                        inputValue={shipInputValue}
                        onChange={(event, newValue) => {
                            console.log(newValue)
                            setShipValue(newValue.id)
                            setFieldValue(ship.name, newValue.id)
                            console.log(shipV)
                            
                        }}
                        defaultValue={shipV}
                        getOptionLabel={(option) => option.label}
                        onInputChange={(event, newInputValue) => {
                            setShipInputValue(newInputValue);
                        }}
                        disabled={isView}
                        renderInput={(params) => <MDInput {...params} variant="standard" label={ship.label} /> } 
                    />
            </Grid>
          }

          <Grid item xs={12} md={6}>
            <FormField 
              type={employee.type} label={employee.label} name={employee.name} 
              value={employeeV} error={errors.employee && touched.employee} 
              success={employeeV.length > 0 && !errors.employee}
              InputProps={{readOnly: isView,}} 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField 
              type={revision_employee.type} label={revision_employee.label} name={revision_employee.name} 
              value={revision_employeeV} error={errors.revision_employee && touched.revision_employee} 
              success={revision_employeeV.length > 0 && !errors.revision_employee}
              InputProps={{readOnly: isView,}} 
            />
          </Grid>
        </>  }
        

      </Grid>
    </MDBox>
  )
}

export default TaskForm