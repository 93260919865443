import checkout from "./form";

const {
    formField: {
        client,
        ATTA_code,
        ship,
        description,
        currency,
        subject,
        tax,
        type,
        reference,
    }
} = checkout;


export const initialValues = (typeValue) => {
    return {
        [client.name]:{label: "", id: ""},
        [ATTA_code.name]:"",
        [ship.name]:{label: "", id: ""},
        [description.name]:"",
        [currency.name]:"",
        [subject.name]:"",
        [tax.name]:"",
        [type.name]:typeValue,
        [reference.name]: "",
    }
}

export const initialValuesFromObj = (obj) => {
    return {
        [client.name]:{label: obj.client.name || "", id: obj.client.id || ""},
        [ATTA_code.name]:obj.ATTA_code,
        [ship.name]:{label: obj.ship.serial_number || "", id: obj.ship.id || ""},
        [description.name]:obj.description,
        [currency.name]:obj.currency,
        [subject.name]:obj.subject,
        [tax.name]:obj.tax,
        [type.name]:obj.type,
        [reference.name]: obj.reference
    }
}