
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import FormField from "../../../../components/FormField";
import Select from "react-select";
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';
import { useState, useEffect } from "react";


const About = ({ isView, formData, options, ships }) => {
    const { formField, values, errors, touched } = formData;
    const { name, email, phone, tax_id, phone_2, fax, note, vehicles } = formField;
    const {
        name: nameV,
        email: emailV,
        phone: phoneV,
        tax_id: tax_idV,
        phone_2: phone_2V,
        fax: faxV,
        note: noteV,
        vehicles: vehiclesV
    } = values;
    const [vehiclesF, setvehiclesF] = useState(true);
    //vehiclesV = options
    return (
        <MDBox mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={name.type} label={name.label} name={name.name}
                        value={nameV} error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={email.type} label={email.label} name={email.name}
                        value={emailV} error={errors.email && touched.email}
                        success={emailV.length > 0 && !errors.email}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={phone.type} label={phone.label} name={phone.name}
                        value={phoneV} error={errors.phone && touched.phone}
                        success={phoneV.length > 0 && !errors.phone}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={phone_2.type} label={phone_2.label} name={phone_2.name}
                        value={phone_2V}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={fax.type} label={fax.label} name={fax.name}
                        value={faxV}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={tax_id.type} label={tax_id.label} name={tax_id.name}
                        value={tax_idV} error={errors.tax_id && touched.tax_id}
                        success={tax_idV.length > 0 && !errors.tax_id}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={note.type} label={note.label} name={note.name}
                        value={noteV}
                        InputProps={{ readOnly: isView, }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    {
                        (isView) ?
                            options.length > 0 &&

                            (<>

                                <Select
                                    placeholder="Nave..."
                                    options={options}
                                    isMulti
                                    isDisabled={isView ? true : false}
                                    defaultValue={isView ? options : null}

                                />
                            </>)
                            :
                            (<>
                                {
                                    options.length > 0 &&
                                        <Select
                                            placeholder="Nave..."
                                            options={ships}
                                            isMulti
                                            isDisabled={false}
                                            defaultValue={options}
                                            onChange={(e) => {
                                                vehiclesV.splice(0, vehiclesV.length)
                                                vehiclesV.push(e)
                                            }}
                                        />}
                                        {
                                        options.length <= 0 &&
                                        <Select
                                            placeholder="Nave..."
                                            options={ships}
                                            isMulti
                                            isDisabled={false}
                                            //defaultValue={options}
                                            onChange={(e) => {
                                                vehiclesV.splice(0, vehiclesV.length)
                                                vehiclesV.push(e)
                                            }}
                                        />
                                }

                            </>)
                    }



                </Grid>
            </Grid>
        </MDBox>
    );
}

export default About;