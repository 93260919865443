import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import { Autocomplete, TextField } from '@mui/material';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';

import SuccessCreatedDialog from '../../../components/SuccessCreatedDialog';
import ContentDialog from '../../../components/ContentDialog';

import InventoryDetail from '../../Inventory/MainInventory/InventoryDetail';

// Wizard page components
import AircraftForm from './components/AircraftForm';

import SnackNotification from '../../../components/SnackNotification';
import DeleteDialog from '../../../components/DeleteDialog';
import DataTable from '../../../components/DataTable';

//Utils
import { buildData } from '../../../utils/tableData';
import {
  shipPartsHeaders,
  shipAssemblyHeaders,
} from '../../../utils/tableHeaders';
import {
  addAirCraft,
  fetchAircraftById,
  getAssemblyShipParts,
  getAssemly,
  getShipParts,
  updateAirCraft,
} from '../../../Services/ShipService';

const CreateAircraft = ({ action }) => {
  const { id } = useParams();
  const { formId, formField } = form;

  const isAdd = action === 'add';
  const isUpdate = action === 'update';
  const isView = action === 'view';

  const [inputs, setInputs] = useState();

  const navigate = useNavigate();

  const [shipParts, setShipParts] = useState(null);
  const [currentShipPartsPage, setCurrentShipPartsPage] = useState(1);
  const [totalShipPartsPages, setTotalShipPartsPages] = useState();
  const [shipPartsLoading, setShipPartsLoading] = useState(false);

  const [assemblies, setAssemblies] = useState(null);
  const [currentAssembliesPage, setCurrentAssembliesPage] = useState(1);
  const [totalAssembliesPages, setTotalAssembliesPages] = useState();
  const [assembliesLoading, setAssembliesLoading] = useState(false);

  const [selectedAssembly, setSelectedAssembly] = useState(null);
  const [assemblyShipParts, setAssemblyShipParts] = useState(null);
  const [currentAssemblyShipPartsPage, setCurrentAssemblyShipPartsPage] =
    useState(1);
  const [totalAssemblyShipPartsPages, setTotalAssemblyShipPartsPages] =
    useState();
  const [assemblyShipPartsLoading, setAssemblyShipPartsLoading] =
    useState(false);

  const loadShipParts = async (page) => {
    setShipPartsLoading(true);

    try {
      const { data } = await getShipParts(id, page);
      setShipParts(buildData(data.ship_parts, shipPartsHeaders()));
      setCurrentShipPartsPage(parseInt(data.current_page));
      setTotalShipPartsPages(data.pages);
    } catch (err) {
      console.log(err);
    } finally {
      setShipPartsLoading(false);
    }
  };

  const loadAssemblies = async (page) => {
    setAssembliesLoading(true);

    try {
      const { data } = await getAssemly(id, page);
      setAssemblies(buildData(data.assembly, shipAssemblyHeaders()));
      setCurrentAssembliesPage(parseInt(data.current_page));
      setTotalAssembliesPages(data.pages);
    } catch (err) {
      console.log(err);
    } finally {
      setAssembliesLoading(false);
    }
  };

  const loadAssemblyShipParts = async (id, page) => {
    setAssemblyShipPartsLoading(true);

    try {
      const { data } = await getAssemblyShipParts(id, page);
      setAssemblyShipParts(buildData(data.ship_parts, shipPartsHeaders()));
      setCurrentAssemblyShipPartsPage(parseInt(data.current_page));
      setTotalAssemblyShipPartsPages(data.pages);
    } catch (err) {
      console.log(err);
    } finally {
      setAssemblyShipPartsLoading(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (isAdd) {
      const { data } = await addAirCraft(values);
      navigate(`/ships/aircraft/view/${data?.id}`, { replace: true });
    } else if (isUpdate) {
      const { data } = await updateAirCraft(values, id);
      navigate(`/ships/aircraft/view/${data?.id}`, { replace: true });
    }
    actions.setSubmitting(false);
  };

  const onAssemblySelectHandler = (assembly) => {
    setSelectedAssembly(assembly?.id);
  };

  const handleChangePageAssemblyShipParts = (event, value) => {
    setCurrentAssembliesPage(value);
    loadAssemblyShipParts(selectedAssembly, value);
  };

  useEffect(() => {
    if (selectedAssembly !== null) {
      loadAssemblyShipParts(selectedAssembly, 1);
    }
  }, [selectedAssembly]);

  useEffect(() => {
    if (id != null) {
      fetchAircraftById(id)
        .then((resp) => {
          setInputs(resp.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id]);

  const handleChangePageShipParts = (event, value) => {
    setCurrentShipPartsPage(value);
    loadShipParts(value);
  };

  const handleChangePageAssembly = (event, value) => {
    setCurrentAssembliesPage(value);
    loadAssemblies(value);
  };

  useEffect(() => {
    if (id !== undefined) {
      loadShipParts(1);
      loadAssemblies(1);
      setSelectedAssembly(null);
      setAssemblyShipParts(null);
    }
  }, [id]);

  return (
    <MDBox pt={3} pb={8}>
      <Grid container justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign='center'>
            <MDBox mb={1}>
              <MDTypography variant='h3' fontWeight='bold'>
                {isAdd && 'Creacion de Aircraft'}
                {isUpdate && 'Edicion de Aircraft'}
                {isView && 'Visualizacion de Aircraft'}
              </MDTypography>
            </MDBox>
            {inputs != null && inputs !== undefined && (
              <div>
                <MDBox
                  mb={1}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    No. {inputs.id}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Date: {inputs.created_at}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Created By: {inputs.user.name}
                  </MDTypography>
                </MDBox>
                <Divider sx={{ margin: '0.5rem 0' }} />
              </div>
            )}
          </MDBox>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <AircraftForm
                      isView={isView}
                      isUpdate={isUpdate}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <Link to='/ships/list' style={{ alignSelf: 'start' }}>
                        <MDButton
                          variant='gradient'
                          color='error'
                          sx={{ height: '100%' }}
                        >
                          Cancelar
                        </MDButton>
                      </Link>
                      <MDButton
                        disabled={isView}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>

          {shipParts && (
            <>
              <Typography
                variant='h4'
                component='div'
                style={{ marginTop: 25, marginBottom: 15 }}
              >
                Ship parts
              </Typography>

              <MDBox
                display='flex'
                justifyContent='space-between'
                alignItems='flex-start'
                mb={2}
              >
                <Link to={`/ships/shipparts/create?aircraft=${inputs?.id}`}>
                  <MDButton variant='gradient' color='info'>
                    Agregar
                  </MDButton>
                </Link>
              </MDBox>

              <Card>
                {shipParts.rows !== undefined && shipParts.rows.length > 0 && (
                  <>
                    <DataTable
                      //   handleSearch={handleSearch}
                      useActions
                      useView
                      useEdit
                      // useDelete
                      // useSelect
                      // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                      editAction={(data) =>
                        navigate(
                          `/ships/shipparts/update/${data.id}?aircraft=${inputs?.id}`
                        )
                      }
                      viewAction={(data) =>
                        navigate(`/ships/shipparts/view/${data.id}`)
                      }
                      // deleteAction={deleteRegister}
                      table={shipParts}
                      showTotalEntries={false}
                      entriesPerPage={false}
                      // canSearch
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={totalShipPartsPages}
                        page={currentShipPartsPage}
                        onChange={handleChangePageShipParts}
                      />{' '}
                    </MDBox>{' '}
                  </>
                )}
                {shipPartsLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )}
                {shipParts?.rows !== undefined && shipParts?.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen ship parts
                  </Typography>
                )}
              </Card>
            </>
          )}

          {assemblies && (
            <>
              <Typography
                variant='h4'
                component='div'
                style={{ marginTop: 25, marginBottom: 15 }}
              >
                Assembly
              </Typography>

              <MDBox
                display='flex'
                alignItems='flex-start'
                // mb={2}
              >
                <Link to={`/ships/assembly/create?aircraft=${inputs?.id}`}>
                  <MDButton
                    variant='gradient'
                    color='info'
                    onClick={() => console.log('agregar assembly')}
                  >
                    Agregar
                  </MDButton>
                </Link>

                {assemblyShipParts && <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  style={{ marginLeft: 10 }}
                  mb={2}
                >
                  <Link
                    to={`/ships/shipparts/create?aircraft=${inputs?.id}&assembly=${selectedAssembly}`}
                  >
                    <MDButton variant='gradient' color='info'>
                      Agregar Assembly Part
                    </MDButton>
                  </Link>
                </MDBox>}
              </MDBox>

              <Card>
                {assemblies.rows !== undefined && assemblies.rows.length > 0 && (
                  <>
                    <DataTable
                      //   handleSearch={handleSearch}
                      useActions
                      //   useView
                      useEdit
                      //   useDelete
                      useSelect
                      selectAction={onAssemblySelectHandler}
                      editAction={(data) =>
                        navigate(`/ships/assembly/update/${data.id}`)
                      }
                      //   viewAction={viewRegister}
                      //   deleteAction={deleteRegister}
                      table={assemblies}
                      showTotalEntries={false}
                      entriesPerPage={false}
                      collapseContent={(data) => {
                        return (
                          <>
                            {assemblyShipParts && (
                              <>
                                <Card style={{backgroundColor: '#fcfcfc'}}>
                                  {assemblyShipParts.rows !== undefined &&
                                    assemblyShipParts.rows.length > 0 && (
                                      <>
                                        <DataTable
                                          //   handleSearch={handleSearch}
                                          useActions
                                          useView
                                          useEdit
                                          // useDelete
                                          // useSelect
                                          // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                                          editAction={(data) =>
                                            navigate(
                                              `/ships/shipparts/update/${data.id}?aircraft=${inputs?.id}&assembly=${selectedAssembly}`
                                            )
                                          }
                                          viewAction={(data) =>
                                            navigate(
                                              `/ships/shipparts/view/${data.id}`
                                            )
                                          }
                                          // deleteAction={deleteRegister}
                                          table={assemblyShipParts}
                                          showTotalEntries={false}
                                          entriesPerPage={false}
                                          // canSearch
                                        />
                                        <MDBox ml={1}>
                                          <Pagination
                                            sx={{
                                              marginTop: '20px',
                                              marginBottom: '20px',
                                            }}
                                            color='info'
                                            count={totalAssemblyShipPartsPages}
                                            page={currentAssemblyShipPartsPage}
                                            onChange={
                                              handleChangePageAssemblyShipParts
                                            }
                                          />{' '}
                                        </MDBox>{' '}
                                      </>
                                    )}
                                  {assemblyShipPartsLoading && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '200px',
                                      }}
                                    >
                                      <CircularProgress
                                        color='info'
                                        size={80}
                                      />
                                    </Box>
                                  )}
                                  {assemblyShipParts?.rows !== undefined &&
                                    assemblyShipParts?.rows.length === 0 && (
                                      <Typography
                                        variant='h4'
                                        component='div'
                                        sx={{ margin: '100px' }}
                                      >
                                        No ship parts asociated to this assembly
                                      </Typography>
                                    )}
                                </Card>
                              </>
                            )}
                          </>
                        );
                      }}
                      // canSearch
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={totalAssembliesPages}
                        page={currentAssembliesPage}
                        onChange={handleChangePageAssembly}
                      />{' '}
                    </MDBox>{' '}
                  </>
                )}
                {assembliesLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )}
                {assemblies?.rows !== undefined &&
                  assemblies?.rows.length === 0 && (
                    <Typography
                      variant='h4'
                      component='div'
                      sx={{ margin: '100px' }}
                    >
                      No Existen assemblies
                    </Typography>
                  )}
              </Card>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default CreateAircraft;
