/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {name,
    address,
    city,
    state,
    zip_code,
    country,
    note,
    tax_id,
    phone,
    phone_2,
    fax,
    email,
    contact_name_1,
    contact_phone_1,
    contact_name_2,
    contact_phone_2,
    contact_name_3,
    contact_phone_3,
    },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [phone.name]: Yup.string().required(phone.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [tax_id.name]: Yup.string().required(tax_id.errorMsg),
  }),
  Yup.object().shape({
    [address.name]: Yup.string().required(address.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [country.name]: Yup.string().required(country.errorMsg),
    [zip_code.name]: Yup.string().required(zip_code.errorMsg),
  }),
  Yup.object().shape({
    [contact_name_1.name]: Yup.string().required(contact_name_1.errorMsg),
    [contact_phone_1.name]: Yup.string().required(contact_phone_1.errorMsg),
  }),
];

export default validations;
