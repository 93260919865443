import { useState, useEffect, useRef, useMemo } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import axios from "axios";
// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "./formLayout";
import countryList from 'react-select-country-list'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


// Images
import bgImage from "../../assets/images/fn.jpg";
import './form.css'
import { getClientSatisID, getQualitySupplierID } from "../../Services/formServices";
import Loader from "../../components/Loader";

function ClientSatisfaction({ action }) {
  const params = useParams();
  const userToken = localStorage.getItem('Token') || '';
  const [geographic, setGeographic] = useState("");
  const [service, setService] = useState('');
  const [serviceMetric, setServiceMetric] = useState();
  const [contactService, setContactService] = useState();
  const [comeback, setComeback] = useState();
  const [comments, setComments] = useState('');
  const [clientName, setClientName] = useState();
  const [clientSecName, setClientSecName] = useState();
  const [company, setCompany] = useState();
  const [email, setEmail] = useState();
  const [servicesList, setServicesList] = useState([0, 0, 0, 0, 0]);
  const [metricsList, setMetricsList] = useState([0, 0, 0, 0, 0]);


  const [Address, setAddress] = useState();
  const [Telephone, setTelephone] = useState();
  
  const [Product, setProduct] = useState();
  const [business, setBusiness] = useState();
  const [Quality, setQuality] = useState();
  const [Engineering, setEngineering] = useState();
  const [Manufacturing, setManufacturing] = useState();
  const [Points, setPoints] = useState();
  const [PointsName, setPointsName] = useState();
  
  const [qualitygScore, setqualitygScore] = useState([]);
  const [receivingScore, setreceivingScore] = useState([]);
  const [inprocessScore, setinprocessScore] = useState([]);
  const [measuringSore, setmeasuringSore] = useState([]);
  const [controlScore, setcontrolScore] = useState([]);
  const [finalScore, setfinalScore] = useState([]);
  const [shippingScore, setshippingScore] = useState([]);


  

  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);

  const countries = useMemo(() => countryList().getData(), [])

  const rates = [
    { label: "YES", value: "1" },
    { label: "NO", value: "2" },
    { label: "UNDER WAY", value: "3" },
    { label: "N/A", value: "0" },
  ];


  const answersQuality = '0,1,2,1,3,2,0'
  const questionQuality = [
    {
      label: "The quality assurance organization's authorities and responsibilities are clearly defined in writing.",
      value: 0
    },
    {
      label: "The QA/QC Dept. has the authority to withhold items that have not met acceptable quality standards.",
      value: 1
    },
    {
      label: "There is a Quality System Manual defining the company’s QMS in detail.  ",
      value: 2
    },
    {
      label: "The company prepares and issues periodic reports and maintains records relative to item acceptance/rejection, and disposition of rejected items.",
      value: 3
    },
    {
      label: "The quality assurance organization maintains a system for the use and control of inspection stamps.",
      value: 4
    },
    {
      label: "The quality assurance organization has a system for quality evaluations of potential suppliers.",
      value: 5
    },
    {
      label: "The quality assurance organization reviews all purchase orders.",
      value: 6
    },
  ]

  const receiving = [
    {
      label: "Is each lot of material received subjected to receiving inspection?",
      value: 1
    },
    {
      label: "Inspectors are provided with adequate inspection instructions.",
      value: 2
    },
    {
      label: "Does a documentation system exist to trace lot of material received until lot is expended?",
      value: 3
    },
    {
      label: "Can evidence of receiving inspection acceptance be found in each lot of material as it moves through the manufacturing process?",
      value: 4
    },
    {
      label: "Drawings used by receiving inspection are legible and reflect the latest changes.",
      value: 5
    },
    {
      label: "Sampling inspection, when applicable is performed in compliance with established recognized standards.",
      value: 6
    },
  ]

  const inprocess = [
    {
      label: "Is there an in-process traveler or inspection record on each unit or lot?",
      value: 1
    },
    {
      label: "Do manufacturing and quality personnel annotate the traveler for each operation performed?",
      value: 2
    },
    {
      label: "Adequate inspection instructions are made available to all in-process inspection personnel?",
      value: 3
    },
    {
      label: "Drawings used by inspection are legible and reflect the latest changes.",
      value: 4
    },
    {
      label: "The measuring devices, gauges and test equipment required for in-process inspection are available and are adequate.",
      value: 5
    },
    {
      label: "Sampling inspection, when applicable, is performed in compliance with established, recognized standards.",
      value: 6
    },
    {
      label: "The supplier maintains a system for the proper identification of the inspection status of in-process materials.",
      value: 7
    },
  ]

  const measuring = [
    {
      label: "Whenever measuring devices, gauges or test equipment items are reworked, they are inspected and calibrated prior to use.",
      value: 1
    },
    {
      label: "When new measuring devices, gauges and test equipment are acquired, they are inspected and calibrated prior to use.",
      value: 2
    },
    {
      label: "The processes for calibrating measuring devices, gauges and test equipment are covered by written procedures.",
      value: 3
    },
    {
      label: "All measuring devices, gauges and test equipment carry stamps which indicate the most recent calibration date and the date when the next calibration is to be performed.",
      value: 4
    },
    {
      label: "The supplier's quality assurance organization maintains a system for the automatic recall and periodic recalibration of all measuring devices, gauges and test equipment.",
      value: 5
    },
    {
      label: "Are calibration certificates with NIST traceability on file for all tools or calibration standards?",
      value: 6
    },
  ]

  const control = [
    {
      label: "The supplier maintains a documented system for handling of nonconforming materials",
      value: 1
    },
    {
      label: "The supplier maintains a system for removing nonconforming supplies from the product flow.",
      value: 2
    },
    {
      label: "The supplier maintains a system for taking corrective action in order to prevent repetitive discrepancies.",
      value: 3
    },
    {
      label: "The supplier maintains a system for following up on all corrective action requests.",
      value: 4
    },
    {
      label: "Reports on nonconforming materials are regularly prepared and are reviewed by management for action.",
      value: 5
    },

  ]

  const final = [
    {
      label: "Adequate inspection instructions are available to final inspection personnel.",
      value: 1
    },
    {
      label: "Written instructions and procedures are readily available to all final inspection personnel.",
      value: 2
    },
    {
      label: "Drawings used by final inspection are legible and reflect the latest changes.",
      value: 3
    },
    {
      label: "The measuring devices, gauges and test equipment required for final inspection are available and adequate.",
      value: 4
    },
    {
      label: "Sampling inspection, when applicable, is performed in compliance with established, recognized standards.",
      value: 5
    },

  ]

  const shipping = [
    {
      label: "The supplier's quality assurance organization operates a shipping inspection function.",
      value: 1
    },
    {
      label: "All shipping inspection operations are performed in accordance with written instructions.",
      value: 2
    },
    {
      label: "Shipping inspectors have ready access to customer specified packaging instructions.",
      value: 3
    },
    {
      label: "When required, certified packaging materials are used.",
      value: 4
    },


  ]
  const rates1 = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ];

  const jsonAnswers = {
    geo_site: geographic,
    services: servicesList,
    metrics: metricsList,
    contact_rec: contactService,
    comeback: comeback,
    comments: comments,
    name: clientName,
    sec_name: clientSecName,
    company: company,
    email: email,
    otherservi: service

  };

  const createClientSatis = () => {

    const processData = new FormData();
    console.log(jsonAnswers)
    processData.append('company_name', company);
    processData.append('address', Address);
    processData.append('telephone', Telephone);
    processData.append('primary_business', business);
    processData.append('product_considered', Product);
    processData.append('quality', Quality);
    processData.append('engineering', Engineering);
    processData.append('manufacturing', Manufacturing);
    processData.append('point_of_contact', Points);
    processData.append('point_of_contact_name', PointsName);

    
    processData.append('quality_assurance_info', qualitygScore);
    processData.append('receiving_inspection', receivingScore);
    processData.append('in_process_inspection', inprocessScore);
    processData.append('measuring_devices', measuringSore);
    processData.append('control_of_product', controlScore);
    processData.append('final_inspection', finalScore);
    processData.append('shipping_and_packing', shippingScore);

    

    axios.post(
      'https://fn.devpackgroup.com/api/v1/supplier_quality/',
      processData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Proceso Creado Exitosamente")
      window.location.replace("/SupplierQuality_List");
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      alert("Error al crear proceso")
    }.bind(this))
  };

  const handleGeoChange = (event) => {
    setGeographic(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleMetricChange = (event) => {
    setServiceMetric(event.target.value);
  };

  const handleBackChange = (event) => {
    setComeback(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComments(event.target.value);
  };

  const handleNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handleSecNameChange = (event) => {
    setClientSecName(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleMailChange = (event) => {
    setEmail(event.target.value);
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getQualitySupplierID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    (action === 'view') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);


  return (
    !flag?
<Loader/>
    :
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Supplier Quality Survey Form
          </MDTypography>
        </MDBox>
      </Card>
      <br />
      <br />
      {/* <br />
      <p style={{ fontSize: "2vh" }}>Estimado cliente,</p>
      <br />
      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
        Acaba de recibir un servicio ofrecido por Franconaves. La Satisfacción
        de nuestros clientes es nuestro principal motor en Franconaves. Por lo
        tanto, nos gustaria recibir sus comentarios sobre los servicios
        proporcionados por nuestro equipo. Agradecemos los pocos minutos que le
        llevará contestar este breve cuestionario.
      </p>
      <br />
      <p style={{ fontSize: "2vh" }}>Atentamente,</p>
      <p style={{ fontSize: "2vh" }}>El equipo de Franconaves.</p>
      <br />
      <br />
      <br /> */}
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Company Name{" "}
            </p>
            <br />
            {
 
                <MDInput
                  type="text"
                  label="Company Name"
                  variant="standard"
                  fullWidth
                  placeholder="Company Name"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setCompany(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.company_name : null}
                />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Address{" "}
            </p>
            <br />
            {

                <MDInput
                  type="text"
                  label="Address"
                  variant="standard"
                  fullWidth
                  placeholder="Address"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setAddress(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.address : null}
                />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Telephone{" "}
            </p>
            <br />
            {

              /*   <MDInput
                  type="text"
                  label="Telephone"
                  variant="standard"
                  fullWidth
                  placeholder="Telephone"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setTelephone(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.telephone : null}
                /> */
                <PhoneInput
                        placeholder="Enter phone number"
                        value={(action === 'update' || action === 'view' ) && inputs ? inputs.telephone : null}
                        onChange={setTelephone} 
                        defaultCountry="GT"
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Supplier’s Primary Business/Product{" "}
            </p>
            <br />
            {
                <MDInput
                  type="text"
                  label="Supplier’s Primary Business/Product"
                  variant="standard"
                  fullWidth
                  placeholder="Supplier’s Primary Business/Product"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setBusiness(e.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.primary_business : null}
                />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Product Being Considered From This Source{" "}
            </p>
            <br />
            {
            
                <MDInput
                  type="text"
                  label="Product Being Considered From This Source"
                  variant="standard"
                  fullWidth
                  placeholder="Product Being Considered From This Source"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setProduct(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.product_considered : null}
                />
            }
          </MDBox>
          <p>
            Total Number Of Personnel:
          </p>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Quality{" "}
            </p>
            <br />
            {
      
                <MDInput
                  type="text"
                  label="Quality"
                  variant="standard"
                  fullWidth
                  placeholder="Quality"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setQuality(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.company_name : null}
                />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Engineering{" "}
            </p>
            <br />
            {

              <MDInput
                type="text"
                label="Engineering"
                variant="standard"
                fullWidth
                placeholder="Engineering"
                InputLabelProps={{ shrink: true }}
                onChange={e => setEngineering(e.target.value)}
                InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.engineering : null}
              />
            }
          </MDBox>
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Manufacturing{" "}
            </p>
            <br />
            {

              <MDInput
                type="text"
                label="Manufacturing"
                variant="standard"
                fullWidth
                placeholder="Manufacturing"
                InputLabelProps={{ shrink: true }}
                onChange={e => setManufacturing(e.target.value)}
                InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.manufacturing : null}
              />
            }
          </MDBox>
          <MDBox mb={6}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Points of Contact{" "}
            </p>
            <br />
            {

              <MDInput
                type="text"
                label="Points of Contact"
                variant="standard"
                fullWidth
                placeholder="Points of Contact"
                InputLabelProps={{ shrink: true }}
                onChange={e => setPoints(e.target.value)}
                InputProps={{ readOnly: action === 'view' ? true : false, }}
                value={(action === 'view') && inputs ? inputs.point_of_contact : null}
              />
            }
            

                <MDInput
                  type="text"
                  label="Name"
                  variant="standard"
                  fullWidth
                  placeholder="Name"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setPointsName(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  value={(action === 'view') && inputs ? inputs.point_of_contact_name : null}
                />
             
            
          </MDBox>



























          <br />
          {/*  <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              2. ¿Cual de los siguientes servicios utilizaste el dia de hoy?{" "}
            </p>
            <br />
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[0] = 0)} checked={inputs.services.split(",")[0] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[0] = 0)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Reparaciones y Overhaul
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[1] = 1)} checked={inputs.services.split(",")[1] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[1] = 1)} />
                }

                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Pedido de Repuestos
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[2] = 2)} checked={inputs.services.split(",")[2] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[2] = 2)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Servicio estándar de intercambio o alquiler
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[3] = 3)} checked={inputs.services.split(",")[3] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[3] = 3)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Servicio estándar de intercambio o alquiler bajo contrato de
                  soporte por horas
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[4] = 4)} checked={inputs.services.split(",")[4] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[4] = 4)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Asistencia técnica realizada por un Network Partner
                </FormLabel>
              </li>
              <li>
                <br />
                {
                  action === 'view' ?
                    <p>
                      Otros: <br></br>
                      {inputs.other_services}
                    </p>
                    :
                    <MDInput
                      type="text"
                      label="Especifique otra"
                      variant="standard"
                      fullWidth
                      placeholder="Especifique otra"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleServiceChange}
                    />
                }
              </li>
            </ul>
          </MDBox>
          <br /> */}
          <MDBox mb={3}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              QUALITY ASSURANCE INFORMATION{" "}
            </p>
            <br />
            <hr />
            <br />
            {
              questionQuality.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.quality_assurance_info.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  qualitygScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  qualitygScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }


            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              RECEIVING INSPECTION{" "}
            </p>
            {
              receiving.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.receiving_inspection.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  receivingScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  receivingScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }
            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              IN-PROCESS INSPECTION{" "}
            </p>
            {
              inprocess.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.in_process_inspection.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  inprocessScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  inprocessScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }


            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              MEASURING DEVICES AND TEST EQUIPMENT{" "}
            </p>
            {
              measuring.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.measuring_devices.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  measuringSore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  measuringSore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }



            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              CONTROL OF NONCONFORMING PRODUCT{" "}
            </p>
            {
              control.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.control_of_product.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  controlScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  controlScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }


            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              FINAL INSPECTION{" "}
            </p>
            {
              final.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={inputs.final_inspection.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  finalScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  finalScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }


            <br />
            <br />
            <hr />
            <br />
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              SHIPPING AND PACKAGING{" "}
            </p>
            {
              shipping.map((index, key) =>
              (
                <>
                  <div
                    style={{
                      fontSize: "1.5vh",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ textAlign: "justify", width: "35%" }}>
                      {index.label}{" "}
                    </div>
                    <div style={{ marginLeft: "4vh" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {rates.map((index, id) => (
                          <>
                            {action === 'view' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                
                                checked={inputs.shipping_and_packing.split(",")[key] == index.value ? true : false}
                                control={<Radio onClick={() => {
                                  shippingScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                //checked = {answersQuality.split(",")[key] ==  id? true : false}
                                control={<Radio onClick={() => {
                                  shippingScore[key] = index.value
                                  //jsonAnswers.metrics.at(0).rate = index.value
                                  console.log(jsonAnswers.metrics.at(0).rate)
                                }} />}
                              />

                            }
                          </>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))
            }
          </MDBox>


          <br />
<MDButton
          variant='gradient'
          color='error'
          style={{marginRight: '10px'}}
          sx={{ height: '100%' }}
          onClick={() => window.location.replace("/SupplierQuality_List")}
        >
          Cancelar
        </MDButton>
          <MDBox mt={4} mb={1}>
          {action === 'view'?
          <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => window.location.replace("/SupplierQuality_List")}
            >
              Regresar
            </MDButton>
          :
            <MDButton
              variant="gradient"
              color="success"
              fullWidth
              onClick={() => createClientSatis()}
            >
              Enviar
            </MDButton>}
          </MDBox>
        </MDBox>
      </MDBox>
    </CoverLayout>
  );
}
export default ClientSatisfaction;
