import { useState, useEffect, useRef, useMemo } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import axios from "axios";
// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import countryList from 'react-select-country-list'



// Images
import bgImage from "../../assets/images/fn.jpg";
import './form.css'
import { getClientSatisID } from "../../Services/formServices";
import Loader from "../../components/Loader";

function ClientSatisfaction({ action }) {
  const params = useParams();
  const userToken = localStorage.getItem('Token') || '';
  const [geographic, setGeographic] = useState("");
  const [service, setService] = useState('');
  const [serviceMetric, setServiceMetric] = useState();
  const [contactService, setContactService] = useState();
  const [comeback, setComeback] = useState();
  const [comments, setComments] = useState('');
  const [clientName, setClientName] = useState();
  const [clientSecName, setClientSecName] = useState();
  const [company, setCompany] = useState();
  const [email, setEmail] = useState();
  const [servicesList, setServicesList] = useState([0, 0, 0, 0, 0]);
  const [metricsList, setMetricsList] = useState([0, 0, 0, 0, 0]);


  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);

  const countries = useMemo(() => countryList().getData(), [])

  const rates = [
    { label: "Muy Malo", value: "0" },
    { label: "Malo", value: "1" },
    { label: "Bueno", value: "2" },
    { label: "Muy Bueno", value: "3" },
  ];

  const rates1 = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ];

  const jsonAnswers = {
    geo_site: geographic,
    services: servicesList,
    metrics: metricsList,
    contact_rec: contactService,
    comeback: comeback,
    comments: comments,
    name: clientName,
    sec_name: clientSecName,
    company: company,
    email: email,
    otherservi: service

  };

  const createClientSatis = () => {

    const processData = new FormData();
    processData.append('country', geographic);
    processData.append('services', servicesList);
    processData.append('other_services', service);
    processData.append('punctuation', metricsList);
    processData.append('contacts', contactService);
    processData.append('new_services', comeback);
    processData.append('commments', comments);
    processData.append('user_name', clientName);
    processData.append('user_last_name', clientSecName);
    processData.append('user_company', company);
    processData.append('user_email', email);


    axios.post(
      'https://fn.devpackgroup.com/api/v1/client_satisfaction/create_client_satisfaction/',
      processData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      alert("Encuesta de Satisfacción Creado Exitosamente")
      window.location.replace("/ClientSatisfactionList");
    }.bind(this)).catch(function (error) {
      alert("Error al crear Encuesta de Satisfacción")
    }.bind(this))
  };

  const handleGeoChange = (event) => {
    setGeographic(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleMetricChange = (event) => {
    setServiceMetric(event.target.value);
  };

  const handleBackChange = (event) => {
    setComeback(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComments(event.target.value);
  };

  const handleNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handleSecNameChange = (event) => {
    setClientSecName(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleMailChange = (event) => {
    setEmail(event.target.value);
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getClientSatisID(params.id).then(resp => {
        setInputs(resp.data)
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    (action === 'view') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);


  return (
    !flag? 
    <Loader/>
    :
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Encuesta de Satisfacción del Cliente
          </MDTypography>
        </MDBox>
      </Card>
      <br />
      <br />
      <br />
      <p style={{ fontSize: "2vh" }}>Estimado cliente,</p>
      <br />
      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
        Acaba de recibir un servicio ofrecido por Franconaves. La Satisfacción
        de nuestros clientes es nuestro principal motor en Franconaves. Por lo
        tanto, nos gustaria recibir sus comentarios sobre los servicios
        proporcionados por nuestro equipo. Agradecemos los pocos minutos que le
        llevará contestar este breve cuestionario.
      </p>
      <br />
      <p style={{ fontSize: "2vh" }}>Atentamente,</p>
      <p style={{ fontSize: "2vh" }}>El equipo de Franconaves.</p>
      <br />
      <br />
      <br />
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              1. Ingrese el área geográfica en la que reside{" "}
            </p>
            <br />
            {
              action === 'view' ?
                <h1>
                  {inputs.country}
                </h1>
                :
                <Select placeholder="Área Geográfica" options={countries} onChange={(o) => (setGeographic(o.label))}></Select>
            }
          </MDBox>
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              2. ¿Cual de los siguientes servicios utilizaste el dia de hoy?{" "}
            </p>
            <br />
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[0] = 1)} checked={inputs.services.split(",")[0] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[0] = 1)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Reparaciones y Overhaul
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[1] = 1)} checked={inputs.services.split(",")[1] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[1] = 1)} />
                }

                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Pedido de Repuestos
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[2] = 1)} checked={inputs.services.split(",")[2] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[2] = 1)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Servicio estándar de intercambio o alquiler
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[3] = 1)} checked={inputs.services.split(",")[3] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[3] = 1)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Servicio estándar de intercambio o alquiler bajo contrato de
                  soporte por horas
                </FormLabel>
              </li>
              <li>
                {
                  action === 'view' ?
                    <Checkbox onChange={() => (servicesList[4] = 1)} checked={inputs.services.split(",")[4] > 0 ? true : false} />
                    :
                    <Checkbox onChange={() => (servicesList[4] = 1)} />
                }
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Asistencia técnica realizada por un Network Partner
                </FormLabel>
              </li>
              <li>
                <br />
                {
                  action === 'view' ?
                    <p>
                      Otros: <br></br>
                      {inputs.other_services}
                    </p>
                    :
                    <MDInput
                      type="text"
                      label="Especifique otra"
                      variant="standard"
                      fullWidth
                      placeholder="Especifique otra"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleServiceChange}
                    />
                }
              </li>
            </ul>
          </MDBox>
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              3. Según el servicio seleccionado, puntee las siguientes métricas.{" "}
            </p>
            <br />
            <hr />
            <br />
            <div
              style={{
                fontSize: "1.5vh",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ textAlign: "justify", width: "35%" }}>
                La facilidad de acceder al servicio al cliente de Franconaves{" "}
              </div>
              <div style={{ marginLeft: "4vh" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {rates.map((index, id) => (
                    <>
                      {action === 'view' ?
                        <FormControlLabel
                          style={{ display: "flex", flexDirection: "row" }}
                          value={index.value}
                          label={index.label}
                          checked={action === 'view' && inputs.punctuation.split(",")[0] == id ? true : false}
                          control={<Radio onClick={() => {
                            metricsList[0] = index.value
                            //jsonAnswers.metrics.at(0).rate = index.value
                          }} />}
                        />
                        :
                        <FormControlLabel
                          style={{ display: "flex", flexDirection: "row" }}
                          value={index.value}
                          label={index.label}
                          //checked = {action === 'view' && inputs.punctuation.split(",")[0] ==  id? true : false}
                          control={<Radio onClick={() => {
                            metricsList[0] = index.value
                            //jsonAnswers.metrics.at(0).rate = index.value
                          }} />}
                        />

                      }
                    </>
                  ))}
                </RadioGroup>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div
              style={{
                fontSize: "1.5vh",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ textAlign: "justify", width: "35%" }}>
                Comunicación con Franconaves{" "}
              </div>
              <div style={{ marginLeft: "4vh" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {rates.map((index, id) => (
                    <>
                      {
                        action === 'view' ?
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            checked={action === 'view' && inputs.punctuation.split(",")[1] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[1] = index.value
                              //jsonAnswers.metrics.at(1).rate = index.value
                            }} />}
                          />
                          :
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            //checked = {action === 'view' && inputs.punctuation.split(",")[1] ==  id? true : false}
                            control={<Radio onClick={() => {
                              metricsList[1] = index.value
                              //jsonAnswers.metrics.at(1).rate = index.value
                            }} />}
                          />
                      }
                    </>
                  ))}
                </RadioGroup>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div
              style={{
                fontSize: "1.5vh",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ textAlign: "justify", width: "35%" }}>
                El equipo de Franconaves{" "}
              </div>
              <div style={{ marginLeft: "4vh" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {rates.map((index, id) => (
                    <>
                      {
                        action === 'view' ?
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            checked={action === 'view' && inputs.punctuation.split(",")[2] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[2] = index.value
                              //jsonAnswers.metrics.at(2).rate = index.value
                            }} />}
                          />
                          :
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            //checked={action === 'view' && inputs.punctuation.split(",")[2] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[2] = index.value
                              //jsonAnswers.metrics.at(2).rate = index.value
                            }} />}
                          />
                      }
                    </>
                  ))}
                </RadioGroup>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div
              style={{
                fontSize: "1.5vh",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ textAlign: "justify", width: "35%" }}>
                El conocimiento de Franconaves sobre los productos y servicios
                que ofrecen{" "}
              </div>
              <div style={{ marginLeft: "4vh" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {rates.map((index, id) => (
                    <>
                      {
                        action === 'view' ?
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            checked={action === 'view' && inputs.punctuation.split(",")[3] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[3] = index.value
                              //jsonAnswers.metrics.at(3).rate = index.value
                            }} />}
                          />
                          :
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            //checked={action === 'view' && inputs.punctuation.split(",")[3] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[3] = index.value
                              //jsonAnswers.metrics.at(3).rate = index.value
                            }} />}
                          />
                      }
                    </>
                  ))}
                </RadioGroup>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div
              style={{
                fontSize: "1.5vh",
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
              }}
            >
              <div style={{ textAlign: "justify", width: "35%" }}>
                El tiempo de atención{" "}
              </div>
              <div style={{ marginLeft: "4vh" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {rates.map((index, id) => (
                    <>
                      {
                        action === 'view' ?
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            checked={action === 'view' && inputs.punctuation.split(",")[4] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[4] = index.value
                              //jsonAnswers.metrics.at(4).rate = index.value
                            }} />}
                          />
                          :
                          <FormControlLabel
                            style={{ display: "flex", flexDirection: "row" }}
                            value={index.value}
                            label={index.label}
                            //checked={action === 'view' && inputs.punctuation.split(",")[4] == id ? true : false}
                            control={<Radio onClick={() => {
                              metricsList[4] = index.value
                              //jsonAnswers.metrics.at(4).rate = index.value
                            }} />}
                          />
                      }
                    </>
                  ))}
                </RadioGroup>
              </div>
            </div>
            <br />
            <hr />
          </MDBox>
          <br />
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              4. ¿Qué tan probable es que recomiende nuestros servicios con
              alguno de sus contactos?.{" "}
            </p>
            <p style={{ fontSize: "1.5vh", textAlign: "justify" }}>
              {" "}
              Siendo 0=Ninguna posibilidad y 10=Muy Probable{" "}
            </p>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {rates1.map((index,id) => (
                  <>
                   {
                        action === 'view' ?
                    <FormControlLabel
                      style={{ display: "flex", flexDirection: "column" }}
                      value={index.value}
                      label={index.label}
                      checked={action === 'view' && inputs.contacts == id ? true : false}
                      control={<Radio onClick={() => {
                        setContactService(index.value)
                      }} />}
                    />
                    :
                    <FormControlLabel
                    style={{ display: "flex", flexDirection: "column" }}
                    value={index.value}
                    label={index.label}
                    control={<Radio onClick={() => {
                      setContactService(index.value)
                    }} />}
                  />
}
                  </>
                ))}
              </RadioGroup>
            </div>
          </MDBox>
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              5. ¿Qué tan probable es que vuelva a solicitar alguno de nuestros
              servicios?{" "}
            </p>
            <p style={{ fontSize: "1.5vh", textAlign: "justify" }}>
              {" "}
              Siendo 0=Ninguna posibilidad y 10=Muy Probable{" "}
            </p>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {rates1.map((index,id) => (
                  <>
                    {
                        action === 'view' ?
                    <FormControlLabel
                      style={{ display: "flex", flexDirection: "column" }}
                      value={index.value}
                      label={index.label}
                      checked={action === 'view' && inputs.new_services == id ? true : false}
                      control={<Radio onClick={() => {
                        setComeback(index.value)
                      }} />}
                    />
                    :
                    <FormControlLabel
                    style={{ display: "flex", flexDirection: "column" }}
                    value={index.value}
                    label={index.label}
                    control={<Radio onClick={() => {
                      setComeback(index.value)
                    }} />}
                  />
}
                  </>
                ))}
              </RadioGroup>
            </div>
          </MDBox>
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              6. Sientase en libertad de compartir cualquier comentario sobre el
              servicio brindado.{" "}
            </p>
            <br />
            <textarea
              name=""
              id=""
              rows="10"
              style={{
                resize: "None",
                width: "100%",
                border: "None",
                padding: "2vh",
                fontFamily: "Arial",
              }}
              defaultValue={action === 'view'? inputs.commments: ''}
              disabled = {action === 'view'? true: false}
              onChange={(o) => (setComments(o.target.value))}
            ></textarea>
          </MDBox>
          <br />
          <MDBox mb={2}>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              7. Información de contacto (opcional){" "}
            </p>
            <br />
            <MDInput
              type="text"
              label="Nombre"
              variant="standard"
              fullWidth
              placeholder="Nombre"
              InputLabelProps={{ shrink: true }}
              onChange={handleNameChange}
              defaultValue={(action === 'update') && inputs ? inputs.user_name : null}
              value={(action === 'view') && inputs ? inputs.user_name : null}
              InputProps={{ readOnly: action === 'view' ? true : false, }}

            />
          </MDBox>
          <br />
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Apellido"
              variant="standard"
              fullWidth
              placeholder="Apellido"
              InputLabelProps={{ shrink: true }}
              onChange={handleSecNameChange}
              defaultValue={(action === 'update') && inputs ? inputs.user_last_name : null}
              value={(action === 'view') && inputs ? inputs.user_last_name : null}
              InputProps={{ readOnly: action === 'view' ? true : false, }}
            />
          </MDBox>
          <br />
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Compañia (si aplica)"
              variant="standard"
              fullWidth
              placeholder="Compañia"
              InputLabelProps={{ shrink: true }}
              onChange={handleCompanyChange}
              defaultValue={(action === 'update') && inputs ? inputs.user_company : null}
              value={(action === 'view') && inputs ? inputs.user_company : null}
              InputProps={{ readOnly: action === 'view' ? true : false, }}
            />
          </MDBox>
          <br />
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              placeholder="Email"
              InputLabelProps={{ shrink: true }}
              onChange={handleMailChange}
              defaultValue={(action === 'update') && inputs ? inputs.user_email : null}
              value={(action === 'view') && inputs ? inputs.user_email : null}
              InputProps={{ readOnly: action === 'view' ? true : false, }}
            />
          </MDBox>
          <br />

          <MDBox mt={4} mb={1}>
          <MDButton
                    variant='gradient'
                    color='error'
                    style={{ marginRight: '10px' }}
                    sx={{ height: '100%' }}
                    onClick={() => window.location.replace("/ClientSatisfactionList")}
                  >
                    Cancelar
                  </MDButton>
            <MDButton
              variant="gradient"
              color="success"
              fullWidth
              onClick={() => createClientSatis()}
            >
              Enviar
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </CoverLayout>
  );
}
export default ClientSatisfaction;
