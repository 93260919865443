/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

// Authentication layout components
import CoverLayout from "../../components/authentication/CoverLayout";

// Images
import bgImage from "../../assets/images/bg-sign-in-cover.jpeg";

import { signIn, saveSession, saveNotifications } from "../../Services/authService";

function Login({ setSession }) {
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();

  const handleMailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePassChange = (event) => {
    setPass(event.target.value);
  };

  const sendCredentials = () => {
    let body = { email: email, password: pass };
    signIn(body)
      .then((resp) => {
        saveSession(resp.data.token, resp.data);
        window.location.replace("/");
        //notificationsGet(resp.data.token)
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const notificationsGet = (token) => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/date_notifications/`,
      {
        headers: { "Authorization": "Token " + token }
      }
    ).then(function (response) {
      saveNotifications(response.data)

    }.bind(this)).catch(function (error) {
      console.log(error.message)
      //alert("Error al crear proceso")
    }.bind(this))
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Inicio de Sesion
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Correo"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                onChange={handleMailChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contrasena"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                onChange={handlePassChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={sendCredentials}
              >
                Aceptar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                No tienes una cuenta?{" "}
                <MDTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Registrate
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDBox mt={3} mb={0} textAlign="center">
        <MDTypography variant="button" color="text">
          Encuesta de Satisfacción{" "}
          <MDTypography
            component={Link}
            to="/encuesta"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            ABRIR
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox mt={0} mb={0} textAlign="center">
        <MDTypography variant="button" color="text">
          Evaluacion de Proveedor{" "}
          <MDTypography
            component={Link}
            to="/providers_evaluation"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            ABRIR
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox mt={0} mb={0} textAlign="center">
        <MDTypography variant="button" color="text">
          Supplier Quality{" "}
          <MDTypography
            component={Link}
            to="/supplier_Quality"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            ABRIR
          </MDTypography>
        </MDTypography>
      </MDBox>
    </CoverLayout>
  );
}

export default Login;
