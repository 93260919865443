import React, { useState, useEffect } from 'react';
import {
  getInvoices,
  getEstimations,
  deleteInvoice,
  getWorkReports,
  getInvoice
} from '../../Services/InvoicieService';

import { InvoiceHeaders, headersManager } from '../../utils/tableHeaders';
import { buildData } from '../../utils/tableData';
import axios from "axios";

import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import Pagination from '@mui/material/Pagination';
import Menu from '@mui/material/Menu';
import MDTypography from '../../components/MDTypography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from "@mui/material/InputLabel";
import DataTable from '../../components/DataTable';

function InvoiceManager({ type }) {
  //userToken = localStorage.getItem("userToken") || "";
  const [data, setData] = useState([])
  const [items, setItems] = useState();
  const [number, setNumber] = useState();
  const [invoices, setInvoices] = useState([]);
  const [filters, setFilters] = useState([]);
  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState();
  const [filterLabel, setFilterLabel] = useState('Filtros');
  const [valueFilter, setValueFilter] = useState();
  const [list, setList] = useState([])

  const [registerToDetele, setRegisterToDelete] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const getWPS = () => {
    const listWPS = []
    let promise = getWorkReports;
    promise()
      .then((resp) => {
        console.log(resp.data)
        setFilters(resp.data.work_reports)
        filters.map((item) => {
          listWPS.push(item)
        })
        console.log(listWPS)
        setList(listWPS)

      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  const handleSearch = (value) => {
    console.log('search', value);
    setValueFilter(value);
    loadItems(1, filter, value);
  };

  const editRegister = (item) => {
    console.log('edit', item);
    let route = type === 'Q' ? '/estimation/update/' : '/invoice/update/';
    window.location.replace(route.concat(item.id));
  };
  const viewRegister = (item) => {
    console.log('view', item);
    let route = type === 'Q' ? '/estimation/view/' : '/invoice/view/';
    window.location.replace(route.concat(item.id));
  };


  const clearFilter = () => {
    setMenu(null);
    setFilter();
    setFilterLabel('Filtros');
    loadItems(1);
    closeMenu();
  };

  const getFilterLabel = (value) => {
    let label;
    list.forEach((option) => {
      if (option.id === value) {
        label = option.number;
      }
    });
    return label;
  };

  const deleteRegister = (register) => {
    setRegisterToDelete(register);
    setOpenDeleteDialog(true);
  };


  const closeMenu = () => {
    setMenu(null);
  };
  const handleChange = (value) => {
    setFilter(value);
    setFilterLabel(getFilterLabel(value));
    setMenu(null);
    closeMenu();
  };

  const loadItems = (page, filter, valueFilter) => {
    let promise = type === 'Q' ? getEstimations : getInvoices;
    promise(page, filter, valueFilter)
      .then((resp) => {
        console.log(resp.data);
        setData(buildData(resp.data.invoices, headersManager()));
        setCurrentPage(parseInt(resp.data.current_page));
        setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  useEffect(() => {
    getWPS()
    loadItems(1);
  }, []);


  return (
    <div>
      <h3>Invoices Consultation</h3>
      <br></br>
      <Card style={{ padding: '3vh' }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormControl style={{ width: '8vh' }}>
            <InputLabel id="demo-simple-select-label">WR#</InputLabel>
            <Select style={{ padding: '1vh' }} value={items} onChange={(e) => setItems(e.target.value)}>
              {list.map((index) => (
                //console.log(index, 'hplo')
                <MenuItem key={index.id} value={index.number}>
                  {index.id}
                </MenuItem>
              ))
              }

            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: '6vh' }} >
            <InputLabel id="demo-simple-select-label">Number</InputLabel>
            <Input onChange={(e) => setNumber(e.target.value)}>
            </Input>
          </FormControl>

          <MDButton
            variant="gradient"
            style={{ marginLeft: '6vh' }}
            color="info"
            type="submit"
            onClick={() => {
              getInvoice(number)
            }}
          >
            Search
          </MDButton>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        {data.rows !== undefined && data.rows.length > 0 && (
          <>
            <DataTable
              useActions
              useView
              useDelete
              deleteAction={deleteRegister}
              viewAction={viewRegister}
              useEdit
              editAction={editRegister}
              table={data}
              showTotalEntries={false}
              entriesPerPage={false}
              handleSearch={handleSearch}
              canSearch
            />
            <MDBox ml={1}>
              <Pagination
                sx={{ marginTop: '20px', marginBottom: '20px' }}
                color='info'
                count={totalPages}
                page={currentPage}
              //onChange={handlePagination}
              />{' '}
            </MDBox>{' '}
          </>
        )}
      </Card>
    </div>
  );
}

export default InvoiceManager;
