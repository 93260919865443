import { useState, useRef, useMemo, useEffect } from "react";
import axios from "axios";
// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import countryList from "react-select-country-list";


import { getProviders, getEvaProvidersID } from "../../Services/formServices";
import { buildData } from "../../utils/tableData";
import { supplierHeaders } from "../../utils/tableHeaders";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from "../../components/Loader";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


function SupplierEvaluationForm({ isView, formData, isUpdate, action }) {
  const params = useParams();
  const [id, setId] = useState(0)

  const [cert, setCert] = useState();
  const [phone, setPhone] = useState();
  const [type, setType] = useState();
  const [participants, setParticipants] = useState([{ participant: "" }]);
  const [instructor, setInstructor] = useState();
  const [issued, setIssued] = useState();
  const [geographic, setGeographic] = useState("");
  const [expiration, setExpiration] = useState();
  const countries = useMemo(() => countryList().getData(), []);

  const [loading, setLoading] = useState(true);
  const [ships, setShips] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);

  const userToken = localStorage.getItem('Token') || '';

  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);

  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = day + "/" + month + "/" + year;

  const part1 = [
    { id: 1, label: "Customer required Supplier", value: 0 },
    { id: 2, label: "Sole Source", value: 1 },
  ];


  const part2 = [
    { id: 1, label: "Quality appears adequate" },
    { id: 2, label: "Delivery availability and terms are adequate" },
    { id: 3, label: "References verified" },
    {
      id: 4,
      label:
        "Quality management system certification to ISO 9001 or equivalent.",
    },
    { id: 5, label: "Pricing & value" },
    { id: 6, label: "Responsiveness" },
    { id: 7, label: "Results of evaluation of sample parts or materials" },
    { id: 8, label: "Supplier is capable of meeting regulatory concerns" },
    { id: 9, label: "Supplier survey results." },
    { id: 10, label: "Results of on-site audit" },
  ];
  

  const jsonAnswers = {
    certification: cert,
    date: today,
    evaluated: expiration,
    phone: phone,
    location: geographic,
    provider: type,
    first: first,
    second: second,
  };

  const addDescription = () => {
    setParticipants([...participants, { participant: "" }]);
    console.log(participants);
  };

  const handleRemoveClick = (index) => {
    const list = [...participants];
    list.splice(index, 1);
    setParticipants(list);
  };

  const createEvaluation = () => {
    console.log(jsonAnswers);
    const pilotdata = new FormData();
    cert && pilotdata.append('code', cert);
    expiration && pilotdata.append('evaluated', expiration);
    pilotdata.append('location', jsonAnswers.location);
    pilotdata.append('date', jsonAnswers.date);
    phone && pilotdata.append('phone', phone);
    pilotdata.append('first', first);
    pilotdata.append('second', second);
    pilotdata.append('provider', jsonAnswers.provider);
    //pilotdata.append('is_staff', true);
    // pilotdata.append('change_pwd', false);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/provider_evaluation/',
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Form Creado Exitosamente")
      window.location.replace("/providers_evl_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Form")
    }.bind(this))
  };


  function getEvaluId() {
    if (params.id != null) {
      setId(params.id)
      getEvaProvidersID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)

      }).catch(err => {
        console.error(err);
      });
    }
  }


  useEffect(() => {
    loadShips();
    (action === 'view' || action === 'update') && getEvaluId();
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const loadShips = () => {
    getProviders()
      .then((resp) => {
        console.log(resp.data);
        var data = resp.data.map(index => {
          return {
            label: index.company,
            value: index.id
          }
        })
        setShips(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };


  return (
    <CoverLayout image={bgImage}>
      {
        !flag?
        <Loader/>
        :
        <>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Evaluacion de Proveedores
              </MDTypography>
            </MDBox>
          </Card>
          <br />
          <br />
          <br />
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                  {" "}
                  Supplier ID Code (if known):{" "}
                </p>
                <br />
                <MDInput
                  type="text"
                  label="Supplier ID Code:"
                  variant="standard"
                  fullWidth
                  placeholder="Supplier ID Code"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setCert(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  value={(action === 'view') && inputs ? inputs.code : null}

                />
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}> Provider: </p>
                <br />
                <Select
                  placeholder="Select a Provider..."
                  options={ships}
                  onChange={(e) => setType(e.value)}
                  isDisabled={action === 'view' ? true : false}
                  defaultValue={(action === 'view' || action === 'update') && ships.find(label => label.label === inputs.provider.company)}

                />
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}> Location: </p>
                <br />
                <Select
                  placeholder="Location"
                  options={countries}
                  onChange={(o) => setGeographic(o.label)}
                  isDisabled={action === 'view' ? true : false}
                  defaultValue={(action === 'view' || action === 'update') && countries.find(label => label.label === inputs.location)}

                ></Select>
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}> Date: </p>
                <br />
                <MDInput
                  type="text"
                  label="Date *"
                  disabled={true}
                  variant="standard"
                  value={(action === 'view') && inputs ? inputs.date : today}
                  fullWidth
                  //placeholder="Fecha *"
                  InputLabelProps={{ shrink: true }}
                //onChange={handleGeoChange}
                />
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}> Phone: </p>
                <br />
              {/*   <MDInput
                  type="text"
                  label="Phone"
                  variant="standard"
                  fullWidth
                  placeholder="Phone"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setPhone(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'view') && inputs ? inputs.phone : null}

                /> */}
                <PhoneInput
                        placeholder="Enter phone number"
                        value={(action === 'update' || action === 'view' ) && inputs ? inputs.phone : null}
                        onChange={setPhone} 
                        defaultCountry="GT"
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                  {" "}
                  Evaluated By:{" "}
                </p>
                <br />
                <MDInput
                  type="text"
                  label="Evaluated By"
                  variant="standard"
                  fullWidth
                  placeholder="Evaluated By"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setExpiration(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'view') && inputs ? inputs.evaluated : null}
                />
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>Part I</p>
                <br />
                <ul
                  style={{
                    listStyleType: "none",
                    display: "flex",
                    fontSize: "1.3vh",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  {part1.map((index, key) => (
                    <>
                      {action === 'view' ?
                        <li>


                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                first.push(index.label);
                                console.log(first);
                              } else if (e.target.checked === false) {
                                first.splice(key, 1);
                                console.log(first);
                              }
                            }}
                            checked={(inputs.first.split(",").find(e => e == index.label) ? true : false)}

                          />
                          {console.log((inputs.first.split(",")[key]))}
                          {console.log(index.label)}
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>



                        </li>
                        :
                        <li>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                first.push(index.label);
                                console.log(first);
                              } else if (e.target.checked === false) {
                                first.splice(key, 1);
                                console.log(first);
                              }
                            }}
                          //checked={(inputs.first.split(",")[key] == index.label ? true : false)}

                          />
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>
                        </li>
                      }
                    </>
                  ))}
                </ul>
              </MDBox>
              <br />
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>Part II</p>
                <br />
                <ul
                  style={{
                    listStyleType: "none",
                    display: "flex",
                    fontSize: "1.3vh",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
               {/*    {part2.map((index, key) => (
                    <>
                      <li>
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              second.push(index.label);
                              console.log(second);
                            } else if (e.target.checked === false) {
                              second.splice(key, 1);
                              console.log(second);
                            }
                          }}
                          checked={(inputs && inputs.second.split(",").find(e => e == index.label) ? true : false)}


                        />
                        <FormLabel style={{ fontSize: "1.8vh" }}>
                          {index.label}
                        </FormLabel>
                      </li>
                    </>
                  ))} */}

                  {part2.map((index, key) => (
                    <>
                      {action === 'view' ?
                        <li>


                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                second.push(index.label);
                                console.log(second);
                              } else if (e.target.checked === false) {
                                second.splice(key, 1);
                                console.log(second);
                              }
                            }}
                            checked={(inputs.second.split(",").find(e => e == index.label) ? true : false)}

                          />
                          {console.log((inputs.second.split(",")[key]))}
                          {console.log(index.label)}
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>



                        </li>
                        :
                        <li>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                second.push(index.label);
                                console.log(second);
                              } else if (e.target.checked === false) {
                                second.splice(key, 1);
                                console.log(second);
                              }
                            }}
                          //checked={(inputs.first.split(",")[key] == index.label ? true : false)}

                          />
                          <FormLabel style={{ fontSize: "1.8vh" }}>
                            {index.label}
                          </FormLabel>
                        </li>
                      }
                    </>
                  ))}
                </ul>
              </MDBox>
            </MDBox>
            <br />
           {action != "view" ?
           <>
            <MDButton
                    variant='gradient'
                    color='error'
                    style={{ marginRight: '10px' }}
                    sx={{ height: '100%' }}
                    onClick={() => window.location.replace("/providers_evl_list")}
                  >
                    Cancelar
                  </MDButton>
             <MDButton
              variant="gradient"
              color="success"
              onClick={() => createEvaluation()}
            >
              Save Evaluation
            </MDButton>
           </>
            :
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => window.location.replace("/providers_evl_list")}
            >
              Regresar
            </MDButton>
            }
          </MDBox>

        </>
      }
    </CoverLayout>
  );
}
export default SupplierEvaluationForm;
