/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    name,
    address,
    city,
    state,
    zip_code,
    country,
    note,
    tax_id,
    phone,
    phone_2,
    fax,
    email,
    contact_name_1,
    contact_phone_1,
    contact_name_2,
    contact_phone_2,
    contact_name_3,
    contact_phone_3,
    vehicles
  },
} = checkout;

export const initialValues = {
  [name.name]: "",
  [address.name]: "",
  [city.name]: "",
  [state.name]: "",
  [zip_code.name]: "",
  [country.name]: "",
  [note.name]: "",
  [tax_id.name]: "",
  [phone.name]: "",
  [phone_2.name]: "",
  [fax.name]: "",
  [email.name]: "",
  [contact_name_1.name]: "",
  [contact_phone_1.name]: "",
  [contact_name_2.name]: "",
  [contact_phone_2.name]: "",
  [contact_name_3.name]: "",
  [contact_phone_3.name]: "",
  [vehicles.name]: [],
};

export const initialValuesFromObj = (obj) => {
  let resp = {
    [name.name]: obj.name,
    [address.name]: obj.address,
    [city.name]: obj.city,
    [state.name]: obj.state,
    [zip_code.name]: obj.zip_code,
    [country.name]: obj.country,
    [note.name]: obj.note,
    [tax_id.name]: obj.tax_id,
    [phone.name]: obj.phone,
    [phone_2.name]: obj.phone_2,
    [fax.name]: obj.fax,
    [email.name]: obj.email,
    [contact_name_1.name]: obj.contact_name_1,
    [contact_phone_1.name]: obj.contact_phone_1,
    [contact_name_2.name]: obj.contact_name_2,
    [contact_phone_2.name]: obj.contact_phone_2,
    [contact_name_3.name]: obj.contact_name_3,
    [contact_phone_3.name]: obj.contact_phone_3,
    [vehicles.name]: obj.vehicles
  }
  return resp
}
