export const LINKS = {
  clandsu: "/clandsu/list",
  notifications: "/notifications",
  client_list: "/client_list",
  clients_add: "/clients/add",
  suppliers_list: "/suppliers/list",
  suppliers_add: "/suppliers/add",
  pilots_add: "/pilots_add",
  shipments_add: "/ships_add",
  inventory_main: "/inventory/main",
  inventory_quarantine: "/inventory/quarantine",
  inventory_repair: "/inventory/repair",
  inventory_scrap: "/inventory/scrap",
  inventory_missing_expiration: "/inventory/missing_expitarion",
  inventory_history: "/inventory/history",
  inventory_general_search: "/inventory/general_search",
  purchase_order_list: "/purchase_order/list",
  purchase_order_create: "/purchase_order/create",
  purchase_order_pending: "/purchase_order/detail/pending",
  invoice_list: "/invoice/list",
  invoice_manager: "/invoice/manager",
  estimarion_list: "/estimation/list",
  ships_list: "/ships_list",
  pilots_list: "/pilots_list",
  task_list: "/task/list",
  staff_list: "/staff/list",
  staff_add: "/staff/create",
  main_board: "/mainboard",
  ClientSatisfaction: "/ClientSatisfaction",
  ClientSatisfactionList: "/ClientSatisfactionList",
  reception: "/reception",
  reception_table: "/reception_data",
  deliver: "/deliver",
  deliver_table: "/deliver_table",
  job_desc: "/job_description",
  job_desc_list: "/job_desc_list",
  certifications: "/certifications",
  employee_card: "/employee_card",
  providers_evl: "/providers_evl",
  processes: "/processes",
  processes_list: "/processes_list",
  car_form: "/car_form",
  car_form_table: "/car_form_table",
  coto_form: "/coto_form",
  coto_table: "/coto_table",
  cotoRisk_form: "/cotoRisk_form",
  provider_list: "/provider_list",
  employee_list: "/employee_list",
  providers_evl_list: "/providers_evl_list",
  employee_card_list: "/employee_card_list",
  certifications_list: "/certifications_list",
  SupplierQuality: "/SupplierQuality",
  SupplierQuality_List: "/SupplierQuality_List",

  party_form: "/party_form",
  party_table: "/party_table",
  issues_form: "/issues_form",
  issues_table: "/issues_table",
};
