
import './App.css';
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import MainLayout from '../MainLayout';
import AuthenticationLayout from '../AuthenticationLayout';

const App = () => {
  const location = useLocation();
  const [session, setSession ] = useState(false)

  useEffect(() => {
    if(window.localStorage.getItem('isAuthenticated')){
        setSession(true);
    }
  }, []);

  return (
    <div className="App">
        <Routes>
          { session && <Route path='/*' element={<MainLayout />} /> }
          { !session && <Route path='/*' element={<AuthenticationLayout session={session} setSession={setSession} />} /> }
        </Routes>
    </div>
  );
}

export default App;
