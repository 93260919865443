import React, { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "./formLayout";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import { getCOTOID, getIssues, getParty, getProcess } from "../../Services/formServices";
import Loader from "../../components/Loader";

function JobDescriptionForm({ isView, action }) {
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);
  const userToken = localStorage.getItem('Token') || '';
  const [id, setId] = useState(0)

  const [optionsV, setOptions] = useState([]);
  const [optionsParty, setoptionsParty] = useState([]);
  const [optionsIssues, setoptionsIssues] = useState([]);

  const [occurrence, setocurrences] = useState();
  const [occurrencescore, setOcurrecescore] = useState();
  const [likelihooddata, setlikelihooddata] = useState();
  const [likelihoodscore, setlikelihoodscore] = useState();

  const [jobType, setJobType] = useState();
  const [jobTypeIssues, setJobTypeIssues] = useState();

  const [specific_opportunity, setspecific_opportunity] = useState();
  const [main_process, setmain_process] = useState();
  const [PotencialBusinessdata, setPotencialBusinessdata] = useState();
  const [PotencialBusinessscore, setPotencialBusinessscore] = useState();
  const [PotencialExpansion, setPotencialExpansion] = useState();
  const [PotencialExpansionscore, setPotencialExpansionscore] = useState();

  const [Potentialimprovement, setPotentialimprovement] = useState();
  const [Potentialimprovementscore, setPotentialimprovementscore] = useState();
  const [PotentialimprovementQMS, setPotentialimprovementQMS] = useState();
  const [PotentialimprovementQMSscore, setPotentialimprovementQMSscore] = useState();
  const [Repulations, setRepulation] = useState();
  const [Repulationscore, setRepulationscore] = useState();

  const [Implementation, setImplementation] = useState();
  const [Implementationscore, setImplementationscore] = useState();
  const [OpportunityPursuit, setOpportunityPursuit] = useState();
  const [statusdata, setstatus] = useState();
  const [PostImplementation, setPostImplementation] = useState();





  const jsonAnswers = {
    occurrence: occurrence,
    likelihood: likelihooddata,
    interested_party: jobType,
    issues: jobTypeIssues,
    specific_opportunity: specific_opportunity,
    main_process: main_process, //array of objects
    PotencialBusinessdata: PotencialBusinessdata, //array of objects
    PotencialExpansion: PotencialExpansion, //array of objects
    Potentialimprovement: Potentialimprovement, //array of objects
    PotentialimprovementQMS: PotentialimprovementQMS, //array of objects
    Repulation: Repulations, //array of objects
    Implementation: Implementation, //array of objects
    OpportunityPursuit: OpportunityPursuit, //array of objects
    status: statusdata, //array of objects
    PostImplementation: PostImplementation, //array of objects

  };


  const createCOTO = () => {
    const shipData = new FormData();


    shipData.append('party', jobType);
    shipData.append('issue', jobTypeIssues);
    shipData.append('process', main_process);
    shipData.append('specific_opportunity', specific_opportunity);    
    shipData.append('likelihood', likelihooddata);
    shipData.append('occurrences', occurrence);
    shipData.append('potential_expansion', PotencialExpansion);
    shipData.append('potential_improvement', Potentialimprovement);
    shipData.append('potential_improvement_QMS_processes', PotentialimprovementQMS);
    shipData.append('improvement_to_company_reputation', Repulations);
    shipData.append('opportunity_pursuit_plan', OpportunityPursuit);
    shipData.append('benefit', PotencialBusinessdata);

    shipData.append('potential_cost', Implementation);
    shipData.append('post_implementation_success', PostImplementation);


    axios.post(
      'https://fn.devpackgroup.com/api/v1/coto/',
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("COTO Creado Exitosamente")
      window.location.replace("/coto_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al crear Party")
    }.bind(this))

  };

  const updateCOTO = () => {
    const shipData = new FormData();
    /*  matricula && shipData.append('reason', matricula);
     serie && shipData.append('name', serie);
     v2type && shipData.append('type', v2type); */
     jobType && shipData.append('party', jobType);
    jobTypeIssues && shipData.append('issue', jobTypeIssues);
    main_process && shipData.append('process', main_process);
    specific_opportunity && shipData.append('specific_opportunity', specific_opportunity);    
    likelihooddata && shipData.append('likelihood', likelihooddata);
    occurrence && shipData.append('occurrences', occurrence);
    PotencialExpansion && shipData.append('potential_expansion', PotencialExpansion);
    Potentialimprovement && shipData.append('potential_improvement', Potentialimprovement);
    PotentialimprovementQMS && shipData.append('potential_improvement_QMS_processes', PotentialimprovementQMS);
    Repulations && shipData.append('improvement_to_company_reputation', Repulations);
    OpportunityPursuit && shipData.append('opportunity_pursuit_plan', OpportunityPursuit);
    PotencialBusinessdata && shipData.append('benefit', PotencialBusinessdata);
    Implementation && shipData.append('potential_cost', Implementation);
    PostImplementation && shipData.append('post_implementation_success', PostImplementation);
    axios.patch(
      `https://fn.devpackgroup.com/api/v1/coto/${params.id}/`,
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("COTO Actualizado Exitosamente")
      //window.location.reload();
      window.location.replace("/coto_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al actualizar party")
    }.bind(this))

  };


  const jobTypes = [
    { label: "Certification Body: Audits us for QMS certification compliance, issue certifications[External]", value: "Certification Body: Audits us for QMS certification compliance, issue certifications[External]" },
    { label: "Customer: Purchases our products and services[External]", value: "Customer: Purchases our products and services[External]" },
    { label: "Employees: Directly responsible for manufacture of products, delivery of service[External]", value: "Employees: Directly responsible for manufacture of products, delivery of service[External]" },
    { label: "Top Management: Has direct responsibility for management of the company[External]", value: "Top Management: Has direct responsibility for management of the company[External]" },
    { label: "Suppliers: Provide our raw materials and critical support services[External]", value: "Suppliers: Provide our raw materials and critical support services[External]" },
    { label: "Attorney: Provides legal services[Internal]", value: "Attorney: Provides legal services[Internal]" },
    { label: "CPA: Provides accounting services and annual financial reviews[Internal]", value: "CPA: Provides accounting services and annual financial reviews[Internal]" },
    { label: "Investors: Concerned with financial health of the company[Internal]", value: "Investors: Concerned with financial health of the company[Internal]" },
    { label: "Labor Union Representatives: Interfaces with management and labor union[External]", value: "Labor Union Representatives: Interfaces with management and labor union[External]" },
    { label: "Local Community: Impacted by our activities in the region[External]", value: "Local Community: Impacted by our activities in the region[External]" },
    { label: "Parent Company: Concerned with financial health of the company[Internal]", value: "Parent Company: Concerned with financial health of the company[Internal]" },
    { label: "Product End User: End user of our products and services[External]", value: "Product End User: End user of our products and services[External]" },
    { label: "Public: Receive some products[External]", value: "Public: Receive some products[External]" },
    { label: "Regulatory Bodies: Mandate regulatory requirements[External]", value: "Regulatory Bodies: Mandate regulatory requirements[External]" },
    { label: "Staffing Agencies: Provide candidates for hiring - conduct initial vetting of candidates[External]", value: "Staffing Agencies: Provide candidates for hiring - conduct initial vetting of candidates[External]" },
  ];

  const process = [
    { label: 'Process 1', value: 'Process 1' },
    { label: 'Process 2', value: 'Process 2' },
    { label: 'Process 3', value: 'Process 3' },
    { label: 'Process 4', value: 'Process 4' },
    { label: 'Process 5', value: 'Process 5' },
    { label: 'Process 6', value: 'Process 6' },
    { label: 'Process 7', value: 'Process 7' },
    { label: 'Process 8', value: 'Process 8' },
  ]

  const likelihood = [
    { label: 'Cannot occur / not applicable', value: 'Cannot occur / not applicable', score: 1 },
    { label: 'Unlikely to occur', value: 'Unlikely to occur', score: 2 },
    { label: 'Somewhat likely to occur', value: 'Somewhat likely to occur', score: 3 },
    { label: 'Likely to occur', value: 'Likely to occur', score: 4 },
    { label: 'Very likely to occur', value: 'Very likely to occur', score: 5 },
  ]

  const occurrences = [
    { label: 'Has never occurred.', value: 'Has never occurred.', score: 1 },
    { label: 'Has not occurred in past 10 years.', value: 'Has not occurred in past 10 years.', score: 2 },
    { label: 'Has occurred in past 10 years.', value: 'Has occurred in past 10 years.', score: 3 },
    { label: 'Has occurred in past 5 years.', value: 'Has occurred in past 5 years.', score: 4 },
    { label: 'Has occurred in past year.', value: 'Has occurred in past year.', score: 5 },
  ]

  const potencial = [
    { label: 'None / NA', value: 'None / NA', score: 1 },
    { label: 'Minor', value: 'Minor', score: 2 },
    { label: 'Moderate', value: 'Moderate', score: 3 },
    { label: 'High', value: 'High', score: 4 },
    { label: 'Very High', value: 'Very High', score: 5 },
  ]

  const repulation = [
    { label: 'No impact  / NA', value: 'No impact  / NA', score: 1 },
    { label: 'Minimal impact', value: 'Minimal impact', score: 2 },
    { label: 'Moderate impact', value: 'Moderate impact', score: 3 },
    { label: 'Good impact', value: 'Good impact', score: 4 },
    { label: 'Great impact', value: 'Great impact', score: 5 },
  ]
  const cost = [
    { label: '$ 0 or N/A', value: '$ 0 or N/A', score: 5 },
    { label: '< $ 5000', value: '< $ 5000', score: 4 },
    { label: '< $ 10000', value: '< $ 10000', score: 3 },
    { label: '> $ 10000', value: '> $ 10000', score: 2 },
    { label: '> $ 50000', value: '> $ 50000', score: 1 },
  ]
  const sucess = [
    { label: 'Opportunity Failed', value: 'Opportunity Failed', score: 1 },
    { label: 'Opportunity Abandoned', value: 'Opportunity Abandoned', score: 2 },
    { label: 'Met some expectations', value: 'Met some expectations', score: 3 },
    { label: 'Met all expectations', value: 'Met all expectations', score: 4 },
    { label: 'Exceeded expectations', value: 'Exceeded expectations', score: 5 },
  ]



  const status = [
    { label: 'Open', value: 'Open' },
    { label: 'CLOSED', value: 'CLOSED' },
  ]

  const createPositionDescription = () => {
    console.log(jsonAnswers);
  };

  function getShipId() {
    if (params.id != null) {
      setId(params.id)
      getCOTOID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)


      }).catch(err => {
        console.error(err);
      });
    }
  }

  useEffect(() => {
    loadShips();
    loadIssues();
    loadParty();
    (action === 'view' || action === 'update') && getShipId()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const loadShips = () => {
    getProcess()
      .then((resp) => {
        console.log(resp.data);
        //setShips(resp.data);
        var data = resp.data.map(index => {
          optionsV.push({
            label: index.processName,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadParty = () => {
    getParty()
      .then((resp) => {
        console.log(resp.data);
        //setShips(resp.data);
        var data = resp.data.map(index => {
          optionsParty.push({
            label: index.name,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadIssues = () => {
    getIssues()
      .then((resp) => {
        console.log(resp.data);
        setShips(resp.data);
        var data = resp.data.map(index => {
          optionsIssues.push({
            label: index.name,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };




  return (
    <CoverLayout image={bgImage}>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Franconaves COTO Form Opp Register
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <br />
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Interested Party: </p>
                  <br />
                  {/*  <Select
              placeholder="Select a Interested Party..."
              options={optionsParty}
              onChange={(e) => setJobType(e.value)}
            /> */}
                  {
                    action === 'add' ?
                      <Select
                        options={optionsParty}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setJobType(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsParty}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsParty.find(label => label.label === (inputs && inputs.party.name)))] : null}
                        onChange={(e) => {
                          setJobType(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>Issue: </p>
                  <br />
                  {/* <Select
              placeholder="Select a Issues..."
              options={optionsIssues}
              onChange={(e) => setJobTypeIssues(e.value)}
            /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={optionsIssues}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setJobTypeIssues(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsIssues}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsIssues.find(label => label.label === (inputs && inputs.issue.name)))] : null}
                        onChange={(e) => {
                          setJobTypeIssues(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Specific Opportunity:{" "}
                  </p>
                  <br />
                  {/*   <MDInput
              type="text"
              //label="Reports to:"
              variant="standard"
              fullWidth
              placeholder="Specific Opportunity"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setspecific_opportunity(e.target.value)}
            /> */}
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      //label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setspecific_opportunity(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.specific_opportunity : null}
                      value={action === 'view' && inputs ? inputs.specific_opportunity : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      //label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setspecific_opportunity(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Main Process Affected: </p>
                  <br />
                  {/*  <Select
              placeholder="Select a Main Process..."
              options={optionsV}
              onChange={(e) => setmain_process(e.value)}
            /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={optionsV}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setmain_process(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsV}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsV.find(label => label.label === (inputs && inputs.process[0].processName)))] : null}
                        onChange={(e) => {
                          setmain_process(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <h>
                  Probability (of achieving the opportunity)
                </h>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Likelihood: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Likelihood..."
                    options={likelihood}
                    onChange={(e) => {
                      setlikelihooddata(e.label)
                      setlikelihoodscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={likelihood}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setlikelihooddata(e.label)
                          setlikelihoodscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={likelihood}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(likelihood.find(label => label.label === (inputs && inputs.likelihood)))] : null}
                        onChange={(e) => {
                          setlikelihooddata(e.label)
                          setlikelihoodscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Occurrences: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Occurrences..."
                    options={occurrences}
                    onChange={(e) => {
                      setocurrences(e.label)
                      setOcurrecescore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={occurrences}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setocurrences(e.label)
                          setOcurrecescore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={occurrences}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(occurrences.find(label => label.label === (inputs && inputs.occurrences)))] : null}
                        onChange={(e) => {
                          setocurrences(e.label)
                          setOcurrecescore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <h3>
                  {occurrencescore &&
                    'Prob Rating: ' + ((likelihoodscore + occurrencescore) / 2).toFixed(1)
                  }
                </h3>
                <br />
                <h>
                  Benefit (if opportunity is encountered)
                </h>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential for New Business: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential for New Business..."
                    options={potencial}
                    onChange={(e) => {
                      setPotencialBusinessdata(e.label)
                      setPotencialBusinessscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotencialBusinessdata(e.label)
                          setPotencialBusinessscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.benefit)))] : null}
                        onChange={(e) => {
                          setPotencialBusinessdata(e.label)
                          setPotencialBusinessscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Expansion of Current Business: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential Expansion of Current Business..."
                    options={potencial}
                    onChange={(e) => {
                      setPotencialExpansion(e.label)
                      setPotencialExpansionscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotencialExpansion(e.label)
                          setPotencialExpansionscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.potential_expansion)))] : null}
                        onChange={(e) => {
                          setPotencialExpansion(e.label)
                          setPotencialExpansionscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential improvement in satisfying regulations: </p>
                  <br />
                  {/*    <Select
                    placeholder="Select a Potential improvement..."
                    options={potencial}
                    onChange={(e) => {
                      setPotentialimprovement(e.label)
                      setPotentialimprovementscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotentialimprovement(e.label)
                          setPotentialimprovementscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.potential_improvement)))] : null}
                        onChange={(e) => {
                          setPotentialimprovement(e.label)
                          setPotentialimprovementscore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential improvement to internal QMS processes: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential improvement to internal QMS processes..."
                    options={potencial}
                    onChange={(e) => {
                      setPotentialimprovementQMS(e.label)
                      setPotentialimprovementQMSscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotentialimprovementQMS(e.label)
                          setPotentialimprovementQMSscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.potential_improvement_QMS_processes)))] : null}
                        onChange={(e) => {
                          setPotentialimprovementQMS(e.label)
                          setPotentialimprovementQMSscore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Improvement to Company Reputation: </p>
                  <br />
                  {/*     <Select
                    placeholder="Select a Improvement to Company Reputation..."
                    options={repulation}
                    onChange={(e) => {
                      setRepulation(e.label)
                      setRepulationscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={repulation}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setRepulation(e.label)
                          setRepulationscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={repulation}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(repulation.find(label => label.label === (inputs && inputs.improvement_to_company_reputation)))] : null}
                        onChange={(e) => {
                          setRepulation(e.label)
                          setRepulationscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Cost of Implementation: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Potential Cost of Implementation..."
                    options={cost}
                    onChange={(e) => {
                      setImplementation(e.label)
                      setImplementationscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={cost}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setImplementation(e.label)
                          setImplementationscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={cost}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(cost.find(label => label.label === (inputs && inputs.potential_cost)))] : null}
                        onChange={(e) => {
                          setImplementation(e.label)
                          setImplementationscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <h3>
                  {occurrencescore &&
                    'Ben Rating: ' + ((PotencialBusinessscore + PotencialExpansionscore + PotentialimprovementQMSscore + Potentialimprovementscore + Repulationscore + Implementationscore) / 6).toFixed(1)
                  }
                </h3>
                <br />
                <h3>
                  {occurrencescore &&
                    'Opp Factor (Prob x Ben): ' + ((likelihoodscore + occurrencescore) / 2).toFixed(1) * ((PotencialBusinessscore + PotencialExpansionscore + PotentialimprovementQMSscore + Potentialimprovementscore + Repulationscore + Implementationscore) / 6).toFixed(1)
                  }
                </h3>
                <br />
              </MDBox>
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              Opportunity Pursuit Plan (suggested for Opp Factors >=5) May reference external planning document:
                </p>
                <br />
                {/*   <MDInput
                  type="text"
                  //label="Job Title:"
                  variant="standard"
                  fullWidth
                  //placeholder="Job Title"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setOpportunityPursuit(e.target.value)}
                /> */}
                {
                  (action !== 'add' && inputs) &&
                  <MDInput
                    type="text"
                    //label="Name:"
                    variant="standard"
                    fullWidth
                    placeholder="Name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setOpportunityPursuit(e.target.value)}
                    defaultValue={(action === 'update') && inputs ? inputs.opportunity_pursuit_plan : null}
                    value={action === 'view' && inputs ? inputs.opportunity_pursuit_plan : null}
                    InputProps={{ readOnly: isView, }}
                  />
                }
                {
                  (action === 'add') &&
                  <MDInput
                    type="text"
                    //label="Name:"
                    variant="standard"
                    fullWidth
                    placeholder="Name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setOpportunityPursuit(e.target.value)}
                    //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                    //value={action === 'view' && inputs? inputs.serie: null }
                    InputProps={{ readOnly: isView, }}
                  />
                }
              </MDBox>
              {/*   <MDBox mb={2}>
          <p style={{ fontSize: "2vh", textAlign: "justify" }}> Status: </p>
          <br />
          <Select
            placeholder="Select a Status..."
            options={status}
            onChange={(e) => {
              setstatus(e.label)
            }}
          />
        </MDBox> */}
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}> Post- Implementation Success?: </p>
                <br />
              {/*   <Select
                  placeholder="Select a Post- Implementation..."
                  options={sucess}
                  onChange={(e) => {
                    setPostImplementation(e.label)
                  }}
                /> */}

                {
                  action === 'add' ?
                    <Select
                      options={sucess}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setPostImplementation(e.label)
                      }}
                    ></Select>
                    :
                    <Select
                      options={sucess}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(sucess.find(label => label.label === (inputs && inputs.post_implementation_success)))] : null}
                      onChange={(e) => {
                        setPostImplementation(e.label)
                      }}
                    ></Select>
                }
              </MDBox>
              
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/coto_table")}
              >
                Cancelar
              </MDButton>
                {/*  <MDButton
                 variant="gradient"
                 color="success"
                 onClick={() => createCOTO() }
               >
                 Create COTO
               </MDButton> */}
              {
                 action !== 'view' &&
                 <MDButton
                 variant="gradient"
                 color="success"
                 onClick={() => action === 'add' ? createCOTO() : updateCOTO()}
               >
                {
                   action === 'add' ? 'Creare COTO' : 'Update COTO' 
                }
               </MDButton>
              }
            </MDBox>
          </>
      }
    </CoverLayout>
  );
}
export default JobDescriptionForm;
