import React from 'react';
import './Loader.css'; // Asegúrate de crear un archivo CSS para los estilos del loader.

class Loader extends React.Component {
  render() {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
}

export default Loader;
