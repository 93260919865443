// description, correction, date, date_repair, TTAP

const form = {
  formId: 'new-deffects-form',
  formField: {
    date: {
      name: 'date',
      label: 'Date',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    remark: {
      name: 'remark',
      label: 'Remark',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    air_time: {
      name: 'air_time',
      label: 'Air time',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    ng: {
      name: 'ng',
      label: 'NG',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    nf: {
      name: 'nf',
      label: 'NF',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
  },
};

export default form;
