import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    TTAF,
    TTAFW,
    TTAFP,
    TTAFD,
    calendarLimit,
    calendarWarning,
    calendarPerformed,
    calendarNextDue,
    cycle_ng,
    cycle_nf,
    cycleLimit,
    cycleWarning,
    cyclePerformed,
    cycleNextDue,
    workReportCorrection,
    remark,
  },
} = checkout;

const validations = Yup.object().shape({
  [TTAF.name]: Yup.string() ,
  [TTAFW.name]: Yup.string() ,
  [TTAFP.name]: Yup.string()  ,
  [TTAFD.name]: Yup.string()  ,
  [calendarLimit.name]: Yup.string(),
  [calendarWarning.name]: Yup.string()  ,
  [calendarPerformed.name]: Yup.string()  ,
  [calendarNextDue.name]: Yup.string()  ,
  [cycle_ng.name]: Yup.string(),
  [cycle_nf.name]: Yup.string(),
  [cycleLimit.name]: Yup.string(),
  [cycleWarning.name]: Yup.string(),
  [cyclePerformed.name]: Yup.string(),
  [cycleNextDue.name]: Yup.string(),
  [workReportCorrection.name]: Yup.string(),
  [remark.name]: Yup.string(),
});

export default validations;
