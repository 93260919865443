import React, { useEffect, useState } from 'react';
import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';
import { getAllAircrafts } from '../../../../Services/ShipService';
import { Typography } from '@mui/material';

const InspectionsTempleteForm = ({
  handleChange,
  inputs,
  isView,
  formData,
  isUpdate,
}) => {
  // const [ships, setShips] = useState([]);

  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    TTAF,
    TTAW,
    calendarLimit,
    calendarWarning,
    cycle_ng,
    cycle_nf,
    cycleLimit,
    cycleWarning,
    workReportCorrection,
    remark,
  } = formField;

  const {
    TTAF: TTAFV,
    TTAW: TTAWV,
    calendarLimit: calendarLimitV,
    calendarWarning: calendarWarningV,
    cycle_ng: cycle_ngV,
    cycle_nf: cycle_nfV,
    cycleLimit: cycleLimitV,
    cycleWarning: cycleWarningV,
    workReportCorrection: workReportCorrectionV,
    remark: remarkV,
  } = values;

  console.log(TTAWV);
  console.log(TTAW);

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormField
            type={TTAF.type}
            label={TTAF.label}
            name={TTAF.name}
            value={TTAFV}
            error={errors.TTAF && touched.TTAF}
            // success={TTAFV.length > 0 && !errors.TTAF}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={TTAW.type}
            label={TTAW.label}
            name={TTAW.name}
            value={TTAWV}
            error={errors.TTAW && touched.TTAW}
            // success={TTAWV.length > 0 && !errors.TTAW}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        
        <Grid item xs={12} md={4}>
          <FormField
            type={calendarLimit.type}
            label={calendarLimit.label}
            name={calendarLimit.name}
            value={calendarLimitV}
            error={errors.calendarLimit && touched.calendarLimit}
            // success={calendarLimitV.length > 0 && !errors.calendarLimit}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendarWarning.type}
            label={calendarWarning.label}
            name={calendarWarning.name}
            value={calendarWarningV}
            error={errors.calendarWarning && touched.calendarWarning}
            // success={calendarWarningV.length > 0 && !errors.calendarWarning}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={cycle_ng.type}
            label={cycle_ng.label}
            name={cycle_ng.name}
            value={cycle_ngV}
            error={errors.cycle_ng && touched.cycle_ng}
            // success={cycle_ngV.length > 0 && !errors.cycle_ng}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={cycle_nf.type}
            label={cycle_nf.label}
            name={cycle_nf.name}
            value={cycle_nfV}
            error={errors.cycle_nf && touched.cycle_nf}
            // success={cycle_nfV.length > 0 && !errors.cycle_nf}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={cycleLimit.type}
            label={cycleLimit.label}
            name={cycleLimit.name}
            value={cycleLimitV}
            error={errors.cycleLimit && touched.cycleLimit}
            // success={cycleLimitV.length > 0 && !errors.cycleLimit}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={cycleWarning.type}
            label={cycleWarning.label}
            name={cycleWarning.name}
            value={cycleWarningV}
            error={errors.cycleWarning && touched.cycleWarning}
            // success={cycleWarningV.length > 0 && !errors.cycleWarning}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={workReportCorrection.type}
            label={workReportCorrection.label}
            name={workReportCorrection.name}
            value={workReportCorrectionV}
            error={errors.workReportCorrection && touched.workReportCorrection}
            // success={workReportCorrectionV.length > 0 && !errors.workReportCorrection}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={remark.type}
            label={remark.label}
            name={remark.name}
            value={remarkV}
            error={errors.remark && touched.remark}
            // success={remarkV.length > 0 && !errors.remark}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default InspectionsTempleteForm;
