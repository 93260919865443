import React, { useEffect, useState } from 'react';import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';
import { getAllAircrafts } from '../../../../Services/ShipService';
import { Typography } from '@mui/material';
import {
  getPreviousFlight
} from '../../../../Services/MainBoard';

const LogForm = ({ handleChange, inputs, isView, formData, isUpdate }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;

  const { date, remark, air_time, ng, nf } = formField;

  const {
    date: dateV,
    remark: remarkV,
    air_time: air_timeV,
    ng: ngV,
    nf: nfV,
  } = values;

  const [totalTimeFlight, setTotalTime] = useState();

  const { id: aircraftId } = useParams();

  const loadFlightTime = async () => {
    const { data } = await getPreviousFlight(aircraftId);
    setTotalTime(data.total_air_time);
  };

  useEffect(() => {
    loadFlightTime();
  }, []);


  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormField
            type={date.type}
            label={date.label}
            name={date.name}
            value={dateV}
            error={errors.date && touched.date}
            success={dateV.length > 0 && !errors.date}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        

        <Grid item xs={12} md={4}>
          <FormField
            type={remark.type}
            label={remark.label}
            name={remark.name}
            value={remarkV}
            error={errors.remark && touched.remark}
            success={remarkV.length > 0 && !errors.remark}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={air_time.type}
            label={air_time.label}
            name={air_time.name}
            value={air_timeV}
            error={errors.air_time && touched.air_time}
            success={air_timeV.length > 0 && !errors.air_time}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={air_time.type}
            label={'Previous Air Time'}
            name={'air_time_record'}
            value={totalTimeFlight+0}
            disabled={true}
            error={errors.air_time && touched.air_time}
           //success={air_timeV.length > 0 && !errors.air_time}
            //InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={air_time.type}
            label={'Total Air Time'}
            name={'air_time_total'}
            value={air_timeV+totalTimeFlight}
            disabled={true}
            error={errors.air_time && touched.air_time}
            //success={air_timeV.length > 0 && !errors.air_time}
            //InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}></Grid>

        <Grid item xs={12}>
          <Typography
            variant='h4'
            component='div'
            style={{ marginTop: 10, marginBottom: 5, fontSize: '1.2rem' }}
          >
            Ciclos
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={ng.type}
            label={ng.label}
            name={ng.name}
            value={ngV}
            error={errors.ng && touched.ng}
            success={ngV.length > 0 && !errors.ng}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={nf.type}
            label={nf.label}
            name={nf.name}
            value={nfV}
            error={errors.nf && touched.nf}
            success={nfV.length > 0 && !errors.nf}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default LogForm;
