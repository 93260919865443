import checkout from "./form";

const {
    formField: {
        client,
        register,
        make,
        model,
        serial_number,
        year,
        fly_hours,
        warnings,
        ATTA_code,
        cicle,
    }
} = checkout;

export const initialValues = {
    [client.name]:{label: "", id: ""},
    [register.name]:"",
    [make.name]:"",
    [model.name]:"",
    [serial_number.name]:"",
    [year.name]:"",
    [fly_hours.name]:"",
    [warnings.name]:"",
    [ATTA_code.name]:"",
    [cicle.name]:"",
};

export const initialValuesFromObj = (obj) => {
    let resp = {
        //[client.name]:{label: obj.client.name || "", id: obj.client.id || ""},
        [register.name]:obj.registration,
        //[make.name]:obj.make,
        //[model.name]:obj.model,
        [serial_number.name]:obj.serie,
       // [year.name]:obj.year,
       // [fly_hours.name]:obj.fly_hours,
       // [warnings.name]:obj.warnings,
       // [ATTA_code.name]:obj.ATTA_code,
       // [cicle.name]:obj.cicle,
    }
    return resp;
}