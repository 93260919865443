import React, { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "./formLayout";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import { getCOTOID, getCOTORiskID, getIssues, getParty, getProcess } from "../../Services/formServices";
import Loader from "../../components/Loader";

function JobDescriptionForm({ isView, action }) {
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [inputs, setInputs] = useState();
  const [flag, setFlag] = useState(false);
  const userToken = localStorage.getItem('Token') || '';
  const [id, setId] = useState(0)

  const [optionsV, setOptions] = useState([]);
  const [optionsParty, setoptionsParty] = useState([]);
  const [optionsIssues, setoptionsIssues] = useState([]);

  const [occurrencescore, setOcurrecescore] = useState();
  const [likelihoodscore, setlikelihoodscore] = useState();
  
  
  
  
  
  const [jobType, setJobType] = useState();
  const [jobTypeIssues, setJobTypeIssues] = useState();
  const [specific_opportunity, setspecific_opportunity] = useState();
  const [main_process, setmain_process] = useState();
  const [likelihooddata, setlikelihooddata] = useState();
  const [occurrence, setocurrences] = useState();

  const [PotencialBusinessdata, setPotencialBusinessdata] = useState();
  const [PotencialExpansion, setPotencialExpansion] = useState();
  const [Potentialimprovement, setPotentialimprovement] = useState();
  const [potencialViolation, setpotencialViolation] = useState();
  const [PotentialimprovementQMS, setPotentialimprovementQMS] = useState();
  const [Implementation, setImplementation] = useState();
  

  
  const [PotencialBusinessscore, setPotencialBusinessscore] = useState();
  const [PotencialExpansionscore, setPotencialExpansionscore] = useState();
  const [Potentialimprovementscore, setPotentialimprovementscore] = useState();
  const [potencialViolationscore, setpotencialViolationscore] = useState();
  const [PotentialimprovementQMSscore, setPotentialimprovementQMSscore] = useState();
  const [Implementationscore, setImplementationscore] = useState();


  const [abilityDetect, setabilityDetect] = useState();
  const [abilityDetectscore, setabilityDetectscore] = useState();



  const [Repulations, setRepulation] = useState();
  const [Repulationscore, setRepulationscore] = useState();

  const [OpportunityPursuit, setOpportunityPursuit] = useState();
  const [statusdata, setstatus] = useState();
  const [PostImplementation, setPostImplementation] = useState();


    
  const [detectabilitys, setdetectability] =  useState();
  const [detectabilityscore, setdetectabilityscore] =  useState();
  const [MitigationPlan, setMitigationPlan] =  useState();



  const jsonAnswers = {
    occurrence: occurrence,
    likelihood: likelihooddata,
    interested_party: jobType,
    issues: jobTypeIssues,
    specific_opportunity: specific_opportunity,
    main_process: main_process, //array of objects
    PotencialBusinessdata: PotencialBusinessdata, //array of objects
    PotencialExpansion: PotencialExpansion, //array of objects
    Potentialimprovement: Potentialimprovement, //array of objects
    PotentialimprovementQMS: PotentialimprovementQMS, //array of objects
    Repulation: Repulations, //array of objects
    Implementation: Implementation, //array of objects
    OpportunityPursuit: OpportunityPursuit, //array of objects
    status: statusdata, //array of objects
    PostImplementation: PostImplementation, //array of objects

  };


  const createCOTO = () => {
    const shipData = new FormData();


    shipData.append('party', jobType);
    shipData.append('issue', jobTypeIssues);
    shipData.append('process', main_process);
    shipData.append('name', specific_opportunity);    
    shipData.append('likelihood', likelihooddata);
    shipData.append('occurrences', occurrence);
    shipData.append('pontential_loss', PotencialBusinessdata);
    shipData.append('potential_inability', PotencialExpansion);
    shipData.append('potential_human_risk', Potentialimprovement);
    shipData.append('potential_violation', potencialViolation);
    shipData.append('improvement_to_company_reputation', PotentialimprovementQMS);
    shipData.append('finanial_penalty', Implementation);
    shipData.append('ability_to_detect_risk', abilityDetect);
    shipData.append('migration_plan', OpportunityPursuit);


    axios.post(
      'https://fn.devpackgroup.com/api/v1/coto_risk/',
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("COTO Creado Exitosamente")
      window.location.replace("/coto_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al crear Coto")
    }.bind(this))

  };

  const updateCOTO = () => {
    const shipData = new FormData();
    /*  matricula && shipData.append('reason', matricula);
     serie && shipData.append('name', serie);
     v2type && shipData.append('type', v2type); */
    
     jobType && shipData.append('party', jobType);
     jobTypeIssues && shipData.append('issue', jobTypeIssues);
     main_process && shipData.append('process', main_process);
     specific_opportunity && shipData.append('name', specific_opportunity);    
     likelihooddata && shipData.append('likelihood', likelihooddata);
     occurrence && shipData.append('occurrences', occurrence);
     PotencialBusinessdata && shipData.append('pontential_loss', PotencialBusinessdata);
     PotencialExpansion && shipData.append('potential_inability', PotencialExpansion);
     Potentialimprovement && shipData.append('potential_human_risk', Potentialimprovement);
     potencialViolation && shipData.append('potential_violation', potencialViolation);
     PotentialimprovementQMS && shipData.append('improvement_to_company_reputation', PotentialimprovementQMS);
     Implementation && shipData.append('finanial_penalty', Implementation);
     abilityDetect && shipData.append('ability_to_detect_risk', abilityDetect);
     OpportunityPursuit && shipData.append('migration_plan', OpportunityPursuit);



    axios.patch(
      `https://fn.devpackgroup.com/api/v1/coto_risk/${params.id}/`,
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("COTO Actualizado Exitosamente")
      //window.location.reload();
      window.location.replace("/coto_table");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al actualizar Coto")
    }.bind(this))

  };


  const jobTypes = [
    { label: "Certification Body: Audits us for QMS certification compliance, issue certifications[External]", value: "Certification Body: Audits us for QMS certification compliance, issue certifications[External]" },
    { label: "Customer: Purchases our products and services[External]", value: "Customer: Purchases our products and services[External]" },
    { label: "Employees: Directly responsible for manufacture of products, delivery of service[External]", value: "Employees: Directly responsible for manufacture of products, delivery of service[External]" },
    { label: "Top Management: Has direct responsibility for management of the company[External]", value: "Top Management: Has direct responsibility for management of the company[External]" },
    { label: "Suppliers: Provide our raw materials and critical support services[External]", value: "Suppliers: Provide our raw materials and critical support services[External]" },
    { label: "Attorney: Provides legal services[Internal]", value: "Attorney: Provides legal services[Internal]" },
    { label: "CPA: Provides accounting services and annual financial reviews[Internal]", value: "CPA: Provides accounting services and annual financial reviews[Internal]" },
    { label: "Investors: Concerned with financial health of the company[Internal]", value: "Investors: Concerned with financial health of the company[Internal]" },
    { label: "Labor Union Representatives: Interfaces with management and labor union[External]", value: "Labor Union Representatives: Interfaces with management and labor union[External]" },
    { label: "Local Community: Impacted by our activities in the region[External]", value: "Local Community: Impacted by our activities in the region[External]" },
    { label: "Parent Company: Concerned with financial health of the company[Internal]", value: "Parent Company: Concerned with financial health of the company[Internal]" },
    { label: "Product End User: End user of our products and services[External]", value: "Product End User: End user of our products and services[External]" },
    { label: "Public: Receive some products[External]", value: "Public: Receive some products[External]" },
    { label: "Regulatory Bodies: Mandate regulatory requirements[External]", value: "Regulatory Bodies: Mandate regulatory requirements[External]" },
    { label: "Staffing Agencies: Provide candidates for hiring - conduct initial vetting of candidates[External]", value: "Staffing Agencies: Provide candidates for hiring - conduct initial vetting of candidates[External]" },
  ];

  const process = [
    { label: 'Process 1', value: 'Process 1' },
    { label: 'Process 2', value: 'Process 2' },
    { label: 'Process 3', value: 'Process 3' },
    { label: 'Process 4', value: 'Process 4' },
    { label: 'Process 5', value: 'Process 5' },
    { label: 'Process 6', value: 'Process 6' },
    { label: 'Process 7', value: 'Process 7' },
    { label: 'Process 8', value: 'Process 8' },
  ]

  const likelihood = [
    { label: 'Cannot occur / not applicable', value: 'Cannot occur / not applicable', score: 1 },
    { label: 'Unlikely to occur', value: 'Unlikely to occur', score: 2 },
    { label: 'Somewhat likely to occur', value: 'Somewhat likely to occur', score: 3 },
    { label: 'Likely to occur', value: 'Likely to occur', score: 4 },
    { label: 'Very likely to occur', value: 'Very likely to occur', score: 5 },
  ]

  const occurrences = [
    { label: 'Has never occurred.', value: 'Has never occurred.', score: 1 },
    { label: 'Has not occurred in past 10 years.', value: 'Has not occurred in past 10 years.', score: 2 },
    { label: 'Has occurred in past 10 years.', value: 'Has occurred in past 10 years.', score: 3 },
    { label: 'Has occurred in past 5 years.', value: 'Has occurred in past 5 years.', score: 4 },
    { label: 'Has occurred in past year.', value: 'Has occurred in past year.', score: 5 },
  ]

  const potencial = [
    { label: 'None / NA', value: 'None / NA', score: 1 },
    { label: 'Minor', value: 'Minor', score: 2 },
    { label: 'Moderate', value: 'Moderate', score: 3 },
    { label: 'High', value: 'High', score: 4 },
    { label: 'Very High', value: 'Very High', score: 5 },
  ]

  const reputation = [
    { label: 'No impact / NA', value: 'No impact  / NA', score: 1 },
    { label: 'Minimal impact', value: 'Minimal impact', score: 2 },
    { label: 'Moderate impact', value: 'Moderate impact', score: 3 },
    { label: 'Good impact', value: 'Good impact', score: 4 },
    { label: 'Great impact', value: 'Great impact', score: 5 },
  ]
  const cost = [
    { label: '$ 0 or N/A', value: '$ 0 or N/A', score: 1 },
    { label: '< $ 5000', value: '< $ 5000', score: 2 },
    { label: '< $ 10000', value: '< $ 10000', score: 3 },
    { label: '> $ 10000', value: '> $ 10000', score: 4 },
    { label: '> $ 50000', value: '> $ 50000', score: 5 },
  ]
  const violation = [
    { label: 'None / NA', value: 'None / NA', score: 1 },
    { label: 'Unlikely', value: 'Unlikely', score: 2 },
    { label: 'Possible', value: 'Possible', score: 3 },
    { label: 'Very likely', value: 'Very likely', score: 4 },
    { label: 'Legal Risk', value: 'Legal Risk', score: 5 },
  ]

  const detectability = [
    { label: 'Always detected / NA', value: 'Always detected / NA', score: 1 },
    { label: 'Easy to detect', value: 'Easy to detect', score: 2 },
    { label: 'Somewhat easy to detect', value: 'Somewhat easy to detect', score: 3 },
    { label: 'Difficult to detect', value: 'Difficult to detect', score: 4 },
    { label: 'Impossible to detect', value: 'Impossible to detect', score: 5 },
  ]

  const status = [
    { label: 'Open', value: 'Open' },
    { label: 'CLOSED', value: 'CLOSED' },
  ]

  const createPositionDescription = () => {
    console.log(jsonAnswers);
  };

  function getShipId() {
    if (params.id != null) {
      setId(params.id)
      getCOTORiskID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)


      }).catch(err => {
        console.error(err);
      });
    }
  }

  useEffect(() => {
    loadShips();
    loadIssues();
    loadParty();
    (action === 'view' || action === 'update') && getShipId()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const loadShips = () => {
    getProcess()
      .then((resp) => {
        console.log(resp.data);
        //setShips(resp.data);
        var data = resp.data.map(index => {
          optionsV.push({
            label: index.processName,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadParty = () => {
    getParty()
      .then((resp) => {
        console.log(resp.data);
        //setShips(resp.data);
        var data = resp.data.map(index => {
          optionsParty.push({
            label: index.name,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadIssues = () => {
    getIssues()
      .then((resp) => {
        console.log(resp.data);
        setShips(resp.data);
        var data = resp.data.map(index => {
          optionsIssues.push({
            label: index.name,
            value: index.id
          })
        })
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };




  return (
    <CoverLayout image={bgImage}>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Franconaves COTO Form Opp Register
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <br />
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Interested Party: </p>
                  <br />
                  {/*  <Select
              placeholder="Select a Interested Party..."
              options={optionsParty}
              onChange={(e) => setJobType(e.value)}
            /> */}
                  {
                    action === 'add' ?
                      <Select
                        options={optionsParty}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setJobType(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsParty}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsParty.find(label => label.label === (inputs && inputs.party.name)))] : null}
                        onChange={(e) => {
                          setJobType(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>Issue: </p>
                  <br />
                  {/* <Select
              placeholder="Select a Issues..."
              options={optionsIssues}
              onChange={(e) => setJobTypeIssues(e.value)}
            /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={optionsIssues}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setJobTypeIssues(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsIssues}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsIssues.find(label => label.label === (inputs && inputs.issue.name)))] : null}
                        onChange={(e) => {
                          setJobTypeIssues(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Specific Risk:{" "}
                  </p>
                  <br />
                  {/*   <MDInput
              type="text"
              //label="Reports to:"
              variant="standard"
              fullWidth
              placeholder="Specific Opportunity"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setspecific_opportunity(e.target.value)}
            /> */}
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      //label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setspecific_opportunity(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.specific_opportunity : null}
                      value={action === 'view' && inputs ? inputs.specific_opportunity : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      //label="Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setspecific_opportunity(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Main Process Affected: </p>
                  <br />
                  {/*  <Select
              placeholder="Select a Main Process..."
              options={optionsV}
              onChange={(e) => setmain_process(e.value)}
            /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={optionsV}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setmain_process(e.value)
                        }}
                      ></Select>
                      :
                      <Select
                        options={optionsV}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(optionsV.find(label => label.label === (inputs && inputs.process[0].processName)))] : null}
                        onChange={(e) => {
                          setmain_process(e.value)
                        }}
                      ></Select>
                  }
                </MDBox>
                <h>
                  Probability (of risk occuring)
                </h>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Likelihood: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Likelihood..."
                    options={likelihood}
                    onChange={(e) => {
                      setlikelihooddata(e.label)
                      setlikelihoodscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={likelihood}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setlikelihooddata(e.label)
                          setlikelihoodscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={likelihood}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(likelihood.find(label => label.label === (inputs && inputs.likelihood)))] : null}
                        onChange={(e) => {
                          setlikelihooddata(e.label)
                          setlikelihoodscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Previous Occurrences: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Occurrences..."
                    options={occurrences}
                    onChange={(e) => {
                      setocurrences(e.label)
                      setOcurrecescore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={occurrences}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setocurrences(e.label)
                          setOcurrecescore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={occurrences}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(occurrences.find(label => label.label === (inputs && inputs.occurrences)))] : null}
                        onChange={(e) => {
                          setocurrences(e.label)
                          setOcurrecescore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <h3>
                  {occurrencescore &&
                     'Prob Rating: ' + ((likelihoodscore + occurrencescore) / 2).toFixed(1)
                  }
                </h3>
                <br />
                <h>
                Consequence (if risk is encountered)
                </h>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Loss of Customer or Entire Contract: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential for New Business..."
                    options={potencial}
                    onChange={(e) => {
                      setPotencialBusinessdata(e.label)
                      setPotencialBusinessscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotencialBusinessdata(e.label)
                          setPotencialBusinessscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.pontential_loss)))] : null}
                        onChange={(e) => {
                          setPotencialBusinessdata(e.label)
                          setPotencialBusinessscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Inability to Satisfy Immediate Job or Order: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential Expansion of Current Business..."
                    options={potencial}
                    onChange={(e) => {
                      setPotencialExpansion(e.label)
                      setPotencialExpansionscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotencialExpansion(e.label)
                          setPotencialExpansionscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.potential_inability)))] : null}
                        onChange={(e) => {
                          setPotencialExpansion(e.label)
                          setPotencialExpansionscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Risk to Human Health: </p>
                  <br />
                  {/*    <Select
                    placeholder="Select a Potential improvement..."
                    options={potencial}
                    onChange={(e) => {
                      setPotentialimprovement(e.label)
                      setPotentialimprovementscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={potencial}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotentialimprovement(e.label)
                          setPotentialimprovementscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={potencial}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(potencial.find(label => label.label === (inputs && inputs.potential_human_risk)))] : null}
                        onChange={(e) => {
                          setPotentialimprovement(e.label)
                          setPotentialimprovementscore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Potential Violation of Regulations: </p>
                  <br />
                  {/*    <Select
                    placeholder="Select a Potential improvement..."
                    options={potencial}
                    onChange={(e) => {
                      setPotentialimprovement(e.label)
                      setPotentialimprovementscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={violation}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setpotencialViolation(e.label)
                          setpotencialViolationscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={violation}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(violation.find(label => label.label === (inputs && inputs.potential_violation)))] : null}
                        onChange={(e) => {
                          setpotencialViolation(e.label)
                          setpotencialViolationscore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>

                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Impact on Company Reputation: </p>
                  <br />
                  {/*  <Select
                    placeholder="Select a Potential improvement to internal QMS processes..."
                    options={potencial}
                    onChange={(e) => {
                      setPotentialimprovementQMS(e.label)
                      setPotentialimprovementQMSscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={reputation}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setPotentialimprovementQMS(e.label)
                          setPotentialimprovementQMSscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={reputation}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(reputation.find(label => label.label === (inputs && inputs.improvement_to_company_reputation)))] : null}
                        onChange={(e) => {
                          setPotentialimprovementQMS(e.label)
                          setPotentialimprovementQMSscore(e.score)
                        }}
                      ></Select>
                  }

                </MDBox>
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Est. Cost of Correction / Financial Penalty: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Potential Cost of Implementation..."
                    options={cost}
                    onChange={(e) => {
                      setImplementation(e.label)
                      setImplementationscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={cost}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setImplementation(e.label)
                          setImplementationscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={cost}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(cost.find(label => label.label === (inputs && inputs.finanial_penalty)))] : null}
                        onChange={(e) => {
                          setImplementation(e.label)
                          setImplementationscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>
                <h3>
            {occurrencescore &&
              'Cons. Rating: ' + ((PotencialBusinessscore + PotencialExpansionscore +Potentialimprovementscore+potencialViolationscore+PotentialimprovementQMSscore+Implementationscore ) / 6).toFixed(1)
            }
          </h3>
                <br />

                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Ability to detect the risk: </p>
                  <br />
                  {/*   <Select
                    placeholder="Select a Potential Cost of Implementation..."
                    options={cost}
                    onChange={(e) => {
                      setImplementation(e.label)
                      setImplementationscore(e.score)
                    }}
                  /> */}

                  {
                    action === 'add' ?
                      <Select
                        options={detectability}
                        //isDisabled={action === 'view'? true : false}
                        //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                        onChange={(e) => {
                          setabilityDetect(e.label)
                          setabilityDetectscore(e.score)
                        }}
                      ></Select>
                      :
                      <Select
                        options={detectability}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && inputs ? [(detectability.find(label => label.label === (inputs && inputs.ability_to_detect_risk)))] : null}
                        onChange={(e) => {
                          setabilityDetect(e.label)
                          setabilityDetectscore(e.score)
                        }}
                      ></Select>
                  }
                </MDBox>

                <br />
          <h3>
            {abilityDetectscore &&
              'Det. Rating: ' + (abilityDetectscore).toFixed(1)
            }
          </h3>
          <br />
          <h3>
            {abilityDetectscore &&
              'Risk Factor (Prob x Cons x Det): ' +  (((PotencialBusinessscore + PotencialExpansionscore +Potentialimprovementscore+potencialViolationscore+PotentialimprovementQMSscore+Implementationscore ) / 6).toFixed(1)) * abilityDetectscore
            }
          </h3>
          <br />



              </MDBox>
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              Required for risk factors >= 25, suggested for risk factors between 15 and 25:
                </p>
                <br />
                {/*   <MDInput
                  type="text"
                  //label="Job Title:"
                  variant="standard"
                  fullWidth
                  //placeholder="Job Title"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setOpportunityPursuit(e.target.value)}
                /> */}
                {
                  (action !== 'add' && inputs) &&
                  <MDInput
                    type="text"
                    //label="Name:"
                    variant="standard"
                    fullWidth
                    placeholder="Name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setOpportunityPursuit(e.target.value)}
                    defaultValue={(action === 'update') && inputs ? inputs.migration_plan : null}
                    value={action === 'view' && inputs ? inputs.migration_plan : null}
                    InputProps={{ readOnly: isView, }}
                  />
                }
                {
                  (action === 'add') &&
                  <MDInput
                    type="text"
                    //label="Name:"
                    variant="standard"
                    fullWidth
                    placeholder="Name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setOpportunityPursuit(e.target.value)}
                    //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                    //value={action === 'view' && inputs? inputs.serie: null }
                    InputProps={{ readOnly: isView, }}
                  />
                }
              </MDBox>
              {/*   <MDBox mb={2}>
          <p style={{ fontSize: "2vh", textAlign: "justify" }}> Status: </p>
          <br />
          <Select
            placeholder="Select a Status..."
            options={status}
            onChange={(e) => {
              setstatus(e.label)
            }}
          />
        </MDBox> */}
 
              
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/coto_table")}
              >
                Cancelar
              </MDButton>
                {/*  <MDButton
                 variant="gradient"
                 color="success"
                 onClick={() => createCOTO() }
               >
                 Create COTO
               </MDButton> */}
              {
                 action !== 'view' &&
                 <MDButton
                 variant="gradient"
                 color="success"
                 onClick={() => action === 'add' ? createCOTO() : updateCOTO()}
               >
                {
                   action === 'add' ? 'Creare COTO Risk' : 'Update COTO Risk' 
                }
               </MDButton>
              }
            </MDBox>
          </>
      }
    </CoverLayout>
  );
}
export default JobDescriptionForm;
