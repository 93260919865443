import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

import form from './schemas/form';
import validations from './schemas/validations';
import {initialValues, initialValuesFromObj} from './schemas/initialValues';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import SuccessCreatedDialog from '../../../components/SuccessCreatedDialog';
import SnackNotification from '../../../components/SnackNotification';
import ContentDialog from '../../../components/ContentDialog';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import DataTable from '../../../components/DataTable';
import MDInput from '../../../components/MDInput';
import { TextField } from '@mui/material';

import TaskForm from './components/TaskForm'
import CreateSubTask from '../CreateSubTask';

import { addTask, getTaskById, updateTask, getSubTasks, getSubTasksDetails, addSubTaskDetail } from '../../../Services/TaskService'

import { subTaskHeaders, taskItemsHeaders } from '../../../utils/tableHeaders'
import { buildData } from '../../../utils/tableData'

const CreateTask = ({action}) => {
    const params = useParams();
    const [openError, setOpenError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [inputs, setInputs] = useState();
    const [id, setId] = useState(0)

    const [task, setTask] = useState([]);
    const [taskPage, setTaskPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState();
    const [taskLoading, setTaskLoading] = useState(true);

    const [details, setDetails] = useState([]);
    const [detailsPage, setDetailsPage] = useState(1);
    const [detailsTotalPages, setDetailsTotalPages] = useState();
    const [detailsLoading, setDetailsLoading] = useState(true);

    const { formId, formField } = form;

    const isAdd = action === "add";
    const isUpdate = action === "update";
    const isView = action === "view";

    const submitForm = async (values, actions) => {
        console.log(values);
        if(isAdd){
            addTask(values).then(resp => {
                console.log(resp.data)
                actions.setSubmitting(false);
                setOpenDialog(true)
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            });
        }else if (isUpdate){
            updateTask(id,values).then(resp => {
                console.log(resp.data)
                actions.setSubmitting(false);
                setOpenDialog(true)
            }).catch(err => {
                console.error(err.response);
                setOpenError(true)
            });
        }
        actions.setSubmitting(false);
    }

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    }

    const loadSubTasks = (page, task) => {
        getSubTasks(page, task).then(resp => {
            console.log(resp.data)
            setTask(buildData(resp.data.sub_tasks, subTaskHeaders()));
            setTaskPage(parseInt(resp.data.current_page));
            setTaskTotalPages(resp.data.pages);
            setTaskLoading(false);
        }).catch(err => {
            console.error(err.response);
        });
    }

    const loadSubTaskDetail = (page, subTask) => {
        getSubTasksDetails(page, subTask).then(resp => {
            console.log(buildData(resp.data.sub_task_details, taskItemsHeaders()))
            setDetails(buildData(resp.data.sub_task_details, taskItemsHeaders()));
            setDetailsPage(parseInt(resp.data.current_page));
            setDetailsTotalPages(resp.data.pages);
            setDetailsLoading(false);
        }).catch(err => {
            console.error(err.response);
        });
    }

    const handleTaskPagination = (event, value) => {
        setTaskPage(value);
        loadSubTasks(value, params.id);
    };

    const handleDetailsPagination = (event, value) => {
        setDetailsPage(value);
        loadSubTaskDetail(value, params.id);
    };

    useEffect(()=>{
        if(params.id != null){
            setId(params.id)
            getTaskById(params.id).then(resp => {
                console.log(resp.data)
                setInputs(resp.data)
                loadSubTasks(1, params.id)
            }).catch(err => {
                console.error(err);
            });
        }
    },[ ]);

    //SubTask Actions
    const [openCreateSubTask, setOpenCreateSubTask] = useState(false);
    const [subTaskAction, setSubTaskAction] = useState()
    const [subTaskId, setSubTaskId] = useState()
    const [openDetials, setOpenDetails] = useState(false)

    const closeModals = () => {
        setOpenCreateSubTask(false);
        setSubTaskId()
        setSubTaskAction()
        loadSubTasks(1, params.id)
        setOpenDetails(false)
    };

    const updateSubTask = (value) => {
        setSubTaskId(value.id)
        setOpenCreateSubTask(true)
        setSubTaskAction("update")
    }

    const viewSubTask = (value) => {
        setSubTaskId(value.id)
        setOpenCreateSubTask(true)
        setSubTaskAction("view")
    }

    const selectSubTask = (value) => {
        loadSubTaskDetail(1, value.id)
        setOpenDetails(true)
        setSubTaskId(value.id)
    }

    const [openCreateDetail, setopenCreateDetail] = useState(false)
    const [inputsDetail, setInputsDetail] = useState({});
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleChange = (event, type) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputsDetail(values => ({...values, [name]: value})) ;
    }

    const createDetail = () => {
        addSubTaskDetail(inputsDetail).then(resp => {
            console.log(resp.data)
            setOpenSuccess(true)
            loadSubTaskDetail(1, subTaskId)
            closeDetailModal()
        }).catch((err) => {
            console.error(err);
            setOpenError(true)
        })
    }

    const closeDetailModal = () => {
        setInputsDetail({})
        setopenCreateDetail(false)
        setSubTaskId()
    }

    return (
        <MDBox pt={3} pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
                <Grid item xs={12} lg={8}>
                    <MDBox mt={6} mb={8} textAlign="center">
                        <MDBox mb={1}>
                            <MDTypography variant="h3" fontWeight="bold">
                            { isAdd && "Creacion de Task" }
                            { isUpdate && "Edicion de Task" }
                            { isView && "Visualizacion de Task" }
                            </MDTypography>
                        </MDBox>
                        {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                            { inputs != null && inputs != undefined &&  inputs.part_id }
                        </MDTypography> */}
                    </MDBox>
                    <Formik
                        initialValues={ inputs != null && inputs != undefined ? initialValuesFromObj(inputs) : initialValues }
                        validationSchema={validations}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card>
                                    <MDBox p={3}>
                                        <TaskForm isView={isView} isUpdate={isUpdate} formData={{values,touched,formField, errors,setFieldValue}}/>
                                        <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                            <Link to="/task/list" style={{ alignSelf: "start" }} >
                                                <MDButton variant="gradient" color="error" sx={{ height: "100%" }}>
                                                    Cancelar
                                                </MDButton>
                                            </Link>
                                            <MDButton
                                                disabled={isView}
                                                variant="gradient"
                                                color="success"
                                                type="submit"
                                                >
                                                Aceptar
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                    {!isAdd && (
                        <Grid container>
                        <Grid item xs={12} md={12}>
                            <MDBox mt={6} mb={8} textAlign='center'>
                            <MDBox mb={1}>
                                <MDTypography variant='h5' fontWeight='bold'>
                                    Sub-Tasks
                                </MDTypography>
                            </MDBox>
                            <MDBox
                                display='flex'
                                justifyContent='space-between'
                                alignItems='flex-start'
                                mb={2}
                            >
                                <MDButton
                                variant='gradient'
                                color='info'
                                onClick={()=>{ setOpenCreateSubTask(true); setSubTaskAction("add")}}
                                >
                                Agregar
                                </MDButton>
                            </MDBox>
                            <Card>
                                {task.rows !== undefined && task.rows.length > 0 && (
                                <>
                                    <DataTable
                                    useActions
                                    useEdit
                                    useView
                                    useSelect
                                    selectAction={selectSubTask}
                                    viewAction={viewSubTask}
                                    editAction={updateSubTask}
                                    table={task}
                                    showTotalEntries={false}
                                    entriesPerPage={false}
                                    />
                                    <MDBox ml={1}>
                                    <Pagination
                                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                                        color='info'
                                        count={taskTotalPages}
                                        page={taskPage}
                                        onChange={handleTaskPagination}
                                    />{' '}
                                    </MDBox>
                                </>
                                )}
                                {taskLoading && (
                                <Box
                                    sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '200px',
                                    }}
                                >
                                    <CircularProgress color='info' size={80} />
                                </Box>
                                )}
                                {task.rows !== undefined && task.rows.length === 0 && (
                                <Typography
                                    variant='h4'
                                    component='div'
                                    sx={{ margin: '100px' }}
                                >
                                    No Existen registros
                                </Typography>
                                )}
                            </Card>
                            </MDBox>
                        </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <ContentDialog open={openCreateSubTask} title={'SubTask'} closeCallback={closeModals} maxWidth='xl' fullWidth={true} >
                <CreateSubTask action={subTaskAction} task={params.id} id={subTaskId} cancelAction={closeModals} isSubTask />
            </ContentDialog>

            <ContentDialog open={openDetials} title={'SubTask Details'} closeCallback={closeModals} maxWidth='xl' fullWidth={true} >
                <MDBox mt={6} mb={8} textAlign='center'>
                    {/* <MDBox mb={1}>
                        <MDTypography variant='h5' fontWeight='bold'>
                        Items
                        </MDTypography>
                    </MDBox> */}
                    <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <MDButton variant="gradient" color="info" onClick={()=>{setopenCreateDetail(true); setInputsDetail({ sub_task:subTaskId })}}>
                            Agregar 
                        </MDButton>
                    </MDBox>
                    <Card>
                        {details.rows !== undefined && details.rows.length > 0 && (
                        <>
                            <DataTable
                            table={details}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            />
                            <MDBox ml={1}>
                            <Pagination
                                sx={{ marginTop: '20px', marginBottom: '20px' }}
                                color='info'
                                count={detailsTotalPages}
                                page={detailsPage}
                                onChange={handleDetailsPagination}
                            />{' '}
                            </MDBox>
                        </>
                        )}
                        {detailsLoading && (
                        <Box
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '200px',
                            }}
                        >
                            <CircularProgress color='info' size={80} />
                        </Box>
                        )}
                        {details.rows !== undefined && details.rows.length === 0 && (
                        <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                            No Existen registros
                        </Typography>
                        )}
                    </Card>
                </MDBox>
            </ContentDialog>

            <ContentDialog open={openCreateDetail} title={"Task Detail"}  closeCallback={closeDetailModal} maxWidth="md" fullWidth={true} >
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={1.5}>
                                <TextField value={inputsDetail.inventory_detail || ""} onChange={handleChange} sx={{ width: "100%" }} id="inventory_detail" InputProps={{name: "inventory_detail" }} label="Item" variant="outlined" />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    <MDBox mt={2}>
                        <MDButton
                            variant="gradient"
                            color="success"
                            type="submit"
                            onClick={createDetail}
                            >
                                Aceptar
                        </MDButton>
                    </MDBox>
                </MDBox>
            </ContentDialog>

            <SuccessCreatedDialog open={openDialog} message={ isUpdate ? "Task Actualizada!" : "Task Creada!" } route="/task/list" />
            <SnackNotification  type="error" message="Ha ocurrido un error" closeCallback={console.log()} openFlag={openError} setOpenFlag={setOpenError} />
            <SnackNotification  type="success" message="Detalle creado" closeCallback={console.log()} openFlag={openSuccess} setOpenFlag={setOpenSuccess} />
        </MDBox>
    )
}

export default CreateTask