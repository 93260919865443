import { doDelete, doGet, doPost } from "../utils/api";

export const getDefaultSignInObj = () => {
  return {
    email: "",
    password: "",
  };
};

export const getRolLabel = (r) => {
  let rolLabel;
  switch (r) {
    case "A":
      rolLabel = "Admin";
      break;
    case "U":
      rolLabel = "Supervisor";
      break;
    case "I":
      rolLabel = "Inventario";
      break;
    case "S":
      rolLabel = "Naves";
      break;
    default:
      break;
  }
  return rolLabel;
};

export const checkSession = () => {
  return window.localStorage.getItem("isAuthenticated");
};

export const getTokenSession = () => {
  return window.localStorage.getItem("Token");
};

export const getSessionData = () => {
  return JSON.parse(window.localStorage.getItem("data"));
};

export const getCurrentRol = () => {
  return JSON.parse(window.localStorage.getItem("data").role);
};

export const saveSession = (token, data) => {
  
  window.localStorage.setItem("isAuthenticated", true);
  window.localStorage.setItem("Token", token);
  window.localStorage.setItem("data", JSON.stringify(data));
  window.localStorage.setItem("notification", JSON.stringify(data.notification));

  console.log(JSON.stringify(data))
};

export const saveNotifications = ( data) => {
  
  window.localStorage.setItem("notifications", JSON.stringify(data));
  console.log(JSON.stringify(data))
};

export const signIn = (data) => {
  console.log(data);
  let route = "/user/signin/";
  let headers = { "Content-Type": "multipart/form-data" };
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return doPost(route, formData, headers);
};

export const signUp = (data) => {
  let route = "/user/register";
  let headers = { Authorization: "Token ".concat(getTokenSession()) };
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return doPost(route, formData, headers);
};

export const getAllUsers = (page, filter, value) => {
  let filterStr =
    filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
  let route = "/users/"
  let headers = { Authorization: "Token ".concat(getTokenSession()) };
  return doGet(route, headers);
};

export const signOut = () => {
  window.localStorage.clear();
};
