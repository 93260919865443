
import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

const token = "321f62017eca8e1477420f48ce3faea32b07092a";

export const getDefaultObject = () => {
    return (
        {
            "company": "",
            "address": "",
            "approved": false,
            "city": "",
            "state": "",
            "note": "",
            "zip_code": "",
            "phone": "",
            "contact": "",
            "files": []
        }
    )
}

export const getSuppliers = (page, filter, value) => {
    let filterStr = filter != null && value !== undefined ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/supplier/?".concat("page=".concat(page)).concat(filterStr)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getSuppliersList = () => {
    let route = "/supplier/get_suppliers_list";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addSuppliers = (data) => {
    let route = "/provider/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()), "Content-Type": "multipart/form-data" }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if (key === "approved"){
            const str = data[key].toString();
            const str2 = str.slice(0,1).toUpperCase();
            console.log(str2);
            formData.append(key, str2)
        }
        else{
            if(data[key] != null && data[key] !== ""){
                formData.append(key, data[key])
            }
        }
    });
    return doPatch(route, formData, headers);
}

export const getSupplier = (id) => {
    let route = "/provider/".concat(id).concat("/")
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const deleteSupplier = (id) => {
    let route = "/provider/".concat(id).concat("/")
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const updateSupplier = (data, id) => {
    let route = "/provider/".concat(id).concat("/")
    let headers = { 'Authorization': "Token ".concat(getTokenSession()), "Content-Type": "multipart/form-data" }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if (key === "approved"){
            const str = data[key].toString();
            const str2 = str.slice(0,1).toUpperCase();
            console.log(str2);
            formData.append(key, str2)
        }
        else{
            if(data[key] != null && data[key] !== ""){
                formData.append(key, data[key])
            }
        }
    });
    return doPatch(route, formData, headers);
}

export const exportSupliers = () =>{
    let route = "/supplier/export";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}