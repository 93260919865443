import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';
import { getAllDeffects } from '../../../Services/MainBoard';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import InspectionList from '../CreateInspectionsTemplete/components/InspectionList';
import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';

// Wizard page components

import { addDeffect } from '../../../Services/MainBoard';
import { buildData } from '../../../utils/tableData';
import { deffectsHeaders } from '../../../utils/tableHeaders';
import { Pagination, Typography } from '@mui/material';
import DataTable from '../../../components/DataTable';

import { createAircraft } from '../../../Services/ShipService'
// import {
//   addAssembly,
//   getAssemblyById,
//   updateAssembly,
// } from '../../../Services/ShipService';

const CreateInspectionsTemplete = () => {
  const {aircrafts, setAircrafts} = useState([]);
  const { id: aircraftid } = useParams();
  const { formId, formField } = form;
  const [loadingAircrafts, setLoadingAircrafts] = useState(true);
  const [state, setState] = useState('create');

  const [inputs, setInputs] = useState();

  const [deffects, setdeffects] = useState(null);
  const [currentdeffectsPage, setCurrentdeffectsPage] = useState(1);
  const [totaldeffectsPages, setTotaldeffectsPages] = useState();

  const navigate = useNavigate();

  // get search params
  const [searchparams] = useSearchParams();

  const handleSubmit = async (values, actions) => {
    // if (state === 'create') {
    //   const newdata = {...values, aircraft: aircraftid};
    //   const { data } = await addDeffect(newdata);
    // }

    // actions.resetForm();
    // loadDeffect(1);
  };

  const loadDeffect = async (page) => {
    // const { data } = await getAllDeffects(page, aircraftid);
    // setdeffects(buildData(data.defects, deffectsHeaders()));
    // setCurrentdeffectsPage(parseInt(data.current_page));
    // setTotaldeffectsPages(data.pages);
  };

  const handleChangePageDeffects = (event, value) => {
    // setCurrentdeffectsPage(value);
    // loadDeffect(value);
  };

  const loadAircrafts = async (id, page) =>{
    try {
      const {data} = await createAircraft(id)
      console.log(data)
      setLoadingAircrafts(false)
    }
    catch(err){
      console.error(err)
    } finally {
      setLoadingAircrafts(false)
    }
  }

  useEffect(() => {
    loadAircrafts(1)
  }, []);

  return (
    <MDBox pt={3} pb={8}>
      <InspectionList />
      

      {deffects && (
            <>
              <Typography
                variant='h4'
                component='div'
                style={{ marginTop: 25, marginBottom: 15 }}
              >
                Inspections
              </Typography>

              <Card>
                {deffects.rows !== undefined && deffects.rows.length > 0 && (
                  <>
                    <DataTable
                      //   handleSearch={handleSearch}
                      // useActions
                      useView
                      useEdit
                      // useDelete
                      // useSelect
                      // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                      editAction={(data) =>
                        navigate(
                          `/ships/shipparts/update/${data.id}?aircraft=${inputs?.id}`
                        )
                      }
                      viewAction={(data) =>
                        navigate(`/ships/shipparts/view/${data.id}`)
                      }
                      // deleteAction={deleteRegister}
                      table={deffects}
                      showTotalEntries={false}
                      entriesPerPage={false}
                      // canSearch
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={totaldeffectsPages}
                        page={currentdeffectsPage}
                        onChange={handleChangePageDeffects}
                      />{' '}
                    </MDBox>{' '}
                  </>
                )}
                {/* {shipPartsLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )} */}
                {deffects?.rows !== undefined && deffects?.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen inspections
                  </Typography>
                )}
              </Card>
            </>
          )}
    </MDBox>
  );
};

export default CreateInspectionsTemplete;
