import React, { useState, useEffect } from 'react'
import axios from "axios";
// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

import { getAircraft, getShip, getTurbine, getTypeAircraft } from "../../Services/formServices";
import { getShipById, addShip, updateShip } from '../../Services/ShipService'

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from '../../components/Loader';
import FileViewer from "react-file-viewer";




function ShipCreator({ isView, formData, isUpdate, action }) {
  const params = useParams();
  const [matricula, setMatricula] = useState();
  const [serie, setSerie] = useState();
  const [aircraft, setAircraft] = useState();
  const [turbine, setTurbine] = useState();
  const [typeAircraft, setTypeAircraft] = useState();
  const [serieT, setSerieT] = useState();
  const [vtype, setVtypeT] = useState();
  const [vtypeT, setVtype] = useState();
  const [v2type, setV2type] = useState();
  const [subtype, setSubtype] = useState();
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [imageAir, setImageAir] = useState();
  const [imageRe, setImageRe] = useState();
  const [re_path, setRE] = useState(false);
  const [air_path, setAir] = useState(false);
  const [years, setYear] = useState([]);
  const [YearS, setYearS] = useState();

  const [oparator_address, setoparator_address] = useState();
  const [oparator_name, setoparator_name] = useState();
  const [registration_date, setregistration_date] = useState();
  const [airworthiness_date, setairworthiness_date] = useState();
  const [registration_path, setregistration_path] = useState();
  const [airworthiness_path, setairworthiness_path] = useState();


  const userToken = localStorage.getItem('Token') || '';
  const [id, setId] = useState(0)
  const [optionsV, setOptions] = useState([]);
  const [optionsV2, setOptions2] = useState([]);
  const [inputs, setInputs] = useState();
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);

  const [loadingData, setloadingData] = useState(false)

  const vtypes = [
    { label: 'NO', value: 'NO' },
    { label: 'TG', value: 'TG' },
    { label: 'OO', value: 'OO' },
    { label: 'N', value: 'N' },
  ]

  const v2types = [
    { label: 'Monomotor', value: 'M' },
    { label: 'Bimotor', value: 'B' },
    { label: 'NONE', value: 'N' },
  ]

  const createShip = () => {
    const shipData = new FormData();
    shipData.append('v2type', subtype);


    shipData.append('aircraft_type', v2type);
    shipData.append('serieT', serieT);
    shipData.append('serie', serie);
    shipData.append('registration', matricula);
    shipData.append('turbine', vtype);
    shipData.append('aircraft', vtypeT);
    shipData.append('file', file);
    shipData.append('manufacturing_year', YearS);

    shipData.append('oparator_address', oparator_address);
    shipData.append('oparator_name', oparator_name);
    shipData.append('registration_date', registration_date);
    shipData.append('airworthiness_date', airworthiness_date);
    shipData.append('registration_path', registration_path);
    shipData.append('airworthiness_path', airworthiness_path);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/vehicle/',
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Nave Creada Exitosamente")
      window.location.replace("/ships_list");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al crear nave")
    }.bind(this))

  };

  const updateShip = () => {
    const shipData = new FormData();
    subtype && shipData.append('v2type', subtype);


    v2type && shipData.append('aircraft_type', v2type);
    serieT && shipData.append('serieT', serieT);
    serie && shipData.append('serie', serie);
    matricula && shipData.append('registration', matricula);
    vtype && shipData.append('turbine', vtype);
    vtypeT && shipData.append('aircraft', vtypeT);
    YearS && shipData.append('manufacturing_year', YearS);
    file && shipData.append('file', file);

    oparator_address && shipData.append('oparator_address', oparator_address);
    oparator_name && shipData.append('oparator_name', oparator_name);
    registration_date && shipData.append('registration_date', registration_date);
    airworthiness_date && shipData.append('airworthiness_date', airworthiness_date);
    registration_path && shipData.append('registration_path', registration_path);
    airworthiness_path && shipData.append('airworthiness_path', airworthiness_path);
    axios.patch(
      `https://fn.devpackgroup.com/api/v1/vehicle/${params.id}/`,
      shipData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Nave Actualizada Exitosamente")
      //window.location.reload();
      window.location.replace("/ships_list");
    }.bind(this)).catch(function (response) {
      console.log(response)
      alert("Error al actualizar nave")
    }.bind(this))

  };

  const loadaricraft = () => {
    getAircraft()
      .then((resp) => {
        console.log(resp.data);
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setAircraft(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadturbine = () => {
    getTurbine()
      .then((resp) => {
        console.log(resp.data);
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setTurbine(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const loadtypeAircraft = () => {
    getTypeAircraft()
      .then((resp) => {
        console.log(resp.data);
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setTypeAircraft(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  function getShipId() {
    if (params.id != null) {
      setId(params.id)
      getShipById(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)
        downloadFile(resp.data.profile_path)
        //downloadFileAir(resp.data.airworthiness_path)
        //downloadFileRe(resp.data.registration_path)
        var vtypeF = (vtypes.find(label => label.label === resp.data.vtype))
        optionsV.push(vtypeF)
        var vtypeF2 = (v2types.find(label => label.value === resp.data.v2type))
        optionsV2.push(vtypeF2)
      }).catch(err => {
        console.error(err);
      });
    }
  }

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth()).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const date = new Date(2023, 10, 18);
  const formattedDate = formatDate(date);

  console.log(formattedDate); // Debería imprimir "18/10/2023"
  const registrationDate2 = new Date(2023, 10, 18);

  const downloadFile = (url) => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/vehicle/download/?key=${url}`,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      /* window.open(
        `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`
      ); */
      setImage(response.data.url)
      //alert("Proceso Creado Exitosamente")
      //window.location.reload();
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      //alert("Error al crear proceso")
    }.bind(this))
  }
  const downloadFileAir = (url) => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/vehicle/download/?key=${url}`,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      /* window.open(
        `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`
      ); */
      setImageAir(response.data.url)
      setAir(!air_path);
      //alert("Proceso Creado Exitosamente")
      //window.location.reload();
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      //alert("Error al crear proceso")
    }.bind(this))
  }
  const downloadFileRe = (url) => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/vehicle/download/?key=${url}`,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      /* window.open(
        `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`
      ); */
      setImageRe(response.data.url)
      setRE(!re_path);
      //alert("Proceso Creado Exitosamente")
      //window.location.reload();
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      //alert("Error al crear proceso")
    }.bind(this))
  }
  function getYear() {
    let year = new Date().getFullYear();

    for (let i = year; i >= 1990; i--) {
      years.push({ label: i, value: i });
    }

  }
  useEffect(() => {
    loadaricraft();
    loadturbine();
    getYear();
    loadtypeAircraft();
    (action === 'view' || action === 'update') && getShipId()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);

  }, []);

  const onError = e => {
    console.log(e, "error in file-viewer");
  };

  return (
    <div
    //image={bgImage}
    >
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {action === 'view' && 'Vista de Nave'}
                  {action === 'add' && 'Creacion de Naves'}
                  {action === 'update' && 'Editar Nave'}
                </MDTypography>
              </MDBox>
            </Card>

            <MDBox pt={4} pb={3} px={3}>
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2} >
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Matricula: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Matricula:"
                      variant="standard"
                      fullWidth
                      placeholder="Matricula"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.registration : null}
                      value={(action === 'view') && inputs ? inputs.registration : null}
                      onChange={(e) => setMatricula(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Matricula:"
                      variant="standard"
                      fullWidth
                      placeholder="Matricula"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setMatricula(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Modelo de Nave: </p>
                  <br />
                  {
                    
                  }
                  {
                    action === 'add' ?
                    <Select
                      options={aircraft}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setVtype(e.value)
                      }}
                    ></Select>
                    :
                    action === 'update'?
                    (inputs && [(aircraft && aircraft.find(label => label.label === inputs.aircraft.name))].length > 0) &&
                    <Select
                      options={aircraft}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(aircraft && aircraft.find(label => label.label === inputs.aircraft.name))] : null}
                      onChange={(e) => {
                        setVtype(e.value)
                      }}
                    ></Select>
                    :
                    <MDInput
                    type="text"
                    label="Modelo de Nave:"
                    variant="standard"
                    fullWidth
                    placeholder="Matricula"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={(action === 'view') && inputs ? inputs.aircraft.name: null }
                    //value={(action === 'view') && inputs ? inputs.registration: null }
                    onChange={(e) => setMatricula(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                  />
                  }

                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Numero de serie de nave: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Numero De Serie:"
                      variant="standard"
                      fullWidth
                      placeholder="Numero De Serie"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerie(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.serie : null}
                      value={action === 'view' && inputs ? inputs.serie : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Numero De Serie:"
                      variant="standard"
                      fullWidth
                      placeholder="Numero De Serie"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerie(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                </MDBox>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Numero de serie de Turbina: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Numero De Serie:"
                      variant="standard"
                      fullWidth
                      placeholder="Numero De Serie"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerieT(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.serieT : null}
                      value={action === 'view' && inputs ? inputs.serieT : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Numero De Serie:"
                      variant="standard"
                      fullWidth
                      placeholder="Numero De Serie"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setSerieT(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }

                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Modelo de Turbina: </p>
                  <br />
                
                  {
                    action === 'add' ?
                    <Select
                      options={turbine}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setVtypeT(e.value)
                      }}
                    ></Select>
                    :
                    action === 'update'?
                    (inputs && [(turbine && turbine.find(label => label.label === inputs.turbine.name))].length > 0) &&
                    <Select
                      options={turbine}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(turbine && turbine.find(label => label.label === inputs.turbine.name))] : null}
                      onChange={(e) => {
                        setVtypeT(e.value)
                      }}
                    ></Select>
                    :
                    <MDInput
                    type="text"
                    label="Modelo de Turbina:"
                    variant="standard"
                    fullWidth
                    placeholder="Matricula"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={(action === 'view') && inputs ? inputs.turbine.name: null }
                    //value={(action === 'view') && inputs ? inputs.registration: null }
                    onChange={(e) => setMatricula(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                  />
                  }
                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Tipo de Aeronave: </p>
                  <br />
                  {
                    
                  }
                  {
                    action === 'add' ?
                    <Select
                      options={typeAircraft}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setV2type(e.value)
                      }}
                    ></Select>
                    :
                    action === 'update'?
                    (inputs && [(typeAircraft && typeAircraft.find(label => label.label === inputs.aircraft_type.name))].length > 0) &&
                    <Select
                      options={typeAircraft}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(typeAircraft.find(label => label.label === (inputs && inputs.aircraft_type.name)))] : null}
                      onChange={(e) => {
                        setV2type(e.value)
                      }}
                    ></Select>
                    :
                    <MDInput
                    type="text"
                    label="Modelo de Aeronave:"
                    variant="standard"
                    fullWidth
                    placeholder="Matricula"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={(action === 'view') && inputs ? inputs.aircraft_type.name: null }
                    //value={(action === 'view') && inputs ? inputs.registration: null }
                    onChange={(e) => setMatricula(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                  />
                  }

                </MDBox>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> SubTipo de Aeronave: </p>
                  <br />
                  {
                 
                  }
                  {
                    action === 'add' ?
                    <Select
                      options={v2types}
                      //isDisabled={action === 'view'? true : false}
                      //defaultValue={action === 'view' && inputs ?[(vtypes.find( label => label.label === inputs.vtype ))] : null}
                      onChange={(e) => {
                        setSubtype(e.value)
                      }}
                    ></Select>
                    :
                    action === 'update'?
                    (inputs && [(v2types.find(label => label.value === inputs.v2type))].length > 0) &&
                    <Select
                      options={v2types}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && inputs ? [(v2types.find(label => label.value === inputs.v2type))] : null}
                      onChange={(e) => {
                        setSubtype(e.value)
                      }}
                    ></Select>
                    :
                    <MDInput
                    type="text"
                    label="subtipo de Aeronave:"
                    variant="standard"
                    fullWidth
                    placeholder="Matricula"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={(action === 'view') && inputs ? inputs.v2type === 'M'? 'Monotipo':inputs.v2type === 'B'? 'Bimotor': 'None': null }
                    //value={(action === 'view') && inputs ? inputs.registration: null }
                    onChange={(e) => setMatricula(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                  />
                  }

                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>

                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Año: </p>
                  <br />
                  {
                    action === 'add' || action === 'update'?
                    <Select
                      placeholder="Manufacturing Year:"
                      options={years}
                      //isMulti={true}
                      onChange={(o) => { setYearS(o.value) }}
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'update') && (inputs.manufacturing_year ? years.find(label => label.value === parseInt(inputs.manufacturing_year)) : '')}
  
                    ></Select>
                    :
                    <MDInput
                    type="text"
                    label="Manufacturing Year:"
                    variant="standard"
                    fullWidth
                    placeholder="Manufacturing Year"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={(action === 'view') && inputs ? inputs.manufacturing_year: null }
                    //value={(action === 'view') && inputs ? inputs.registration: null }
                    onChange={(e) => setMatricula(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                  />
                  }
                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Registration Date: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    /*
                    <MDInput
                      type="date"
                      label="Registration Date:"
                      variant="standard"
                      fullWidth
                      placeholder="Registration Date"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setregistration_date(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.registration_date : null}
                      value={action === 'view' && inputs ? inputs.registration_date : null}
                      InputProps={{ readOnly: isView, }}
                    />
                    */
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setregistration_date(formattedDate)
                            console.log(formattedDate)

                          }}
                          disabled={action === 'view'}
                          defaultValue={(action === 'update') && inputs ? dayjs(inputs.registration_date, 'DD/MM/YYYY') : null}
                          value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.registration_date, 'DD/MM/YYYY') : null}
                          label="Registration Date:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  }
                  {
                    (action === 'add') &&
                    /*
                    <MDInput
                      type="date"
                      label="Registration Date:"
                      variant="standard"
                      fullWidth
                      placeholder="Registration Date"
                      InputLabelProps={{ shrink: true }}
                      //onChange={handleChange}
                      onChange={(e) => setregistration_date(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //InputProps={{ readOnly: isView, }}
                      
                    />
                    */
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setregistration_date(formattedDate)
                            console.log(formattedDate)

                          }}
                          //defaultValue={(action === 'update') && inputs ? dayjs(inputs.registration_date) : null}
                          value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.registration_date, 'DD/MM/YYYY') : null}
                          disabled={action === 'view'}
                          label="Registration Date:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  }

                </MDBox>


              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Operator Address: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Operator Address:"
                      variant="standard"
                      fullWidth
                      placeholder="Operator Address"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setoparator_address(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.oparator_address : null}
                      value={action === 'view' && inputs ? inputs.oparator_address : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Operator Address:"
                      variant="standard"
                      fullWidth
                      placeholder="Operator Address"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setoparator_address(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }

                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Operator Name: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Operator Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Operator Address"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setoparator_name(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.oparator_name : null}
                      value={action === 'view' && inputs ? inputs.oparator_name : null}
                      InputProps={{ readOnly: isView, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Operator Name:"
                      variant="standard"
                      fullWidth
                      placeholder="Operator Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setoparator_name(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                  }

                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Airworthiness Date: </p>
                  <br />
                  {
                    (action !== 'add' && inputs) &&
                    /*
                    <MDInput
                      type="date"
                      label="Airworthiness Date:"
                      variant="standard"
                      fullWidth
                      placeholder="Airworthiness Date"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setairworthiness_date(e.target.value)}
                      defaultValue={(action === 'update') && inputs ? inputs.airworthiness_date : null}
                      value={action === 'view' && inputs ? inputs.airworthiness_date : null}
                      InputProps={{ readOnly: isView, }}
                    />
                    */
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setairworthiness_date(formattedDate)
                            console.log(formattedDate)

                          }}
                          defaultValue={(action === 'update') && inputs ? dayjs(inputs.airworthiness_date, 'DD/MM/YYYY') : null}
                          value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.airworthiness_date, 'DD/MM/YYYY') : null}
                          disabled={action === 'view'}
                          label="Airworthiness Date:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  }
                  {
                    (action === 'add') &&
                    /*
                    <MDInput
                      type="date"
                      label="Airworthiness Date:"
                      variant="standard"
                      fullWidth
                      placeholder="Airworthiness Date"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setairworthiness_date(e.target.value)}
                      //defaultValue={(action === 'update') && inputs ? inputs.serie: null }
                      //value={action === 'view' && inputs? inputs.serie: null }
                      InputProps={{ readOnly: isView, }}
                    />
                    */
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setairworthiness_date(formattedDate)
                            console.log(formattedDate)

                          }}
                          InputProps={{ readOnly: isView, }}
                          //defaultValue={(action === 'update') && inputs ? dayjs(inputs.airworthiness_date) : null}
                          //value={action === 'view' && inputs ? dayjs(inputs.airworthiness_date) : null}

                          label="Airworthiness Date:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  }

                </MDBox>
              </div>
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Foto: </p>
                  <br />
                  {
                    action !== 'view' &&
                    <input
                      type={'file'}
                      //multiple="multiple"
                      onChange={(e) => {
                        setImage(URL.createObjectURL(e.target.files[0]));
                        setFile(e.target.files?.[0])
                      }}
                      name="pathToCP_POS"
                      id="input_pathToTechpack"
                    />
                  }
                  {image && <img style={{ width: '100%' }} src={image} />}
                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Registration: </p>
                  <br />
                  {
                    action !== 'view' &&
                    <input
                      type={'file'}
                      //multiple="multiple"
                      onChange={(e) => {
                        //setImage(URL.createObjectURL(e.target.files[0]));
                        setregistration_path(e.target.files?.[0])
                      }}
                      name="pathToCP_POS"
                      id="input_pathToTechpack"
                    />
                  }

                  <MDButton
                    className="btn-simple"
                    color="info"
                    size="sm"
                    onClick={() => {

                      downloadFileRe(inputs.registration_path)
                      setAir(false)
                      //this.toggleUploadPOModal();
                    }}
                  >
                    <i className="tim-icons icon-cloud-upload-94" />
                    View PROCESS File
                  </MDButton>
                </MDBox>

                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Airworthiness: </p>
                  <br />
                  {
                    action !== 'view' &&
                    <input
                      type={'file'}
                      //multiple="multiple"
                      onChange={(e) => {
                        //setImage(URL.createObjectURL(e.target.files[0]));
                        setairworthiness_path(e.target.files?.[0])
                      }}
                      name="pathToCP_POS"
                      id="input_pathToTechpack"
                    />
                  }

                  <MDButton
                    className="btn-simple"
                    color="info"
                    size="sm"
                    onClick={() => {
                      downloadFileAir(inputs.airworthiness_path)
                      setRE(false)
                      //this.toggleUploadPOModal();
                    }}
                  >
                    <i className="tim-icons icon-cloud-upload-94" />
                    View PROCESS File
                  </MDButton>
                </MDBox>
              
              </div>

              <MDBox>

              {air_path &&
                  <>

                    <FileViewer fileType={inputs && inputs.airworthiness_path.split('.').pop()} filePath={imageAir && imageAir.split("?")[0]} onError={onError} />

                  </>
                }
                
                {re_path &&
                  <>

                    <FileViewer fileType={inputs && inputs.registration_path.split('.').pop()} filePath={imageRe.split("?")[0]} onError={onError} />

                  </>
                }
              </MDBox>
              <br></br>
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/ships_list")}
              >
                Cancelar
              </MDButton>
              {
                action !== 'view' &&
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => action === 'add' ? createShip() : updateShip()}
                >
                  {action === 'add' && 'Create Ship'}
                  {action === 'update' && 'Edit Ship'}


                </MDButton>
              }
            </MDBox>

          </>
      }
    </div>
  );
}
export default ShipCreator;
