import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        ship,
        serial,
        hours_limit,
        hours_performed,
        hours_instalation,
        hours_warning,
        hours_next_due,
        calendar_months,
        calendar_performed,
        calendar_months_instalation,
        calendar_months_warning,
        calendar_next_due,
    }
} = checkout;

const validations = Yup.object().shape({

});

export default validations;