import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useMenuItems from './useMenuItems'
import './Menu.css';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Card from "@mui/material/Card";
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import ComplexStatisticsCard from '../../components/cards/ComplexStatisticsCard'

import { getExpiredItems, getItemsToExpire } from "../../Services/InventoryService"

import { inventoryItems } from "../../utils/tableHeaders"
import { buildData } from "../../utils/tableData";

import ContentDialog from '../../components/ContentDialog'
import DataTable from "../../components/DataTable"

const Menu = () => {

    const [expiredItems, setExpiredItems] = useState({});
    const [currentFirstPage, setCurrentFirstPage] = useState(1);
    const [totalFirstPages, setTotalFirstPages] = useState();
    const [firstLoading, setFirstLoading] = useState(true);

    const [itemsToExpire, setItemsToExpire] = useState({});
    const [currentSecondPage, setCurrentSecondPage] = useState(1);
    const [totalSecondPages, setTotalSecondPages] = useState();
    const [secondLoading, setSecondLoading] = useState(true);

    const [openNotification, setOpenNotificaiton] = useState(false)

    const loadExpiredItems = (page) => {
        getExpiredItems(page).then(resp => {
            console.log(buildData(resp.data.inventory_detail, inventoryItems()))
            setExpiredItems(buildData(resp.data.inventory_detail, inventoryItems()))
            setCurrentFirstPage(parseInt(resp.data.current_page))
            setTotalFirstPages(resp.data.pages)
            setFirstLoading(false)
        }).catch((err) => {
            console.error(err);
        })
    }

    const loadItemsToExpire = (page) => {
        getItemsToExpire(page).then(resp => {
            console.log(resp.data)
            setItemsToExpire(buildData(resp.data.inventory_detail, inventoryItems()))
            setCurrentSecondPage(parseInt(resp.data.current_page))
            setTotalSecondPages(resp.data.pages)
            setSecondLoading(false)
        }).catch((err) => {
            console.error(err);
        })
    }

    const handleFirstPagination = (event, value) => {
        setCurrentFirstPage(value)
        loadExpiredItems(value)
    }

    const handleSecondPagination = (event, value) => {
        setCurrentSecondPage(value)
        loadItemsToExpire(value)
    }

    useEffect(()=>{
        /* //loadExpiredItems(1) */
       /*  loadItemsToExpire(1) */
        setOpenNotificaiton(true)
    }, [])

    const menuItems = useMenuItems();

    return(
        <div className="menu-container">
            <Grid container item xs={10} marginTop={10} spacing={6} direction="row" justifyContent="center" alignItems="center" alignContent="center">
                {menuItems.map(item => (
                    <Grid key={item.title} item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <Link to={item.link}>
                                <ComplexStatisticsCard
                                    color="info"
                                    icon={item.icon}
                                    title={item.title}
                                    count=""
                                    percentage={{
                                    color: "success",
                                    amount: "+55%",
                                    label: "than lask week",
                                    }}
                                />
                            </Link>
                        </MDBox>
                    </Grid>
                ))}
            </Grid>

            <ContentDialog open={false} title={"Notificacion"} maxWidth="lg"  closeCallback={()=>{ setOpenNotificaiton(false) }}>
                <MDBox mt={6} mb={8} textAlign="center">
                    <MDBox mb={1}>
                        <MDTypography variant="h5" fontWeight="bold">Items expirados</MDTypography>
                    </MDBox>
                    <Card>
                        { expiredItems.rows !== undefined && expiredItems.rows.length > 0 &&
                            <><DataTable table={expiredItems} showTotalEntries={false} entriesPerPage={false}  />
                            <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={totalFirstPages} page={currentFirstPage} onChange={handleFirstPagination} /> </MDBox></>
                        }
                        { firstLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                        { expiredItems.rows !== undefined &&  expiredItems.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                    </Card>
                </MDBox>
                <MDBox mt={6} mb={8} textAlign="center">
                    <MDBox mb={1}>
                        <MDTypography variant="h5" fontWeight="bold">Items por expirar</MDTypography>
                    </MDBox>
                    <Card>
                        { itemsToExpire.rows !== undefined && itemsToExpire.rows.length > 0 &&
                            <><DataTable table={itemsToExpire} showTotalEntries={false} entriesPerPage={false}  />
                            <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={totalSecondPages} page={currentSecondPage} onChange={handleFirstPagination} /> </MDBox></>
                        }
                        { secondLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                        { itemsToExpire.rows !== undefined &&  itemsToExpire.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                    </Card>
                </MDBox>
            </ContentDialog>

        </div>
    );
}

export default Menu;