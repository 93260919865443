import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import MDInput from '../../../components/MDInput';
import { TextField } from '@mui/material';

import SuccessCreatedDialog from '../../../components/SuccessCreatedDialog';
import ContentDialog from '../../../components/ContentDialog';
import SnackNotification from '../../../components/SnackNotification';
import DeleteDialog from '../../../components/DeleteDialog';

import DataTable from '../../../components/DataTable';

import {
  addInvoice,
  getInvoiceById,
  updateInvoice,
  getLabors,
  getMiscs,
  getInvoiceTask,
  createLabors,
  createMiscs,
  deleteInvoiceTask,
  addInvoiceTask,
  addInvoiceItem,
  getItemsInvoiceList,
} from '../../../Services/InvoicieService';
import { getTaskDetail, getTaskList } from '../../../Services/TaskService';
import { getItemList } from '../../../Services/InventoryService';

//Utils
import { buildData } from '../../../utils/tableData';
import {
  laborHeaders,
  micsHeaders,
  taskHeaders,
  taskItemsHeaders,
} from '../../../utils/tableHeaders';

import InvoiceForm from './components/InvoiceForm';

const CreateInvoice = ({ type, action }) => {
  const [openError, setOpenError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [inputs, setInputs] = useState();
  const [id, setId] = useState(0);

  const params = useParams();

  const { formId, formField } = form;

  const isAdd = action === 'add';
  const isUpdate = action === 'update';
  const isView = action === 'view';

  const [labors, setLabors] = useState([]);
  const [laborPage, setLaborPage] = useState(1);
  const [laborTotalPages, setLaborTotalPages] = useState();
  const [laborLoading, setLaborLoading] = useState(true);

  const [misc, setMisc] = useState([]);
  const [miscPage, setMiscPage] = useState(1);
  const [miscTotalPages, setMiscTotalPages] = useState();
  const [miscLoading, setMiscLoading] = useState(true);

  const [task, setTask] = useState([]);
  const [taskPage, setTaskPage] = useState(1);
  const [taskTotalPages, setTaskTotalPages] = useState();
  const [taskLoading, setTaskLoading] = useState(true);

  const [itemsInvoice, setItemsInvoice] = useState([]);
  const [itemsInvoicePage, setItemsInvoicePage] = useState(1);
  const [itemsInvoiceTotalPages, setItemsInvoiceTotalPages] = useState();
  const [itemsInvoiceLoading, setItemsInvoiceLoading] = useState(true);


  const [items, setItems] = useState([]);
  const [itemsPage, setItemsPage] = useState(1);
  const [itemsTotalPages, setItemsTotalPages] = useState();
  const [itemsLoading, setItemsLoading] = useState(true);

  const [taskList, setTaskList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const setTotalLabor = (laborList) => {
    let newList = [];
    laborList.forEach((element) => {
      newList.push({
        ...element,
        total: parseInt(element.rate) * parseInt(element.hours),
      });
    });
    return newList;
  };

  const loadLabors = (invoiceId, page) => {
    getLabors(page, invoiceId)
      .then((resp) => {
        setLabors(
          buildData(setTotalLabor(resp.data.invoice_labors), laborHeaders())
        );
        setLaborPage(parseInt(resp.data.current_page));
        setLaborTotalPages(resp.data.pages);
        setLaborLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const loadMiscs = (invoiceId, page) => {
    getMiscs(page, invoiceId)
      .then((resp) => {
        setMisc(buildData(resp.data.invoice_misc, micsHeaders()));
        setMiscPage(parseInt(resp.data.current_page));
        setMiscTotalPages(resp.data.pages);
        setMiscLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const loadTask = (invoiceId, page) => {
    getInvoiceTask(page, invoiceId)
      .then((resp) => {
        setTask(buildData(resp.data.invoice_tasks, taskHeaders()));
        setTaskPage(parseInt(resp.data.current_page));
        setTaskTotalPages(resp.data.pages);
        setTaskLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const loadItemsInvoice = (invoiceId, page) => {
    setItemsInvoiceLoading(true);
    getItemsInvoiceList(page, invoiceId).then(resp => {
      setItemsInvoice(buildData(resp.data.invoice_items, taskItemsHeaders()));
      setItemsInvoicePage(parseInt(resp.data.current_page));
      setItemsInvoiceTotalPages(resp.data.pages);
      
    }).finally(() => {
      setItemsInvoiceLoading(false);
    });
  };

  const loadItems = (taskId, page) => {
    getTaskDetail(page, taskId)
      .then((resp) => {
        // console.log(buildData(resp.data.task_details, taskItemsHeaders()))
        // console.log(resp.data.task_details[0].inventory_detail.tracker_id)
        setItems(buildData(resp.data.task_details, taskItemsHeaders()));
        setItemsPage(parseInt(resp.data.current_page));
        setItemsTotalPages(resp.data.pages);
        setItemsLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const loadItemsList = () => {
    getItemList()
      .then((resp) => {
        setItemList(resp.data);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const loadTaskList = () => {
    getTaskList()
      .then((resp) => {
        setTaskList(resp.data);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const handleMiscPagination = (event, value) => {
    setLaborPage(value);
    loadLabors(params.id, value);
  };

  const handleLaborPagination = (event, value) => {
    setMiscPage(value);
    loadMiscs(params.id, value);
  };

  const handleTaskPagination = (event, value) => {
    setTaskPage(value);
    loadTask(params.id, value);
  };

  const handleItemsPagination = (event, value) => {
    setItemsPage(value);
    loadItems(params.id, value);
  };

  const [idCreated, setIdCreated] = useState();

  const submitForm = async (values, actions) => {
    console.log(values);
    if (isAdd) {
      addInvoice(values)
        .then((resp) => {
          console.log(resp.data);
          setIdCreated(resp.data.id);
          actions.setSubmitting(false);
          setOpenDialog(true);
        })
        .catch((err) => {
          console.error(err.response);
          setOpenError(true);
        });
    } else if (isUpdate) {
      updateInvoice(id, values)
        .then((resp) => {
          console.log(resp.data);
          setOpenDialog(true);
        })
        .catch((err) => {
          console.error(err.response);
          setOpenError(true);
        });
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  useEffect(() => {
    if (params.id != null) {
      setId(params.id);
      getInvoiceById(params.id)
        .then((resp) => {
          setInputs(resp.data);
          loadTask(params.id, 1);
          loadItems(params.id, 1);
          loadItemsInvoice(params.id, 1);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  //Task Actions
  const [openViewTask, setOpenViewTask] = useState(false);
  const [taskToDetele, setTaskToDelete] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openTaskSelector, setOpenTaskSelector] = useState(false);
  const [openItemSelector, setOpenItemSelector] = useState(false);
  const [taskSelected, setTaskSelected] = useState();
  const [itemSelected, setItemSelected] = useState();
  const [taskToView, setTaskToView] = useState();

  const viewTask = (item) => {
    console.log(item.task.id);
    setTaskToView(item.task.id);
    setItemsLoading(true);
    setOpenViewTask(true);
    loadItems(item.task.id, 1);
    loadMiscs(item.task.id, 1);
    loadLabors(item.task.id, 1);
  };

  const deleteRegister = (task) => {
    setTaskToDelete(task);
    setOpenDeleteDialog(true);
  };

  const successDeleteDialog = () => {
    deleteInvoiceTask(taskToDetele.id)
      .then((resp) => {
        loadTask(params.id, taskPage);
      })
      .catch((err) => {
        console.error(err);
      });
    setOpenDeleteDialog(false);
  };

  const openAddTask = () => {
    loadTaskList();
    setOpenTaskSelector(true);
  };

  const openAddItems = () => {
    loadItemsList();
    setOpenItemSelector(true);
  };

  const createTask = () => {
    addInvoiceTask(params.id, taskSelected.id)
      .then((resp) => {
        setTaskSelected('');
        loadTask(params.id, 1);
        setOpenTaskSelector(false);
      })
      .catch((err) => {
        console.error(err);
      });
    console.log(taskSelected);
  };

  const createItem = () => {
    addInvoiceItem(params.id, itemSelected.id)
      .then((resp) => {
        setItemSelected('');
        loadItemsInvoice(params.id, 1);
        setOpenItemSelector(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Labor and Misc modals
  const [openCreateLabor, setOpenCreateLabor] = useState(false);
  const [openCreateMisc, setOpenCreateMisc] = useState(false);
  const [laborInputs, setLaborInputs] = useState({});
  const [miscInputs, setMiscInputs] = useState({});

  const createLabor = () => {
    createLabors(laborInputs, taskToView)
      .then((resp) => {
        setLaborInputs({});
        loadLabors(taskToView, 1);
        setOpenCreateLabor(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createMisc = () => {
    createMiscs(miscInputs, taskToView)
      .then((resp) => {
        setMiscInputs({});
        loadMiscs(taskToView, 1);
        setOpenCreateMisc(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const closeModals = () => {
    setOpenCreateLabor(false);
    setOpenCreateMisc(false);
    setOpenViewTask(false);
    setOpenDeleteDialog(false);
    setOpenTaskSelector(false);
    setOpenItemSelector(false);
    setLaborInputs({});
    setMiscInputs({});
    setTaskToView();
  };

  const handleChange = (event, type) => {
    const name = event.target.name;
    const value = event.target.value;
    type === 'l'
      ? setLaborInputs((values) => ({ ...values, [name]: value }))
      : setMiscInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <MDBox pt={3} pb={8}>
      <Grid container justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign='center'>
            <MDBox mb={1}>
              <MDTypography variant='h3' fontWeight='bold'>
                {isAdd && 'Creacion'}
                {isUpdate && 'Edicion'}
                {isView && 'Visualizacion'}
              </MDTypography>
            </MDBox>
            {inputs != null && inputs !== undefined && (
              <div>
                <MDBox
                  mb={1}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    No. {inputs.id}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Date: {inputs.created_at}
                  </MDTypography>
                  <MDTypography
                    variant='span'
                    fontWeight='regular'
                    color='secondary'
                  >
                    Created By: {inputs.user.name}
                  </MDTypography>
                </MDBox>
                <Divider sx={{ margin: '0.5rem 0' }} />
              </div>
            )}
          </MDBox>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues(type)
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <InvoiceForm
                      isView={isView}
                      isUpdate={isUpdate}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginTop: 25,
                      }}
                    >
                      {/* <p>Total labor: {inputs?.labor_total}</p>
                      <p>Total Misc: {inputs?.misc_total}</p>
                      <p>Tax: {inputs?.sale_tax}</p> */}
                      <p>SubTotal: {inputs?.subtotal}</p>
                      <p>Total: {inputs?.total}</p>
                    </div>

                    {/* "labor_total": 12.8,
                    "misc_total": 1.0,
                    "subtotal": 13.8,
                    "sale_tax": 1.656,
                    "total": 15.456 */}
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <Link
                        to={type === 'Q' ? '/estimation/list' : '/invoice/list'}
                        style={{ alignSelf: 'start' }}
                      >
                        <MDButton
                          variant='gradient'
                          color='error'
                          sx={{ height: '100%' }}
                        >
                          Cancelar
                        </MDButton>
                      </Link>
                      <MDButton
                        disabled={isView}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
          {!isAdd && (
            <Grid container>
              <Grid item xs={12} md={12}>
                <MDBox mt={6} mb={8} textAlign='center'>
                  <MDBox mb={1}>
                    <MDTypography variant='h5' fontWeight='bold'>
                      Tasks
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                  >
                    <MDButton
                      variant='gradient'
                      color='info'
                      onClick={openAddTask}
                    >
                      Agregar
                    </MDButton>
                  </MDBox>
                  <Card>
                    {task.rows !== undefined && task.rows.length > 0 && (
                      <>
                        <DataTable
                          useActions
                          useView
                          useDelete
                          deleteAction={deleteRegister}
                          viewAction={viewTask}
                          table={task}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                        <MDBox ml={1}>
                          <Pagination
                            sx={{ marginTop: '20px', marginBottom: '20px' }}
                            color='info'
                            count={taskTotalPages}
                            page={taskPage}
                            onChange={handleTaskPagination}
                          />{' '}
                        </MDBox>
                      </>
                    )}
                    {taskLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '200px',
                        }}
                      >
                        <CircularProgress color='info' size={80} />
                      </Box>
                    )}
                    {task.rows !== undefined && task.rows.length === 0 && (
                      <Typography
                        variant='h4'
                        component='div'
                        sx={{ margin: '100px' }}
                      >
                        No Existen registros
                      </Typography>
                    )}
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          )}

          {!isAdd && (
            <Grid container>
              <Grid item xs={12} md={12}>
                <MDBox mt={6} mb={8} textAlign='center'>
                  <MDBox mb={1}>
                    <MDTypography variant='h5' fontWeight='bold'>
                      Items
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                  >
                    <MDButton
                      variant='gradient'
                      color='info'
                      onClick={openAddItems}
                    >
                      Agregar
                    </MDButton>
                  </MDBox>
                  <Card>
                    {itemsInvoice?.rows !== undefined && itemsInvoice?.rows.length > 0 && (
                      <>
                        <DataTable
                          useActions
                          useView
                          useDelete
                          deleteAction={deleteRegister}
                          viewAction={viewTask}
                          table={itemsInvoice}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                        <MDBox ml={1}>
                          <Pagination
                            sx={{ marginTop: '20px', marginBottom: '20px' }}
                            color='info'
                            count={itemsInvoiceTotalPages}
                            page={itemsInvoicePage}
                            onChange={handleItemsPagination}
                          />{' '}
                        </MDBox>
                      </>
                    )}
                    {itemsInvoiceLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '200px',
                        }}
                      >
                        <CircularProgress color='info' size={80} />
                      </Box>
                    )}
                    {itemsInvoice?.rows !== undefined && itemsInvoice?.rows.length === 0 && (
                      <Typography
                        variant='h4'
                        component='div'
                        sx={{ margin: '100px' }}
                      >
                        No Existen registros
                      </Typography>
                    )}
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <SuccessCreatedDialog
        open={openDialog}
        message='Operacion finalizada!'
        route={
          type === 'Q'
            ? '/estimation/view/'.concat(idCreated)
            : '/invoice/view/'.concat(idCreated)
        }
      />
      <SnackNotification
        type='error'
        message='Ha ocurrido un error'
        closeCallback={console.log()}
        openFlag={openError}
        setOpenFlag={setOpenError}
      />

      <ContentDialog
        open={openViewTask}
        title={'Task Info'}
        closeCallback={closeModals}
        maxWidth='xl'
        fullWidth={true}
      >
        <MDBox mt={6} mb={8} textAlign='center'>
          <MDBox mb={1}>
            <MDTypography variant='h5' fontWeight='bold'>
              Items
            </MDTypography>
          </MDBox>
          {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <MDButton variant="gradient" color="info" onClick={()=>{setOpenCreateMisc(true)}}>
                            Agregar 
                        </MDButton>
                    </MDBox> */}
          <Card>
            {items.rows !== undefined && items.rows.length > 0 && (
              <>
                <DataTable
                  table={items}
                  showTotalEntries={false}
                  entriesPerPage={false}
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={itemsTotalPages}
                    page={itemsPage}
                    onChange={handleItemsPagination}
                  />{' '}
                </MDBox>
              </>
            )}
            {itemsLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress color='info' size={80} />
              </Box>
            )}
            {items.rows !== undefined && items.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen registros
              </Typography>
            )}
          </Card>
        </MDBox>

        <Grid item xs={12} md={12}>
          <MDBox mt={6} mb={8} textAlign='center'>
            <MDBox mb={1}>
              <MDTypography variant='h5' fontWeight='bold'>
                Labor
              </MDTypography>
            </MDBox>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='flex-start'
              mb={2}
            >
              <MDButton
                variant='gradient'
                color='info'
                onClick={() => {
                  setOpenCreateLabor(true);
                }}
              >
                Agregar
              </MDButton>
            </MDBox>
            <Card>
              {labors.rows !== undefined && labors.rows.length > 0 && (
                <>
                  <DataTable
                    table={labors}
                    showTotalEntries={false}
                    entriesPerPage={false}
                  />
                  <MDBox ml={1}>
                    <Pagination
                      sx={{ marginTop: '20px', marginBottom: '20px' }}
                      color='info'
                      count={laborTotalPages}
                      page={laborPage}
                      onChange={handleLaborPagination}
                    />{' '}
                  </MDBox>
                </>
              )}
              {laborLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                  }}
                >
                  <CircularProgress color='info' size={80} />
                </Box>
              )}
              {labors.rows !== undefined && labors.rows.length === 0 && (
                <Typography
                  variant='h4'
                  component='div'
                  sx={{ margin: '100px' }}
                >
                  No Existen registros
                </Typography>
              )}
            </Card>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12}>
          <MDBox mt={6} mb={8} textAlign='center'>
            <MDBox mb={1}>
              <MDTypography variant='h5' fontWeight='bold'>
                Miscelaneos
              </MDTypography>
            </MDBox>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='flex-start'
              mb={2}
            >
              <MDButton
                variant='gradient'
                color='info'
                onClick={() => {
                  setOpenCreateMisc(true);
                }}
              >
                Agregar
              </MDButton>
            </MDBox>
            <Card>
              {misc.rows !== undefined && misc.rows.length > 0 && (
                <>
                  <DataTable
                    table={misc}
                    showTotalEntries={false}
                    entriesPerPage={false}
                  />
                  <MDBox ml={1}>
                    <Pagination
                      sx={{ marginTop: '20px', marginBottom: '20px' }}
                      color='info'
                      count={miscTotalPages}
                      page={miscPage}
                      onChange={handleMiscPagination}
                    />{' '}
                  </MDBox>
                </>
              )}
              {miscLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                  }}
                >
                  <CircularProgress color='info' size={80} />
                </Box>
              )}
              {misc.rows !== undefined && misc.rows.length === 0 && (
                <Typography
                  variant='h4'
                  component='div'
                  sx={{ margin: '100px' }}
                >
                  No Existen registros
                </Typography>
              )}
            </Card>
          </MDBox>
        </Grid>
      </ContentDialog>

      <ContentDialog
        open={openTaskSelector}
        title={'Seleccione el Task que desea Agregar'}
        closeCallback={closeModals}
        buttonText='Cancelar'
      >
        <Grid item xs={12} md={12}>
          <Autocomplete
            options={taskList}
            onChange={(event, newValue) => {
              setTaskSelected(newValue);
            }}
            renderInput={(params) => (
              <MDInput {...params} variant='standard' label={'Task'} />
            )}
          />
        </Grid>
        <MDBox
          mt={3}
          width='100%'
          display='flex'
          justifyContent='space-between'
        >
          <MDBox mt={2}>
            <MDButton
              variant='gradient'
              color='success'
              type='submit'
              onClick={createTask}
            >
              Aceptar
            </MDButton>
          </MDBox>
        </MDBox>
      </ContentDialog>

      <ContentDialog
        open={openItemSelector}
        title={'Seleccione el Item que desea agregar'}
        closeCallback={closeModals}
        buttonText='Cancelar'
      >
        <Grid item xs={12} md={12}>
          <Autocomplete
            options={itemList}
            onChange={(event, newValue) => {
              setItemSelected(newValue);
            }}
            renderInput={(params) => (
              <MDInput {...params} variant='standard' label={'Item'} />
            )}
          />
        </Grid>
        <MDBox
          mt={3}
          width='100%'
          display='flex'
          justifyContent='space-between'
        >
          <MDBox mt={2}>
            <MDButton
              variant='gradient'
              color='success'
              type='submit'
              onClick={createItem}
            >
              Aceptar
            </MDButton>
          </MDBox>
        </MDBox>
      </ContentDialog>

      <ContentDialog
        open={openCreateLabor}
        title={'Labor'}
        closeCallback={closeModals}
        maxWidth='md'
        fullWidth={true}
      >
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MDBox mb={1.5}>
                <TextField
                  value={laborInputs.date || ''}
                  onChange={(e) => {
                    handleChange(e, 'l');
                  }}
                  sx={{ width: '100%' }}
                  id='date'
                  InputProps={{ name: 'date', type: 'date' }}
                  label=''
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={1.5}>
                <TextField
                  value={laborInputs.rate || ''}
                  onChange={(e) => {
                    handleChange(e, 'l');
                  }}
                  sx={{ width: '100%' }}
                  id='rate'
                  InputProps={{ name: 'rate' }}
                  label='Rate'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={1.5}>
                <TextField
                  value={laborInputs.hours || ''}
                  onChange={(e) => {
                    handleChange(e, 'l');
                  }}
                  sx={{ width: '100%' }}
                  id='hours'
                  InputProps={{ name: 'hours' }}
                  label='Hours'
                  variant='outlined'
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={laborInputs.description || ''}
                  onChange={(e) => {
                    handleChange(e, 'l');
                  }}
                  sx={{ width: '100%' }}
                  id='description'
                  InputProps={{ name: 'description' }}
                  label='Description'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={laborInputs.employee || ''}
                  onChange={(e) => {
                    handleChange(e, 'l');
                  }}
                  sx={{ width: '100%' }}
                  id='employee'
                  InputProps={{ name: 'employee' }}
                  label='Employee'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          mt={3}
          width='100%'
          display='flex'
          justifyContent='space-between'
        >
          <MDBox mt={2}>
            <MDButton
              variant='gradient'
              color='success'
              type='submit'
              onClick={createLabor}
            >
              Aceptar
            </MDButton>
          </MDBox>
        </MDBox>
      </ContentDialog>

      <ContentDialog
        open={openCreateMisc}
        title={'Miscelaneo'}
        closeCallback={closeModals}
      >
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={miscInputs.qty || ''}
                  onChange={(e) => {
                    handleChange(e, 'm');
                  }}
                  sx={{ width: '100%' }}
                  id='qty'
                  InputProps={{ name: 'qty' }}
                  label='QTY'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={miscInputs.price || ''}
                  onChange={(e) => {
                    handleChange(e, 'm');
                  }}
                  sx={{ width: '100%' }}
                  id='price'
                  InputProps={{ name: 'price' }}
                  label='Price'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox mb={1.5}>
                <TextField
                  value={miscInputs.description || ''}
                  onChange={(e) => {
                    handleChange(e, 'm');
                  }}
                  sx={{ width: '100%' }}
                  id='description'
                  InputProps={{ name: 'description' }}
                  label='Description'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          mt={3}
          width='100%'
          display='flex'
          justifyContent='space-between'
        >
          <MDBox mt={2}>
            <MDButton
              variant='gradient'
              color='success'
              type='submit'
              onClick={createMisc}
            >
              Aceptar
            </MDButton>
          </MDBox>
        </MDBox>
      </ContentDialog>
      <DeleteDialog
        open={openDeleteDialog}
        message='Esta seguro de eliminar este registro?'
        successCalback={successDeleteDialog}
        cancelCallback={closeModals}
      />
    </MDBox>
  );
};

export default CreateInvoice;
