import checkout from "./form";

//   description
//   correction
//   date
//   date_repair
//   TTAP

const {
    formField: {
        TTAF, TTAFW, TTAFP, TTAFD, calendarLimit, calendarWarning, calendarPerformed, calendarNextDue, cycle_ng, cycle_nf, cycleLimit, cycleWarning, cyclePerformed, cycleNextDue, workReportCorrection, remark
    }
} = checkout;

export const initialValues = {
    [TTAF.name]: "",
    [TTAFW.name]: "",
    [TTAFP.name]: "",
    [TTAFD.name]: "",
    [calendarLimit.name]: "",
    [calendarWarning.name]: "",
    [calendarPerformed.name]: "",
    [calendarNextDue.name]: "",
    [cycle_ng.name]: "",
    [cycle_nf.name]: "",
    [cycleLimit.name]: "",
    [cycleWarning.name]: "",
    [cyclePerformed.name]: "",
    [cycleNextDue.name]: "",
    [workReportCorrection.name]: "",
    [remark.name]: ""
}

export const initialValuesFromObj = (obj) => {
    return {
        [TTAF.name]: obj.TTAF,
        [TTAFW.name]: obj.TTAFW,
        [TTAFP.name]: obj.TTAFP,
        [TTAFD.name]: obj.TTAFD,
        [calendarLimit.name]: obj.calendarLimit,
        [calendarWarning.name]: obj.calendarWarning,
        [calendarPerformed.name]: obj.calendarPerformed,
        [calendarNextDue.name]: obj.calendarNextDue,
        [cycle_ng.name]: obj.cycle_ng,
        [cycle_nf.name]: obj.cycle_nf,
        [cycleLimit.name]: obj.cycleLimit,
        [cycleWarning.name]: obj.cycleWarning,
        [cyclePerformed.name]: obj.cyclePerformed,
        [cycleNextDue.name]: obj.cycleNextDue,
        [workReportCorrection.name]: obj.workReportCorrection,
        [remark.name]: obj.remark
    }
}
