import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';



export const getInspections = (id) => { 
    let route = "/inspection/?aircraft_id=".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers)
}

export const getInspectionsTemplate = (id) => {
    let route = "/inspection/?aircraft__ship__id=".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers)
}

export const createInspection = (aircraft, atta, inspect, link, active) => {
    let route = "/inspection/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession())};
    let formData = new FormData();
    formData.append('aircraft', aircraft);
    formData.append('ATTA_code', atta);
    formData.append('inspections', inspect);
    formData.append('link', link);
    formData.append('active', active);
    return doPost(route, formData, headers);

}

export const updateInspection = (id, values)=>{
    let route = "/inspection/".concat(id).concat("/");
    let headers = { 'Authorization': "Token ".concat(getTokenSession())};
    let formData = new FormData();
    formData.append('id', id);
    Object.keys(values).forEach(key => {
        if(values[key] != null && values[key] !== ""){
            formData.append(key, values[key])
        }
    });
    return doPatch(route, formData, headers);
}