import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

export const laborObj = {
    date: "",
    invoice_header: "",
    employee:"",
    rate:"",
    hours:"",
    description: "",
}

export const miscObj = {
    invoice_header: "",
    description: "",
    qty: "",
    price: ""
}

// ---------- HTTP Calls

export const getInvoices = (page, filter, value) => {
    let filterStr = filter != null && filter != undefined ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/invoice_header/?".concat("page=".concat(page)).concat("&type=I").concat(filterStr);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addInvoice = (data) => {
    let route ="/invoice_header/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getInvoiceById = (id) => {
    let route = `/invoice_header/${id}/get_full_invoice_info/`;
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doPost(route, {}, headers);
}

export const updateInvoice = (id, data) => {
    let route = "/invoice_header/".concat(id.concat("/"));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(key === "client" || key === "ship"){
            if(typeof data[key] === "object"){
                formData.append(key, data[key].id);
            }
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPatch(route, formData, headers);
}

export const deleteInvoice = (id) => {
    let route ="/invoice_header/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getInvoice = (id) => 
{
    let route ="/invoice_header/".concat(id);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers)
}

export const getEstimations = (page, filter, value) => {
    let filterStr = filter != null && filter != undefined ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/invoice_header/?".concat("page=".concat(page)).concat("&type=Q").concat(filterStr);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getLabors = (page, taskId) => {
    let route = "/invoice_labor/?".concat("page=".concat(page)).concat("&task__id=".concat(taskId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const createLabors = (data, task) => {
    let route ="/invoice_labor/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    formData.append("task", task)
    Object.keys(data).forEach(key => {
        if(key === "date"){
            formData.append(key, data[key].concat("T00:00"))
        }else if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const createMiscs = (data, task) => {
    let route ="/invoice_misc/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    formData.append("task", task)
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getMiscs = (page, taskId) => {
    let route = "/invoice_misc/?".concat("page=".concat(page)).concat("&task__id=".concat(taskId));
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getInvoiceTask = (page, invoice) => {
    let route = "/invoice_task/?".concat("page=".concat(page)).concat("&invoice_header_id=".concat(invoice))
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const deleteInvoiceTask = (id) => {
    let route ="/invoice_task/".concat(id).concat("/");
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const addInvoiceTask = (invoice, task) => {
    let route = "/invoice_task/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData()
    formData.append("invoice_header", invoice)
    formData.append("task", task)
    return doPost(route, formData, headers);
}

export const addInvoiceItem = (invoice, itemId) => {
    let route = "/invoice_items/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData()
    formData.append("invoice_header", invoice)
    formData.append("inventory_detail", itemId)
    return doPost(route, formData, headers);
}


export const getItemsInvoiceList = (page, invoice_header_id) => {
    let route = `/invoice_items/?page=${page}&invoice_header_id=${invoice_header_id}`;
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
};

// ---------- Aux Methods

export const formatInvoceItems = () => {}

export const getTaskIds = (invoiceTaskDetails) => {
    let taskIds = [];
    invoiceTaskDetails.forEach(item => {
        taskIds.push(item.task.id);
    })
    return taskIds;
}

export const getWorkReports = () => {
    let route = `/work_report/?page=1`;
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}