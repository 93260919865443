import React, { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";


import { getEmployeeCard, getCertificationsID } from "../../Services/formServices";
import Loader from "../../components/Loader";



function CertificationForm({ action }) {
  const [cert, setCert] = useState();
  const [type, setType] = useState();
  const [participants, setParticipants] = useState();
  const [instructor, setInstructor] = useState();
  const [issued, setIssued] = useState();
  const [expiration, setExpiration] = useState();

  const params = useParams();
  const userToken = localStorage.getItem('Token') || '';
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState();
  const [optionsV, setOptions] = useState([]);
  const [flag, setFlag] = useState(false);
  const [id, setId] = useState(0)


  useEffect(() => {
    loadShips();
  }, []);

  const loadShips = () => {
    getEmployeeCard()
      .then((resp) => {
        console.log(resp.data);
        //setShips(buildData(resp.data, EmployeeCardHeaders()));
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setShips(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };


  const certificates = [
    { id: 1, label: "Habilitación de taller (2 años)", flag: "C" },
    { id: 2, label: "Recurrente (2 años)", flag: "C" },
    { id: 3, label: "Capacitación (x años)", flag: "C" },
    { id: 4, label: "No Recurrente", flag: "N" }
  ]

  const jsonAnswers = {
    certification: cert,
    type: type,
    instructors: instructor,
    participants: participants,
    issued: issued,
    expiration: expiration,
  };

  const addDescription = () => {
    setParticipants([...participants, { participant: '' }]);
    console.log(participants);
  };

  const handleRemoveClick = (index) => {
    const list = [...participants];
    list.splice(index, 1);
    setParticipants(list);
  };

  const createCertification = () => {
    let ver = null
    participants.map(index => {
      ver = ver + index.value + ','
      console.log(ver)
    })
    const pilotdata = new FormData();
    pilotdata.append('cert', cert);
    pilotdata.append('courseName', type.label);
    ver && pilotdata.append('participants', ver);

    pilotdata.append('instructor', instructor);
    pilotdata.append('dateEmision', issued);
    expiration && pilotdata.append('dateVenc', expiration);
    pilotdata.append('courseType', type.flag);


    axios.post(
      'https://fn.devpackgroup.com/api/v1/certifications/',
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Certificacion Creada Exitosamente")
      window.location.replace("/certifications_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Certificacion")
    }.bind(this))
  };
  const updateCertification = () => {
    let ver = null
    const pilotdata = new FormData();
    participants && (participants.length > 0 ? participants.map(index => {
      //ver = ver + index.value + ','
      pilotdata.append('participants', index.value);
    })
      :
      pilotdata.append('participants', null)
    )
    cert && pilotdata.append('cert', cert);
    type && pilotdata.append('courseName', type.label);
    //ver && pilotdata.append('participants', participants);

    instructor && pilotdata.append('instructor', instructor);
    issued && pilotdata.append('dateEmision', issued);
    expiration && pilotdata.append('dateVenc', expiration);
    type && pilotdata.append('courseType', type.flag);


    axios.patch(
      `https://fn.devpackgroup.com/api/v1/certifications/${params.id}/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Certificacion Actualizado Exitosamente")
      window.location.replace("/certifications_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al Actualizar Certificacion")
    }.bind(this))
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getCertificationsID(params.id).then(resp => {
        console.log(resp.data, 'iputs')
        setInputs(resp.data)
        var data = resp.data.participants.map(index => {
          optionsV.push({
            label: index.name + " " + index.dpi,
            value: index.id
          })
        })
        console.log(optionsV, 'optionsV')
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    loadShips();
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Formulario de Certificaciones
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <MDBox pt={4} pb={3} px={3}>
              {(flag &&
                <>
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Certificación:{" "}
                      </p>
                      <br />
                      {
                        (action != 'add' && inputs) &&
                        <MDInput
                          type="text"
                          label="Certificación:"
                          variant="standard"
                          fullWidth
                          placeholder="Certificación"
                          InputLabelProps={{ shrink: true }}
                          defaultValue={(action === 'update') && inputs ? inputs.cert : null}
                          value={(action === 'view') && inputs ? inputs.cert : null}
                          onChange={(e) => setCert(e.target.value)}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
                      }
                      {
                        (action === 'add') &&
                        <MDInput
                          type="text"
                          label="Certificación:"
                          variant="standard"
                          fullWidth
                          placeholder="Certificación"
                          InputLabelProps={{ shrink: true }}
                          //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                          //value={(action === 'view') && inputs ? inputs.registration: null }
                          onChange={(e) => setCert(e.target.value)}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
                      }
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}> Tipo: </p>
                      <br />
                      <Select
                        placeholder="Certificaciones..."
                        options={certificates}
                        //isMulti
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && certificates.find(label => label.label === inputs.courseName)}
                        onChange={(e) => {
                          //console.log(e)
                          setType(e)
                        }}
                      />
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Instructor:{" "}
                      </p>
                      <br />
                      {
                        (action != 'add' && inputs) &&
                        <MDInput
                          type="text"
                          label="Instructor:"
                          variant="standard"
                          fullWidth
                          placeholder="Instructor"
                          InputLabelProps={{ shrink: true }}
                          defaultValue={(action === 'update') && inputs ? inputs.instructor : null}
                          value={(action === 'view') && inputs ? inputs.instructor : null}
                          onChange={(e) => setInstructor(e.target.value)}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
                      }
                      {
                        (action === 'add') &&
                        <MDInput
                          type="text"
                          label="Instructor:"
                          variant="standard"
                          fullWidth
                          placeholder="Instructor"
                          InputLabelProps={{ shrink: true }}
                          //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                          //value={(action === 'view') && inputs ? inputs.registration: null }
                          onChange={(e) => setInstructor(e.target.value)}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                        />
                      }
                    </MDBox>
                    <br />
                  </div>
                  <div style={{ display: 'flex' }}>
                  <MDBox width={'30%'}  mb={2}>
                    <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                      {" "}
                      Fecha de emisión:{" "}
                    </p>
                    <br />
                    {/* 
            <MDInput
                  type="date"
                  label="Fecha de vencimiento:"
                  variant="standard"
                  fullWidth
                  placeholder="Fecha de vencimiento"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setIssued(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.dateEmision : null}
                  value={(action === 'view') && inputs ? inputs.dateEmision : null}
                />
                */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setIssued(formattedDate)
                            console.log(formattedDate)

                          }}
                          disabled={action === 'view'}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                          defaultValue={(action === 'update') && inputs ? dayjs(inputs.dateEmision, 'DD/MM/YYYY') : null}
                          value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.dateEmision, 'DD/MM/YYYY') : null}
                          label="Fecha de emisión:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </MDBox>
                  <br />
                  <MDBox width={'30%'} ml={6} mb={2}>
                    <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                      {" "}
                      Fecha de vencimiento:{" "}
                    </p>
                    <br />
                    {/* 
            <MDInput
                  type="date"
                  label="Fecha de vencimiento:"
                  variant="standard"
                  fullWidth
                  placeholder="Fecha de vencimiento"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setExpiration(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.dateVenc : null}
                  value={(action === 'view') && inputs ? inputs.dateVenc : null}
                />
              */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            //setdateB(e)
                            const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                            setExpiration(formattedDate)
                            console.log(formattedDate)

                          }}
                          disabled={action === 'view'}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                          defaultValue={(action === 'update') && inputs ? dayjs(inputs.dateVenc, 'DD/MM/YYYY') : null}
                          value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.dateVenc, 'DD/MM/YYYY') : null}
                          label="Fecha de vencimiento:" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </MDBox>
                  <br />
                  <MDBox width={'30%'} ml={6} mb={2}>
                    <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                      {" "}
                      Empleados:{" "}
                    </p>
                    <Select
                      placeholder="Empleados..."
                      options={ships}
                      isMulti
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && (optionsV.length > 0 && inputs) && optionsV}
                      onChange={(e) => {
                        //console.log(e)
                        setParticipants(e)
                      }}
                    />
                  </MDBox>
                  <br />
                  </div>
                </>
              )}
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/certifications_list")}
              >
                Cancelar
              </MDButton>
              {
                action !== 'view' &&
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => action === 'update' ? updateCertification() : createCertification()}
                >
                  {action === 'add' && 'Create Certification'}
                  {action === 'update' && 'Edit Certification'}


                </MDButton>
              }
            </MDBox>
          </>
      }
    </>
  );
}
export default CertificationForm;
