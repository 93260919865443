import React, { useState, useEffect } from 'react';
import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';
import MDInput from '../../../../components/MDInput';
import { getShipList } from '../../../../Services/ShipService';

const AircraftForm = ({ handleChange, inputs, isView, formData, isUpdate }) => {
  const [currencyValue, setCurrencyValue] = useState();
  const [inputCurrencyValue, setInputCurrencyValue] = useState();
  const [ships, setShips] = useState([]);

  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    ship,
    serial,
    hours_limit,
    hours_performed,
    hours_instalation,
    hours_warning,
    hours_next_due,
    calendar_months,
    calendar_performed,
    calendar_months_instalation,
    calendar_months_warning,
    calendar_next_due,
  } = formField;

  const {
    ship: shipV,
    serial: serialV,
    hours_limit: hours_limitV,
    hours_performed: hours_performedV,
    hours_instalation: hours_instalationV,
    hours_warning: hours_warningV,
    hours_next_due: hours_next_dueV,
    calendar_months: calendar_monthsV,
    calendar_performed: calendar_performedV,
    calendar_months_instalation: calendar_months_instalationV,
    calendar_months_warning: calendar_months_warningV,
    calendar_next_due: calendar_next_dueV,
  } = values;

  const loadSuppliers = () => {
    getShipList().then((resp) => {
      setShips(resp.data);
      console.log(resp.data)
    });
  };

  useEffect(() => {
    loadSuppliers();
  }, []);

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        {(isView || isUpdate) && (
          <Grid item xs={12} md={4}>
            <Typography variant='subtitle2' component='div'>
              Currency. {shipV.label}
            </Typography>
          </Grid>
        )}
        {!isView && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={ships}
              value={currencyValue}
              inputValue={inputCurrencyValue}
              onChange={(event, newValue) => {
                setCurrencyValue(newValue);
                setFieldValue('ship', newValue.id);
              }}
              defaultValue={shipV}
              onInputChange={(event, newInputValue) => {
                setInputCurrencyValue(newInputValue);
              }}
              disabled={isView}
              renderInput={(params) => (
                <MDInput {...params} variant='standard' label={ship.label} />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormField
            type={serial.type}
            label={serial.label}
            name={serial.name}
            value={serialV}
            
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={hours_limit.type}
            label={hours_limit.label}
            name={hours_limit.name}
            value={hours_limitV}
            
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={hours_performed.type}
            label={hours_performed.label}
            name={hours_performed.name}
            value={hours_performedV}
            
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={hours_instalation.type}
            label={hours_instalation.label}
            name={hours_instalation.name}
            value={hours_instalationV}
            // error={errors.hours_instalation && touched.hours_instalation}
            // success={hours_instalationV.length > 0 && !errors.hours_instalation}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={hours_warning.type}
            label={hours_warning.label}
            name={hours_warning.name}
            value={hours_warningV}
            // error={errors.hours_warning && touched.hours_warning}
            // success={hours_warningV.length > 0 && !errors.hours_warning}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={hours_next_due.type}
            label={hours_next_due.label}
            name={hours_next_due.name}
            value={hours_next_dueV}
            // error={errors.hours_next_due && touched.hours_next_due}
            // success={hours_next_dueV.length > 0 && !errors.hours_next_due}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendar_months.type}
            label={calendar_months.label}
            name={calendar_months.name}
            value={calendar_monthsV}
            // error={errors.calendar_months && touched.calendar_months}
            // success={calendar_monthsV.length > 0 && !errors.calendar_months}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendar_performed.type}
            label={calendar_performed.label}
            name={calendar_performed.name}
            value={calendar_performedV}
            // error={errors.calendar_performed && touched.calendar_performed}
            // success={
            //   calendar_performedV.length > 0 && !errors.calendar_performed
            // }
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendar_months_instalation.type}
            label={calendar_months_instalation.label}
            name={calendar_months_instalation.name}
            value={calendar_months_instalationV}
            // error={
            //   errors.calendar_months_instalation &&
            //   touched.calendar_months_instalation
            // }
            // success={
            //   calendar_months_instalationV.length > 0 &&
            //   !errors.calendar_months_instalation
            // }
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendar_months_warning.type}
            label={calendar_months_warning.label}
            name={calendar_months_warning.name}
            value={calendar_months_warningV}
            // error={
            //   errors.calendar_months_warning && touched.calendar_months_warning
            // }
            // success={
            //   calendar_months_warningV.length > 0 &&
            //   !errors.calendar_months_warning
            // }
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={calendar_next_due.type}
            label={calendar_next_due.label}
            name={calendar_next_due.name}
            value={calendar_next_dueV}
            // error={errors.calendar_next_due && touched.calendar_next_due}
            // success={calendar_next_dueV.length > 0 && !errors.calendar_next_due}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default AircraftForm;
