/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-customer-form",
  formField: {
    name: {
      name: "name",
      label: "Nombre",
      type: "text",
      errorMsg: "Campo requerido",
    },
    address: {
      name: "address",
      label: "Direccion",
      type: "text",
      errorMsg: "Campo requerido",
    },
    city: {
      name: "city",
      label: "Ciudad",
      type: "text",
      errorMsg: "Campo requerido",
    },
    state: {
      name: "state",
      label: "Estado",
      type: "text",
      errorMsg: "Campo requerido",
    },
    zip_code: {
      name: "zip_code",
      label: "Zip Code",
      type: "text",
      errorMsg: "Campo requerido",
    },
    country: {
      name: "country",
      label: "Pais",
      type: "text",
      errorMsg: "Campo requerido",
    },
    note: {
      name: "note",
      label: "Nota",
      type: "text",
    },
    tax_id: {
      name: "tax_id",
      label: "Tax id",
      type: "text",
      errorMsg: "Campo requerido",
    },
    phone: {
      name: "phone",
      label: "Telefono",
      type: "text",
      errorMsg: "Campo requerido",
    },
    phone_2: {
      name: "phone_2",
      label: "Telefono 2",
      type: "text",
      errorMsg: "Campo requerido",
    },
    fax: {
      name: "fax",
      label: "Fax",
      type: "text",
    },
    email: {
      name: "email",
      label: "Correo",
      type: "text",
      errorMsg: "Campo requerido",
      invalidMsg: "Correo invalido",
    },
    contact_name_1: {
      name: "contact_name_1",
      label: "Contacto 1",
      type: "text",
      errorMsg: "Campo requerido",
    },
    contact_phone_1: {
      name: "contact_phone_1",
      label: "Telefono 1",
      type: "text",
      errorMsg: "Campo requerido",
    },
    contact_name_2: {
      name: "contact_name_2",
      label: "Contacto 2",
      type: "text",
      errorMsg: "Campo requerido",
    },
    contact_phone_2: {
      name: "contact_phone_2",
      label: "Telefono 2",
      type: "text",
      errorMsg: "Campo requerido",
    },
    contact_name_3: {
      name: "contact_name_3",
      label: "Contacto 3",
      type: "text",
      errorMsg: "Campo requerido",
    },
    contact_phone_3: {
      name: "contact_phone_3",
      label: "Telefono 3",
      type: "text",
      errorMsg: "Campo requerido",
    },
    vehicles: {
      name: "vehicles",
      label: "Naves",
      type: "select",
      //errorMsg: "Campo requerido",
    },
  },
};

export default form;
