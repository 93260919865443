import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';
import { getAllDeffects } from '../../../Services/MainBoard';
import CreateInspectionsTemplete from '../CreateInspectionsTemplete';


// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';

// Wizard page components
import ComponentsForm from './components/ComponentsForm';
import { addDeffect } from '../../../Services/MainBoard';
import { buildData } from '../../../utils/tableData';
import { deffectsHeaders } from '../../../utils/tableHeaders';
import { Pagination, Typography } from '@mui/material';
import DataTable from '../../../components/DataTable';

// import {
//   addAssembly,
//   getAssemblyById,
//   updateAssembly,
// } from '../../../Services/ShipService';

const CreateComponents = () => {
  const { id: aircraftid } = useParams();
  const { formId, formField } = form;

  const [state, setState] = useState('create');

  const [inputs, setInputs] = useState();

  const [deffects, setdeffects] = useState(null);
  const [currentdeffectsPage, setCurrentdeffectsPage] = useState(1);
  const [totaldeffectsPages, setTotaldeffectsPages] = useState();

  const navigate = useNavigate();

  // get search params
  const [searchparams] = useSearchParams();

  const handleSubmit = async (values, actions) => {
    if (state === 'create') {
      const newdata = {...values, aircraft: aircraftid};
      const { data } = await addDeffect(newdata);
    }

    actions.resetForm();
    loadDeffect(1);
  };

  const loadDeffect = async (page) => {
    const { data } = await getAllDeffects(page, aircraftid);
    setdeffects(buildData(data.defects, deffectsHeaders()));
    setCurrentdeffectsPage(parseInt(data.current_page));
    setTotaldeffectsPages(data.pages);
  };

  const handleChangePageDeffects = (event, value) => {
    setCurrentdeffectsPage(value);
    loadDeffect(value);
  };

  useEffect(() => {
    loadDeffect(1);
  }, []);

  return (
    <MDBox pt={3} pb={8}>
      <Grid container justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} lg={8}>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {/*({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <ComponentsForm
                      isView={false}
                      isUpdate={false}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <MDButton
                        variant='gradient'
                        color='error'
                        sx={{ height: '100%' }}
                      >
                        Cancelar
                      </MDButton>
                   
                      <MDButton
                        disabled={false}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )*/}
          </Formik>
        </Grid>
      </Grid>

      {deffects && (
            <>
              <Typography
                variant='h4'
                component='div'
                style={{ marginTop: 25, marginBottom: 15 }}
              >
                Components
              </Typography>

              <Card>
                {deffects.rows !== undefined && deffects.rows.length > 0 && (
                  <>
                    <DataTable
                      //   handleSearch={handleSearch}
                      // useActions
                      useView
                      useEdit
                      // useDelete
                      // useSelect
                      // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                      editAction={(data) =>
                        navigate(
                          `/ships/shipparts/update/${data.id}?aircraft=${inputs?.id}`
                        )
                      }
                      viewAction={(data) =>
                        navigate(`/ships/shipparts/view/${data.id}`)
                      }
                      // deleteAction={deleteRegister}
                      table={deffects}
                      showTotalEntries={false}
                      entriesPerPage={false}
                      // canSearch
                    />
                    <MDBox ml={1}>
                      <Pagination
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        color='info'
                        count={totaldeffectsPages}
                        page={currentdeffectsPage}
                        onChange={handleChangePageDeffects}
                      />{' '}
                    </MDBox>{' '}
                  </>
                )}
                
                {deffects?.rows !== undefined && deffects?.rows.length === 0 && (
                  <Typography
                    variant='h4'
                    component='div'
                    sx={{ margin: '100px' }}
                  >
                    No Existen components
                  </Typography>
                )}
              </Card>
            </>
          )}
          <CreateInspectionsTemplete />
    </MDBox>
  );
};

export default CreateComponents;
