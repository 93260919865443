import checkout from "./form";

const {
    formField: {
        date, remark, air_time, ng, nf
    }
} = checkout;

let currentDate = new Date().toJSON().slice(0, 10);

export const initialValues = {
    [date.name]: currentDate,
    [remark.name]: "",
    [air_time.name]: "",
    [ng.name]: "",
    [nf.name]: "",
}

export const initialValuesFromObj = (obj) => {
    return {
        [date.name]: obj.date,
        [remark.name]: obj.remark,
        [air_time.name]: obj.air_time,
        [ng.name]: obj.ng,
        [nf.name]: obj.nf,
    }
}
