import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import FormField from "../../../../components/FormField";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


 const AboutSupplierForm = ({handleChange, inputs, isView, formData, }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const { company, approved, phone, contact } = formField;
    const {
        company: CompanyV,
        approved: approvedV,
        phone: phoneV,
        contact: contactV,
      } = values;
      useEffect(()=>{
        console.log(values)
      },[])
    return (
        <MDBox mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormField 
                    type={company.type} label={company.label} name={company.name} 
                    value={CompanyV} error={errors.Company && touched.Company} 
                    //success={CompanyV.length > 0 && !errors.Company}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField 
                    type={phone.type} label={phone.label} name={phone.name} 
                    value={phoneV} error={errors.phone && touched.phone} 
                    success={phoneV.length > 0 && !errors.phone}
                    InputProps={{readOnly: isView,}} 
                    />
                    {/*  <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneV} error={errors.phone && touched.phone} 
                        //onChange={setTelephone} 
                        defaultCountry="GT"
                        InputProps={{readOnly: isView,}} 
                        /> */}
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField 
                    type={contact.type} label={contact.label} name={contact.name} 
                    value={contactV} error={errors.contact && touched.contact} 
                    success={contactV.length > 0 && !errors.contact}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        {
                            isView ?
                            <FormControlLabel  
                            control={
                            <Switch name="approved" 
                            value={isView ? (approvedV === 'F'? false: true) : approved}  
                            checked={isView ?(approvedV === 'F'? false: true): approved} 
                            disabled={isView} 
                            onChange={(event)=>{setFieldValue(approved.name, event.target.checked)}} 
                            />} 
                            label="Aprobado" />
                            :
                            <FormControlLabel  
                            control={
                            <Switch name="approved" 
                            value={isView ? (approved === 'F'? false: true) : approved}  
                            //checked={isView ?(approved === 'F'? false: true): approved} 
                            disabled={isView} 
                            onChange={(event)=>{setFieldValue(approved.name, event.target.checked)}} 
                            />} 
                            label="Aprobado" />
                        }
                    </FormGroup>
                </Grid>
            </Grid>
        </MDBox>
    );
 }

 export default AboutSupplierForm;