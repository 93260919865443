import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getShips, fetchShipAircrafts } from "../../Services/ShipService";

import { shipsHeaders } from "../../utils/tableHeaders";
import { buildData } from "../../utils/tableData";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import DataTable from "../../components/DataTable";

import { shipAircraftsHeaders } from "../../utils/tableHeaders";

const MainBoard = () => {
  const navigate = useNavigate();

  // Ships states
  const [ships, setShips] = useState([]);
  const [currentShipPage, setCurrentShipPage] = useState(1);
  const [totalShipPages, setTotalShipPages] = useState();
  const [selectedShipId, setSelectedShipId] = useState(null);
  const [loadingShips, setLoadingShips] = useState(true);
  const [shipId, setShipId] = useState();

  const [aircrafts, setAircrafts] = useState(null);
  const [currentAircraftPage, setCurrentAircraftPage] = useState(1);
  const [totalAircraftPages, setTotalAircraftPages] = useState();
  const [loadingAircrafts, setLoadingAircrafts] = useState(false);

  const loadAircrafts = async (page, id) => {
    setLoadingAircrafts(true);
    try {
      const { data } = await fetchShipAircrafts(id, page);
      setAircrafts(buildData(data.aircrafts, shipAircraftsHeaders()));
      setCurrentAircraftPage(parseInt(data.current_page));
      setTotalAircraftPages(data.pages);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAircrafts(false);
    }
  };

  const loadShips = async (page, filter, value) => {
    try {
      const { data } = await getShips(page, filter, value);
      setShips(buildData(data.ships, shipsHeaders()));
      setShipId(data.ships.id);
      setCurrentShipPage(parseInt(data.current_page));
      setTotalShipPages(data.pages);
      setLoadingShips(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingShips(false);
    }
  };

  // Menu and Filters
  const [filter, setFilter] = useState();
  const [filterLabel, setFilterLabel] = useState("Filtros");
  const [valueFilter, setValueFilter] = useState();
  const [ship, setShip] = useState();

  const filters = [
    { label: "No.", value: "id" },
    { label: "Registro", value: "register" },
    { label: "Model", value: "model" },
    { label: "Serial", value: "serial_number" },
  ];

  const getFilterLabel = (value) => {
    let label;
    filters.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };

  const handleSearch = (value) => {
    setValueFilter(value);
    loadShips(1, filter, value);
  };

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => {
    setMenu(null);
  };
  const handleChangeFilter = (value) => {
    setFilter(value);
    setFilterLabel(getFilterLabel(value));
    setMenu(null);
    closeMenu();
  };
  const clearFilter = () => {
    setMenu(null);
    setFilter();
    setFilterLabel("Filtros");
    loadShips(1);
    closeMenu();
  };

  //Pagination Ships
  const handleChangePageShips = (event, value) => {
    setCurrentShipPage(value);
    loadShips(value, filter, valueFilter);
  };

  useEffect(() => {
    loadShips(1);
  }, []);

  useEffect(() => {
    loadAircrafts(1, selectedShipId);
  }, [selectedShipId]);

  const viewShip = (ship) => {
    navigate(`/mainboard/${ship.id}`);
  };

  const handleChangePageAircrafts = (event, value) => {
    setCurrentAircraftPage(value);
    loadAircrafts(value, selectedShipId);
  };

  const onAircraftSelectHandler = (assembly) => {
    setSelectedShipId(assembly?.id);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {filters.map((option) => (
        <MenuItem
          key={option.value}
          onClick={() => {
            handleChangeFilter(option.value);
          }}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
      {/* <MenuItem onClick={()=> handleChange('nombre')} value="nombre" >Nombre</MenuItem>
          <MenuItem onClick={()=> handleChange('correo')} value="correo" >Correo</MenuItem> */}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={clearFilter}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Eliminar filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <Typography variant="h4" component="div">
        Ships Table
      </Typography>
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          {/* <Link to='/ships/create'>
            <MDButton variant='gradient' color='info'>
              Agregar
            </MDButton>
          </Link> */}
          <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              {filterLabel}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
          <Link to="/ships/create">
            <MDButton variant="gradient" color="info">
              Agregar Template
            </MDButton>
          </Link>
        </MDBox>
        <Card>
          {ships.rows !== undefined && ships.rows.length > 0 && (
            <>
              <DataTable
                handleSearch={handleSearch}
                useActions
                useView
                useSelect
                selectAction={onAircraftSelectHandler}
                viewAction={viewShip}
                table={ships}
                data={ships.id}
                showTotalEntries={false}
                entriesPerPage={false}
                canSearch
                collapseContent={(data) => {
                  console.log(data.id);
                  localStorage.setItem('ship', data.id)
                  return (
                    <>
                      {aircrafts && (
                        <>
                          <Link to="/ships/aircraft/create-from-mat">
                            <MDButton variant="gradient" color="info">
                              Agregar
                            </MDButton>
                          </Link>
                          <br></br>
                          <Card style={{ backgroundColor: "#fcfcfc" }}>
                            {aircrafts.rows !== undefined &&
                              aircrafts.rows.length > 0 && (
                                <>
                                  <DataTable
                                    useActions
                                    useView
                                    dataid={ships}
                                    viewAction={(data) => {
                                      {
                                        console.log(ship, 'datos de aircraft')
                                        navigate(
                                        `/mainboard/aircraft/${data.id}`,
                                        {
                                          state: {
                                            id: ship,
                                          },
                                        }
                                      );}
                                    }}
                                    // deleteAction={deleteRegister}
                                    table={aircrafts}
                                    showTotalEntries={false}
                                    entriesPerPage={false}

                                    // canSearch
                                  />
                                  <MDBox ml={1}>
                                    <Pagination
                                      sx={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                      }}
                                      color="info"
                                      count={totalAircraftPages}
                                      page={totalAircraftPages}
                                      onChange={handleChangePageAircrafts}
                                    />{" "}
                                  </MDBox>{" "}
                                </>
                              )}
                            {loadingAircrafts && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "200px",
                                }}
                              >
                                <CircularProgress color="info" size={80} />
                              </Box>
                            )}
                            {aircrafts?.rows !== undefined &&
                              aircrafts?.rows.length === 0 && (
                                <Typography
                                  variant="h4"
                                  component="div"
                                  sx={{ margin: "100px" }}
                                >
                                  No ship parts asociated to this assembly
                                </Typography>
                              )}
                          </Card>
                        </>
                      )}
                    </>
                  );
                }}
              />
              <MDBox ml={1}>
                <Pagination
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                  color="info"
                  count={totalShipPages}
                  page={currentShipPage}
                  onChange={handleChangePageShips}
                />{" "}
              </MDBox>{" "}
            </>
          )}
          {loadingShips && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress color="info" size={80} />
            </Box>
          )}
          {ships.rows !== undefined && ships.rows.length === 0 && (
            <Typography variant="h4" component="div" sx={{ margin: "100px" }}>
              No Existen registros
            </Typography>
          )}
        </Card>
      </MDBox>
    </div>
  );
};

export default MainBoard;
