import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { fetchBluePrintTasks, createBPtask } from "../../../Services/TaskService";

import { BPTasksHeaders } from "../../../utils/tableHeaders";
import { buildData } from "../../../utils/tableData";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Typography, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../components/DataTable";
import DeleteDialog from "../../../components/DeleteDialog";
import ContentDialog from "../../../components/ContentDialog";
import SnackNotification from "../../../components/SnackNotification";

const BPTasks = () => {
  const navigate = useNavigate();
  // Tasks states
  const [tasks, setTasks] = useState([]);
  const [currentTaskPage, setCurrentTaskPage] = useState(1);
  const [totalTaskPages, setTotalTaskPages] = useState();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openTaskSelector, setOpenTaskSelector] = useState(false);
  const [openViewBDTask, setOpenViewBDTask] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(true);
  let [TTAFperfom, setTTAFperfom] = useState();
  let [performDate, setPerfomDate] = useState();
  let [performCycles, setPerfomCycles] = useState();
  let [perfomWarning, setPerfomWarning] = useState();
  let [TTAFperfom1, setTTAFperfom1] = useState();
  let [performDate1, setPerfomDate1] = useState();
  let [performCycles1, setPerfomCycles1] = useState();
  let [correction, setCorrection] = useState();
  let [addTask, setAddTask] = useState(false);

  // Aircrafts States

  const [tasktToDelete, setTaskToDelete] = useState();
  const [openDeteleDialog, setOpenDeleteDialog] = React.useState(false);

  const [openCreate, setopenCreate] = useState(false);
  const [inputs, setInputs] = useState({});
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [action, setAction] = useState();

  const loadTasks = async (page, filter, value) => {
    try {
      const { data } = await fetchBluePrintTasks(page, filter, value);
      console.log(data);
      setTasks(buildData(data.bp_tasks, BPTasksHeaders()));
      setCurrentTaskPage(parseInt(data.current_page));
      setTotalTaskPages(data.pages);
      setLoadingTasks(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTasks(false);
    }
  };

  // const loadTaskParts = (page, taskId) =>{
  //     getTaskParts(taskId, page).then(resp => {
  //         console.log(resp.data)
  //         setPieces(buildData(resp.data.task_parts, taskPartsHeaders()))
  //         setCurrentSecondPage(parseInt(resp.data.current_page))
  //         setTotalSecondPages(resp.data.pages)
  //         setSecondLoading(false)
  //     }).catch((err) => {
  //         console.error(err);
  //     })
  // }

  // Menu and Filters
  const [filter, setFilter] = useState();
  const [filterLabel, setFilterLabel] = useState("Filtros");
  const [valueFilter, setValueFilter] = useState();

  const filters = [
    { label: "No.", value: "id" },
    { label: "Registro", value: "register" },
    { label: "Model", value: "model" },
    { label: "Serial", value: "serial_number" },
  ];

  const getFilterLabel = (value) => {
    let label;
    filters.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };

  const handleSearch = (value) => {
    setValueFilter(value);
    loadTasks(1, filter, value);
  };

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => {
    setMenu(null);
  };
  const handleChangeFilter = (value) => {
    setFilter(value);
    setFilterLabel(getFilterLabel(value));
    setMenu(null);
    closeMenu();
  };
  const clearFilter = () => {
    setMenu(null);
    setFilter();
    setFilterLabel("Filtros");
    loadTasks(1);
    closeMenu();
  };

  //Pagination Tasks
  const handleChangePageTasks = (event, value) => {
    setCurrentTaskPage(value);
    loadTasks(value, filter, valueFilter);
  };

  

  const editRegister = (task) => {
    console.log(task);
    setOpenTaskSelector(true);
  };
  const viewRegister = (task) => {
    console.log(task);
    setPerfomCycles(task.cycle_limit);
    setPerfomDate(task.calendar_limit);
    setTTAFperfom(task.hours_limit);
    setPerfomWarning(task.hours_warning);
    setOpenViewBDTask(true);
  };

  // const handleSecondPagination = (event, value) => {
  //     setCurrentSecondPage(value)
  //     loadTaskParts(value, idSelected)
  // }

  const deleteRegister = (task) => {
    setTaskToDelete(task);
    setOpenDeleteDialog(true);
    setAction("deleteTask");
  };

  // const selectRegister = (task) => {
  //     setIdSelected(task.id)
  //     loadTaskParts(1, task.id)
  // }

  //Calbacks to Delete dialog
  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const createTask = (ata, desc, cond, active) => {
    const ship = localStorage.getItem("ship");
    let act = active === '' ? true : active === 'true' ? true : false;
    createBPtask(ship, ata, desc, cond, act)
  }


  const handleChange = (event, type) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const addTaskModal = () => {
    setAddTask(true);
  };

  const closeModals = () => {
    setAddTask(false);
    setOpenTaskSelector(false);
    setOpenViewBDTask(false);
  };

  // const createPart = () => {
  //     if(action === 'update'){
  //         console.log(inputs)
  //         updateTaskPart(inputs, inputs.id).then(resp => {
  //             console.log(resp.data)
  //             setOpenSuccess(true)
  //             loadTaskParts(1, idSelected)
  //             closeModals()
  //         }).catch((err) => {
  //             console.error(err);
  //             setOpenError(true)
  //         })
  //     }else{
  //         addTaskPart(inputs).then(resp => {
  //             console.log(resp.data)
  //             setOpenSuccess(true)
  //             loadTaskParts(1, idSelected)
  //             closeModals()
  //         }).catch((err) => {
  //             console.error(err);
  //             setOpenError(true)
  //         })
  //     }

  // }

  // const deletePart = (value) => {
  //     setPartToDelete(value)
  //     setOpenDeleteDialog(true);
  //     setAction('deletePart')
  // }

  const onSelectTaskHandler = (task) => {
    setSelectedTaskId(task.id);
  };

  useEffect(() => {
    loadTasks(1);
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {filters.map((option) => (
        <MenuItem
          key={option.value}
          onClick={() => handleChangeFilter(option.value)}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
      {/* <MenuItem onClick={()=> handleChange('nombre')} value="nombre" >Nombre</MenuItem>
          <MenuItem onClick={()=> handleChange('correo')} value="correo" >Correo</MenuItem> */}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={clearFilter}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Eliminar filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      {/*Modal*/}
      <ContentDialog
          open={addTask}
          title={"Crear Nueva Task"}
          closeCallback={closeModals}
          buttonText="Cancelar"
        >
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">ATTA Code:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setTTAFperfom1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Description:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setPerfomDate1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Conditional:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setPerfomCycles1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Active:</InputLabel>
              <br />
              <br />
              <select
                onChange={(e) => {
                  setCorrection(e.target.value);
                  console.log(e.target.value);
                }}>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
            </FormControl>
          </Grid>
          <br></br>

          <MDBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <MDBox mt={2}>
              <MDButton
                variant="gradient"
                color="success"
                type="submit"
                onClick={() => {
                  setTTAFperfom(TTAFperfom1);
                  setPerfomDate(performDate1);
                  setPerfomCycles(performCycles1);
                  setCorrection(correction);
                  createTask(TTAFperfom1, performDate1, performCycles1, correction);

                  closeModals();
                }}
              >
                Crear Task
              </MDButton>
            </MDBox>
          </MDBox>
        </ContentDialog>

      <ContentDialog
        open={openTaskSelector}
        title={"Editar datos del task"}
        closeCallback={closeModals}
        buttonText="Cancelar"
      >
        <Grid item xs={12} md={12}>
          <FormControl style={{ width: "25vh" }}>
            <InputLabel htmlFor="my-input">Perform TTAF:</InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              onChange={(e) => {
                setTTAFperfom1(e.target.value)
              }}
            />
          </FormControl>
        </Grid>
        <br></br>
        <Grid item xs={12} md={12}>
          <FormControl style={{ width: "25vh" }}>
            <InputLabel htmlFor="my-input">Perform Date:</InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              onChange={(e) => {
                setPerfomDate1(e.target.value)
              }}
            />
          </FormControl>
        </Grid>
        <br></br>
        <Grid item xs={12} md={12}>
          <FormControl style={{ width: "25vh" }}>
            <InputLabel htmlFor="my-input">Perform Cycles:</InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              onChange={(e) => {
                setPerfomCycles1(e.target.value)
              }}
            />
          </FormControl>
        </Grid>
        <br></br>
        <br></br>
        <Grid item xs={12} md={12}>
          <InputLabel htmlFor="my-input">Correction:</InputLabel>
          <br></br>
          <FormControl style={{ width: "25vh" }}>
            <textarea
              style={{ resize: "none", height: "10vh" }}
              onChange={(e) => {
                setCorrection(e.target.value)
              }}
            ></textarea>
          </FormControl>
        </Grid>
        <MDBox
          mt={3}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <MDBox mt={2}>
            <MDButton
              variant="gradient"
              color="success"
              type="submit"
              onClick={()=>{
                setTTAFperfom(TTAFperfom1)
                setPerfomDate(performDate1)
                setPerfomCycles(performCycles1)
                setCorrection(correction)
                closeModals()
              }}
            >
              Guardar
            </MDButton>
          </MDBox>
        </MDBox>
      </ContentDialog>
      <ContentDialog
        open={openViewBDTask}
        title={"Task Data"}
        closeCallback={closeModals}
        buttonText="Cancelar"
      >
        <Grid>
          <Typography variant="body2" component="div" style={{ width: "25vh" }}>
            Perform TTAF: {TTAFperfom1 ? TTAFperfom1 : TTAFperfom}
            {" "}
            
          </Typography>
        </Grid>
        <br></br>
        <Grid>
          <Typography variant="body2" component="div" style={{ width: "25vh" }}>
            Perform Date: {performDate1 ? performDate1 : performDate}{" "}
          </Typography>
        </Grid>
        <br></br>
        <Typography variant="body2" component="div" style={{ width: "25vh" }}>
          Perform Cycles: {performCycles1 ? performCycles1 : performCycles}{" "}
        </Typography>
        <br></br>
        <Typography variant="body2" component="div" style={{ width: "25vh" }}>
          Correction: {correction}{" "}
        </Typography>
        {/* <MDBox
          mt={3}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          {/* <MDBox mt={2}>
            <MDButton
              variant="gradient"
              color="primary"
              type="submit"
              onClick={editRegister}
            >
              Editar
            </MDButton>
          </MDBox> 
        </MDBox>*/}
      </ContentDialog>
      {/* Tasks */}
      <Typography variant="h4" component="div">
        Work Report from Due Tasks
      </Typography>
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDButton
            variant="gradient"
            color="info"
            onClick={addTaskModal}
          >
            Agregar Task
          </MDButton>
          <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              {filterLabel}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {tasks.rows !== undefined && tasks.rows.length > 0 && (
            <>
              <DataTable
                handleSearch={handleSearch}
                useView
                useEdit
                useDelete
                editAction={editRegister}
                viewAction={viewRegister}
                selectAction={onSelectTaskHandler}
                deleteAction={deleteRegister}
                table={tasks}
                useActions
                showTotalEntries={false}
                entriesPerPage={false}
                canSearch
              />
              <MDBox ml={1}>
                <Pagination
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                  color="info"
                  count={totalTaskPages}
                  page={currentTaskPage}
                  onChange={handleChangePageTasks}
                />{" "}
              </MDBox>{" "}
            </>
          )}
          {loadingTasks && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress color="info" size={80} />
            </Box>
          )}
          {tasks.rows !== undefined && tasks.rows.length === 0 && (
            <Typography variant="h4" component="div" sx={{ margin: "100px" }}>
              No Existen registros
            </Typography>
          )}
        </Card>
      </MDBox>

      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          {/* idSelected &&
                        <MDButton variant="gradient" color="info" onClick={()=>{setopenCreate(true); setInputs({ task:idSelected })}}>
                            Agregar 
                        </MDButton>
                    */}
          {/* <MDBox display="flex">
                        <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                        Filtros&nbsp;
                        <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                        <MDButton variant="outlined" color="dark">
                            <Icon>description</Icon>
                            &nbsp;export csv
                        </MDButton>
                        </MDBox>
                    </MDBox> */}
        </MDBox>
        {/* <Card>
                    { pieces.rows !== undefined && pieces.rows.length > 0 && 
                        <><DataTable useActions  useView useEdit useDelete viewAction={viewPart} editAction={updatePart} deleteAction={deletePart} table={pieces} showTotalEntries={false} entriesPerPage={false} 
                        /> 
                        <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={totalSecondPages} page={currentSecondPage} onChange={handleSecondPagination} /> </MDBox> </>}  
                    { secondLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                    { pieces.rows !== undefined &&  pieces.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                </Card> */}
      </MDBox>

      <ContentDialog
        open={openCreate}
        title={"Part"}
        closeCallback={closeModals}
        maxWidth="md"
        fullWidth={true}
      >
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.inventory_detail || ""}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  id="inventory_detail"
                  InputProps={{
                    name: "inventory_detail",
                    disabled: action === "view",
                  }}
                  label="Item"
                  variant="outlined"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.instalation_date || ""}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  id="instalation_date"
                  InputProps={{
                    name: "instalation_date",
                    type: "date",
                    disabled: action === "view",
                  }}
                  label="instalation date"
                  variant="outlined"
                  focused
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.link || ""}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  id="link"
                  InputProps={{ name: "link", disabled: action === "view" }}
                  label="link"
                  variant="outlined"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.next_date || ""}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  id="next_date"
                  InputProps={{
                    name: "next_date",
                    type: "date",
                    disabled: action === "view",
                  }}
                  label="next date"
                  variant="outlined"
                  focused
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    <MDBox mt={2}>
                        <MDButton
                            variant="gradient"
                            color="success"
                            type="submit"
                            onClick={createPart}
                            disabled={action === 'view'}
                            >
                                Aceptar
                        </MDButton>
                    </MDBox>
                </MDBox> */}
      </ContentDialog>

      <SnackNotification
        type="error"
        message="Ha ocurrido un error"
        closeCallback={console.log()}
        openFlag={openError}
        setOpenFlag={setOpenError}
      />
      <SnackNotification
        type="success"
        message="Parte creado"
        closeCallback={console.log()}
        openFlag={openSuccess}
        setOpenFlag={setOpenSuccess}
      />
    </div>
  );
};

export default BPTasks;
