import { useState, useEffect, useMemo, useRef } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import axios from "axios";
// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";

import { getEmployeeCardID, getJobDescript, getLicenList } from "../../Services/formServices";
import countryList from "react-select-country-list";
import Loader from "../../components/Loader";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

function EmployeeCard({ action }) {
  const params = useParams();
  const [name, setname] = useState();
  const [dateB, setdateB] = useState();
  const [dpi, setDpi] = useState();
  const [address, setaddress] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();

  const [visa, setpassport] = useState(false);
  const [passport, setvisa] = useState();
  const countries = useMemo(() => countryList().getData(), []);

  const [blood, setblood] = useState();
  const [conditions, setconditions] = useState();
  const [aller, setaller] = useState();
  const [nameRef, setnameRef] = useState();
  const [family, setfamily] = useState();
  const [phoneRef, setphoneRef] = useState();

  const [position, setposition] = useState();
  const [licen, setlicen] = useState();
  const [number_licen, setnumber_licen] = useState();
  const [date_licen, setdate_licen] = useState();
  const [date_cert, setdate_cert] = useState();
  const [course, setcourse] = useState();
  const [courseRe, setcourseRe] = useState();
  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const userToken = localStorage.getItem('Token') || '';
  const [flag, setFlag] = useState(false);
  const [date_init_work, setdate_init_work] = useState();



  const [jobs, setJobs] = useState();
  const [licenList, setLicen] = useState();
  const [optionsV, setOptions] = useState([]);
  const [licences, setLicences] = useState([
    { id: "", license_number: "", date_licen: "", date_cert: "" }
  ]);

  const bloodList = [
    { label: "A+", value: 'A+' },
    { label: "A-", value: 'A-' },
    { label: "B+", value: 'B+' },
    { label: "B-", value: 'B-' },
    { label: "O+", value: 'O+' },
    { label: "O-", value: 'O-' },
    { label: "AB+", value: 'AB+' },
    { label: "AB-", value: 'AB-' },
  ];

  const familyList = [
    { label: "PADRES", value: 'P' },
    { label: "OTROS", value: 'O' },
    { label: "CONYUGE", value: 'C' },
    { label: "HERMANOS", value: 'H' },
  ];



  /*  const licenList = [
     { label: "A", value: 'A' },
     { label: "B", value: 'B' },
     { label: "C", value: 'C' },
     { label: "M", value: 'M' },
     { label: "E", value: 'E' },
   ]; */
  const jsonAnswers = {
    name: name,
    dateB: dateB,
    dpi: dpi,
    address: address,
    phone: phone,
    email: email,

    visa: visa,
    passport: passport,
    blood: blood,
    conditions: conditions,
    aller: aller,
    nameRef: nameRef,
    family: family,
    phoneRef: phoneRef,

    position: position,
    licen: licen,
    number_licen: number_licen,
    date_licen: date_licen,
    date_cert: date_cert,
    course: course,
    courseRe: courseRe,
  };

  const loadJobs = () => {
    getJobDescript()
      .then((resp) => {
        var data = resp.data.map(index => {
          return {
            label: index.job_title,
            value: index.id
          }
        })
        setJobs(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        //setLoading(false);
      });
  };

  const createCertification = () => {
    let ver = null
    const obj = JSON.stringify(passport);
    const licenseJSON = JSON.stringify(licences);

    const pilotdata = new FormData();
    pilotdata.append('name', name);
    pilotdata.append('dateB', dateB);
    pilotdata.append('dpi', dpi);
    pilotdata.append('address', address);
    pilotdata.append('phone', phone);
    pilotdata.append('email', email);
    pilotdata.append('visa', visa);
    pilotdata.append('passport', obj);
    //pilotdata.append('blood', blood);
    blood && pilotdata.append('blood', blood)
    //pilotdata.append('conditions', conditions);
    conditions && pilotdata.append('conditions', conditions);
    //pilotdata.append('aller', aller);
    aller && pilotdata.append('aller', aller);
    pilotdata.append('nameRef', nameRef);
    //pilotdata.append('family', family);
    family && pilotdata.append('family', family);
    pilotdata.append('phoneRef', phoneRef);
    pilotdata.append('position', position);
    pilotdata.append('licen', licenseJSON);
    date_init_work && pilotdata.append('date_init_work', date_init_work);
    //pilotdata.append('date_init_work', date_init_work);


    //pilotdata.append('number_licen', number_licen);
    //pilotdata.append('date_licen', date_licen);
    //pilotdata.append('date_cert', date_cert);
    //pilotdata.append('course', course);
    //pilotdata.append('courseRe', courseRe);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/employee_card/',
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Employee Card Creado Exitosamente")
      window.location.replace("/employee_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Employee Card")
    }.bind(this))
  };

  const updateCertification = () => {
    let ver = null
    const obj = JSON.stringify(passport);
    const licenseJSON = JSON.stringify(licences);
    const pilotdata = new FormData();
    name && pilotdata.append('name', name);
    dateB && pilotdata.append('dateB', dateB);
    dpi && pilotdata.append('dpi', dpi);
    address && pilotdata.append('address', address);
    phone && pilotdata.append('phone', phone);
    email && pilotdata.append('email', email);
    visa && pilotdata.append('visa', visa);
    passport && pilotdata.append('passport', obj);
    blood && pilotdata.append('blood', blood);
    conditions && pilotdata.append('conditions', conditions);
    aller && pilotdata.append('aller', aller);
    nameRef && pilotdata.append('nameRef', nameRef);
    family && pilotdata.append('family', family);
    phoneRef && pilotdata.append('phoneRef', phoneRef);
    position && pilotdata.append('position', position);
    licences && pilotdata.append('licen', licenseJSON);
    date_init_work && pilotdata.append('date_init_work', date_init_work);
    //number_licen && pilotdata.append('number_licen', number_licen);
    //date_licen && pilotdata.append('date_licen', date_licen);
    //date_cert && pilotdata.append('date_cert', date_cert);
    //course && pilotdata.append('course', course);
    //courseRe && pilotdata.append('courseRe', courseRe);

    axios.patch(
      `https://fn.devpackgroup.com/api/v1/employee_card/${params.id}/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Employee Card Creado Exitosamente")
      window.location.replace("/employee_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Employee Card")
    }.bind(this))
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getEmployeeCardID(params.id).then(resp => {
        //console.log(resp.data)
        setInputs(resp.data)
        var data = resp.data.passport.map((index, key) => {
          optionsV.push(countries.find(label => label.label === index))
        })
        var data2 = resp.data.licen.map((index, key) => {
          licences[key] = {
            id: index.type.id,
            date_cert: index.date_cert,
            date_licen: index.date_licen,
            license_number: index.number_licen,
          };

          //licences.push({ id: "", license_number: "",date_licen: "",date_cert: "" })
        })
        console.log(licences, 'lincencs')

      }).catch(err => {
        console.error(err);
      });
    }
  }

  const loadLicen = () => {
    getLicenList()
      .then((resp) => {
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setLicen(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        //setLoading(false);
      });
  };


  useEffect(() => {
    loadJobs();
    loadLicen();
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);


  const handleRemoveClick = (index) => {
    const list = [...licences];
    list.splice(index, 1);
    setLicences(list);
  };

  const addDescription = (flag) => {

    setLicences([...licences, { id: "", license_number: "", date_licen: "", date_cert: "" }]);
    console.log(licences);

  };


  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {action === 'view' && 'Visualizar Ficha del Empleado'}
                  {action === 'update' && 'Editar Ficha del Empleado'}
                  {action === 'add' && 'Crear Ficha del Empleado'}
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <MDBox pt={4} pb={3} px={3}>
              {(flag &&
                <>
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}

                        Datos Personales:{" "}
                      </p>
                      <br />

                      <MDInput
                        type="text"
                        label="Nombre y Apellido:"
                        variant="standard"
                        fullWidth
                        placeholder="Nombre y Apellido"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setname(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.name : null}
                        value={(action === 'view') && inputs ? inputs.name : null}
                      />

                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      {/*   <MDInput
                        type="date"
                        label="Fecha de nacimiento:"
                        variant="standard"
                        fullWidth
                        placeholder="Fecha de nacimiento"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setdateB(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.dateB : null}
                        value={(action === 'view') && inputs ? inputs.dateB : null}
                      /> */}
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}

                        Nacimiento:{" "}
                      </p>
                      <br />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              //setdateB(e)
                              const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                              setdateB(formattedDate)
                              console.log(formattedDate)

                            }}
                            disabled={action === 'view'}
                            defaultValue={(action === 'update') && inputs ? dayjs(inputs.dateB) : null}
                            value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.dateB, 'DD/MM/YYYY') : null}
                            label="Fecha de nacimiento:" />
                        </DemoContainer>
                      </LocalizationProvider>
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}

                        DPI:{" "}
                      </p>
                      <br />
                      <MDInput
                        type="number"
                        label="No. de DPI:"
                        variant="standard"
                        fullWidth
                        placeholder="No. de DPI"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDpi(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.dpi : null}
                        value={(action === 'view') && inputs ? inputs.dpi : null}
                      />
                    </MDBox>
                    <br />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <MDInput
                        type="text"
                        label="Direccion:"
                        variant="standard"
                        fullWidth
                        placeholder="Direccion"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setaddress(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}

                        value={(action === 'view') && inputs ? inputs.address : null}
                        defaultValue={(action === 'update') && inputs ? inputs.address : null}
                      />
                    </MDBox>
                    <br />
                    <br />
                    <MDBox width={'30%'} ml={6} mt={4} mb={2}>
                      {/*  <MDInput
                        type="number"
                        label="Telefono:"
                        variant="standard"
                        fullWidth
                        placeholder="Telefono"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setphone(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.phone : null}
                        value={(action === 'view') && inputs ? inputs.phone : null}
                      /> */}
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={(action === 'update' || action === 'view') && inputs ? inputs.phone : null}
                        onChange={setphone}
                        defaultCountry="GT"
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                      />

                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <MDInput
                        type="mail"
                        label="Email:"
                        variant="standard"
                        fullWidth
                        placeholder="Email"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setemail(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.email : null}
                        value={(action === 'view') && inputs ? inputs.email : null}
                      />
                    </MDBox>
                    <br />
                  </div>
                  <MDBox mb={2}>
                    <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                      {" "}
                      Visa Americana:{" "}

                      <Checkbox
                        onChange={(e) => {
                          setpassport(e.target.checked)
                        }}
                        checked={(inputs && inputs.visa)}

                      />
                    </p>

                    {/* <MDInput
                  type="number"
                  label="Pasaporte:"
                  variant="standard"
                  fullWidth
                  placeholder="Pasaporte"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setpassport(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.visa : null}
                  value={(action === 'view') && inputs ? inputs.visa : null}
                /> */}
                  </MDBox>
                  <br />

                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Pasaportes de:{" "}
                      </p>
                      <MDBox mb={2}>
                        <Select
                          placeholder="Location"
                          options={countries}
                          isMulti={true}
                          onChange={(o) => { console.log(o); setvisa(o) }}
                          isDisabled={action === 'view' ? true : false}
                          defaultValue={(action === 'view' || action === 'update') && (optionsV && optionsV)}

                        ></Select>
                      </MDBox>
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Datos Clinicos:{" "}
                      </p>

                      {/*  <MDInput
                  type="text"
                  label="Tipo de Sangre:"
                  variant="standard"
                  fullWidth
                  placeholder="Tipo de Sangre"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setblood(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.blood : null}
                  value={(action === 'view') && inputs ? inputs.blood : null}
                /> */}
                      <Select

                        placeholder="Tipo de Sangre"
                        options={bloodList}
                        //isMulti={true}
                        onChange={(o) => { setblood(o.label) }}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && bloodList.find(label => label.label === inputs.blood)}

                      ></Select>
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <br />
                      <MDInput
                        type="text"
                        label="Condiciones Particulares:"
                        variant="standard"
                        fullWidth
                        placeholder="Condiciones Particulares"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setconditions(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.conditions : null}
                        value={(action === 'view') && inputs ? inputs.conditions : null}
                      />

                    </MDBox>
                    <br />
                  </div>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Alergias:"
                      variant="standard"
                      fullWidth
                      placeholder="Alergias"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setaller(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                      defaultValue={(action === 'update') && inputs ? inputs.aller : null}
                      value={(action === 'view') && inputs ? inputs.aller : null}
                    />
                  </MDBox>
                  <br />



                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Referencias personales en caso de emergencia:{" "}
                  </p>
                  <br />
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <MDInput
                        type="text"
                        label="Nombre:"
                        variant="standard"
                        fullWidth
                        placeholder="Nombre"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setnameRef(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.nameRef : null}
                        value={(action === 'view') && inputs ? inputs.nameRef : null}
                      />
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      {/*    <MDInput
                  type="text"
                  label="Parentesco:"
                  variant="standard"
                  fullWidth
                  placeholder="Parentesco"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setfamily(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.family : null}
                  value={(action === 'view') && inputs ? inputs.family : null}
                /> */}
                      <Select
                        placeholder="Parentesco:"
                        options={familyList}
                        //isMulti={true}
                        onChange={(o) => { setfamily(o.value) }}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && familyList.find(label => label.value === inputs.family)}

                      ></Select>
                    </MDBox>
                    <MDBox width={'30%'} ml={6} mb={2} mt={2}>
                      {/*    <MDInput
                        type="number"
                        label="Telefono:"
                        variant="standard"
                        fullWidth
                        placeholder="Telefono"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setphoneRef(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.phoneRef : null}
                        value={(action === 'view') && inputs ? inputs.phoneRef : null}
                      /> */}
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={(action === 'update' || action === 'view') && inputs ? inputs.phoneRef : null}
                        onChange={setphoneRef}
                        defaultCountry="GT"
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                      />
                    </MDBox>
                    <br />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Puesto:{" "}
                      </p>
                      <br />
                      {/*    <textarea
                  name=""
                  id=""
                  rows="5"
                  style={{
                    resize: "None",
                    width: "100%",
                    border: "None",
                    borderRadius: '8px',
                    padding: "2vh",
                    fontFamily: "Arial",
                  }}
                  onChange={(o) => (setposition(o.target.value))}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.position : null}
                  value={(action === 'view') && inputs ? inputs.position : null}
                ></textarea> */}
                      <Select
                        placeholder="Puesto:"
                        options={jobs}
                        //isMulti={true}
                        onChange={(o) => { setposition(o.value) }}
                        isDisabled={action === 'view' ? true : false}
                        defaultValue={(action === 'view' || action === 'update') && jobs.find(label => label.value === inputs.position.id)}

                      ></Select>
                    </MDBox>
                    <br />
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}

                        Fecha de Inicio:{" "}
                      </p>
                      <br />
                      {
                        /*<MDInput
                          type="date"
                          label="Fecha de Inicio:"
                          variant="standard"
                          fullWidth
                          placeholder="Fecha de nacimiento"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setdate_init_work(e.target.value)}
                          InputProps={{ readOnly: action === 'view' ? true : false, }}
                          defaultValue={(action === 'update') && inputs ? inputs.date_init_work : null}
                          value={(action === 'view') && inputs ? inputs.date_init_work : null}
                        />
                        */
                      }
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              //setdateB(e)
                              const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                              setdate_init_work(formattedDate)
                              console.log(formattedDate)

                            }}
                            disabled={action === 'view'}
                            value={(action === 'view' || action === 'update') && inputs ? dayjs(inputs.date_init_work, 'DD/MM/YYYY') : null}
                            defaultValue={(action === 'update') && inputs ? dayjs(inputs.date_init_work, 'DD/MM/YYYY') : null}
                            label="Fecha de Inicio:" />
                        </DemoContainer>
                      </LocalizationProvider>
                    </MDBox>
                  </div>

                  <br />


                  {licences.map((index, key) => {
                    return (
                      <>
                        <li>
                          <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                            {" "}
                            Licencia{" "}
                          </p>
                          <br />
                          <div style={{ display: 'flex' }}>
                          <MDBox width={'30%'} mb={2}>
                            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                              {" "}
                              Licencias:{" "}
                            </p>
                            <br />
                            <Select
                              placeholder="Licencia Tipo:"
                              options={licenList}
                              //isMulti={true}
                              //onChange={(o) => { setlicen(o.value) }}
                              onChange={(e) =>
                              //console.log(e,'value')
                              (licences.at(key).id =
                                e.value)
                              }
                              isDisabled={action === 'view' ? true : false}
                              defaultValue={(action === 'view' || action === 'update') && licenList.find(label => label.value === licences[key].id)}

                            ></Select>
                          </MDBox>
                          <br />
                          <MDBox width={'30%'} ml={6} mb={2}>
                          <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                              {" "}
                              No. de Licencia:{" "}
                            </p>
                            <br />
                            <MDInput
                              type="text"
                              label="No. de Licencia:"
                              variant="standard"
                              fullWidth
                              placeholder="No. de Licencia"
                              InputLabelProps={{ shrink: true }}
                              //onChange={(e) => setnumber_licen(e.target.value)}
                              onChange={(e) =>
                              (licences.at(key).license_number =
                                e.target.value)
                              }
                              InputProps={{ readOnly: action === 'view' ? true : false, }}
                              defaultValue={(action === 'update') ? index.license_number : null}
                              value={(action === 'view') ? licences[key].license_number : null}
                            />
                            {console.log(index)}

                          </MDBox>
                          <br />
                          <MDBox width={'30%'} ml={6} mb={2}>
                          <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                              {" "}
                              Fecha Vencimiento Licencia:{" "}
                            </p>
                            <br />
                            
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  onChange={(e) => {
                                    //setdateB(e)
                                    //const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                                    (licences.at(key).date_licen = `${e.$D}/${e.$M + 1}/${e.$y}`)
                                    console.log(`${e.$D}/${e.$M + 1}/${e.$y}`)

                                  }}
                                  disabled={action === 'view'}

                                  value={(action === 'view' || action === 'update') ? dayjs(licences[key].date_licen, 'DD/MM/YYYY') : null}
                                  defaultValue={(action === 'update') ? dayjs(index.date_licen, 'DD/MM/YYYY') : null}
                                  label="Fecha de vencimiento de licencia:" />
                              </DemoContainer>
                            </LocalizationProvider>
                          </MDBox>
                          <br />
                          <MDBox width={'30%'} ml={6} mb={2}>
                          <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                              {" "}
                              Fecha Vencimiento Certificado:{" "}
                            </p>
                            <br />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  onChange={(e) => {
                                    //setdateB(e)
                                    //const formattedDate = `${e.$D}/${e.$M + 1}/${e.$y}`
                                    (licences.at(key).date_cert = `${e.$D}/${e.$M + 1}/${e.$y}`)
                                    console.log(`${e.$D}/${e.$M + 1}/${e.$y}`)

                                  }}
                                  disabled={action === 'view'}
                                  value={(action === 'view' || action === 'update') ? dayjs(licences[key].date_cert, 'DD/MM/YYYY') : null}

                                  defaultValue={(action === 'update') ? dayjs(licences[key].date_cert, 'DD/MM/YYYY') : null}
                                  label="Fecha de vencimiento de certificado de validez:" />
                              </DemoContainer>
                            </LocalizationProvider>
                          </MDBox>
                          </div>


                          {(licences.length > 1 && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="error"
                              onClick={() => {
                                handleRemoveClick(key, 1);
                              }}
                            >
                              Remove
                            </MDButton>
                          )}{" "}
                          {(licences.length - 1 === key && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => addDescription(1)}
                            >
                              Add License
                            </MDButton>
                          )}
                          <br />
                        </li>
                      </>
                    );
                  })}
                  <br />

                  <br />
                </>
              )}
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/employee_list")}
              >
                Cancelar
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => action === 'update' ? updateCertification() : createCertification()}
              >
                {action === 'update' ? 'Editar Ficha' : 'Crear Ficha'}
              </MDButton>
            </MDBox>
          </>
      }
    </>
  );
}
export default EmployeeCard;
