import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        client,
        register,
        make,
        model,
        serial_number,
        year,
        fly_hours,
        warnings,
        ATTA_code,
        cicle,
    }
} = checkout;

const validations = Yup.object().shape();

export default validations;