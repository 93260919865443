import { useState, useEffect, useMemo, useRef } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import { QuillEditor } from "./components/QuillEditor";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import countryList from "react-select-country-list";
import axios from "axios";
import FileViewer from "react-file-viewer";


import { getProcessID, deleteProcessID } from "../../Services/formServices";


// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from "../../components/Loader";

function ProcessDefinition({ action }) {
  const params = useParams();
  const userToken = localStorage.getItem('Token') || '';
  const [processName, setProcessName] = useState();
  const [viewPdf, setViewPdf] = useState();
  const [flagviewPdf, setFlagViewPdf] = useState();
  // const [definition, setDefinition] = useState();
  // const [owner, setOwner] = useState();
  // const [responseDesc, setResponseDesc] = useState();
  // const [technInfo, setTechnInfo] = useState();
  // const [resources, setResources] = useState();
  // const [personnel, setPersonnel] = useState();
  // const [specialTraining, setSpecialTraining] = useState();
  // const [processSteps, setProcessSteps] = useState(); // recibis una lista separada por comas
  // const [product, setProduct] = useState();
  const [documents, setDocuments] = useState();// recibis una lista separada por comas
  // const [records, setRecords] = useState(); // recibis una lista separada por comas
  // const [services, setServices] = useState();// recibis una lista separada por comas
  // const [editorState, setEditorState] = useState();// recibis una lista
  const [id, setId] = useState(0)

  //const [state, setState] = React.useState({ value: null });
  //const quill = new Quill();


  const [flag, setFlag] = useState(false);
  const [inputs, setInputs] = useState();



  const [editorState, setEditorState] = useState('');





  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = day + "/" + month + "/" + year;



  const createProcess = () => {
    const processData = new FormData();

    processData.append('name', processName);
    // processData.append('definition', definition);
    // processData.append('owner', owner);
    // processData.append('responseDesc', responseDesc);
    // processData.append('technInfo', technInfo);
    // processData.append('resources', resources);
    // processData.append('personnel', personnel);
    // processData.append('specialTraining', specialTraining);
    // processData.append('processSteps', processSteps);
    // processData.append('product', product);
    // processData.append('product', product);
    processData.append('file', documents);
    // processData.append('records', records);
    // processData.append('services', services);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/procces/',
      processData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Proceso Creado Exitosamente")
      window.location.replace("/processes_list");
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      alert("Error al crear proceso")
    }.bind(this))
  };

  const updateProcess = () => {
    const processData = new FormData();

    processData.append('name', processName ? processName : inputs.processName);
    documents && processData.append('file', documents);
    processData.append('id', id)
    // processData.append('records', records);
    // processData.append('services', services);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/procces/update_proccess/',
      processData,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      console.log(response.data)
      alert("Proceso Creado Exitosamente")
      window.location.replace("/processes_list");
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      alert("Error al crear proceso")
    }.bind(this))
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getProcessID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)

      }).catch(err => {
        console.error(err);
      });
    }
  }

  const downloadFile = () => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      window.open(
        `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`
      );
      alert("Proceso Creado Exitosamente")
      //window.location.reload();
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      alert("Error al crear proceso")
    }.bind(this))
  }

  const viewFile = () => {
    axios.get(
      `https://fn.devpackgroup.com/api/v1/procces/download/?key=${inputs.file_path}`,
      {
        headers: { "Authorization": "Token " + userToken }
      }
    ).then(function (response) {
      setViewPdf(response.data.url)
      setFlagViewPdf(true)
      //window.location.reload();
    }.bind(this)).catch(function (error) {
      console.log(error.message)
      alert("Error al crear proceso")
    }.bind(this))
  }

  useEffect(() => {
    //loadJobs();
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);

  const onError = e => {
    console.log(e, "error in file-viewer");
  };

  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {action === 'add' && 'Definicion '}
                  {action === 'view' && 'Vista '}
                  {action === 'update' && 'Edición '}
                  de Procesos
                  <br />

                </MDTypography>
              </MDBox>
            </Card>
            <br />
            {
              flag &&
              <>
                <Card className="text-editor">
                  <div style={{ display: 'flex' }}>
                    <MDBox width={'30%'}   ml={2} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}> Proceso: </p>
                      <MDInput
                        type="text"
                        label="Nombre de Proceso:"
                        variant="standard"
                        fullWidth
                        placeholder="Nombre de Proceso"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setProcessName(e.target.value)}
                        InputProps={{ readOnly: action === 'view' ? true : false, }}
                        defaultValue={(action === 'update') && inputs ? inputs.processName : null}
                        value={(action === 'view') && inputs ? inputs.processName : null}
                      />

                    </MDBox>
                    <MDBox width={'30%'} ml={6} mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}> File: </p>
                      {(action === 'add' || action === 'update') &&
                        <>
                          <br></br>
                          <input
                            type="file"
                            //multiple="multiple"
                            onChange={(e) => setDocuments(e.target.files?.[0])}
                            name="pathToCP_POS"
                            id="input_pathToTechpack"
                          />
                        </>
                      }



                      {
                        (action === 'view' || action === 'update') &&
                        <>
                          <br></br>
                          <MDInput
                            type="text"
                            label="Nombre de File:"
                            variant="standard"
                            fullWidth
                            //placeholder="Nombre de Proceso"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setProcessName(e.target.value)}
                            InputProps={{ readOnly: (action === 'view' || action === 'update') ? true : false, }}
                            defaultValue={(action === 'update') && inputs ? inputs.file_path : null}
                            value={(action === 'view') && inputs ? inputs.file_path : null}
                          />
                          <br></br>
                          <MDInput
                            type="text"
                            label="Version de Proceso:"
                            variant="standard"
                            fullWidth
                            //placeholder="Nombre de Proceso"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setProcessName(e.target.value)}
                            InputProps={{ readOnly: (action === 'view' || action === 'update') ? true : false, }}
                            defaultValue={(action === 'update') && inputs ? inputs.version : null}
                            value={(action === 'view') && inputs ? inputs.version : null}
                          />
                        </>
                      }

                    </MDBox>
                    <MDBox width={'30%'} ml={6} mt={6} mb={2}>
                      <MDButton
                        className="btn-simple"
                        color="info"
                        size="sm"
                        onClick={() => {
                          viewFile();
                          //this.toggleUploadPOModal();
                        }}
                      >
                        <i className="tim-icons icon-cloud-upload-94" />
                        View PROCESS File
                      </MDButton>
                    </MDBox>
                  </div>


                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <MDBox width={'25%'} mb={2}>
                      <MDButton
                        variant='gradient'
                        color='error'
                        //style={{ marginRight: '10px' }}
                        sx={{ height: '100%' }}
                        onClick={() => window.location.replace("/processes_list")}
                      >
                        Cancelar
                      </MDButton>
                    </MDBox>
                    <MDBox width={'25%'}  mb={2}>
                      <MDButton
                        className="btn-simple"
                        color="info"
                        size="sm"
                        onClick={() => {
                          action === 'add' ? createProcess() : action === 'update' ? updateProcess() : downloadFile();
                          //this.toggleUploadPOModal();
                        }}
                      >
                        <i className="tim-icons icon-cloud-upload-94" />
                        &nbsp;{(action === 'add' || action === 'update') ? 'Upload PROCESS File' : 'Download PROCESS File'}
                      </MDButton>

                    </MDBox>
                  </div>


                  {
                    flagviewPdf === true &&
                    <>

                      <FileViewer fileType="docx" filePath={viewPdf.split("?")[0]} onError={onError} />

                      {console.log(viewPdf, 'ciecs')}
                    </>
                  }
                  {/*  <QuillEditor /> */}
                </Card>
                <br />
                <br />
              </>
            }
          </>
      }
    </>
  );
}
export default ProcessDefinition;
