const form = {
    formId: "new-ship-form",
    formField: {
        client: {
            name: "client",
            label:"Client",
            type:"text",
            errorMsg:"Campo requerido"
        },
        make: {
            name: "make",
            label:"Make",
            type:"text",
            errorMsg:"Campo requerido"
        },
        register: {
            name: "register",
            label:"Register",
            type:"text",
            errorMsg:"Campo requerido"
        },
        serial_number: {
            name: "serial_number",
            label:"Serial",
            type:"text",
            errorMsg:"Campo requerido"
        },
        year: {
            name: "year",
            label:"Year",
            type:"number",
            errorMsg:"Campo requerido"
        },
        fly_hours: {
            name: "fly_hours",
            label:"Fly Hours",
            type:"number",
            errorMsg:"Campo requerido"
        },

        warnings: {
            name: "warnings",
            label:"Warnings.",
            type:"text",
            errorMsg:"Campo requerido"
        },
        model: {
            name: "model",
            label:"Model",
            type:"text",
            errorMsg:"Campo requerido"
        },
        ATTA_code: {
            name: "ATTA_code",
            label:"ATTA_ Code",
            type:"text",
            errorMsg:"Campo requerido"
        },
        cicle: {
            name: "cicle",
            label:"Cicle",
            type:"text",
            errorMsg:"Campo requerido"
        },
    },
}

export default form;