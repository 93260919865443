
import ClientsList from "../Clients/List";
import SuppliersList from "../suppliers/List";

const ClientsAndSuppliers = () => {
    return(
        <div>
            <ClientsList />
            <br/>
            <SuppliersList />
        </div>
    );
}

export default ClientsAndSuppliers;