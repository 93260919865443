import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  getShips,
  deleteShip,
  getShipParts,
  addShipPart,
  formatShipParts,
  updateShipPart,
  deleteShipPart,
  fetchShipMotors,
  fetchShipAircrafts,
} from '../../Services/ShipService';

import {
  shipsHeaders,
  shipPartsHeaders,
  shipMotorHeaders,
  shipAircraftsHeaders,
} from '../../utils/tableHeaders';
import { buildData } from '../../utils/tableData';

import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { Typography, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import MDBox from '../../components/MDBox';
import DataTable from '../../components/DataTable';
import DeleteDialog from '../../components/DeleteDialog';
import ContentDialog from '../../components/ContentDialog';
import SnackNotification from '../../components/SnackNotification';

const Ships = () => {

    const navigate = useNavigate();

  // Ships states
  const [ships, setShips] = useState([]);
  const [currentShipPage, setCurrentShipPage] = useState(1);
  const [totalShipPages, setTotalShipPages] = useState();
  const [selectedShipId, setSelectedShipId] = useState(null);
  const [loadingShips, setLoadingShips] = useState(true);

  // Motor States
  const [motors, setMotors] = useState(null);
  const [currentMotorPage, setCurrentMotorPage] = useState(1);
  const [totalMotorPages, setTotalMotorPages] = useState();
  const [loadingMotors, setLoadingMotors] = useState(false);

  // Aircrafts States
  const [aircrafts, setAircrafts] = useState(null);
  const [currentAircraftPage, setCurrentAircraftPage] = useState(1);
  const [totalAircraftPages, setTotalAircraftPages] = useState();
  const [loadingAircrafts, setLoadingAircrafts] = useState(false);


  const [shiptToDelete, setShipToDelete] = useState();
  const [openDeteleDialog, setOpenDeleteDialog] = React.useState(false);

  const [openCreate, setopenCreate] = useState(false);
  const [inputs, setInputs] = useState({});
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [action, setAction] = useState();

  const loadShips = async (page, filter, value) => {
    try {
      const { data } = await getShips(page, filter, value);
      setShips(buildData(data.ships, shipsHeaders()));
      setCurrentShipPage(parseInt(data.current_page));
      setTotalShipPages(data.pages);
      setLoadingShips(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingShips(false);
    }
  };

  const loadMotors = async (page, id) => {
    setLoadingMotors(true);
    try {
      const { data } = await fetchShipMotors(id, page);
      setMotors(buildData(data.motors, shipMotorHeaders()));
      setCurrentMotorPage(parseInt(data.current_page));
      setTotalMotorPages(data.pages);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingMotors(false);
    }
  };

  const loadAircrafts = async (page, id) => {
    setLoadingAircrafts(true);
    try {
      const { data } = await fetchShipAircrafts(id, page);
      setAircrafts(buildData(data.aircrafts, shipAircraftsHeaders()));
      setCurrentAircraftPage(parseInt(data.current_page));
      setTotalAircraftPages(data.pages);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAircrafts(false);
    }
  };

  // const loadShipParts = (page, shipId) =>{
  //     getShipParts(shipId, page).then(resp => {
  //         console.log(resp.data)
  //         setPieces(buildData(resp.data.ship_parts, shipPartsHeaders()))
  //         setCurrentSecondPage(parseInt(resp.data.current_page))
  //         setTotalSecondPages(resp.data.pages)
  //         setSecondLoading(false)
  //     }).catch((err) => {
  //         console.error(err);
  //     })
  // }

  // Menu and Filters
  const [filter, setFilter] = useState();
  const [filterLabel, setFilterLabel] = useState('Filtros');
  const [valueFilter, setValueFilter] = useState();

  const filters = [
    { label: 'No.', value: 'id' },
    { label: 'Registro', value: 'register' },
    { label: 'Model', value: 'model' },
    { label: 'Serial', value: 'serial_number' },
  ];

  const getFilterLabel = (value) => {
    let label;
    filters.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };

  const handleSearch = (value) => {
    setValueFilter(value);
    loadShips(1, filter, value);
  };

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => {
    setMenu(null);
  };
  const handleChangeFilter = (value) => {
    setFilter(value);
    setFilterLabel(getFilterLabel(value));
    setMenu(null);
    closeMenu();
  };
  const clearFilter = () => {
    setMenu(null);
    setFilter();
    setFilterLabel('Filtros');
    loadShips(1);
    closeMenu();
  };

  //Pagination Ships
  const handleChangePageShips = (event, value) => {
    setCurrentShipPage(value);
    loadShips(value, filter, valueFilter);
  };

  const handleChangePageMotors = (event, value) => {
    setCurrentMotorPage(value);
    loadMotors(value, selectedShipId);
  };

  // const handleSecondPagination = (event, value) => {
  //     setCurrentSecondPage(value)
  //     loadShipParts(value, idSelected)
  // }

  const deleteRegister = (ship) => {
    setShipToDelete(ship);
    setOpenDeleteDialog(true);
    setAction('deleteShip');
  };
  const editRegister = (ship) => {
    navigate(`/ships/update/${ship.id}`);
  };
  const viewRegister = (ship) => {
    navigate(`/ships/view/${ship.id}`);
  };

  // const selectRegister = (ship) => {
  //     setIdSelected(ship.id)
  //     loadShipParts(1, ship.id)
  // }

  //Calbacks to Delete dialog
  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const successDeleteDialog = () => {
    if (action === 'deleteShip') {
      deleteShip(shiptToDelete.id)
        .then((resp) => {
          setShipToDelete();
          loadShips(currentShipPage, filter, valueFilter);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // else if(action === 'deletePart'){
    //     deleteShipPart(partToDelete.id)
    //     .then((resp) => {
    //         setPartToDelete()
    //         loadShipParts(currentSecondPage, idSelected);
    //     })
    //     .catch((err) => {
    //         console.error(err);
    //     });
    // }

    setOpenDeleteDialog(false);
  };

  const handleChange = (event, type) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const closeModals = () => {
    setopenCreate(false);
    setInputs({});
  };

  // const createPart = () => {
  //     if(action === 'update'){
  //         console.log(inputs)
  //         updateShipPart(inputs, inputs.id).then(resp => {
  //             console.log(resp.data)
  //             setOpenSuccess(true)
  //             loadShipParts(1, idSelected)
  //             closeModals()
  //         }).catch((err) => {
  //             console.error(err);
  //             setOpenError(true)
  //         })
  //     }else{
  //         addShipPart(inputs).then(resp => {
  //             console.log(resp.data)
  //             setOpenSuccess(true)
  //             loadShipParts(1, idSelected)
  //             closeModals()
  //         }).catch((err) => {
  //             console.error(err);
  //             setOpenError(true)
  //         })
  //     }

  // }

  const viewPart = (value) => {
    console.log(value);
    setopenCreate(true);
    setInputs(formatShipParts(value));
    setAction('view');
  };

  const updatePart = (value) => {
    setopenCreate(true);
    setInputs(formatShipParts(value));
    setAction('update');
  };

  // const deletePart = (value) => {
  //     setPartToDelete(value)
  //     setOpenDeleteDialog(true);
  //     setAction('deletePart')
  // }

  const onSelectShipHandler = (ship) => {
    setSelectedShipId(ship.id);
  };

  const onSelectAirCraftHandler = (type, airCraft) => {
    if (type === 'edit'){
        navigate(`/ships/aircraft/update/${airCraft.id}`);
    }else if (type === 'view'){
        navigate(`/ships/aircraft/view/${airCraft.id}`);
    }
  };

  useEffect(() => {
    loadShips(1);
  }, []);

  useEffect(() => {
    loadMotors(1, selectedShipId);
    loadAircrafts(1, selectedShipId);
  }, [selectedShipId]);


  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {filters.map((option) => (
        <MenuItem
          key={option.value}
          onClick={() => handleChangeFilter(option.value)}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
      {/* <MenuItem onClick={()=> handleChange('nombre')} value="nombre" >Nombre</MenuItem>
          <MenuItem onClick={()=> handleChange('correo')} value="correo" >Correo</MenuItem> */}
      <Divider sx={{ margin: '0.5rem 0' }} />
      <MenuItem onClick={clearFilter}>
        <MDTypography variant='button' color='error' fontWeight='regular'>
          Eliminar filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      {/* Ships */}
      <Typography variant='h4' component='div'>
        Aircraft Template
      </Typography>

      <MDBox my={3}>
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          mb={2}
        >
          <Link to='/ships/create'>
            <MDButton variant='gradient' color='info'>
              Agregar
            </MDButton>
          </Link>
          <MDBox display='flex'>
            <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color='dark'
              onClick={openMenu}
            >
              {filterLabel}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark'>
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {ships.rows !== undefined && ships.rows.length > 0 && (
            <>
              <DataTable
                handleSearch={handleSearch}
                useActions
                useView
                useEdit
                useDelete
                useSelect
                selectAction={onSelectShipHandler}
                editAction={editRegister}
                viewAction={viewRegister}
                deleteAction={deleteRegister}
                table={ships}
                showTotalEntries={false}
                entriesPerPage={false}
                canSearch
              />
              <MDBox ml={1}>
                <Pagination
                  sx={{ marginTop: '20px', marginBottom: '20px' }}
                  color='info'
                  count={totalShipPages}
                  page={currentShipPage}
                  onChange={handleChangePageShips}
                />{' '}
              </MDBox>{' '}
            </>
          )}
          {loadingShips && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
              }}
            >
              <CircularProgress color='info' size={80} />
            </Box>
          )}
          {ships.rows !== undefined && ships.rows.length === 0 && (
            <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
              No Existen registros
            </Typography>
          )}
        </Card>
      </MDBox>

      {/* Motors */}

      {motors && (
        <>
          <Typography
            variant='h4'
            component='div'
            style={{ marginTop: 25, marginBottom: 15 }}
          >
            Motores ship: {selectedShipId}
          </Typography>

          <Card>
            {motors.rows !== undefined && motors.rows.length > 0 && (
              <>
                <DataTable
                  //   handleSearch={handleSearch}
                  //   useActions
                  //   useView
                  //   useEdit
                  //   useDelete
                  // useSelect
                  // selectAction={onSelectAirCraftHandler}
                  //   editAction={editRegister}
                  //   viewAction={viewRegister}
                  //   deleteAction={deleteRegister}
                  table={motors}
                  showTotalEntries={false}
                  entriesPerPage={false}
                  canSearch
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={totalMotorPages}
                    page={currentMotorPage}
                    onChange={handleChangePageMotors}
                  />{' '}
                </MDBox>{' '}
              </>
            )}
            {loadingMotors && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress color='info' size={80} />
              </Box>
            )}
            {motors?.rows !== undefined && motors?.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen motores
              </Typography>
            )}
          </Card>
        </>
      )}

      {/* Aircrafts */}

      {aircrafts && (
        <>
          <Typography
            variant='h4'
            component='div'
            style={{ marginTop: 25, marginBottom: 15 }}
          >
            Aircrafts ship: {selectedShipId}
          </Typography>

          <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          mb={2}
        >
          <Link to='/ships/aircraft/create'>
            <MDButton variant='gradient' color='info'>
              Agregar
            </MDButton>
          </Link>

          {/* <MDBox display='flex'>
            <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color='dark'
              onClick={openMenu}
            >
              {filterLabel}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark'>
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox> */}
        </MDBox>

          <Card>
            {aircrafts.rows !== undefined && aircrafts.rows.length > 0 && (
              <>
                <DataTable
                  //   handleSearch={handleSearch}
                  useActions
                  useView
                    useEdit
                  //   useDelete
                //   useSelect
                //   selectAction={onSelectAirCraftHandler}
                    editAction={onSelectAirCraftHandler.bind(this, 'edit')}
                  viewAction={(onSelectAirCraftHandler.bind(this, 'view'))}
                  //   deleteAction={deleteRegister}
                  table={aircrafts}
                  showTotalEntries={false}
                  entriesPerPage={false}
                  canSearch
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={totalAircraftPages}
                    page={currentAircraftPage}
                    onChange={handleChangePageMotors}
                  />{' '}
                </MDBox>{' '}
              </>
            )}
            {loadingAircrafts && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
              >
                <CircularProgress color='info' size={80} />
              </Box>
            )}
            {aircrafts?.rows !== undefined && aircrafts?.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen motores
              </Typography>
            )}
          </Card>
        </>
      )}

      <MDBox my={3}>
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          mb={2}
        >
          {/* idSelected &&
                        <MDButton variant="gradient" color="info" onClick={()=>{setopenCreate(true); setInputs({ ship:idSelected })}}>
                            Agregar 
                        </MDButton>
                    */}
          {/* <MDBox display="flex">
                        <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                        Filtros&nbsp;
                        <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                        <MDButton variant="outlined" color="dark">
                            <Icon>description</Icon>
                            &nbsp;export csv
                        </MDButton>
                        </MDBox>
                    </MDBox> */}
        </MDBox>
        {/* <Card>
                    { pieces.rows !== undefined && pieces.rows.length > 0 && 
                        <><DataTable useActions  useView useEdit useDelete viewAction={viewPart} editAction={updatePart} deleteAction={deletePart} table={pieces} showTotalEntries={false} entriesPerPage={false} 
                        /> 
                        <MDBox ml={1}><Pagination sx={{ marginTop: "20px", marginBottom: "20px" }} color="info" count={totalSecondPages} page={currentSecondPage} onChange={handleSecondPagination} /> </MDBox> </>}  
                    { secondLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}><CircularProgress color="info" size={80} /></Box> }
                    { pieces.rows !== undefined &&  pieces.rows.length === 0 && <Typography variant="h4" component="div" sx={{ margin: "100px" }}>No Existen registros</Typography> }
                </Card> */}
      </MDBox>

      <ContentDialog
        open={openCreate}
        title={'Part'}
        closeCallback={closeModals}
        maxWidth='md'
        fullWidth={true}
      >
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.inventory_detail || ''}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  id='inventory_detail'
                  InputProps={{
                    name: 'inventory_detail',
                    disabled: action === 'view',
                  }}
                  label='Item'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.instalation_date || ''}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  id='instalation_date'
                  InputProps={{
                    name: 'instalation_date',
                    type: 'date',
                    disabled: action === 'view',
                  }}
                  label='instalation date'
                  variant='outlined'
                  focused
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.link || ''}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  id='link'
                  InputProps={{ name: 'link', disabled: action === 'view' }}
                  label='link'
                  variant='outlined'
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1.5}>
                <TextField
                  value={inputs.next_date || ''}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  id='next_date'
                  InputProps={{
                    name: 'next_date',
                    type: 'date',
                    disabled: action === 'view',
                  }}
                  label='next date'
                  variant='outlined'
                  focused
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    <MDBox mt={2}>
                        <MDButton
                            variant="gradient"
                            color="success"
                            type="submit"
                            onClick={createPart}
                            disabled={action === 'view'}
                            >
                                Aceptar
                        </MDButton>
                    </MDBox>
                </MDBox> */}
      </ContentDialog>

      <DeleteDialog
        open={openDeteleDialog}
        message='Esta seguro de eliminar este registro?'
        successCalback={successDeleteDialog}
        cancelCallback={closeDeleteDialog}
      />
      <SnackNotification
        type='error'
        message='Ha ocurrido un error'
        closeCallback={console.log()}
        openFlag={openError}
        setOpenFlag={setOpenError}
      />
      <SnackNotification
        type='success'
        message='Parte creado'
        closeCallback={console.log()}
        openFlag={openSuccess}
        setOpenFlag={setOpenSuccess}
      />
    </div>
  );
};

export default Ships;
