import checkout from "./form";

const {
    formField: {
        ship,
        serial,
        hours_limit,
        hours_performed,
        hours_instalation,
        hours_warning,
        hours_next_due,
        calendar_months,
        calendar_performed,
        calendar_months_instalation,
        calendar_months_warning,
        calendar_next_due,
    }
} = checkout;

export const initialValues = {
    [ship.name]: {label: "", id: ""},
    [serial.name]:"",
    [hours_limit.name]:"",
    [hours_performed.name]:"",
    [hours_instalation.name]:"",
    [hours_warning.name]:"",
    [hours_next_due.name]:"",
    [calendar_months.name]:"",
    [calendar_performed.name]: new Date().getFullYear() + "-" + ( new Date().getMonth() < 10 ? ("0" + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1) ) + "-" + ( new Date().getDate() < 10 ? ("0" + new Date().getDate()) : new Date().getDate() ) ,
    [calendar_months_instalation.name]:"",
    [calendar_months_warning.name]:"",
    [calendar_next_due.name]: new Date().getFullYear() + "-" + ( new Date().getMonth() < 10 ? ("0" + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1) ) + "-" + ( new Date().getDate() < 10 ? ("0" + new Date().getDate()) : new Date().getDate() ) ,
}

export const initialValuesFromObj = (obj) => {
    return {
        [ship.name]: {label: obj.ship.serial_number, id: obj.ship.id},
        [serial.name]: obj.serial,
        [hours_limit.name]: obj.hours_limit,
        [hours_performed.name]: obj.hours_performed,
        [hours_instalation.name]: obj.hours_instalation,
        [hours_warning.name]: obj.hours_warning,
        [hours_next_due.name]: obj.hours_next_due,
        [calendar_months.name]: obj.calendar_months,
        [calendar_performed.name]: obj.calendar_performed,
        [calendar_months_instalation.name]: obj.calendar_months_instalation,
        [calendar_months_warning.name]: obj.calendar_months_warning,
        [calendar_next_due.name]: obj.calendar_next_due,

    }
}

// calendar_months: 10
// calendar_months_instalation: 0
// calendar_months_warning: 10
// calendar_next_due: "2022-08-29"
// calendar_performed: "2022-08-29"
// created_at: "2022-08-30T05:58:55.855262Z"
// hours_instalation: "10.00"
// hours_limit: "10.00"
// hours_next_due: "10.00"
// hours_performed: "10.00"
// hours_warning: "10"
// id: 7
// serial: "123123"