import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';
import {
  addLogs,
  getAllDeffects,
  getAllLogs,
} from '../../../Services/MainBoard';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';

// Wizard page components
// import { addDeffect } from '../../../Services/MainBoard';
import { buildData } from '../../../utils/tableData';
import { logsHeaders } from '../../../utils/tableHeaders';
import { Pagination, Typography } from '@mui/material';
import DataTable from '../../../components/DataTable';
import LogForm from './components/LogForm';

// import {
//   addAssembly,
//   getAssemblyById,
//   updateAssembly,
// } from '../../../Services/ShipService';

const CreateLogs = () => {
  const { id: aircraftId } = useParams();
  const { formId, formField } = form;

  const [state, setState] = useState('create');

  const [inputs, setInputs] = useState();

  const [logs, setlogs] = useState(null);
  const [currentlogsPage, setCurrentlogsPage] = useState(1);
  const [totallogsPages, setTotallogsPages] = useState();

  const navigate = useNavigate();

  // get search params
  const [searchparams] = useSearchParams();

  const handleSubmit = async (values, actions) => {
    if (state === 'create') {
      const newdata = { ...values, aircraft: aircraftId };
      const { data } = await addLogs(newdata);
    }

    actions.resetForm();
    loadLogs(1);
  };

  const loadLogs = async (page) => {
    const { data } = await getAllLogs(page, aircraftId);
    setlogs(buildData(data.flight_logs, logsHeaders()));
    setCurrentlogsPage(parseInt(data.current_page));
    setTotallogsPages(data.pages);
  };

  const handleChangePageLogs = (event, value) => {
    setCurrentlogsPage(value);
    loadLogs(value);
  };

  useEffect(() => {
    loadLogs(1);
  }, []);

  return (
    <MDBox>
      <Grid container justifyContent='center'>
        <Grid item>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <LogForm
                      isView={false}
                      isUpdate={false}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <MDButton
                        variant='gradient'
                        color='error'
                        sx={{ height: '100%' }}
                      >
                        Cancelar
                      </MDButton>
                      {/* </Link> */}
                      <MDButton
                        disabled={false}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>

      {logs && (
        <>
          <Typography
            variant='h4'
            component='div'
            style={{ marginTop: 25, marginBottom: 15, textAlign: 'center' }}
          >
            Logs list
          </Typography>

          <Card>
            {logs.rows !== undefined && logs.rows.length > 0 && (
              <>
                <DataTable
                  //   handleSearch={handleSearch}
                  // useActions
                  useView
                  useEdit
                  // useDelete
                  // useSelect
                  // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}
                  editAction={(data) =>
                    navigate(
                      `/ships/shipparts/update/${data.id}?aircraft=${inputs?.id}`
                    )
                  }
                  viewAction={(data) =>
                    navigate(`/ships/shipparts/view/${data.id}`)
                  }
                  // deleteAction={deleteRegister}
                  table={logs}
                  showTotalEntries={false}
                  entriesPerPage={false}
                  // canSearch
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    color='info'
                    count={totallogsPages}
                    page={currentlogsPage}
                    onChange={handleChangePageLogs}
                  />{' '}
                </MDBox>{' '}
              </>
            )}
            {/* {shipPartsLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                    }}
                  >
                    <CircularProgress color='info' size={80} />
                  </Box>
                )} */}
            {logs?.rows !== undefined && logs?.rows.length === 0 && (
              <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
                No Existen logs
              </Typography>
            )}
          </Card>
        </>
      )}
    </MDBox>
  );
};

export default CreateLogs;
