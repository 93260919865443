import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    inventory_detail,
    instalation_date,
    part_number,
    active,
    link,
    next_date,
  },
} = checkout;

const validations = Yup.object().shape({
    [inventory_detail.name]: Yup.string().required(inventory_detail.errorMsg),
    [instalation_date.name]: Yup.string().required(instalation_date.errorMsg),
    [part_number.name]: Yup.string().required(part_number.errorMsg),
    [active.name]: Yup.string().required(active.errorMsg),
    [link.name]: Yup.string().required(link.errorMsg),
    [next_date.name]: Yup.string().required(next_date.errorMsg),
});

export default validations;
