import React, { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "./formLayout";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import { getCarFormID, getProcess } from "../../Services/formServices";
import Loader from "../../components/Loader";
import { getAllUsers } from "../../Services/authService";

function JobDescriptionForm({ action }) {
  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [number, setnumber] = useState();
  const [type, setType] = useState([]);
  const [source, setsource] = useState([]);
  const [process, setprocess] = useState();
  const [priority, setPriority] = useState([]);
  const [desc_issue, setdesc_issue] = useState();
  const [assigned_to, setassigned_to] = useState();
  const [date_assigned, setdate_assigned] = useState();
  const [respondy_by, setrespondy_by] = useState();
  const [contaiment, setcontaiment] = useState();
  const [root_cause, setroot_cause] = useState();
  const [action_plan, setaction_plan] = useState();
  const [date_action, setdate_action] = useState();
  const [name_action, setname_action] = useState();
  const [effectiveness, seteffectiveness] = useState();
  const [date_verified, setdate_verified] = useState();
  const [result, setresult] = useState();
  const [notes, setnotes] = useState();

  const [ships, setShips] = useState([]);
  const [user, setUser] = useState([]);
  const params = useParams();
  const userToken = localStorage.getItem('Token') || '';
  const [optionsV, setOptions] = useState([]);

  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const [asing, setAsing] = useState([]);
  const [flag, setFlag] = useState(false);


  const jsonAnswers = {
    name: name,
    date: date,
    number: number,
    type: type,
    source: source,
    process: process,
    priority: priority,
    desc_issue: desc_issue, //array of objects
    assigned_to: assigned_to, //array of objects
    date_assigned: date_assigned, //array of objects
    respondy_by: respondy_by, //array of objects
    contaiment: contaiment, //array of objects
    root_cause: root_cause,
    action_plan: action_plan,
    date_action: date_action,
    name_action: name_action,
    effectiveness: effectiveness,
    date_verified: date_verified,
    result: result,
    notes: notes,

  };

  const updateCar = () => {
    let ver = null
    const pilotdata = new FormData();
    process && (process.length > 0 ? process.map(index => {
      //ver = ver + index.value + ','
      pilotdata.append('process', index.value);
    })
      :
      pilotdata.append('process', null)
    )
    type && pilotdata.append('type', type);
    source && pilotdata.append('source', source);
    priority && pilotdata.append('priority', priority);
    desc_issue && pilotdata.append('description', desc_issue);
    contaiment && pilotdata.append('inmediate_correction', contaiment);
    root_cause && pilotdata.append('root_problem', root_cause);

    action_plan && pilotdata.append('action_plan', action_plan);

    result && pilotdata.append('result', result);



    axios.patch(
      `https://fn.devpackgroup.com/api/v1/car/${params.id}/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      alert("Car Form Creado Exitosamente")
      window.location.replace("/car_form_table");
    }.bind(this)).catch(function (error) {
      alert(error.message)
      alert("Error al Actualizar Car Form")
    }.bind(this))
  }

  const createPositionDescription = () => {
    let ver = null
    const pilotdata = new FormData();
    process && (process.length > 0 ? process.map(index => {
      //ver = ver + index.value + ','
      pilotdata.append('process', index.value);
    })
      :
      pilotdata.append('process', null)
    )
    type && pilotdata.append('type', type);
    source && pilotdata.append('source', source);
    priority && pilotdata.append('priority', priority);
    desc_issue && pilotdata.append('description', desc_issue);
    contaiment && pilotdata.append('inmediate_correction', contaiment);
    root_cause && pilotdata.append('root_problem', root_cause);

    action_plan && pilotdata.append('action_plan', action_plan);

    result && pilotdata.append('result', result);



    axios.post(
      `https://fn.devpackgroup.com/api/v1/car/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      alert("Car Form Actualizado Exitosamente")
      window.location.replace("/car_form_table");
    }.bind(this)).catch(function (error) {
      alert(error.message)
      alert("Error al Actualizar Certificacion")
    }.bind(this))
  };

  const sendApprove = () => {
    let ver = null
    let flag = ''
    const pilotdata = new FormData();
    if (inputs.approvalStatus === 'P') {
      flag = 'A'
      asing && pilotdata.append('asing', asing[0].value);
    }
    else if (inputs.approvalStatus === 'A') {
      flag = 'PP'
      contaiment && pilotdata.append('inmediate_correction', contaiment);
      root_cause && pilotdata.append('root_problem', root_cause);
      action_plan && pilotdata.append('action_plan', action_plan);
    }
    else if (inputs.approvalStatus === 'PP') {
      flag = 'D'
      pilotdata.append('result', result);
      pilotdata.append('notes', notes)
    }




    pilotdata.append('id', params.id);
    pilotdata.append('flag', flag);






    axios.post(
      `https://fn.devpackgroup.com/api/v1/car/change_status/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      alert("Car Form Actualizado Exitosamente")
      window.location.replace("/car_form_table");
    }.bind(this)).catch(function (error) {
      alert(error.message)
      alert("Error al Actualizar Certificacion")
    }.bind(this))
  };

  const Typeanswers = [
    { id: 1, label: "Corrective Action (existing issue)", value: "Corrective Action (existing issue)" },
    { id: 2, label: "Preventive Action (potential issue)", value: "Preventive Action (potential issue)" },
    { id: 3, label: "Supplier / Subcontractor Feedback", value: "Supplier / Subcontractor Feedback" },
  ];

  const Sourceanswers = [
    { id: 1, label: "Employee Feedback", value: "Employee Feedback" },
    { id: 2, label: "Customer Feedback", value: "Customer Feedback" },
    { id: 3, label: "Supplier / Subcontractor Feedback", value: "Supplier / Subcontractor Feedback" },
    { id: 4, label: "External Audit Finding", value: "External Audit Finding" },
    { id: 5, label: "Internal Audit Finding", value: "Internal Audit Finding" },
    { id: 6, label: "Management Review Action Item", value: "Management Review Action Item" },
  ];

  const Priorityanswers = [
    { id: 1, label: "Low", value: "Low" },
    { id: 2, label: "Medium", value: "Medium" },
    { id: 3, label: "High", value: "High" },
    { id: 4, label: "Urgent/Critical", value: "Urgent/Critical" },
  ];

  const resultyanswers = [
    { id: 1, label: "PASS", value: "PASS" },
    { id: 2, label: "FAIL: Returned for Further Processing", value: "FAIL: Returned for Further Processing" },

  ];


  useEffect(() => {
    loadProcess();
    loadUsers();
    //console.log(action, params, 'data')
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const loadProcess = () => {
    getProcess()
      .then((resp) => {
        var data = resp.data.map(index => {
          return {
            label: index.processName,
            value: index.id
          }
        })
        setShips(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        //setLoading(false);
      });
  };
  const loadUsers = () => {
    getAllUsers()
      .then((resp) => {
        var data = resp.data.map(index => {
          return {
            label: index.name,
            value: index.id
          }
        })
        setUser(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        //setLoading(false);
      });
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getCarFormID(params.id).then(resp => {
        setInputs(resp.data)
        var data = resp.data.process.map(index => {
          optionsV.push({
            label: index.processName,
            value: index.id
          })
        })
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }

  return (
    <CoverLayout image={bgImage}>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  CAR Form
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <br />
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                {
                  <>
                    <MDBox mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Type:{" "}
                      </p>
                      <br />
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {Typeanswers.map((index, key) => (
                          <>
                            {action === 'view' || action === 'update' ?
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                checked={(action === 'view' || action === 'update') && inputs && inputs.type == index.label ? true : false}
                                control={
                                  <Radio
                                    onClick={() => {
                                      type.push(index.value);
                                      console.log(index.value, type);
                                    }}
                                  />
                                }
                              />
                              :
                              <FormControlLabel
                                style={{ display: "flex", flexDirection: "row" }}
                                value={index.value}
                                label={index.label}
                                disabled={
                                  inputs && inputs.approvalStatus !== 'D'
                                    || inputs && inputs.approvalStatus !== 'A'
                                    || inputs && inputs.approvalStatus !== 'P' ? true : false
                                }
                                control={
                                  <Radio
                                    onClick={() => {
                                      type.push(index.value);
                                      console.log(index.value, type);
                                    }}
                                  />
                                }
                              />

                            }

                          </>
                        ))}
                      </RadioGroup>
                      {/*   <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <li>
                <Checkbox
                  onChange={() => (setType('Corrective Action (existing issue)'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Corrective Action (existing issue)
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setType('Preventive Action (potential issue)'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Preventive Action (potential issue)
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setType('Opportunity for Improvement / Suggestion'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Opportunity for Improvement / Suggestion
                </FormLabel>
              </li>
            </ul> */}
                    </MDBox>
                    <br />
                    <MDBox mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}> Source: </p>
                      <br />
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {Sourceanswers.map((index, key) => (
                          <>
                            {
                              action === 'view' || action === 'update' ?
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  checked={(action === 'view' || action === 'update') && inputs && inputs.source == index.label ? true : false}
                                  control={
                                    <Radio
                                      onClick={() => {
                                        source.push(index.value);
                                        console.log(index.value, source);
                                      }}
                                    />
                                  }
                                />
                                :
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  disabled={
                                    inputs && inputs.approvalStatus !== 'D'
                                      || inputs && inputs.approvalStatus !== 'A'
                                      || inputs && inputs.approvalStatus !== 'P' ? true : false
                                  }
                                  control={
                                    <Radio
                                      onClick={() => {
                                        source.push(index.value);
                                        console.log(index.value, source);
                                      }}
                                    />
                                  }
                                />
                            }

                          </>
                        ))}
                      </RadioGroup>
                      <MDInput
                        type="text"
                        label="Especifique otra"
                        variant="standard"
                        fullWidth
                        placeholder="Especifique otra"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => (setsource(e.target.value))}
                      />
                      {/* <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <li>
                <Checkbox
                  onChange={() => (setsource('Employee Feedback'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Employee Feedback
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setsource('Customer Feedback'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Customer Feedback
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setsource('Supplier / Subcontractor Feedback'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Supplier / Subcontractor Feedback
                </FormLabel>
              </li>

              <li>
                <Checkbox
                  onChange={() => (setsource('External Audit Finding'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  External Audit Finding
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setsource('Internal Audit Finding'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Internal Audit Finding
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setsource('Management Review Action Item'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Management Review Action Item
                </FormLabel>
              </li>
              <li>
                <br />
                <MDInput
                  type="text"
                  label="Especifique otra"
                  variant="standard"
                  fullWidth
                  placeholder="Especifique otra"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => (setsource(e.target.value))}
                />
              </li>
            </ul> */}
                    </MDBox>
                    <br />
                    <MDBox mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}> Process: </p>
                      <br />
                      {action === 'view' || action === 'update' ?

                        <Select
                          placeholder="Process..."
                          options={ships}
                          isMulti
                          isDisabled={action === 'view' || action === 'update' ? true : false}
                          defaultValue={(action === 'view' || action === 'update') ? optionsV : null}
                          onChange={(e) => {
                            //console.log(e)
                            setprocess(e)
                          }}
                        />
                        :
                        <Select
                          placeholder="Process..."
                          options={ships}
                          isMulti
                          isDisabled={
                            inputs && inputs.approvalStatus !== 'D'
                              || inputs && inputs.approvalStatus !== 'A'
                              || inputs && inputs.approvalStatus !== 'P' ? true : false
                          }
                          //defaultValue={(action === 'view' || action === 'update') ? optionsV : null}
                          onChange={(e) => {
                            //console.log(e)
                            setprocess(e)
                          }}
                        />

                      }

                    </MDBox>
                    <br />
                    <MDBox mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Priority:{" "}
                      </p>
                      <br />
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {Priorityanswers.map((index, key) => (
                          <>
                            {
                              action === 'view' || action === 'update' ?
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  checked={(action === 'view' || action === 'update') && inputs && inputs.priority == index.label ? true : false}

                                  control={
                                    <Radio
                                      onClick={() => {
                                        priority.push(index.value);
                                        console.log(index.value, priority);
                                      }}
                                    />
                                  }
                                />
                                :
                                <FormControlLabel
                                  style={{ display: "flex", flexDirection: "row" }}
                                  value={index.value}
                                  label={index.label}
                                  disabled={
                                    inputs && inputs.approvalStatus !== 'D'
                                      || inputs && inputs.approvalStatus !== 'A'
                                      || inputs && inputs.approvalStatus !== 'P' ? true : false
                                  }
                                  control={
                                    <Radio
                                      onClick={() => {
                                        priority.push(index.value);
                                        console.log(index.value, priority);
                                      }}
                                    />
                                  }
                                />
                            }
                          </>
                        ))}
                      </RadioGroup>
                      {/*    <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <li>
                <Checkbox
                  onChange={() => (setPriority('Low'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Low
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setPriority('Medium'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Medium
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setPriority('High'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  High
                </FormLabel>
              </li>
              <li>
                <Checkbox
                  onChange={() => (setPriority('Urgent/Critical'))}
                />
                <FormLabel style={{ fontSize: "1.8vh" }}>
                  Urgent/Critical
                </FormLabel>
              </li>
            </ul> */}
                    </MDBox>
                    <br />
                    <MDBox mb={2}>
                      <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                        {" "}
                        Describe the issue or problem in detail. :{" "}
                      </p>
                      <br />
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        style={{
                          resize: "None",
                          width: "100%",
                          border: "None",
                          borderRadius: "8px",
                          padding: "2vh",
                          fontFamily: "Arial",
                        }}
                        disabled={
                          inputs && inputs.approvalStatus !== 'D'
                            || inputs && inputs.approvalStatus !== 'A'
                            || inputs && inputs.approvalStatus !== 'P' ? true : false
                        }
                        defaultValue={(action === 'view' || action === 'update') ? inputs && inputs.description : ''}
                        onChange={(e) =>
                          (setdesc_issue(e.target.value))
                        }
                      ></textarea>
                      <br />
                    </MDBox>
                  </>}
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Asing To: </p>
                  <br />
                  {action === 'view' || action === 'update' ?

                    <Select
                      placeholder="User..."
                      options={user}
                      isMulti
                      isDisabled={(action === 'view' || action === 'update') &&
                        inputs && inputs.approvalStatus === 'D'
                        || inputs && inputs.approvalStatus === 'A'
                        || inputs && inputs.approvalStatus === 'PP' ? true : false
                      }
                      defaultValue={(action === 'view' || action === 'update') ? user.find(label => label.label === inputs.asign_to) : null}
                      onChange={(e) => {
                        //console.log(e)
                        setAsing(e)
                      }}
                    />
                    :
                    <Select
                      placeholder="User..."
                      options={user}
                      isMulti
                      isDisabled={
                        inputs && inputs.approvalStatus !== 'D'
                          || inputs && inputs.approvalStatus !== 'A'
                          || inputs && inputs.approvalStatus !== 'P' ? true : true
                      }
                      //defaultValue={(action === 'view' || action === 'update') ? optionsV : null}
                      onChange={(e) => {
                        //console.log(e)
                        setAsing(e)
                      }}
                    />

                  }

                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Containment / Immediate Corrections: (Required only for corrective action requests.):{" "}
                  </p>
                  <br />
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    style={{
                      resize: "None",
                      width: "100%",
                      border: "None",
                      borderRadius: "8px",
                      padding: "2vh",
                      fontFamily: "Arial",
                    }}
                    disabled={inputs && inputs.approvalStatus === 'A' ? false : true}
                    defaultValue={action === 'view' || action === 'update' ? inputs && inputs.inmediate_correction : ''}

                    onChange={(e) =>
                      (setcontaiment(e.target.value))
                    }
                  ></textarea>
                  <br />
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Root Cause of Problem: (Required for all corrective and preventive issues; optional for suggestions for improvement.):{" "}
                  </p>
                  <br />
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    style={{
                      resize: "None",
                      width: "100%",
                      border: "None",
                      borderRadius: "8px",
                      padding: "2vh",
                      fontFamily: "Arial",
                    }}
                    disabled={inputs && inputs.approvalStatus === 'A' ? false : true}
                    defaultValue={action === 'view' || action === 'update' ? inputs && inputs.root_problem : ''}

                    onChange={(e) =>
                      (setroot_cause(e.target.value))
                    }
                  ></textarea>
                  <br />
                </MDBox>
                <br />
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Action Plan: (Actions taken to eliminate root cause, resolve issue and prevent recurrence.):{" "}
                  </p>
                  <br />
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    style={{
                      resize: "None",
                      width: "100%",
                      border: "None",
                      borderRadius: "8px",
                      padding: "2vh",
                      fontFamily: "Arial",
                    }}
                    disabled={inputs && inputs.approvalStatus === 'A' ? false : true}
                    defaultValue={action === 'view' || action === 'update' ? inputs && inputs.action_plan : ''}
                    onChange={(e) =>
                      (setaction_plan(e.target.value))
                    }
                  ></textarea>
                  <br />
                </MDBox>
                {/*    <MDBox mb={2}>
            <br />
            <MDInput
              type="text"
              label="Date Action Completed:"
              variant="standard"
              fullWidth
              placeholder=""
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setdate_action(e.target.value)}
            />
            <br />
            <MDInput
              type="text"
              label="Name:"
              variant="standard"
              fullWidth
              placeholder=""
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setname_action(e.target.value)}
            />
          </MDBox> */}
              </MDBox>
              {/*    <MDBox mb={2}>
          <br />
          <MDInput
            type="text"
            label="Effectiveness verified by:"
            variant="standard"
            fullWidth
            placeholder=""
            InputLabelProps={{ shrink: true }}
            onChange={(e) => seteffectiveness(e.target.value)}
          />
          <br />
          <MDInput
            type="text"
            label="Date:"
            variant="standard"
            fullWidth
            placeholder=""
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setdate_verified(e.target.value)}
          />
        </MDBox> */}
              {
                inputs && inputs.approvalStatus === 'PP' &&
                <MDBox mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Result:{" "}
                  </p>
                  <br />
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {resultyanswers.map((index, key) => (
                      <>
                        {
                          action === 'view' || action === 'update' ?
                            <FormControlLabel
                              style={{ display: "flex", flexDirection: "row" }}
                              value={index.value}
                              label={index.label}

                              //checked={(action === 'view' || action === 'update') && inputs && inputs.result == index.label ? true : false}
                              control={
                                <Radio
                                  onClick={() => {

                                    setresult(index.value);
                                    console.log(result, 'result');
                                  }}
                                />
                              }
                            />
                            :
                            <FormControlLabel
                              style={{ display: "flex", flexDirection: "row" }}
                              value={index.value}
                              label={index.label}
                              disabled={
                                inputs && inputs.approvalStatus !== 'D'
                                  || inputs && inputs.approvalStatus !== 'A'
                                  || inputs && inputs.approvalStatus !== 'P' ? true : true
                              }
                              //checked={(action === 'view' || action === 'update') && inputs.result == index.label ? true : false}
                              control={
                                <Radio
                                  onClick={() => {
                                    result.push(index.value);
                                    console.log(index.value, result);
                                  }}
                                />
                              }
                            />
                        }

                      </>
                    ))}
                  </RadioGroup>
                  {/*   <ul
            style={{
              listStyleType: "none",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <li>
              <Checkbox
                onChange={() => (setresult('PASS'))}
              />
              <FormLabel style={{ fontSize: "1.8vh" }}>
                PASS
              </FormLabel>
            </li>
            <li>
              <Checkbox
                onChange={() => (setresult('FAIL: Returned for Further Processing'))}
              />
              <FormLabel style={{ fontSize: "1.8vh" }}>
                FAIL: Returned for Further Processing
              </FormLabel>
            </li>
            <p style={{ fontSize: "2vh", textAlign: "justify" }}>
              {" "}
              Notes and justification::{" "}
            </p>
            <br />
            <textarea
              name=""
              id=""
              rows="4"
              style={{
                resize: "None",
                width: "100%",
                border: "None",
                borderRadius: "8px",
                padding: "2vh",
                fontFamily: "Arial",
              }}
              onChange={(e) =>
                (setnotes(e.target.value))
              }
            ></textarea>
          </ul> */}
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    style={{
                      resize: "None",
                      width: "100%",
                      border: "None",
                      borderRadius: "8px",
                      padding: "2vh",
                      fontFamily: "Arial",
                    }}
                    /* disabled={
                      inputs && inputs.approvalStatus !== 'D'
                        || inputs && inputs.approvalStatus !== 'A'
                        || inputs && inputs.approvalStatus !== 'P' ? true : false
                    } */
                    defaultValue={(action === 'view' || action === 'update') ? inputs && inputs.notes : ''}
                    onChange={(e) =>
                      (setnotes(e.target.value))
                    }
                  ></textarea>
                </MDBox>
              }
              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/car_form_table")}
              >
                Cancelar
              </MDButton>
              {action === 'add' ?
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() =>

                    createPositionDescription()

                  }
                >
                  {
                    action === 'add' ? 'Create CAR' : inputs.approvalStatus === 'P' ? 'Asing' :
                      inputs.approvalStatus === 'A' ? 'Send to Approve' : 'Approve'
                  }

                </MDButton>
                :
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => {
                    inputs && inputs.approvalStatus === 'D' || inputs && inputs.approvalStatus === 'F' ?
                      window.location.replace("/car_form_table")
                      :
                      sendApprove()
                    console.log('entoooooo')
                  }
                  }
                >
                  {
                    action === 'add' ? 'Create CAR' : inputs.approvalStatus === 'P' ? 'Asing' :
                      inputs.approvalStatus === 'A' ? 'Send to Approve' : inputs.approvalStatus === 'PP' ? 'Send to Approve' : 'Salir'
                  }

                </MDButton>
              }

            </MDBox>
          </>
      }
    </CoverLayout>
  );
}
export default JobDescriptionForm;
