
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from '../login';
import SignUp from "../SignUp";
import ClientSatisfaction from '../forms/clientSatisfaction'
import SupplierEvaluation from '../forms/supplierEvaluation'
import SupplierQuality from '../forms/supplierQuality'

const AuthenticationLayout = ({ session, setSession }) => {
    return(
        <div className="authentication-layout">
            <Routes>
                <Route path="/*" element={<Login setSession={setSession} />} />
                <Route path="/register" element={ <SignUp /> } />
                <Route path="/encuesta" element={ <ClientSatisfaction /> } />
                <Route path="/providers_evaluation" element={ <SupplierEvaluation /> } />
                <Route path="/supplier_Quality" element={ <SupplierQuality /> } />
            </Routes>
        </div>
    );
}

export default AuthenticationLayout;