import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import form from './schemas/form';
import validations from './schemas/validations';
import { initialValues, initialValuesFromObj } from './schemas/initialValues';
import { getAllDeffects } from '../../../Services/MainBoard';

// formik components
import { Formik, Form } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';

// Wizard page components
import InspectionsAircraftForm from './components/InspectionsAircraftForm';
import InspectionList from './components/InspectionList';
import { addDeffect } from '../../../Services/MainBoard';
import { buildData } from '../../../utils/tableData';
import { deffectsHeaders } from '../../../utils/tableHeaders';
import { Pagination, Typography } from '@mui/material';
import DataTable from '../../../components/DataTable';
import { getInspections } from '../../../Services/inspectionsService';

// import {
//   addAssembly,
//   getAssemblyById,
//   updateAssembly,
// } from '../../../Services/ShipService';

const CreateInspectionsAircraft = () => {
  const { id: aircraftid } = useParams();
  const { formId, formField } = form;

  const [state, setState] = useState('create');

  const [inputs, setInputs] = useState();

  const [deffects, setdeffects] = useState(null);
  const [currentdeffectsPage, setCurrentdeffectsPage] = useState(1);
  const [totaldeffectsPages, setTotaldeffectsPages] = useState();

  const navigate = useNavigate();

  // get search params
  const [searchparams] = useSearchParams();

  const handleSubmit = async (values, actions) => {
    // if (state === 'create') {
    //   const newdata = {...values, aircraft: aircraftid};
    //   const { data } = await addDeffect(newdata);
    // }

    // actions.resetForm();
    // loadDeffect(1);
  };

  const loadDeffect = async (page) => {
    // const { data } = await getAllDeffects(page, aircraftid);
    // setdeffects(buildData(data.defects, deffectsHeaders()));
    // setCurrentdeffectsPage(parseInt(data.current_page));
    // setTotaldeffectsPages(data.pages);
  };

  const handleChangePageDeffects = (event, value) => {
    // setCurrentdeffectsPage(value);
    // loadDeffect(value);
  };

  useEffect(() => {
    loadDeffect(1);
    console.log(inputs)
  }, []);

  return (
    <MDBox pt={3} pb={8}>
          <InspectionList 
            formData={aircraftid}
          />
      
    </MDBox>
  );
};

export default CreateInspectionsAircraft;
