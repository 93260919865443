const form = {
  formId: 'new-aircraft-form',
  formField: {
    ship: {
      name: 'ship',
      label: 'Ship',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    serial: {
      name: 'serial',
      label: 'Serial',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    hours_limit: {
      name: 'hours_limit',
      label: 'Horas limite',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    hours_performed: {
      name: 'hours_performed',
      label: 'Horas realizadas',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    hours_instalation: {
      name: 'hours_instalation',
      label: 'Horas de instalacion',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    hours_warning: {
      name: 'hours_warning',
      label: 'Horas de advertencia',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    hours_next_due: {
      name: 'hours_next_due',
      label: 'Horas de proximo vencimiento',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    calendar_months: {
      name: 'calendar_months',
      label: 'Calendario de meses',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    calendar_performed: {
      name: 'calendar_performed',
      label: 'Calendario de horas realizadas',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
    calendar_months_instalation: {
      name: 'calendar_months_instalation',
      label: 'Calendario de meses de instalacion',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    calendar_months_warning: {
      name: 'calendar_months_warning',
      label: 'Calendario de meses de advertencia',
      type: 'number',
      errorMsg: 'Campo requerido',
    },
    calendar_next_due: {
      name: 'calendar_next_due',
      label: 'Calendario de proximo vencimiento',
      type: 'date',
      errorMsg: 'Campo requerido',
    },
  },
};

export default form;
