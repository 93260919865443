import React, { useState, useEffect } from "react";
import MDBox from "../../../../components/MDBox";
import FormField from "../../../../components/FormField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import MDInput from "../../../../components/MDInput";
import { getShipList } from "../../../../Services/ShipService";

const AircraftForm1 = ({
  handleChange,
  inputs,
  isView,
  formData,
  isUpdate,
}) => {
  const [currencyValue, setCurrencyValue] = useState();
  const [inputCurrencyValue, setInputCurrencyValue] = useState();
  const [ships, setShips] = useState([]);
  const [currencyAtta, setCurrencyAtta] = useState();
  const [inputCurrencyAtta, setInputCurrencyAtta] = useState();
  const [currencyReg, setCurrencyReg] = useState();
  const [inputCurrencyReg, setInputCurrencyReg] = useState();
  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    ship,
    serial,
    ATTA_code,
    register
    
    
  } = formField;

  const {
    ship: shipV,
    serial: serialV,
    ATTA_code: ATTA_codeV,
    register: registerV
  } = values;

  const loadSuppliers = () => {
    getShipList().then((resp) => {
      setShips(resp.data);
      console.log(resp.data);
    });
  };

  useEffect(() => {
    loadSuppliers();
  }, []);

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        {(isView || isUpdate) && (
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" component="div">
              Currency. {shipV.label}
            </Typography>
          </Grid>
        )}
        {!isView && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={ships}
              value={currencyValue}
              inputValue={inputCurrencyValue}
              onChange={(event, newValue) => {
                setCurrencyValue(newValue);
                setFieldValue("ship", newValue.id);
              }}
              defaultValue={shipV}
              onInputChange={(event, newInputValue) => {
                setInputCurrencyValue(newInputValue);
              }}
              disabled={isView}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={ship.label} />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormField
            type={serial.type}
            label={serial.label}
            name={serial.name}
            value={serialV}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput md={8}
            label='ATTA Code'
            variant="standard"
            value={currencyAtta}
            inputValue={inputCurrencyAtta}
            onChange={(e, newAtta)=>{
                setCurrencyAtta(newAtta);
                setFieldValue('ATTA_code', e.target.value)
            }}
            defaultValue={ATTA_codeV}
            onInputChange={(e, newInputAtta) => {
                setInputCurrencyValue(newInputAtta);
              }}
          />

        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput md={8}
            label='Register'
            variant="standard"
            value={currencyReg}
            inputValue={inputCurrencyReg}
            onChange={(e, newReg)=>{
                setCurrencyReg(newReg);
                setFieldValue('register', e.target.value)
            }}
            defaultValue={registerV}
            onInputChange={(e, newInputReg) => {
                setInputCurrencyValue(newInputReg);
              }}
          />

        </Grid>
      </Grid>
    </MDBox>
  );
};

export default AircraftForm1;
