import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    TTAF,
    TTAW,
    calendarLimit,
    calendarWarning,
    cycle_ng,
    cycle_nf,
    cycleLimit,
    cycleWarning,
    workReportCorrection,
    remark,
  },
} = checkout;

const validations = Yup.object().shape({
  [TTAF.name]: Yup.string().required(TTAF.errorMsg),
  [TTAW.name]: Yup.string().required(TTAW.errorMsg),
  [calendarLimit.name]: Yup.string().required(calendarLimit.errorMsg),
  [calendarWarning.name]: Yup.string().required(calendarWarning.errorMsg),
  [cycle_ng.name]: Yup.string().required(cycle_ng.errorMsg),
  [cycle_nf.name]: Yup.string().required(cycle_nf.errorMsg),
  [cycleLimit.name]: Yup.string().required(cycleLimit.errorMsg),
  [cycleWarning.name]: Yup.string().required(cycleWarning.errorMsg),
  [workReportCorrection.name]: Yup.string().required(workReportCorrection.errorMsg),
  [remark.name]: Yup.string().required(remark.errorMsg),
});

export default validations;
