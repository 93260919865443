import React, { useEffect, useState } from 'react';
import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';
import { getAllAircrafts } from '../../../../Services/ShipService';
import { Typography } from '@mui/material';

const DeffectsForm = ({ handleChange, inputs, isView, formData, isUpdate }) => {

  // const [ships, setShips] = useState([]);

  const { formField, values, errors, touched, setFieldValue } = formData;

  const { description, correction, date, date_repair, TTAP } =
    formField;

  const {
    description: descriptionV,
    correction: correctionV,
    date: dateV,
    date_repair: date_repairV,
    TTAP: TTAPV,
  } = values;


  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>

        <Grid item xs={12} md={4}>
          <FormField
            type={description.type}
            label={description.label}
            name={description.name}
            value={descriptionV}
            error={errors.description && touched.description}
            success={descriptionV.length > 0 && !errors.description}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={correction.type}
            label={correction.label}
            name={correction.name}
            value={correctionV}
            error={errors.correction && touched.correction}
            success={correctionV.length > 0 && !errors.correction}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={date.type}
            label={date.label}
            name={date.name}
            value={dateV}
            error={errors.date && touched.date}
            success={dateV.length > 0 && !errors.date}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={date_repair.type}
            label={date_repair.label}
            name={date_repair.name}
            value={date_repairV}
            error={errors.date_repair && touched.date_repair}
            success={date_repairV.length > 0 && !errors.date_repair}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={TTAP.type}
            label={TTAP.label}
            name={TTAP.name}
            value={TTAPV}
            error={errors.TTAP && touched.TTAP}
            success={TTAPV.length > 0 && !errors.TTAP}
            InputProps={{ readOnly: isView }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default DeffectsForm;
