import { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";
import { getJobDescriptID } from "../../Services/formServices";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from "../../components/Loader";

function JobDescriptionForm({ action }) {
  const params = useParams();

  const [jobTitle, setJobTitle] = useState();
  const [location, setLocation] = useState();
  const [jobType, setJobType] = useState();
  const [report, setReport] = useState();
  const [responsabilities, setResponsabilities] = useState([
    { description: "" },
  ]);
  const [education, setEducation] = useState([{ description: "" }]);
  const [experience, setExperience] = useState([{ description: "" }]);
  const [training, setTraining] = useState([{ description: "" }]);
  const [skills, setSkills] = useState([{ description: "" }]);


  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const userToken = localStorage.getItem('Token') || '';
  const [flag, setFlag] = useState(false);


  const jobTypes = [
    { label: "Tiempo Completo", value: "FT" },
    { label: "Medio Tiempo", value: "HT" },
    { label: "Por Contrato", value: "CO" },
  ];

  const jsonAnswers = {
    job_title: jobTitle,
    location: location,
    job_type: jobType,
    reports_to: report,
    responsabilities: responsabilities, //array of objects
    education: education, //array of objects
    experience: experience, //array of objects
    training: training, //array of objects
    skills: skills, //array of objects
  };


  const editPositionDescription = () => {
    let ver = null
    const responsabilitiesJSON = JSON.stringify(responsabilities);
    const educationJSON = JSON.stringify(education);
    const experienceJSON = JSON.stringify(experience);
    const trainingJSON = JSON.stringify(training);
    const skillsJSON = JSON.stringify(skills);
    const pilotdata = new FormData();
    jobTitle && pilotdata.append('job_title', jobTitle);
    location && pilotdata.append('location', location);
    jobType && pilotdata.append('job_type', jobType);
    report && pilotdata.append('reports_to', report);
    responsabilities && pilotdata.append('responsabilities', responsabilitiesJSON);
    education && pilotdata.append('education', educationJSON);
    experience && pilotdata.append('experience', experienceJSON);
    training && pilotdata.append('training', trainingJSON);
    skills && pilotdata.append('skills', skillsJSON);
    axios.patch(
      `https://fn.devpackgroup.com/api/v1/job_description/${params.id}/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Employee Card Creado Exitosamente")
      window.location.replace("/job_desc_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Employee Card")
    }.bind(this))
  };

  const createPositionDescription = () => {
    let ver = null
    const responsabilitiesJSON = JSON.stringify(responsabilities);
    const educationJSON = JSON.stringify(education);
    const experienceJSON = JSON.stringify(experience);
    const trainingJSON = JSON.stringify(training);
    const skillsJSON = JSON.stringify(skills);
    const pilotdata = new FormData();
    pilotdata.append('job_title', jobTitle);
    pilotdata.append('location', location);
    pilotdata.append('job_type', jobType);
    pilotdata.append('reports_to', report);
    pilotdata.append('responsabilities', responsabilitiesJSON);
    pilotdata.append('education', educationJSON);
    pilotdata.append('experience', experienceJSON);
    pilotdata.append('training', trainingJSON);
    pilotdata.append('skills', skillsJSON);
    axios.post(
      'https://fn.devpackgroup.com/api/v1/job_description/',
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Employee Card Creado Exitosamente")
      window.location.replace("/job_desc_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear Employee Card")
    }.bind(this))
  };

  const handleRemoveClick = (index, flag) => {
    if (flag === 1) {
      const list = [...responsabilities];
      list.splice(index, 1);
      setResponsabilities(list);
    } else if (flag === 2) {
      const list = [...education];
      list.splice(index, 1);
      setEducation(list);
    } else if (flag === 3) {
      const list = [...experience];
      list.splice(index, 1);
      setExperience(list);
    } else if (flag === 4) {
      const list = [...training];
      list.splice(index, 1);
      setTraining(list);
    } else if (flag === 5) {
      const list = [...skills];
      list.splice(index, 1);
      setSkills(list);
    }
  };

  const addDescription = (flag) => {
    if (flag === 1) {
      setResponsabilities([...responsabilities, { description: "" }]);
      console.log(responsabilities);
    } else if (flag === 2) {
      setEducation([...education, { description: "" }]);
      console.log(education);
    } else if (flag === 3) {
      setExperience([...experience, { description: "" }]);
      console.log(experience);
    } else if (flag === 4) {
      setTraining([...training, { description: "" }]);
      console.log(training);
    } else if (flag === 5) {
      setSkills([...skills, { description: "" }]);
      console.log(skills);
    }
  };

  function getPilot() {
    if (params.id != null) {
      setId(params.id)
      getJobDescriptID(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)
        var data2 = resp.data.responsabilities.map((index, key) => {
          responsabilities[key] = {
            id: index.id,
            description: index.description,
          };
        })
        var data2 = resp.data.meq.map((index, key) => {
          education[key] = {
            id: index.id,
            description: index.description,
          };
        })
        var data2 = resp.data.mpe.map((index, key) => {
          experience[key] = {
            id: index.id,
            description: index.description,
          };
        })
        var data2 = resp.data.mat.map((index, key) => {
          training[key] = {
            id: index.id,
            description: index.description,
          };
        })
        var data2 = resp.data.other_skills.map((index, key) => {
          skills[key] = {
            id: index.id,
            description: index.description,
          };
        })
      }).catch(err => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    //loadJobs();
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 1000);
    return () => clearTimeout(timer);

  }, []);

  const locationList = [
    { label: "GERENCIA", value: 'GERENCIA' },
    { label: "MANTENIMIENTO", value: 'MANTENIMIENTO' },
    { label: "BODEGA", value: 'BODEGA' },
    { label: "PLANIFICACION", value: 'PLANIFICACION' },
    { label: "CAPACITACIONES", value: 'CAPACITACIONES' },
    { label: "CALIDAD", value: 'CALIDAD' },
    { label: "RAMPA", value: 'RAMPA' },

  ];


  const ReportList = [
    { label: "JEFE", value: 'JEFE' },
    { label: "GERENTE", value: 'GERENTE' },

  ];
  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Franconaves Position Description
                </MDTypography>
              </MDBox>
            </Card>
            <br />

            <MDBox pt={4} pb={3} px={3}>
              <MDBox mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                  {" "}
                  Job Title:{" "}
                </p>
                <br />
                <MDInput
                  type="text"
                  label="Job Title:"
                  variant="standard"
                  fullWidth
                  placeholder="Job Title"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setJobTitle(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.job_title : null}
                  value={(action === 'view') && inputs ? inputs.job_title : null}
                />
              </MDBox>
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Reports to:{" "}
                  </p>
                  <br />
                  <MDInput
                    type="text"
                    label="Reports to:"
                    variant="standard"
                    fullWidth
                    placeholder="Reports to"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setReport(e.target.value)}
                    InputProps={{ readOnly: action === 'view' ? true : false, }}
                    defaultValue={(action === 'update') && inputs ? inputs.reports_to : null}
                    value={(action === 'view') && inputs ? inputs.reports_to : null}
                  />
                  {/*    <Select
                  placeholder="Reports to"
                  options={ReportList}
                  //isMulti={true}
                  onChange={(o) => { setReport(o.label) }}
                  isDisabled={action === 'view' ? true : false}
                  defaultValue={(action === 'view' || action === 'update') && ReportList.find(label => label.label === inputs.reports_to)}

                ></Select> */}
                </MDBox>

                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Location: </p>
                  <br />
                  {/*  <MDInput
                  type="text"
                  label="Location:"
                  variant="standard"
                  fullWidth
                  placeholder="Location"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setLocation(e.target.value)}
                  InputProps={{ readOnly: action === 'view' ? true : false, }}
                  defaultValue={(action === 'update') && inputs ? inputs.location : null}
                  value={(action === 'view') && inputs ? inputs.location : null}
                /> */}
                  <Select
                    placeholder="Location"
                    options={locationList}
                    //isMulti={true}
                    onChange={(o) => { setLocation(o.label) }}
                    isDisabled={action === 'view' ? true : false}
                    defaultValue={(action === 'view' || action === 'update') && locationList.find(label => label.label === inputs.location)}

                  ></Select>
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Job Type: </p>
                  <br />
                  <Select
                    placeholder="Select a Job Type..."
                    options={jobTypes}
                    onChange={(e) => setJobType(e.value)}
                    isDisabled={action === 'view' ? true : false}
                    defaultValue={(action === 'view' || action === 'update') && jobTypes.find(label => label.value === inputs.job_type)} />
                </MDBox>
                <br />

              </div>
              <br />

              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Responsabilities:{" "}
                  </p>
                  <br />
                  <ul>
                    {responsabilities.map((index, key) => {
                      return (
                        <>
                          <li>
                            <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                              {" "}
                              Responsability{" "}
                            </p>
                            <br />
                            <textarea
                              name=""
                              id=""
                              rows="4"
                              style={{
                                resize: "None",
                                width: "100%",
                                border: "None",
                                borderRadius: "8px",
                                padding: "2vh",
                                fontFamily: "Arial",
                              }}
                              onChange={(e) =>
                              (responsabilities.at(key).description =
                                e.target.value)
                              }
                              InputProps={{ readOnly: action === 'view' ? true : false, }}
                              defaultValue={(action === 'update') ? index.description : null}
                              value={(action === 'view') ? index.description : null}
                            ></textarea>
                            {(responsabilities.length > 1 && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="error"
                                onClick={() => {
                                  handleRemoveClick(key, 1);
                                }}
                              >
                                Remove
                              </MDButton>
                            )}{" "}
                            {(responsabilities.length - 1 === key && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(1)}
                              >
                                Add Responsability
                              </MDButton>
                            )}
                            <br />
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <br />
                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Minimum Educational Qualifications:{" "}
                  </p>
                  <br />
                  <ul>
                    {education.map((index, key) => {
                      return (
                        <>
                          <li>
                            <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                              {" "}
                              Description{" "}
                            </p>
                            <br />
                            <textarea
                              name=""
                              id=""
                              rows="4"
                              style={{
                                resize: "None",
                                width: "100%",
                                border: "None",
                                borderRadius: "8px",
                                padding: "2vh",
                                fontFamily: "Arial",
                              }}
                              onChange={(e) =>
                                (education.at(key).description = e.target.value)
                              }
                              InputProps={{ readOnly: action === 'view' ? true : false, }}
                              defaultValue={(action === 'update') ? index.description : null}
                              value={(action === 'view') ? index.description : null}
                            ></textarea>
                            {(education.length > 1 && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="error"
                                onClick={() => handleRemoveClick(key, 2)}
                              >
                                Remove
                              </MDButton>
                            )}{" "}
                            {(education.length - 1 === key && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(2)}
                              >
                                Add Qualification
                              </MDButton>
                            )}
                            <br />
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <br />
                </MDBox>
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                    {" "}
                    Minimum Previous Experience:{" "}
                  </p>
                  <br />
                  <ul>
                    {experience.map((index, key) => {
                      return (
                        <>
                          <li>
                            <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                              {" "}
                              Description{" "}
                            </p>
                            <br />
                            <textarea
                              name=""
                              id=""
                              rows="4"
                              style={{
                                resize: "None",
                                width: "100%",
                                border: "None",
                                borderRadius: "8px",
                                padding: "2vh",
                                fontFamily: "Arial",
                              }}
                              onChange={(e) =>
                                (experience.at(key).description = e.target.value)
                              }
                              InputProps={{ readOnly: action === 'view' ? true : false, }}
                              defaultValue={(action === 'update') ? index.description : null}
                              value={(action === 'view') ? index.description : null}
                            ></textarea>
                            {(experience.length > 1 && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="error"
                                onClick={() => handleRemoveClick(key, 3)}
                              >
                                Remove
                              </MDButton>
                            )}{" "}
                            {(experience.length - 1 === key && (action === 'add' || action === 'update')) && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => addDescription(3)}
                              >
                                Add Experience
                              </MDButton>
                            )}
                            <br />
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <br />
                </MDBox>
              </div>
              <div style={{ display: 'flex' }}>
              <MDBox width={'30%'}  mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                  {" "}
                  Minimum Additional Training:{" "}
                </p>
                <br />
                <ul>
                  {training.map((index, key) => {
                    return (
                      <>
                        <li>
                          <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                            {" "}
                            Description{" "}
                          </p>
                          <br />
                          <textarea
                            name=""
                            id=""
                            rows="4"
                            style={{
                              resize: "None",
                              width: "100%",
                              border: "None",
                              borderRadius: "8px",
                              padding: "2vh",
                              fontFamily: "Arial",
                            }}
                            onChange={(e) =>
                              (training.at(key).description = e.target.value)
                            }
                            InputProps={{ readOnly: action === 'view' ? true : false, }}
                            defaultValue={(action === 'update') ? index.description : null}
                            value={(action === 'view') ? index.description : null}
                          ></textarea>
                          {(training.length > 1 && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="error"
                              onClick={() => handleRemoveClick(key, 4)}
                            >
                              Remove
                            </MDButton>
                          )}{" "}
                          {(training.length - 1 === key && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => addDescription(4)}
                            >
                              Add Training
                            </MDButton>
                          )}
                          <br />
                        </li>
                      </>
                    );
                  })}
                </ul>
                <br />
              </MDBox>
              <MDBox width={'30%'} ml={6} mb={2}>
                <p style={{ fontSize: "2vh", textAlign: "justify" }}>
                  {" "}
                  Other Skills:{" "}
                </p>
                <br />
                <ul>
                  {skills.map((index, key) => {
                    return (
                      <>
                        <li>
                          <p style={{ fontSize: "1.3vh", textAlign: "justify" }}>
                            {" "}
                            Description{" "}
                          </p>
                          <br />
                          <textarea
                            name=""
                            id=""
                            rows="4"
                            style={{
                              resize: "None",
                              width: "100%",
                              border: "None",
                              borderRadius: "8px",
                              padding: "2vh",
                              fontFamily: "Arial",
                            }}
                            onChange={(e) =>
                              (skills.at(key).description = e.target.value)
                            }
                            InputProps={{ readOnly: action === 'view' ? true : false, }}
                            defaultValue={(action === 'update') ? index.description : null}
                            value={(action === 'view') ? index.description : null}
                          ></textarea>
                          {(skills.length > 1 && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="error"
                              onClick={() => handleRemoveClick(key, 5)}
                            >
                              Remove
                            </MDButton>
                          )}{" "}
                          {(skills.length - 1 === key && (action === 'add' || action === 'update')) && (
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => addDescription(5)}
                            >
                              Add Skill
                            </MDButton>
                          )}
                          <br />
                        </li>
                      </>
                    );
                  })}
                </ul>
                <br />
              </MDBox>
              </div>

              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/job_desc_list")}
              >
                Cancelar
              </MDButton>
              {
                action != 'view' &&
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => action === 'add' ? createPositionDescription() : editPositionDescription()}
                >
                  Create Position Description
                </MDButton>
              }
            </MDBox>
          </>
      }
    </>
  );
}
export default JobDescriptionForm;
