
import { doDelete, doGet, doPatch, doPost } from '../utils/api';
import { getTokenSession } from './authService';

const token = "321f62017eca8e1477420f48ce3faea32b07092a";

export const getDefaultObject = () => {
    return (
        {
            "name": "",
            "address": "",
            "city": "",
            "state": "",
            "zip_code": "",
            "country": "",
            "note": "",
            "tax_id": "",
            "phone": "",
            "phone_2": "",
            "fax": "",
            "email": "",
            "contact_name_1": "",
            "contact_phone_1": "",
            "contact_name_2": "",
            "contact_phone_2": "",
            "contact_name_3": "",
            "contact_phone_3": ""
        }
    )
}

export const getClients = (page, filter, value) => {
    let filterStr = filter != null ? "&".concat(filter.concat("=".concat(value))) : "";
    let route = "/clients/?".concat("page=".concat(page)).concat(filterStr);
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const addClient = (data) => {
    let route = "/clients/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()), "Content-Type": "multipart/form-data" }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if(data[key] != null && data[key] !== ""){
            formData.append(key, data[key])
        }
    });
    return doPost(route, formData, headers);
}

export const getClient = (id) => {
    let route = "/clients/".concat(id)
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const getClientList = () => {
    let route = "/client/get_clients_list/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const deleteProvider = (id) => {
    let route = "/provider/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const deleteProviderList = (id) => {
    let route = "/provider_evaluation/".concat(id)+"/"
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doDelete(route, headers)
}

export const getEvaProvidertList = (id) => {
    let route = "/provider_evaluation/".concat(id)+"/";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    return doGet(route, headers);
}

export const updateClient = (data, id) => {
    let route = "/clients/".concat(id).concat("/")
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) }
    let formData = new FormData();
    Object.keys(data).forEach(key => {
                if(data[key] != null && data[key] !== ""){
                    formData.append(key, data[key])
                }
            });
    return doPatch(route, formData, headers);
}

export const exportClient = () =>{
    let route = "/client/export";
    let headers = { 'Authorization': "Token ".concat(getTokenSession()) };
    return doGet(route, headers);
}