import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
 description, correction, date, date_repair, TTAP
  },
} = checkout;

const validations = Yup.object().shape({
  [description.name]: Yup.string().required(description.errorMsg),
  [correction.name]: Yup.string().required(correction.errorMsg),
  [date.name]: Yup.string().required(date.errorMsg),
  // [date_repair.name]: Yup.string().required(date_repair.errorMsg),
  [TTAP.name]: Yup.string().required(TTAP.errorMsg),
});

export default validations;
