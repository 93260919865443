import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        client,
        ATTA_code,
        ship,
        description,
        currency,
        subject,
        tax,
        type,
    }
} = checkout;

const validations = Yup.object().shape();

export default validations;