/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import { LINKS } from "../../../type/globals";

const routes = [
  /* {
    type: "collapse",
    name: "Notificaciones",
    key: "notifications",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado de Notificaciones",
        key: "notifications",
        route: LINKS.notifications,
      }
    ],
  }, */
  {
    type: "collapse",
    name: "Creacion de Cliente",
    key: "createClient",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Agregar Cliente",
        key: "clients_add",
        route: LINKS.clients_add,
      },
      {
        name: "Listado de Clientes",
        key: "client_list",
        route: LINKS.client_list,
      },
    ],
  },
  {
    type: "collapse",
    name: "Creacion de Nave",
    key: "createShip",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Agregar Nave",
        key: "shipments_add",
        route: LINKS.shipments_add,
      },
      {
        name: "Listado de Naves",
        key: "ships_list",
        route: LINKS.ships_list,
      },
    ],
  },

  {
    type: "collapse",
    name: "Creacion de Piloto",
    key: "createpilot",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Agregar Piloto",
        key: "pilots_add",
        route: LINKS.pilots_add,
      },
      {
        name: "Listado de Pilotos",
        key: "pilots_list",
        route: LINKS.pilots_list,
      },
    ],
  },
  {
    type: "collapse",
    name: "Creacion de Proveedor",
    key: "createsupplier",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      
      {
        name: "Agregar Proveedor",
        key: "suppliers_add",
        route: LINKS.suppliers_add,
      },
      {
        name: "Listado de Proveedores",
        key: "provider_list",
        route: LINKS.provider_list,
      }
    ],
  },

  {
    type: "collapse",
    name: "Encuesta de Satisfacción",
    key: "satis",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "ClientSatisfactionList",
        route: LINKS.ClientSatisfactionList,
      },
      {
        name: "Encuesta de Satisfacción",
        key: "satisfaction_form",
        route: LINKS.ClientSatisfaction,
      },
    ],
  },
  {
    type: "collapse",
    name: "Recepción de Naves",
    key: "Reception",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "clients_add",
        route: LINKS.reception_table,
      },
      {
        name: "Recepción de Naves",
        key: "reception_form",
        route: LINKS.reception,
      },
    ],
  },
  {
    type: "collapse",
    name: "Entrega de naves",
    key: "Entrega",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "clients_add",
        route: LINKS.deliver_table,
      },
      {
        name: "Entrega de naves",
        key: "deliver_form",
        route: LINKS.deliver,
      },
    ],
  },
  {
    type: "collapse",
    name: "Job Description",
    key: "JobDescription",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "job_desc_list",
        route: LINKS.job_desc_list,
      },
      {
        name: "Create Job Description",
        key: "job_description",
        route: LINKS.job_desc,
      },
    ],
  },
  {
    type: "collapse",
    name: "Certifications",
    key: "certifications",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "certifications_list",
        route: LINKS.certifications_list,
      },
      {
        name: "Certifications",
        key: "certifications",
        route: LINKS.certifications,
      },
    ],
  },
  {
    type: "collapse",
    name: "Employee Card",
    key: "employeeCard",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "employee_list",
        route: LINKS.employee_list,
      },
      {
        name: "Employee Card",
        key: "employeeCard",
        route: LINKS.employee_card,
      },
    ],
  },
  {
    type: "collapse",
    name: "Providers Evaluation",
    key: "providersEvl",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "providers_evl_list",
        route: LINKS.providers_evl_list,
      },
      {
        name: "Providers Evaluation",
        key: "providersEvl",
        route: LINKS.providers_evl,
      },
    ],
  },
  {
    type: "collapse",
    name: "Definicion de procesos",
    key: "processes",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "processes_list",
        route: LINKS.processes_list,
      },
      {
        name: "Definicion de procesos",
        key: "processes",
        route: LINKS.processes,
      },
      
    ],
  },
  {
    type: "collapse",
    name: "Supplier Quality Survey Form",
    key: "Supplier Quality Survey Form",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "SupplierQuality_List",
        route: LINKS.SupplierQuality_List,
      },
      {
        name: "Supplier Quality Survey Form",
        key: "processes",
        route: LINKS.SupplierQuality,
      },
      
    ],
  },
  {
    type: "collapse",
    name: "Car Form",
    key: "CarForm",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado",
        key: "car_form_table",
        route: LINKS.car_form_table,
      },
      {
        name: "Create Car Form",
        key: "car_form",
        route: LINKS.car_form,
      },
    ],
  },
  {
    type: "collapse",
    name: "Coto Form",
    key: "CotoForm",
    icon: <Icon fontSize="medium">description</Icon>,
    collapse: [
      {
        name: "Listado COTO",
        key: "coto_table",
        route: LINKS.coto_table,
      },

      {
        name: "Listado Party",
        key: "party_table",
        route: LINKS.party_table,
      },
      {
        name: "Listado Issues",
        key: "issues_table",
        route: LINKS.issues_table,
      },
      {
        name: "Create COTO Form",
        key: "coto_form",
        route: LINKS.coto_form,
      },
      {
        name: "Create COTO Risk Form",
        key: "cotoRisk_form",
        route: LINKS.cotoRisk_form,
      },

      {
        name: "Create Party Form",
        key: "party_form",
        route: LINKS.party_form,
      },
      {
        name: "Create Issues Form",
        key: "issues_form",
        route: LINKS.issues_form,
      },
      
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Inventario",
  //   key: "inventory",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "inventory_main",
  //       route: LINKS.inventory_main,
  //     },
  //     {
  //       name: "Listado De Cuarentena",
  //       key: "inventory_quarantine",
  //       route: LINKS.inventory_quarantine,
  //     },
  //     {
  //       name: "Listado en Reparacion",
  //       key: "inventory_repair",
  //       route: LINKS.inventory_repair,
  //     },
  //     {
  //       name: "Listado De Desechos",
  //       key: "inventory_scrap",
  //       route: LINKS.inventory_scrap,
  //     },
  //     {
  //       name: "Listado Sin Expiracion",
  //       key: "inventory_missing_expirations",
  //       route: LINKS.inventory_missing_expiration,
  //     },
  //     {
  //       name: "Historial",
  //       key: "inventory_history",
  //       route: LINKS.inventory_history,
  //     },
  //     {
  //       name: "Busqueda General",
  //       key: "inventory_general_search",
  //       route: LINKS.inventory_general_search,
  //     },
  //     // {
  //     //   name: "Agregar",
  //     //   key: "suppliers_add",
  //     //   route: LINKS.suppliers_add,
  //     // },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Purchase Order",
  //   key: "PO",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "PO_list",
  //       route: LINKS.purchase_order_list,
  //     },
  //     {
  //       name: "Creacion",
  //       key: "PO_create",
  //       route: LINKS.purchase_order_create,
  //     },
  //     {
  //       name: "Pendientes",
  //       key: "PO_detail_pending",
  //       route: LINKS.purchase_order_pending,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Invoicing",
  //   key: "invoice",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "invoice_list",
  //       route: LINKS.invoice_list,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Invoice Manager",
  //   key: "invoice_manager",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "invoice_manager",
  //       route: LINKS.invoice_manager,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Estimation",
  //   key: "estimation",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "estimation_list",
  //       route: LINKS.estimarion_list,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Naves",
  //   key: "ships",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "ships_list",
  //       route: LINKS.ships_list,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Task",
  //   key: "task",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado Principal",
  //       key: "task_list",
  //       route: LINKS.task_list,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Tablero Principal",
  //   key: "mainboard",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Listado de Naves",
  //       key: "main_board_ships_list",
  //       route: LINKS.main_board,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Staff Manager",
  //   key: "staff",
  //   icon: <Icon fontSize="medium">description</Icon>,
  //   collapse: [
  //     {
  //       name: "Staff Manager",
  //       key: "main_board_staff_manager",
  //       route: LINKS.staff_list,
  //     },
  //   ],
  // },
  
];

export default routes;
