import React, {useState, useEffect} from "react";
import MDBox from "../../../../components/MDBox";
import FormField from "../../../../components/FormField";

import Grid from "@mui/material/Grid";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';

import { Typography } from "@mui/material";

import MDInput from "../../../../components/MDInput";

import { getClientList } from "../../../../Services/ClientService"
import { getShipList } from "../../../../Services/ShipService"

const InvoiceForm = ({handleChange, inputs, isView, formData, isUpdate}) => {
    const [clientOptions, setClientOptions] = useState([]);
    const [clientValue, setClientValue] = useState();
    const [clientInputValue, setClientInputValue] = useState();

    const [shipOptions, setShipOptions] = useState([]);
    const [shipValue, setShipValue] = useState();
    const [shipInputValue, setShipInputValue] = useState();
    
    const [currencyValue, setCurrencyValue] = useState();
    const [inputCurrencyValue, setInputCurrencyValue] = useState();

    const { formField, values, errors, touched, setFieldValue } = formData;
    const {
        client,
        ATTA_code,
        ship,
        description,
        currency,
        subject,
        tax,
        reference
    } = formField;
    const {
        client:clientV,
        ATTA_code:ATTA_codeV,
        ship:shipV,
        description:descriptionV,
        currency:currencyV,
        subject:subjectV,
        tax:taxV,
        reference:referenceV
    } = values;

    const loadClients = () => {
        getClientList().then(resp => {
            setClientOptions(resp.data)
        })
    }

    const loadShips = () => {
        getShipList().then(resp => {
            setShipOptions(resp.data)
        })
    }

    useEffect(()=> {
        loadClients();
        loadShips();
    }, []);

    
    const currencyOptions = [
        "Q",
        "D",
        "E"
    ]

    return (
        <MDBox mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <FormField 
                    type={subject.type} label={subject.label} name={subject.name} 
                    value={subjectV} error={errors.subject && touched.subject} 
                    success={subjectV.length > 0 && !errors.subject}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormField 
                    type={reference.type} label={reference.label} name={reference.name} 
                    value={referenceV} error={errors.reference && touched.reference} 
                    success={referenceV.length > 0 && !errors.reference}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormField 
                    type={ATTA_code.type} label={ATTA_code.label} name={ATTA_code.name} 
                    value={ATTA_codeV} error={errors.ATTA_code && touched.ATTA_code} 
                    success={ATTA_codeV.length > 0 && !errors.ATTA_code}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormField 
                    type={description.type} label={description.label} name={description.name} 
                    value={descriptionV} error={errors.description && touched.description} 
                    success={descriptionV.length > 0 && !errors.description}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
                { (isView || isUpdate) &&
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2" component="div" >Currency. {currencyV} </Typography>
                    </Grid>
                    }
                    { !(isView) &&
                        <Grid item xs={12} md={4}>
                            <Autocomplete options={currencyOptions}
                                value={currencyValue}
                                inputValue={inputCurrencyValue}
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setCurrencyValue(newValue)
                                    setFieldValue(currency.name, newValue)
                                    
                                }}
                                defaultValue={currencyV}
                                onInputChange={(event, newInputValue) => {
                                    setInputCurrencyValue(newInputValue);
                                }}
                                disabled={isView}
                                renderInput={(params) => <MDInput {...params} variant="standard" label={currency.label} /> } 
                            />

                            {/* <FormField 
                            type={supplier.type} label={supplier.label} name={supplier.name} 
                            value={supplierV} error={errors.supplier && touched.supplier} 
                            success={supplierV.length > 0 && !errors.supplier}
                            InputProps={{readOnly: isView,}} 
                            /> */}
                        </Grid>
                    }

                {/* <Grid item xs={12} md={4}>
                    <FormField 
                    type={clientV.type} label={client.label} name={client.name} 
                    value={clientV} error={errors.client && touched.client} 
                    success={clientV.length > 0 && !errors.client}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid> */}
                { (isView || isUpdate) &&
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" component="div" >Customer: {clientV.label} </Typography>
                    </Grid>
                }
                { !(isView) &&
                    <Grid item xs={12} md={4}>
                        <Autocomplete options={clientOptions}
                                value={clientValue}
                                inputValue={clientInputValue}
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setClientValue(newValue.id)
                                    setFieldValue(client.name, newValue.id)
                                    console.log(clientV)
                                    
                                }}
                                defaultValue={clientV}
                                getOptionLabel={(option) => option.label}
                                onInputChange={(event, newInputValue) => {
                                    setClientInputValue(newInputValue);
                                }}
                                disabled={isView}
                                renderInput={(params) => <MDInput {...params} variant="standard" label={client.label} /> } 
                            />

                        {/* <FormField 
                        type={supplier.type} label={supplier.label} name={supplier.name} 
                        value={supplierV} error={errors.supplier && touched.supplier} 
                        success={supplierV.length > 0 && !errors.supplier}
                        InputProps={{readOnly: isView,}} 
                        /> */}
                    </Grid>
                }
                
                {/* <Grid item xs={12} md={4}>
                    <FormField 
                    type={ship.type} label={ship.label} name={ship.name} 
                    value={shipV} error={errors.ship && touched.ship} 
                    success={shipV.length > 0 && !errors.ship}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid> */}
                { (isView || isUpdate) &&
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" component="div" >Aircraft: {shipV.label} </Typography>
                    </Grid>
                }
                { !(isView) &&
                    <Grid item xs={12} md={4}>
                        <Autocomplete options={shipOptions}
                                value={shipValue}
                                inputValue={shipInputValue}
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setShipValue(newValue.id)
                                    setFieldValue(ship.name, newValue.id)
                                    console.log(shipV)
                                    
                                }}
                                defaultValue={shipV}
                                getOptionLabel={(option) => option.label}
                                onInputChange={(event, newInputValue) => {
                                    setShipInputValue(newInputValue);
                                }}
                                disabled={isView}
                                renderInput={(params) => <MDInput {...params} variant="standard" label={ship.label} /> } 
                            />

                        {/* <FormField 
                        type={supplier.type} label={supplier.label} name={supplier.name} 
                        value={supplierV} error={errors.supplier && touched.supplier} 
                        success={supplierV.length > 0 && !errors.supplier}
                        InputProps={{readOnly: isView,}} 
                        /> */}
                    </Grid>
                }

                <Grid item xs={12} md={4}>
                    <FormField 
                    type={tax.type} label={tax.label} name={tax.name} 
                    value={taxV} error={errors.tax && touched.tax} 
                    success={taxV.length > 0 && !errors.tax}
                    InputProps={{readOnly: isView,}} 
                    />
                </Grid>
            </Grid>
        </MDBox>
    )
}

export default InvoiceForm;