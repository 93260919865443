import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    date, remark, air_time, ng, nf
  },
} = checkout;

const validations = Yup.object().shape({
  [date.name]: Yup.string().required(date.errorMsg),
  [remark.name]: Yup.string().required(remark.errorMsg),
  [air_time.name]: Yup.string().required(air_time.errorMsg),
  [ng.name]: Yup.string().required(ng.errorMsg),
  [nf.name]: Yup.string().required(nf.errorMsg),
});

export default validations;
