import React, { useEffect, useState } from 'react';
import MDBox from '../../../../components/MDBox';
import FormField from '../../../../components/FormField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../../../components/MDInput';
import { getItemList } from '../../../../Services/InventoryService';

const CreateShipPartForm = ({
  handleChange,
  inputs,
  isView,
  formData,
  isUpdate,
}) => {
  const [activeValue, setActiveValue] = useState();
  const [inputActiveValue, setInputActiveValue] = useState();

  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [inventoryActiveValue, setInventoryActiveValue] = useState();
  const [inputActiveValueIntentory, setInputActiveValueIntentory] = useState();

  // const [ships, setShips] = useState([]);

  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    inventory_detail,
    instalation_date,
    part_number,
    active,
    link,
    next_date,
  } = formField;

  const {
    inventory_detail: inventory_detailV,
    instalation_date: instalation_dateV,
    part_number: part_numberV,
    active: activeV,
    link: linkV,
    next_date: next_dateV,
  } = values;

  const fetchInventoryOptions = async () => {
    const { data } = await getItemList();
    setInventoryOptions(data);
  };

  useEffect(() => {
    fetchInventoryOptions();
  }, []);

  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
        <Autocomplete
            options={inventoryOptions}
            value={inventoryActiveValue}
            inputValue={inputActiveValueIntentory}
            onChange={(event, newValue) => {
              setInventoryActiveValue(newValue);
              setFieldValue('inventory_detail', newValue.id);
            }}
            defaultValue={inventory_detailV}
            onInputChange={(event, newInputValue) => {
              setInputActiveValueIntentory(newInputValue);
            }}
            disabled={isView}
            renderInput={(params) => (
              <MDInput {...params} variant='standard' label={inventory_detail.label} />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={instalation_date.type}
            label={instalation_date.label}
            name={instalation_date.name}
            value={instalation_dateV}
            error={errors.instalation_date && touched.instalation_date}
            success={instalation_dateV.length > 0 && !errors.instalation_date}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={part_number.type}
            label={part_number.label}
            name={part_number.name}
            value={part_numberV}
            error={errors.part_number && touched.part_number}
            success={part_numberV.length > 0 && !errors.part_number}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={link.type}
            label={link.label}
            name={link.name}
            value={linkV}
            error={errors.link && touched.link}
            success={linkV.length > 0 && !errors.link}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormField
            type={next_date.type}
            label={next_date.label}
            name={next_date.name}
            value={next_dateV}
            error={errors.next_date && touched.next_date}
            success={next_dateV.length > 0 && !errors.next_date}
            InputProps={{ readOnly: isView }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Autocomplete
            options={[
              { id: 'True', label: 'Activo' },
              { id: 'False', label: 'Inactivo' },
            ]}
            value={activeValue}
            inputValue={inputActiveValue}
            onChange={(event, newValue) => {
              setActiveValue(newValue);
              setFieldValue('active', newValue.id);
            }}
            defaultValue={activeV}
            onInputChange={(event, newInputValue) => {
              setInputActiveValue(newInputValue);
            }}
            disabled={isView}
            renderInput={(params) => (
              <MDInput {...params} variant='standard' label={active.label} />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default CreateShipPartForm;
