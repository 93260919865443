import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import form from "../schemas/form";
import validations from "../schemas/validations";
import { initialValues, initialValuesFromObj } from "../schemas/initialValues";
import {
  addLogs,
  getAllDeffects,
  getTasks,
} from "../../../../Services/MainBoard";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ContentDialog from "../../../../components/ContentDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import TasksForm from '../components/TasksForm';

import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";

// Wizard page components
// import { addDeffect } from '../../../Services/MainBoard';
import { buildData } from "../../../../utils/tableData";
import { taskHeaders } from "../../../../utils/tableHeaders";
import { Pagination, Typography } from "@mui/material";
import DataTable from "../../../../components/DataTable";

import { getShipTasks, createBPtask, updateTask } from "../../../../Services/TaskService";

import { getInspections } from "../../../../Services/inspectionsService";
import { create } from "yup/lib/Reference";

const TaskList = (props) => {
  const { id: aircraftId } = useParams();
  const { formId, formField } = form;

  const [state, setState] = useState("create");

  const [inputs, setInputs] = useState();

  const [logs, setlogs] = useState(null);
  const [currentlogsPage, setCurrentlogsPage] = useState(1);
  const [totallogsPages, setTotallogsPages] = useState();
  let [TTAFperfom1, setTTAFperfom1] = useState();
  let [dats, setDats] = useState();
  let [performDate1, setPerfomDate1] = useState();
  let [performCycles1, setPerfomCycles1] = useState();
  let [correction, setCorrection] = useState();
  let [performDate, setPerfomDate] = useState();
  let [performCycles, setPerfomCycles] = useState();
  const [openTaskSelector, setOpenTaskSelector] = useState(false);
  const [openFormTask, setOpenFormTask] = useState(false);
  const [openViewBDTask, setOpenViewBDTask] = useState(false);
  let [TTAFperfom, setTTAFperfom] = useState();

  const navigate = useNavigate();

  // get search params
  const [searchparams] = useSearchParams();

  const update = (id, values) => {
    updateTask(id, values)
  }

  const handleSubmit = async (values, actions) => {
    update(dats, values)

    actions.resetForm();
    loadLogs(1);
  };

  const closeModals = () => {
    setOpenTaskSelector(false);
    setOpenViewBDTask(false);
    setOpenFormTask(false);
  };

  const addTaskModal = () => {

    setOpenTaskSelector(true);
  };

  const addFormModal = () => {
    setOpenFormTask(true);
  }

  const loadLogs = async (value) => {
    const { data } = await getShipTasks(value);
    console.log(data);
    setlogs(buildData(data.bp_tasks, taskHeaders()));
    setCurrentlogsPage(parseInt(data.current_page));
    //setTotallogsPages(data.pages);
  };

  const handleChangePageLogs = (event, value) => {
    setCurrentlogsPage(value);
    loadLogs(value);
  };

  const create = (ata, desc, cond, active) => {
    const ship = localStorage.getItem("ship");
    let act = active === '' ? true : active === 'true' ? true : false;
    createBPtask(ship, ata, desc, cond, act)

  }

  const editRegister = (task) => {
    console.log(task);
    return (
      <>

      </>
    )
  };

  useEffect(() => {
    const ship = localStorage.getItem("ship");
    loadLogs(ship);
    console.log(ship);
  }, []);

  return (
    <MDBox>
      <div>
        <ContentDialog
          open={openTaskSelector}
          title={"Crear Nueva Task"}
          closeCallback={closeModals}
          buttonText="Cancelar"
        >
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">ATTA Code:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setTTAFperfom1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Description:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setPerfomDate1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Conditional:</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => {
                  setPerfomCycles1(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <FormControl style={{ width: "25vh" }}>
              <InputLabel htmlFor="my-input">Active:</InputLabel>
              <br />
              <br />
              <select
                onChange={(e) => {
                  setCorrection(e.target.value);
                  console.log(e.target.value);
                }}>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
            </FormControl>
          </Grid>
          <br></br>

          <MDBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <MDBox mt={2}>
              <MDButton
                variant="gradient"
                color="success"
                type="submit"
                onClick={() => {
                  setTTAFperfom(TTAFperfom1);
                  setPerfomDate(performDate1);
                  setPerfomCycles(performCycles1);
                  setCorrection(correction);
                  create(TTAFperfom1, performDate1, performCycles1, correction);

                  closeModals();
                }}
              >
                Crear Task
              </MDButton>
            </MDBox>
          </MDBox>
        </ContentDialog>
      </div>
      {logs && (
        <>
          <Typography
            variant="h4"
            component="div"
            style={{ marginTop: 25, marginBottom: 15, textAlign: "center" }}
          >
            Task Manager
          </Typography>
          <MDButton
            variant="gradient"
            color="info"
            onClick={addTaskModal}
          >
            Agregar Task
          </MDButton>

          <Card>
            {logs.rows !== undefined && logs.rows.length > 0 && (
              
              <>
                <DataTable
                  //   handleSearch={handleSearch}
                  useActions
                  //useView
                  useEdit
                  useDelete
                  editAction={(data)=>{{
                    console.log(data.id)
                    setDats(data.id)
                    addFormModal()
                  }}}
                  //deleteAction={deleteRegister}
                  // useDelete
                  // useSelect
                  // selectAction={(data) => navigate(`/ships/assembly/view/${data.id}`)}

                  // deleteAction={deleteRegister}
                  table={logs}
                  showTotalEntries={false}
                  entriesPerPage={false}
                // canSearch
                />
                <MDBox ml={1}>
                  <Pagination
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                    color="info"
                    count={totallogsPages}
                    page={currentlogsPage}
                    onChange={handleChangePageLogs}
                  />{" "}
                </MDBox>{" "}
              </>
            )}

            {logs?.rows !== undefined && logs?.rows.length === 0 && (
              <Typography variant="h4" component="div" sx={{ margin: "100px" }}>
                No Existen Tasks
              </Typography>
            )}
          </Card>
        </>
      )}

      <ContentDialog
          open={openFormTask}
          title={"Formulario de Asignación"}
          closeCallback={closeModals}
          buttonText="Cancelar"
        >

      <Grid container justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} lg={10}>
          <Formik
            initialValues={
              inputs != null && inputs !== undefined
                ? initialValuesFromObj(inputs)
                : initialValues
            }
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete='off'>
                <Card>
                  <MDBox p={3}>
                    <TasksForm
                      isView={false}
                      isUpdate={false}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={3}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                    >
                      {/* </Link> */}
                      <MDButton
                        disabled={false}
                        variant='gradient'
                        color='success'
                        type='submit'
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      </ContentDialog>
    </MDBox>
  );
};

export default TaskList;
