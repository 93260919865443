import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

// Material Dashboard 2 PRO React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import DataTable from '../../components/DataTable';

import {
  getInvoices,
  getEstimations,
  deleteInvoice,
} from '../../Services/InvoicieService';
import { InvoiceHeaders } from '../../utils/tableHeaders';
import { buildData } from '../../utils/tableData';

import DeleteDialog from '../../components/DeleteDialog';

const Invoice = ({ type }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(true);

  const [registerToDetele, setRegisterToDelete] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteRegister = (register) => {
    setRegisterToDelete(register);
    setOpenDeleteDialog(true);
  };

  //Calbacks to Delete dialog
  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const successDeleteDialog = () => {
    deleteInvoice(registerToDetele.id)
      .then((resp) => {
        loadItems(currentPage, filter, valueFilter);
      })
      .catch((err) => {
        console.error(err);
      });
    setOpenDeleteDialog(false);
  };

  //Pagination
  const handlePagination = (event, value) => {
    setCurrentPage(value);
    loadItems(value, filter, valueFilter);
  };

  const loadItems = (page, filter, valueFilter) => {
    let promise = type === 'Q' ? getEstimations : getInvoices;
    promise(page, filter, valueFilter)
      .then((resp) => {
        console.log(resp.data);
        setItems(buildData(resp.data.invoices, InvoiceHeaders()));
        setCurrentPage(parseInt(resp.data.current_page));
        setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  useEffect(() => {
    loadItems(1);
  }, [type]);

  // Menu and Filters
  const [filter, setFilter] = useState();
  const [filterLabel, setFilterLabel] = useState('Filtros');
  const [valueFilter, setValueFilter] = useState();

  const filters = [
    { label: 'Subject', value: 'subject' },
    { label: 'Descripcion', value: 'description' },
    { label: 'ATTA_code', value: 'ATTA_code' },
    { label: 'Client', value: 'client__name' },
  ];

  const getFilterLabel = (value) => {
    let label;
    filters.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setValueFilter(value);
    loadItems(1, filter, value);
  };

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => {
    setMenu(null);
  };
  const handleChange = (value) => {
    setFilter(value);
    setFilterLabel(getFilterLabel(value));
    setMenu(null);
    closeMenu();
  };
  const clearFilter = () => {
    setMenu(null);
    setFilter();
    setFilterLabel('Filtros');
    loadItems(1);
    closeMenu();
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {filters.map((option) => (
        <MenuItem
          key={option.label}
          onClick={() => handleChange(option.value)}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
      {/* <MenuItem onClick={()=> handleChange('nombre')} value="nombre" >Nombre</MenuItem>
          <MenuItem onClick={()=> handleChange('correo')} value="correo" >Correo</MenuItem> */}
      <Divider sx={{ margin: '0.5rem 0' }} />
      <MenuItem onClick={clearFilter}>
        <MDTypography variant='button' color='error' fontWeight='regular'>
          Eliminar filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const editRegister = (item) => {
    console.log('edit', item);
    let route = type === 'Q' ? '/estimation/update/' : '/invoice/update/';
    window.location.replace(route.concat(item.id));
  };
  const viewRegister = (item) => {
    console.log('view', item);
    let route = type === 'Q' ? '/estimation/view/' : '/invoice/view/';
    window.location.replace(route.concat(item.id));
  };

  return (
    <div>
      <Typography variant='h4' component='div'>
        {type === 'Q' ? 'Estimate' : 'Invoices'} Consultation
      </Typography>
      <MDBox my={3}>
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          mb={2}
        >
          <Link to={type === 'Q' ? '/estimation/create' : '/invoice/create'}>
            <MDButton variant='gradient' color='info'>
              Agregar
            </MDButton>
          </Link>
          <MDBox display='flex'>
            <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color='dark'
              onClick={openMenu}
            >
              {filterLabel}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark'>
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {items.rows !== undefined && items.rows.length > 0 && (
            <>
              <DataTable
                useActions
                useView
                useDelete
                deleteAction={deleteRegister}
                viewAction={viewRegister}
                useEdit
                editAction={editRegister}
                table={items}
                showTotalEntries={false}
                entriesPerPage={false}
                handleSearch={handleSearch}
                canSearch
              />
              <MDBox ml={1}>
                <Pagination
                  sx={{ marginTop: '20px', marginBottom: '20px' }}
                  color='info'
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePagination}
                />{' '}
              </MDBox>{' '}
            </>
          )}
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
              }}
            >
              <CircularProgress color='info' size={80} />
            </Box>
          )}
          {items.rows !== undefined && items.rows.length === 0 && (
            <Typography variant='h4' component='div' sx={{ margin: '100px' }}>
              No Existen registros
            </Typography>
          )}
        </Card>
      </MDBox>
      <DeleteDialog
        open={openDeleteDialog}
        message='Esta seguro de eliminar este registro?'
        successCalback={successDeleteDialog}
        cancelCallback={closeDeleteDialog}
      />
    </div>
  );
};

export default Invoice;
