import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";


import CreateClients from "../Clients/Create/";
import Menu from "../Menu";
import ClientList from "../forms/tableData/clientTable"
import ProviderList from "../forms/tableData/ProviderTable"
import EmployeeList from "../forms/tableData/employeeTable"

import CreateSupplier from "../suppliers/Create";
import ClientsAndSuppliers from "../ClientsAndSuppliers";
import Users from "../Users";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuM from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
} from "./styles";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";

import {
  useMaterialUIController,
  setMiniSidenav,
} from "../../context";

import Sidenav from "../../module/common/Sidenav";
import routes from "../../module/common/routes/routes";

import brandWhite from "../../assets/images/logo-ct.png";
import brandDark from "../../assets/images/logo-ct-dark.png";

import { getSessionData, signOut, getRolLabel } from "../../Services/authService";
import MainInventory from "../Inventory/MainInventory";
import CreateInventoryItem from "../Inventory/MainInventory/CreateInventoryItem"
import QuerantineInventory from "../Inventory/QuerantineInventory";
import RepairInventory from "../Inventory/RepairInventory";
import ScrapInventory from "../Inventory/ScrapInventory";
import MissingExpiration from "../Inventory/MissingExpiration/MissingExpiration";
import InventoryDetail from "../Inventory/MainInventory/InventoryDetail";
import GeneralSearch from "../GeneralSearch";

import History from "../History";

import PurchaseOrder from "../PurchaseOrder"
import CreatePurchaseOrder from "../PurchaseOrder/CreatePurchaseOrder";
import CreatePurchaseOrderDetail from "../PurchaseOrder/CreatePurchaseOrderDetail";
import ReceivedList from "../PurchaseOrder/ReceivedList";

import Invoice from "../Invoice";
import InvoiceManager from "../Invoice/manager";
import CreateInvoice from "../Invoice/CreateInvoice";

import Ships from "../Ships";
import CreateShip from "../Ships/CreateShip";
import CreateShipFromMat from "../Ships/CreateShip/index2.jsx";

import Error from "../Error";
import Task from "../Task";
import CreateTask from "../Task/CreateTask";

import CreateAircraft from '../Ships/CreateAircraft';
import CreateAssembly from "../Ships/CreateAssembly";
import CreateShipPart from "../Ships/CreateShipPart";
import ClientSatisfaction from "../forms/clientSatisfaction";
import ReceptionForm from "../forms/receptionForm";
import DeliverForm from "../forms/deliverForm";
import JobDescriptionForm from "../forms/jobDescriptionForm";
import JobDescriptionList from "../forms/tableData/jobDescripTable";
import CarForm from "../forms/carForm"
import CarFormTable from "../forms/tableData/car_formTable"
import CotoForm from "../forms/logCOTO"
import CotoTable from "../forms/tableData/cotoTable"
import CotoRiskForm from "../forms/logCOTORisk"


import MainBoard from "../MainBoard";
import Board from "../MainBoard/Board";
import CreateDeffects from "../MainBoard/CreateDeffects";
import CreateADS from "../MainBoard/CreateADS";
import CreateComponents from "../MainBoard/CreateComponents";
import CreateTasks from "../MainBoard/CreateTasks";
import CreateInspectionsTemplete from "../MainBoard/CreateInspectionsTemplete";
import CreateInspectionsAircraft from "../MainBoard/CreateInspectionsAircraft";
import CreateWorkReports from "../MainBoard/CreateWorkReports";

import StaffManager from "../Staff/ListStaff/StaffManager";
import CreateStaff from "../Staff/CreateStaff";
import CertificationForm from "../forms/certificationForm";
import CertificationList from "../forms/tableData/certificationsTable";
import ClientsatisfactionListTable from "../forms/tableData/clientsatisfactionListTable";
import EmployeeCard from "../forms/employeeCard";
import PilotCreator from "../forms/pilotCreationForm";
import ShipCreator from "../forms/shipCreationForm";
import SupplierEvaluationForm from "../forms/supplierEvaluation";
import SupplierEvaluationList from "../forms/tableData/providerListTable";


import ReceptionsList from "../forms/tableData/receptionTable";
import DeliversList from "../forms/tableData/deliverTable";
import ShipsList from "../forms/tableData/shipsTable";
import PilotsList from "../forms/tableData/pilotsTable";
import ProcessDefinition from "../forms/processesForm";
import UpdateReception from "../forms/updateForms/reception";
import UpdateDeliver from "../forms/updateForms/deliver";
import Processtable from "../forms/tableData/processTable";
import SupplierQuality from "../forms/supplierQuality";
import SupplierQualityTable from "../forms/tableData/SupplierQualityTable";

import PartyForm from "../forms/partyForm"
import IssuesForm from "../forms/issuesForm"
import PartyTable from "../forms/tableData/PartyTable"
import IssuesTable from "../forms/tableData/IssuesTable"
import NotificationsView from "../forms/tableData/notifications"
import MDTypography from "../../components/MDTypography/index.js";
import { Icon } from "@mui/material";




const MainLayout = () => {
  const notification = localStorage.getItem('notification') || '';
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const [user, setUser] = useState("");
  const [rol, setRol] = useState()
  const [rolLabel, setRolLabel] = useState()
  const [controller, dispatch] = useMaterialUIController();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [openSide, setOpen] = useState(false);

  const {
    miniSidenav,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    transparentNavbar,
  } = controller;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    signOut();
    setAnchorEl(null);
    window.location.replace("/");
  };


  const getUserData = () => {
    let data = getSessionData();
    setUser(data.user_name);
    setRol(data.role)
    setRolLabel(getRolLabel(data.role))
    console.log(data)
  };

  useEffect(() => {
    getUserData();
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (!onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const openNoti = Boolean(anchorElNoti);

  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  return (
    <div className="main-layout" >
      <AppBar position="absolute" color="inherit" 
        sx={(theme) => navbar(theme, { transparentNavbar, darkMode })}>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

          </Typography>
          <Button
            //startIcon={<AccountCircleIcon />}
            color="inherit"
            id="basic-button2"
            //aria-controls={openNoti ? "basic-menu" : undefined}
            aria-haspopup="true"
            //aria-expanded={openNoti ? "true" : undefined}
            onClick={() => {setMiniSidenav(dispatch, openSide); setOpen(!openSide)}}
          >
            <span className="notificacion-icon">menu</span>
          </Button>
          <Button
            //startIcon={<AccountCircleIcon />}
            color="inherit"
            id="basic-button2"
            aria-controls={openNoti ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openNoti ? "true" : undefined}
            onClick={handleClickNoti}
          >
            <span className="notificacion-icon">🔔</span>
          </Button>
          <Button
            startIcon={<AccountCircleIcon />}
            color="inherit"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {user || "USER"} - ({rolLabel || "Rol"})
          </Button>
        </Toolbar>
      </AppBar>
      <MenuM
        anchorEl={anchorElNoti}
        open={openNoti}
        onClose={handleCloseNoti}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {
          notification !== 'undefined' && JSON.parse(notification).length > 0 && (
            <div>
              <h2>Notificaciones</h2>
              <ul>
                {
                  notification && JSON.parse(notification).map((noti, key) => (
                    <li key={key}>{noti.name}</li>
                  ))
                }
              </ul>
            </div>
          )}

      </MenuM>
      <MenuM
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(rol === 'A') && <Link to="/users" ><MenuItem >Administrar Usuarios</MenuItem></Link>}
        <MenuItem onClick={logOut}>Cerrar Sesion</MenuItem>
      </MenuM>
      <>
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={() => setMiniSidenav(dispatch, true)}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <Sidenav
          open={openSide}
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="FN Documents Manager"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      </>
      <MDBox sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        pt: 8,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}>
        <Routes>
          <Route path="/*" element={<Menu />} />

          {["A"].includes(rol) &&
            <Route path="/users" element={<Users />} />
          }
          {!["A"].includes(rol) &&
            <Route path="/users" element={<Error />} />
          }

          {["A", "U", "I"].includes(rol) &&
            <>
              <Route path="/inventory/main" element={<MainInventory />} />
              <Route path="/inventory/main/add" element={<CreateInventoryItem action="add" />} />
              <Route path="/inventory/main/update/:id" element={<CreateInventoryItem action="update" />} />
              <Route path="/inventory/main/view/:id" element={<CreateInventoryItem action="view" />} />
              <Route path="/inventory/detail/add/:part" element={<InventoryDetail action="add" inventory />} />
              <Route path="/inventory/detail/update/:id" element={<InventoryDetail action="update" />} />
              <Route path="/inventory/detail/view/:id" element={<InventoryDetail action="view" />} />
              <Route path="/inventory/quarantine/add" element={<InventoryDetail action="add" quarentine />} />
              <Route path="/inventory/quarantine/update/:id" element={<InventoryDetail action="update" />} />
              <Route path="/inventory/quarantine/view/:id" element={<InventoryDetail action="view" />} />
              <Route path="/inventory/quarantine" element={<QuerantineInventory />} />
              <Route path="/inventory/repair" element={<RepairInventory />} />
              <Route path="/inventory/scrap" element={<ScrapInventory />} />
              <Route path="/inventory/missing_expitarion" element={<MissingExpiration />} />
              <Route path="/inventory/missing_expitarion/update/:id" element={<InventoryDetail action="update" />} />
              <Route path="/inventory/missing_expitarion/view/:id" element={<InventoryDetail action="view" />} />
              <Route path="/inventory/history" element={<History />} />
              <Route path="/inventory/general_search" element={<GeneralSearch />} />
            </>
          }
          {!["A", "U", "I"].includes(rol) &&
            <Route path="/inventory/*" element={<Error />} />
          }

          {["A", "U", "S"].includes(rol) &&
            <>
              <Route path="/ships/list" element={<Ships />} />
              <Route path="/ships/create" element={<CreateShip action="add" />} />
              <Route path="/ships/aircraft/create-from-mat" element={<CreateShipFromMat action="add" />} />

              <Route path="/ships/update/:id" element={<ShipCreator action="update" />} />
              <Route path="/ships/view/:id" element={<ShipCreator action="view" />} />
              {/* Aircrafts */}
              <Route path="/ships/aircraft/create" element={<CreateAircraft action="add" />} />
              <Route path="/ships/aircraft/update/:id" element={<CreateAircraft action="update" />} />
              <Route path="/ships/aircraft/view/:id" element={<CreateAircraft action="view" />} />

              {/* Ship parts */}
              <Route path="/ships/shipparts/create" element={<CreateShipPart action="add" />} />
              <Route path="/ships/shipparts/update/:id" element={<CreateShipPart action="update" />} />
              <Route path="/ships/shipparts/view/:id" element={<CreateShipPart action="view" />} />

              {/* Assemby */}
              <Route path="/ships/assembly/create" element={<CreateAssembly action="add" />} />
              <Route path="/ships/assembly/update/:id" element={<CreateAssembly action="update" />} />
              <Route path="/ships/assembly/view/:id" element={<CreateAssembly action="view" />} />

              {/* Mainboard */}
              <Route path="/mainboard" element={<MainBoard />} />
              <Route path="/mainboard/:id" element={<Board />} />

              {/* Aircrafts MainBoard */}
              <Route path="/mainboard/aircraft/:id" element={<Board isAircraft={true} />} />



              <Route path="/mainboard/:id/deffects" element={<CreateDeffects />} />

              <Route path="/mainboard/:id/ads" element={<CreateADS />} />
              <Route path="/mainboard/:id/components" element={<CreateComponents />} />
              <Route path="/mainboard/:id/inspections-templete" element={<CreateInspectionsTemplete />} />
              <Route path="/mainboard/:id/inspections-aircraft" element={<CreateInspectionsAircraft />} />
              <Route path="/mainboard/:id/work-reports" element={<CreateWorkReports />} />
              <Route path="/mainboard/:id/tasks" element={<CreateTasks />} />


              {/* Staff Manager*/}
              <Route path="/notifications" element={<NotificationsView />} />
              <Route path="/staff/list" element={<StaffManager />} />
              <Route path="/staff/list/:id" element={<Board />} />
              <Route path="/ClientSatisfactionList" element={<ClientsatisfactionListTable />} />
              <Route path="/ClientSatisfaction" element={<ClientSatisfaction />} />
              <Route path="/ClientSatisfaction/view/:id" element={<ClientSatisfaction action="view" />} />
              <Route path="/reception" element={<ReceptionForm action="add" />} />
              <Route path="/reception/view/:id" element={<ReceptionForm action="view" />} />
              <Route path="/reception/update/:id" element={<ReceptionForm action="update" />} />
              <Route path="/deliver" element={<DeliverForm action={"add"} />} />
              <Route path="/deliver/view/:id" element={<DeliverForm action={"view"} />} />
              <Route path="/job_description" element={<JobDescriptionForm action="add" />} />
              <Route path="/job_description/view/:id" element={<JobDescriptionForm action="view" />} />
              <Route path="/job_description/update/:id" element={<JobDescriptionForm action="update" />} />
              <Route path="/job_desc_list" element={<JobDescriptionList />} />
              <Route path="/certifications_list" element={<CertificationList />} />
              <Route path="/certifications" element={<CertificationForm action="add" />} />
              <Route path="/certifications/view/:id" element={<CertificationForm action="view" />} />
              <Route path="/certifications/update/:id" element={<CertificationForm action="update" />} />
              <Route path="/employee_card" element={<EmployeeCard action="add" />} />
              <Route path="/employee_card/view/:id" element={<EmployeeCard action="view" />} />
              <Route path="/employee_card/update/:id" element={<EmployeeCard action="update" />} />
              <Route path="/pilots_add" element={<PilotCreator action="add" />} />
              <Route path="/ships_add" element={<ShipCreator action="add" />} />
              <Route path="/providers_evl" element={<SupplierEvaluationForm />} />
              <Route path="/providers_evl/view/:id" element={<SupplierEvaluationForm action="view" />} />
              <Route path="/providers_evl_list" element={<SupplierEvaluationList />} />
              <Route path="/processes" element={<ProcessDefinition action="add" />} />
              <Route path="/processes/view/:id" element={<ProcessDefinition action="view" />} />
              <Route path="/processes/update/:id" element={<ProcessDefinition action="update" />} />
              <Route path="/processes_list" element={<Processtable />} />
              <Route path="/car_form" element={<CarForm action="add" />} />
              <Route path="/car_form/view/:id" element={<CarForm action="view" />} />
              <Route path="/car_form/update/:id" element={<CarForm action="update" />} />
              <Route path="/car_form_table" element={<CarFormTable />} />


              <Route path="/party_form/view/:id" element={<PartyForm action="view" />} />
              <Route path="/party_form/update/:id" element={<PartyForm action="update" />} />
              <Route path="/party_form" element={<PartyForm action="add" />} />
              <Route path="/party_table" element={<PartyTable />} />

              <Route path="/issues_form/view/:id" element={<IssuesForm action="view" />} />
              <Route path="/issues_form/update/:id" element={<IssuesForm action="update" />} />
              <Route path="/issues_form" element={<IssuesForm action="add" />} />
              <Route path="/issues_table" element={<IssuesTable />} />

              <Route path="/coto_form/view/:id" element={<CotoForm action="view" />} />
              <Route path="/coto_form/update/:id" element={<CotoForm action="update" />} />
              <Route path="/coto_form" element={<CotoForm action="add" />} />
              <Route path="/coto_table" element={<CotoTable />} />
              <Route path="/cotoRisk_form" element={<CotoRiskForm action="add" />} />
              <Route path="/cotoRisk_form/update/:id" element={<CotoRiskForm action="update" />} />
              <Route path="/cotoRisk_form/view/:id" element={<CotoRiskForm action="view" />} />
              <Route path="/employee_list" element={<EmployeeList />} />
              <Route path="/SupplierQuality" element={<SupplierQuality action="add" />} />
              <Route path="/SupplierQuality/update/:id" element={<SupplierQuality action="update" />} />
              <Route path="/SupplierQuality/view/:id" element={<SupplierQuality action="view" />} />
              <Route path="/SupplierQuality_List" element={<SupplierQualityTable />} />


              <Route path="/staff/create" element={<CreateStaff action="add" />} />
              <Route path="/staff/update/:id" element={<CreateStaff action="update" />} />
              {/*<Route  path="/mainboard/:id/logs" element={<CreateLogs />} />*/}

              <Route path="/reception_data" element={<ReceptionsList />} />
              <Route path="/deliver_table" element={<DeliversList />} />
              <Route path="/ships_list" element={<ShipsList />} />
              <Route path="/pilots_list" element={<PilotsList />} />
              <Route path="/client_list" element={<ClientList />} />
              <Route path="/provider_list" element={<ProviderList />} />






              <Route path="/reception/update/" element={<UpdateReception action='update' />} />
              <Route path="/deliver/update/" element={<UpdateDeliver action='update' />} />

            </>
          }
          {!["A", "U", "S"].includes(rol) &&
            <Route path="/ships/*" element={<Error />} />
          }

          {["A", "U"].includes(rol) &&
            <>
              <Route path="/pilot/view/:id" element={<PilotCreator action="view" />} />
              <Route path="/pilot/update/:id" element={<PilotCreator action="update" />} />
              <Route path="/clandsu/list" element={<ClientsAndSuppliers />} />
              <Route path="/clients/add" element={<CreateClients action="add" />} />
              <Route path="/clients/update/:id" element={<CreateClients action="update" />} />
              <Route path="/clients/view/:id" element={<CreateClients action="view" />} />
              <Route path="/suppliers/add" element={<CreateSupplier action="add" />} />
              <Route path="/suppliers/update/:id" element={<CreateSupplier action="update" />} />
              <Route path="/suppliers/view/:id" element={<CreateSupplier action="view" />} />

              <Route path="/purchase_order/list" element={<PurchaseOrder />} />
              <Route path="/purchase_order/create" element={<CreatePurchaseOrder action="add" />} />
              <Route path="/purchase_order/update/:id" element={<CreatePurchaseOrder action="update" />} />
              <Route path="/purchase_order/view/:id" element={<CreatePurchaseOrder action="view" />} />
              <Route path="/purchase_order/detail/pending" element={<ReceivedList />} />
              <Route path="/purchase_order/detail/create/:id" element={<CreatePurchaseOrderDetail action="add" />} />
              <Route path="/purchase_order/detail/update/:po/:id" element={<CreatePurchaseOrderDetail action="update" />} />

              <Route path="/invoice/list" element={<Invoice type="I" />} />
              <Route path="/invoice/manager" element={<InvoiceManager type="I" />} />
              <Route path="/invoice/create" element={<CreateInvoice type="I" action="add" />} />
              <Route path="/invoice/update/:id" element={<CreateInvoice type="I" action="update" />} />
              <Route path="/invoice/view/:id" element={<CreateInvoice type="I" action="view" />} />

              <Route path="/estimation/list" element={<Invoice type="Q" />} />
              <Route path="/estimation/create" element={<CreateInvoice type="Q" action="add" />} />
              <Route path="/estimation/update/:id" element={<CreateInvoice type="Q" action="update" />} />
              <Route path="/estimation/view/:id" element={<CreateInvoice type="Q" action="view" />} />

              <Route path="/task/list" element={<Task />} />
              <Route path="/task/create" element={<CreateTask action="add" />} />
              <Route path="/task/view/:id" element={<CreateTask action="view" />} />
              <Route path="/task/update/:id" element={<CreateTask action="update" />} />
            </>
          }
          {!["A", "U"].includes(rol) &&
            <>
              <Route path="/clandsu/*" element={<Error />} />
              <Route path="/clients/*" element={<Error />} />
              <Route path="/suppliers/*" element={<Error />} />

              <Route path="/purchase_order/*" element={<Error />} />

              <Route path="/invoice/*" element={<Error />} />

              <Route path="/estimation/*" element={<Error />} />

              <Route path="/task/*" element={<Error />} />
            </>
          }

        </Routes>
      </MDBox>

    </div>
  );
};

export default MainLayout;
