import checkout from "./form";

const {
    formField: {
        inventory_detail,
        instalation_date,
        part_number,
        active,
        link,
        next_date,
    }
} = checkout;

export const initialValues = {
[inventory_detail.name] : "",
[instalation_date.name] : "",
[part_number.name] : "",
[active.name] : "",
[link.name] : "",
[next_date.name] : "",
}

export const initialValuesFromObj = (obj) => {
    return {
        [inventory_detail.name] : obj.inventory_detail,
        [instalation_date.name] : obj.instalation_date,
        [part_number.name] : obj.part_number,
        [active.name] : obj.active,
        [link.name] : obj.link,
        [next_date.name] : obj.next_date,
    }
}
