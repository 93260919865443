import React, { useEffect, useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "../../components/MDButton";
import FormLabel from "@mui/material/FormLabel";
import CoverLayout from "../forms/formLayout";

import { getClients, getClient } from "../../Services/PilotService";
import { getShips } from "../../Services/formServices";
import { buildData } from "../../utils/tableData";
import { shipsHeaders } from "../../utils/tableHeaders";

// Images
import bgImage from "../../assets/images/fn.jpg";
import "./form.css";
import Loader from '../../components/Loader';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



function PilotCreator({ action }) {
  const params = useParams();
  const [first_name, setFirstName] = useState();
  const [second_name, setSecondName] = useState();
  const [first_last_name, setFirstLastName] = useState();
  const [second_last_name, setSecondLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [vehicle, setVehicle] = useState();
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem('Token') || '';
  const [id, setId] = useState(0)
  const [inputs, setInputs] = useState();
  const [optionsV, setOptions] = useState([]);
  const [flag, setFlag] = useState(false);




  function getPilot() {
    console.log(params, 'params')
    if (params.id != null) {
      console.log(params, 'params in if')
      setId(params.id)
      getClient(params.id).then(resp => {
        console.log(resp.data)
        setInputs(resp.data)
        var data = resp.data.vehicles.map(index => {
          optionsV.push({
            label: index.registration,
            value: index.id
          })
        })
        console.log(optionsV, 'optionsV')
        //setOptions(data)
      }).catch(err => {
        console.error(err);
      });
    }
  }

  useEffect(() => {
    loadShips();
    //console.log(action, params, 'data')
    (action === 'view' || action === 'update') && getPilot()
    const timer = setTimeout(() => setFlag(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const createPilot = () => {
    let ver = null
    vehicle.map(index => {
      ver = ver + index.value + ','
      console.log(ver)
    })
    const pilotdata = new FormData();
    pilotdata.append('first_name', first_name);
    //pilotdata.append('second_name', second_name);
    second_name && pilotdata.append('second_name', second_name);
    pilotdata.append('first_last_name', first_last_name);
    //pilotdata.append('second_last_name', second_last_name);
    second_last_name && pilotdata.append('second_last_name', second_last_name);
    //pilotdata.append('email', email);
    email && pilotdata.append('email', email);
    //pilotdata.append('phone', phone);
    phone && pilotdata.append('phone', phone);
    pilotdata.append('vehicles', ver);
    // pilotdata.append('is_active', true);
    // pilotdata.append('is_staff', true);
    // pilotdata.append('change_pwd', false);

    axios.post(
      'https://fn.devpackgroup.com/api/v1/pilot/',
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Piloto Creado Exitosamente")
      window.location.replace("/pilots_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear piloto")
    }.bind(this))
  };

  const updatePilot = () => {
    let ver = null
    vehicle && vehicle.map(index => {
      ver = ver + index.value + ','
      console.log(ver)
    })
    const pilotdata = new FormData();
    first_name && pilotdata.append('first_name', first_name);
    second_name && pilotdata.append('second_name', second_name);
    first_last_name && pilotdata.append('first_last_name', first_last_name);
    second_last_name && pilotdata.append('second_last_name', second_last_name);
    email && pilotdata.append('email', email);
    phone && pilotdata.append('phone', phone);
    vehicle && pilotdata.append('vehicles', ver);
    // pilotdata.append('is_active', true);
    // pilotdata.append('is_staff', true);
    // pilotdata.append('change_pwd', false);

    axios.patch(
      `https://fn.devpackgroup.com/api/v1/pilot/${params.id}/`,
      pilotdata,
      {
        headers: { "Authorization": "Token " + userToken }
      },
    ).then(function (response) {
      console.log(response.data)
      alert("Piloto Creado Exitosamente")
      window.location.replace("/pilots_list");
    }.bind(this)).catch(function (error) {
      console.log(error)
      alert(error.message)
      alert("Error al crear piloto")
    }.bind(this))
  };

  const loadShips = () => {
    getShips()
      .then((resp) => {
        console.log(resp.data);
        var data = resp.data.map(index => {
          return {
            label: index.registration,
            value: index.id
          }
        })
        setShips(data);
        // setCurrentPage(parseInt(resp.data.current_page));
        // setTotalPages(resp.data.pages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      {
        !flag ?
          <Loader />
          :
          <>
            <Card
            
            >
              <MDBox

                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {action === 'view' && 'Vista de Piloto'}
                  {action === 'update' && 'Editar Piloto'}
                  {action === 'add' && 'Creacion de Pilotos'}
                </MDTypography>
              </MDBox>
            </Card>
            <br />
            <MDBox pt={4} pb={3} px={3}>
              <div style={{ display: 'flex' }}>

                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Primer Nombre: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Nombre:"
                      variant="standard"
                      fullWidth
                      placeholder="Nombre"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.first_name : null}
                      value={(action === 'view') && inputs ? inputs.first_name : null}
                      onChange={(e) => setFirstName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Nombre:"
                      variant="standard"
                      fullWidth
                      placeholder="Nombre"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setFirstName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Segundo Nombre: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Nombre:"
                      variant="standard"
                      fullWidth
                      placeholder="Nombre"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.second_name : null}
                      value={(action === 'view') && inputs ? inputs.second_name : null}
                      onChange={(e) => setSecondName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Nombre:"
                      variant="standard"
                      fullWidth
                      placeholder="Nombre"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setSecondName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Primer Apellido: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Apellido:"
                      variant="standard"
                      fullWidth
                      placeholder="Apellido"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.first_last_name : null}
                      value={(action === 'view') && inputs ? inputs.first_last_name : null}
                      onChange={(e) => setFirstLastName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Apellido:"
                      variant="standard"
                      fullWidth
                      placeholder="Apellido"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setFirstLastName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <MDBox width={'30%'} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Segundo Apellido: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Apellido:"
                      variant="standard"
                      fullWidth
                      placeholder="Apellido"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.second_last_name : null}
                      value={(action === 'view') && inputs ? inputs.second_last_name : null}
                      onChange={(e) => setSecondLastName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Apellido:"
                      variant="standard"
                      fullWidth
                      placeholder="Apellido"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setSecondLastName(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Email: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    <MDInput
                      type="text"
                      label="Email:"
                      variant="standard"
                      fullWidth
                      placeholder="Email"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={(action === 'update') && inputs ? inputs.email : null}
                      value={(action === 'view') && inputs ? inputs.email : null}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&
                    <MDInput
                      type="text"
                      label="Email:"
                      variant="standard"
                      fullWidth
                      placeholder="Email"
                      InputLabelProps={{ shrink: true }}
                      //defaultValue={(action === 'update') && inputs ? inputs.registration: null }
                      //value={(action === 'view') && inputs ? inputs.registration: null }
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>
                <br />
                <MDBox width={'30%'} ml={6} mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Telefono: </p>
                  <br />
                  {
                    (action != 'add' && inputs) &&
                    < PhoneInput
                      placeholder="Enter phone number"
                      value={(action === 'update' || action === 'view') && inputs ? inputs.phone : null}
                      onChange={setPhone}
                      defaultCountry="GT"
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                  {
                    (action === 'add') &&

                    < PhoneInput
                      placeholder="Enter phone number"
                      value={(action === 'update' || action === 'view') && inputs ? inputs.phone : null}
                      onChange={setPhone}
                      defaultCountry="GT"
                      InputProps={{ readOnly: action === 'view' ? true : false, }}
                    />
                  }
                </MDBox>

              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              {
                <MDBox width={'30%'} mr={6}  mb={2}>
                  <p style={{ fontSize: "2vh", textAlign: "justify" }}> Nave: </p>
                  <br />
                  {(inputs && flag) &&
                    (action === 'view' || action === 'update') &&
                    (inputs) &&
                    <Select
                      placeholder="Nave..."
                      options={ships}
                      isMulti
                      isDisabled={action === 'view' ? true : false}
                      defaultValue={(action === 'view' || action === 'update') && (optionsV.length > 0 && inputs) && optionsV}
                      onChange={(e) => {
                        setVehicle(e)
                      }}
                    />

                  }
                  
                  {action === 'add' &&
                    <Select
                      placeholder="Nave..."
                      options={ships}
                      isMulti
                      //isDisabled={action === 'view' ? true : false}
                      //defaultValue={(action === 'view' || action === 'update') ? optionsV : null}
                      onChange={(e) => {
                        //console.log(e)
                        setVehicle(e)
                      }}
                    />}

                </MDBox>
              }

                </div>
              <br />

              <MDButton
                variant='gradient'
                color='error'
                style={{ marginRight: '10px' }}
                sx={{ height: '100%' }}
                onClick={() => window.location.replace("/pilots_list")}
              >
                Cancelar
              </MDButton>
              {
                action !== 'view' &&
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => action === 'add' ? createPilot() : updatePilot()}
                >
                  {action === 'add' && 'Create Pilot'}
                  {action === 'update' && 'Edit Pilot'}


                </MDButton>
              }
            </MDBox>
          </>
      }
    </>
  );
}
export default PilotCreator;
