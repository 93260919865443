const form = {
  formId: 'new-shippart-form',
  formField: {
    inventory_detail: {
      name: 'inventory_detail',
      type: 'number',
      label: 'Inventory Detail',
      errorMsg: 'Campo requerido',
    },
    instalation_date: {
      name: 'instalation_date',
      type: 'date',
      label: 'Instalation Date',
      errorMsg: 'Campo requerido',
    },
    part_number: {
      name: 'part_number',
      type: 'number',
      label: 'Part Number',
      errorMsg: 'Campo requerido',
    },
    active: {
      name: 'active',
      label: 'Status',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    link: {
      name: 'link',
      label: 'Link',
      type: 'text',
      errorMsg: 'Campo requerido',
    },
    next_date: {
      name: 'next_date',
      type: 'date',
      label: 'Next Date',
      errorMsg: 'Campo requerido',
    },
  },
};

export default form;
