const form = {
    formId: "new-ship-form",
    formField: {
        work_card: {
            name: "work_card",
            label:"Work Card",
            type:"text",
            errorMsg:"Campo requerido"
        },
        ata: {
            name: "ata",
            label:"ATTA",
            type:"text",
            errorMsg:"Campo requerido"
        },
        ata_sect: {
            name: "ata_sect",
            label:"ATTA Sect",
            type:"text",
            errorMsg:"Campo requerido"
        },

        task_number: {
            name: "task_number",
            label:"Task Number",
            type:"text",
            errorMsg:"Campo requerido"
        },
        description: {
            name: "description",
            label:"Description",
            type:"text",
            errorMsg:"Campo requerido"
        },
        remarks: {
            name: "remarks",
            label:"Remarks",
            type:"text",
            errorMsg:"Campo requerido"
        },

        mod: {
            name: "mod",
            label:"Mod",
            type:"text",
            errorMsg:"Campo requerido"
        },
        documentation: {
            name: "documentation",
            label:"Documentation",
            type:"text",
            errorMsg:"Campo requerido"
        },
        applicability: {
            name: "applicability",
            label:"Applicability",
            type:"text",
            errorMsg:"Campo requerido"
        },

        interval: {
            name: "interval",
            label:"Interval",
            type:"text",
            errorMsg:"Campo requerido"
        },
        margin: {
            name: "margin",
            label:"Margin",
            type:"text",
            errorMsg:"Campo requerido"
        },
        mpn: {
            name: "mpn",
            label:"MPN",
            type:"text",
            errorMsg:"Campo requerido"
        },

        pn: {
            name: "pn",
            label:"PN",
            type:"text",
            errorMsg:"Campo requerido"
        },
        limit_type: {
            name: "limit_type",
            label:"Limit Type",
            type:"text",
            errorMsg:"Campo requerido"
        },
        revision: {
            name: "revision",
            label:"Revision",
            type:"text",
            errorMsg:"Campo requerido"
        },

        ship: {
            name: "ship",
            label:"Aircraft",
            type:"text",
            errorMsg:"Campo requerido"
        },

        employee: {
            name: "employee",
            label:"Employee",
            type:"text",
            errorMsg:"Campo requerido"
        },
        revision_employee: {
            name: "revision_employee",
            label:"Revision Employee",
            type:"text",
            errorMsg:"Campo requerido"
        },
        
    },
}

export default form;